import React from 'react';

const PMessageIcon= ({color, width, height, className = null})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height}
            fill="none"
            viewBox="0 0 18 18"
            className={className}
        >
            <path 
                fill={color}
                fillRule="evenodd" 
                d="M0 9c0-4.975 4.026-9 9-9 4.975 0 9 4.026 9 9 0 4.975-4.026 9-9 9-1.579 0-3.12-.412-4.48-1.194l-3.601 1.16c-.254.082-.528.013-.713-.172-.186-.186-.253-.462-.172-.713l1.16-3.6C.412 12.12 0 10.578 0 9zm9.45 1.821c.746 0 1.35-.604 1.35-1.35 0-.746-.604-1.35-1.35-1.35-.746 0-1.35.604-1.35 1.35 0 .745.604 1.35 1.35 1.35zm5.4 0c.746 0 1.35-.604 1.35-1.35 0-.746-.604-1.35-1.35-1.35-.746 0-1.35.604-1.35 1.35 0 .745.604 1.35 1.35 1.35zm-10.8 0c.746 0 1.35-.604 1.35-1.35 0-.746-.604-1.35-1.35-1.35-.745 0-1.35.604-1.35 1.35 0 .745.605 1.35 1.35 1.35z" 
                clipRule="evenodd" 
                opacity="1"
            />
        </svg>
    )
}
export default PMessageIcon;
