import React from 'react';

const BellezaIcon= ({color, width, height, className = null})=>{
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path fill={color}
                  d="M23.92,10.3,20.16,4.77a6.26,6.26,0,0,0-7.39-2.32L1.3,6.84A2,2,0,0,0,.12,9.39a1.78,1.78,0,0,0,2.25,1.1l4.7-1.61a.47.47,0,0,0,.29-.59A.46.46,0,0,0,6.76,8L2.07,9.6A.85.85,0,0,1,1,9.08a1.07,1.07,0,0,1,.63-1.36L13.11,3.33a5.28,5.28,0,0,1,6.27,2l2.31,3.39L17.54,5.77a3.35,3.35,0,0,0-3-.43L10.48,6.73a.47.47,0,0,0-.3.6.48.48,0,0,0,.6.29l4.07-1.39A2.39,2.39,0,0,1,17,6.54L23.26,11a.53.53,0,0,0,.27.08.46.46,0,0,0,.33-.13A.47.47,0,0,0,23.92,10.3Z"/>
            <path fill={color}
                  d="M9.18,8.11a.47.47,0,0,0-.33-.8.45.45,0,0,0-.33.14.46.46,0,0,0,0,.66.45.45,0,0,0,.33.14A.47.47,0,0,0,9.18,8.11Z"/>
            <path fill={color}
                  d="M9.41,19.55a1.92,1.92,0,0,0,.41.05,2,2,0,0,0,1-.3,1.9,1.9,0,0,0-.61-3.46A1.91,1.91,0,0,0,8,17.28a1.84,1.84,0,0,0,.26,1.43A1.87,1.87,0,0,0,9.41,19.55Zm-.53-2.06a.92.92,0,0,1,.42-.61,1,1,0,0,1,.52-.15l.21,0a1,1,0,0,1,.73,1.15,1,1,0,0,1-.42.61.94.94,0,0,1-.73.12.9.9,0,0,1-.6-.42A.92.92,0,0,1,8.88,17.49Z"/>
            <path fill={color}
                  d="M19,10.33a.46.46,0,0,0-.48-.07,11.4,11.4,0,0,1-2.81.87,3.78,3.78,0,0,0,0-1,.46.46,0,0,0-.52-.4.47.47,0,0,0-.41.52,3.08,3.08,0,0,1,0,1c-.36,0-.74.07-1.17.09a3.83,3.83,0,0,0,0-1.31.47.47,0,0,0-.52-.41.48.48,0,0,0-.41.53,2.88,2.88,0,0,1-.09,1.24l-.84,0a3.87,3.87,0,0,0-.07-1.88.46.46,0,0,0-.57-.32.47.47,0,0,0-.33.58,3,3,0,0,1,0,1.64H9.1a7,7,0,0,0-6.36,3.9,12.23,12.23,0,0,0-1.32,3.84.48.48,0,0,0,.27.49.47.47,0,0,0,.62-.23,10.75,10.75,0,0,1,2-2.75A7.92,7.92,0,0,1,6.71,15,4,4,0,0,0,5.8,16.8a4.1,4.1,0,0,0,.54,3.11,4,4,0,0,0,1,1.07,17.21,17.21,0,0,1-2.44-.37.47.47,0,1,0-.2.92A17.23,17.23,0,0,0,8.34,22a10.29,10.29,0,0,0,5.84-1.65,10.48,10.48,0,0,0,3.54-4.21,10.58,10.58,0,0,0,.82-2.38v0l.65-2.9A.47.47,0,0,0,19,10.33Zm-1,1.13-.4,1.79a12.8,12.8,0,0,1-3.37.53,4.16,4.16,0,0,0,1.2-1.67A12.69,12.69,0,0,0,18.08,11.46Zm-3.67.77a3.05,3.05,0,0,1-2.22,1.48l-.09,0a4,4,0,0,0,.77-.76,3.8,3.8,0,0,0,.41-.66Zm-2.25.12a.1.1,0,0,1,0,.05,3,3,0,0,1-1.39,1,4.51,4.51,0,0,0,.64-.85l.1-.2Zm-8.55,3.7c-.13.13-.26.26-.37.39a6.36,6.36,0,0,1,.32-.64,6.05,6.05,0,0,1,5.53-3.42h1.29a3.05,3.05,0,0,1-1.63,1.16A8,8,0,0,0,3.61,16.05Zm3.1.95a3.19,3.19,0,0,1,3.11-2.49,2.71,2.71,0,0,1,.69.08A3.18,3.18,0,1,1,6.71,17Zm7,2.53-.23.14a3.61,3.61,0,0,0,.41-1.08,4.11,4.11,0,0,0-1.17-3.87,17.14,17.14,0,0,0,4.74-.41A9.87,9.87,0,0,1,13.66,19.53Z"/>
            <path fill={color}
                  d="M3.12,20.2a.45.45,0,0,0-.33.14.46.46,0,0,0,0,.66.45.45,0,0,0,.33.14.47.47,0,0,0,0-.94Z"/>
        </svg>
    )
}
export default BellezaIcon;
