import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import Resume from './Resume';
import Graph from './Graph';
import { Circle } from "react-preloaders";
import './style.css';
import Toolboox from "../../Toolbox/Toolbox";
import Promise from "bluebird";

class Ventas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            averageEarnings: 0,
            periodEarninNews: [],
            periodEarninRenews: [],
            periodEarninCoins: [],
            superTotal: {},
            subtotal: [],
            periodEarningDays: [],
            onlyModel: "2",
            dataset: [],
            loadingEarning: true,
            incomes: {},
            lastUpdate: null,
        }
    }

    componentDidMount() {
        this.loadEarningPeriodCached(this.state.onlyModel);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.onlyModel !== this.state.onlyModel) {
            this.loadEarningPeriodCached(this.state.onlyModel, false);
        } else if(
            prevProps.period.start !== this.props.period.start
            || prevProps.period.end !== this.props.period.end
        ) {
            this.loadEarningPeriodCached(this.state.onlyModel);
        }
    }


    changePorcentage = (e, option) => {
        this.setState({onlyModel : option})
    }

    loadEarningPeriodCached = async (onlyModel, getData = true) =>{
        let start = this.props.period.start;
        let end = this.props.period.end;
        this.setState({
            averageEarnings: 0,
            periodEarninNews: [],
            periodEarninRenews: [],
            periodEarninCoins: [],
            superTotal: {},
            subtotal: [],
            loadingEarning: true,
        });

        let incomes = {};
        if (getData || !this.state.incomes) {
            incomes = await axios.get("/api/v2/analytics/earning/model/cached", {
                params: {
                    firstInterval: start,
                    lastInterval: end
                },
            })
                .then(async res => {
                    if (!res.data) {
                        this.setState({
                            loadingEarning: false
                        });
                        return false;
                    }
                    return res.data;
                }).catch(e => {
                    this.setState({
                        loadingEarning: false
                    })
                });
        } else {
            incomes = this.state.incomes;
        }


        if (!incomes ||
            (!incomes.earningSum ||
            (incomes.earnings &&
                !incomes.earnings.length)
            )) return false;

        const { earningSum, earnings, modelFee, customAdjustment } = incomes;

        let {lastUpdate} = incomes;
        if (lastUpdate && moment(lastUpdate).isSameOrBefore(end, 'day'))
            lastUpdate = moment(lastUpdate).format("MMM D, HH:mm");
        else {
            lastUpdate = null;
        }
        this.setState({
            incomes,
            lastUpdate
        });

        const dates = earnings.map(day => {
            return moment(day.transaction_date).format("MMM D");
        });
        const totalNewsByDay = [];
        const totalRenewsByDay = [];
        const totalCoinsByDay = [];
        const supertotalByDay = [];
        const subTotalByDay = [];
        let firstSaturday = false;
        let havePayPeriod = false;

        earnings.map(day => {
            /*
            if have pay period
            sortDate checkbox it activates
            */
            let date = moment(day.transaction_date).day();
            if (date === 6 && !firstSaturday) {
                firstSaturday = true;
            }
            if (date === 5 && firstSaturday && !havePayPeriod) {
                havePayPeriod = true;
            }
            let fee = 1;

            if (onlyModel === "2") {
                fee = day.model_fee / 100;
            }

            totalNewsByDay.push(day.totalNews * fee);
            totalRenewsByDay.push(day.totalRenews * fee);
            totalCoinsByDay.push(day.totalCoins * fee);
            subTotalByDay.push(day.income * fee);
            supertotalByDay.push(day.supertotal * fee);
        });
        let adjustment = 0;
        let dataSales = {};
        if (onlyModel === "2") {
            dataSales = {...earningSum.model};
            if (customAdjustment) {
                adjustment = customAdjustment.only_model
                    ? (customAdjustment.monto || 0)
                    : (customAdjustment.monto || 0) * modelFee
                dataSales.adjustments += adjustment;
            }
        }
        else {
            dataSales = {...earningSum.totalIncomes};
            if (customAdjustment) {
                adjustment = (customAdjustment.monto || 0)
                dataSales.adjustments += adjustment;
            }
        }

        const average = (
            parseFloat(dataSales.supertotal) / earnings.length
        ).toFixed(2);


        let fees = Toolboox.round(dataSales.chargeback) +
            Toolboox.round(dataSales.totalRefunds) +
            Toolboox.round(dataSales.adjustments) - (
                Toolboox.round(dataSales.processFee) +
                Toolboox.round(dataSales.chargebackFee) +
                Toolboox.round(dataSales.refoundsFee)
            );

        await Promise.all([fees, earnings]);

        const totalData = {
            date: "Total",
            totalNews: this.props.formatMoney(dataSales.totalNews),
            totalRenews: this.props.formatMoney(dataSales.totalRenews),
            totalCoins: this.props.formatMoney(dataSales.totalCoins),
            supertotal: this.props.formatMoney(dataSales.supertotal + adjustment),
            superSubTotal: this.props.formatMoney(dataSales.income),
            superProcessFee: Toolboox.round(dataSales.processFee),
            superTotalRefunds: Toolboox.round(dataSales.totalRefunds) - Toolboox.round(dataSales.refoundsFee),
            superChargeback: Toolboox.round(dataSales.chargeback) - Toolboox.round(dataSales.chargebackFee),
            supeAdjustments: Toolboox.round(dataSales.adjustments),
            totalCuota: this.props.formatMoney(fees)
        };

        this.setState({
            averageEarnings: average,
            havePayPeriod: !havePayPeriod,
            periodEarningDays: dates,
            periodEarninNews: totalNewsByDay,
            periodEarninRenews: totalRenewsByDay,
            periodEarninCoins: totalCoinsByDay,
            superTotal: totalData,
            subtotal: subTotalByDay,
            loadingEarning: false,
        });
        this.getDataSet();
    }

    getDataSet = () => {
        let dictionary = this.props.dictionary;
        this.setState({
            dataset: [],
        });
        let dataset = [
            {
                label               : dictionary.newMembers,
                borderColor         : '#692FE1',
                fill                : false,
                borderWidth         : 1,
                pointRadius         : 4,
                pointBorderWidth    : 2.5,
                pointBorderColor    : '#692FE1',
                data                : this.state.periodEarninNews
            },
            {
                label               : dictionary.renewal,
                borderColor         : '#E39DCD',
                fill                : false,
                borderWidth         : 1,
                pointRadius         : 4,
                pointBorderWidth    : 2.5,
                pointBorderColor    : '#E39DCD',
                data                : this.state.periodEarninRenews
            },
            {
                label               : dictionary.coins,
                borderColor         : '#5AB6E2',
                fill                : false,
                borderWidth         : 1,
                pointRadius         : 4,
                pointBorderWidth    : 2.5,
                pointBorderColor    : '#5AB6E2',
                data                : this.state.periodEarninCoins
            },
            {
                label               : 'Subtotal',
                borderColor         : '#5AE2B1',
                fill                : false,
                borderWidth         : 1,
                pointRadius         : 4,
                pointBorderWidth    : 2.5,
                pointBorderColor    : '#5AE2B1',
                data                : this.state.subtotal
            }

        ]
        this.setState({dataset : dataset})
    }

    render() {
        let dictionary = this.props.dictionary;
        let period = this.props.period;
        let show = this.props.show;
        let loadingEarning = <div className="loaderElement large" >
            <Circle background={"transparent !important"} color={this.props.config.color.secondary} />
        </div>;
        return (
            <div className={(show) ? "show sales" : "sales"}>
                <div className="percentage-erning">
                    <h6>
                        * {this.props.dictionary.sales} USD&nbsp;
                        ({
                            Toolboox.formatDatePayment(period.start, this.props.dictionary.languageKey)
                        } &rarr; {
                        Toolboox.formatDatePayment(period.end, this.props.dictionary.languageKey)
                    })
                    </h6>
                    {this.state.lastUpdate && <p className="last-update">{this.props.dictionary.lastUpdate}: {this.state.lastUpdate} hrs</p>}
                    <label>
                        <input
                            type="radio"
                            name="onlyModel"
                            className="input-st"
                            value="2"
                            onChange={(e) => this.changePorcentage(e, "2")}
                            checked={this.state.onlyModel === "2"}
                        />
                        <span className="span-st">
                            {dictionary.mySplit +
                            " (" +
                            this.props.percentage +
                            "%)"}
                        </span>
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="onlyModel"
                            className="input-st"
                            value="0"
                            onChange={(e) => this.changePorcentage(e,"0")}
                            checked={this.state.onlyModel === "0"}
                        />
                        <span className="span-st">Total (100%)</span>
                    </label>
                    {this.state.loadingEarning
                        ? loadingEarning
                        :
                        <>
                            <Resume
                                dictionary={dictionary}
                                superTotal={this.state.superTotal}
                                formatMoney={this.props.formatMoney}
                            />
                            <Graph
                                dictionary={dictionary}
                                superTotal={this.state.superTotal}
                                formatMoney={this.props.formatMoney}
                                dataset={this.state.dataset}
                                periodEarningDays={this.state.periodEarningDays}
                                config={this.props.config}
                            />
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default Ventas;
