import React, { Component } from 'react';
import { Circle } from 'react-preloaders';

class CancelMembershipSteps extends Component {

    constructor(props) {
        super(props);
        this.state = {
            membershipType: null,
            loading: true
        }
    }

    componentDidMount() {
        this.props.getPiCodes()
            .then(resp => {
                this.setState({
                    loading: false,
                    membershipType: resp && resp.length ? resp[0] : false
                });
            });

    }

    renderCanceledSupport(styles = null) {
        if (this.state.membershipType && this.state.membershipType.payment_processor === 2) {
            return (
                <ol style={styles}>
                    <li>
                        {this.props.dictionary.FAQ.q5p1}.
                    </li>
                    <li>
                        {this.props.dictionary.FAQ.q5p2}.
                                    <img
                            src={this.props.config.storageURL + "/private-social-media.appspot.com/FAQ/1_" + this.props.dictionary.stablishLanguage + "_section.png"}
                            alt="section"
                            className="support-image"
                        />
                    </li>
                    <li>
                        {this.props.dictionary.FAQ.q5p3}
                        <img
                            src={this.props.config.storageURL + "/private-social-media.appspot.com/FAQ/2_" + this.props.dictionary.stablishLanguage + "_cancelMembership.png"}
                            alt="section"
                            className="support-image"
                        />
                    </li>
                    <li>
                        {this.props.dictionary.FAQ.q5p4}
                        <img
                            src={this.props.config.storageURL + "/private-social-media.appspot.com/FAQ/3_" + this.props.dictionary.stablishLanguage + "_cancel.png"}
                            alt="section"
                            className="support-image"
                        />
                    </li>
                    <li>
                        {this.props.dictionary.FAQ.q5p5_c}.
                        <img
                            src={this.props.config.storageURL + "/private-social-media.appspot.com/FAQ/4_" + this.props.dictionary.stablishLanguage + "_goToCCbill.png"}
                            alt="section"
                            className="support-image"
                        />
                    </li>
                </ol>
            );
        }
        else {
            return (
                <ol style={styles}>
                    <li>
                        {this.props.dictionary.FAQ.q5p1}.
                        </li>
                    <li>
                        {this.props.dictionary.FAQ.q5p2}.
                            <img
                            src={this.props.config.storageURL + "/private-social-media.appspot.com/FAQ/1_" + this.props.dictionary.stablishLanguage + "_section.png"}
                            alt="section"
                            className="support-image"
                        />
                    </li>
                    <li>
                        {this.props.dictionary.FAQ.q5p3}
                        <img
                            src={this.props.config.storageURL + "/private-social-media.appspot.com/FAQ/2_" + this.props.dictionary.stablishLanguage + "_cancelMembership.png"}
                            alt="section"
                            className="support-image"
                        />
                    </li>
                    <li>
                        {this.props.dictionary.FAQ.q5p4}
                        <img
                            src={this.props.config.storageURL + "/private-social-media.appspot.com/FAQ/3_" + this.props.dictionary.stablishLanguage + "_cancel.png"}
                            alt="section"
                            className="support-image"
                        />
                    </li>
                    <li>
                        {this.props.dictionary.FAQ.q5p5}.
                            <img
                            src={this.props.config.storageURL + "/private-social-media.appspot.com/FAQ/4_" + this.props.dictionary.stablishLanguage + "_goToEpoch.png"}
                            alt="section"
                            className="support-image"
                        />
                    </li>
                    <li>
                        {this.props.dictionary.FAQ.q5p6}
                        <img
                            src={this.props.config.storageURL + "/private-social-media.appspot.com/FAQ/5_epoch.png"}
                            alt="section"
                            className="support-image"
                        />
                    </li>
                    <li>
                        {this.props.dictionary.FAQ.q5p7}
                        <img
                            src={this.props.config.storageURL + "/private-social-media.appspot.com/FAQ/6_fill.png"}
                            alt="section"
                            className="support-image"
                        />
                    </li>
                    <li>
                        {this.props.dictionary.FAQ.q5p8}
                        <img
                            src={this.props.config.storageURL + "/private-social-media.appspot.com/FAQ/7_cancel.png"}
                            alt="section"
                            className="support-image"
                        />
                    </li>
                    <li>
                        {this.props.dictionary.FAQ.q5p9}
                        <img
                            src={this.props.config.storageURL + "/private-social-media.appspot.com/FAQ/8_contact.png"}
                            alt="section"
                            className="support-image"
                        />
                    </li>
                </ol>
            );
        }
    }


    render(){
        if(this.state.loading)
            return <div className="center-align" style={{ paddingTop: 45 }}><Circle background="transparent !important" color="#4285f4" className="center-align" /></div>;

        return(
            this.renderCanceledSupport(this.props.styles || null)
        )
    }
}

export default CancelMembershipSteps;