import React from 'react';

const SexyIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 24 24"
        >
            <path 
                fill={color}
                d="M19.006 9.644c.633.386 1.192.88 1.65 1.456.842 1.058 1.344 2.394 1.344 3.846 0 3.436-2.81 6.22-6.277 6.22-1.394 0-2.682-.45-3.723-1.21 0 0 0-8.737 6.659-10.508H5.341C12 11.219 12 19.955 12 19.955c-1.041.761-2.329 1.212-3.723 1.212-3.466 0-6.277-2.785-6.277-6.22 0-1.453.502-2.789 1.344-3.847.488-.614 1.091-1.135 1.776-1.53.151-.088.306-.169.465-.244 1.738-.98 1.427-2.555 1.054-4.445-.102-.517-.208-1.057-.279-1.615C8.068 2.455 9.98 2 12 2c2.006 0 3.906.448 5.604 1.25-.076.57-.192 1.122-.304 1.65-.388 1.837-.713 3.376.962 4.356.257.114.506.243.744.388z"
            />
        </svg>
    )
}
export default SexyIcon;
