import React from 'react';

const FitnessIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 24 24"
        >
            <path 
                fill={color}
                fillRule="evenodd" 
                opacity = "0.7"
                d="M2 13.714c0-4.517 3.656-8.173 8.174-8.173 4.518 0 8.173 3.656 8.173 8.173 0 4.518-3.656 8.174-8.174 8.174-1.433 0-2.833-.374-4.068-1.084l-3.27 1.053c-.23.074-.48.012-.648-.156-.17-.17-.23-.42-.156-.647l1.053-3.27C2.374 16.547 2 15.147 2 13.713zm8.582 1.654c.677 0 1.226-.549 1.226-1.226 0-.677-.549-1.226-1.226-1.226-.677 0-1.226.55-1.226 1.226 0 .677.549 1.226 1.226 1.226zm4.904 0c.677 0 1.226-.549 1.226-1.226 0-.677-.549-1.226-1.226-1.226-.677 0-1.226.55-1.226 1.226 0 .677.55 1.226 1.226 1.226zm-9.808 0c.677 0 1.226-.549 1.226-1.226 0-.677-.549-1.226-1.226-1.226-.677 0-1.226.55-1.226 1.226 0 .677.55 1.226 1.226 1.226z" 
                clipRule="evenodd"
            />
            <path 
                fill={color}
                d="M16.853 3.26l2.769 2.769-4.158 4.158-2.767-2.769 4.156-4.158zm4.87-.668l-1.235-1.234c-.477-.477-1.252-.477-1.73 0L17.574 2.54l2.768 2.769 1.38-1.38c.37-.37.37-.966 0-1.337zm-9.725 7.968c-.05.227.154.43.38.375l3.086-.748-2.767-2.769-.7 3.142z"
            />
        </svg>
    )
}
export default FitnessIcon;
