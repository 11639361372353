import React from 'react';

const ComicsIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height}
            viewBox="0 0 24 24"
        >
            <g>
                <path
                    fill={color} 
                    d="M15.65,8.47c-1.72-1.72-4-2.66-6.43-2.66c-2.43,0-4.71,0.95-6.43,2.66c-1.72,1.72-2.66,4-2.66,6.43
                    c0,1.75,0.49,3.44,1.43,4.9l-0.91,2.93c-0.07,0.21-0.01,0.44,0.15,0.6c0.16,0.16,0.39,0.21,0.59,0.14l2.93-0.91
                    C5.78,23.51,7.47,24,9.22,24c2.43,0,4.71-0.95,6.43-2.66c1.72-1.72,2.66-4,2.66-6.43C18.31,12.48,17.37,10.19,15.65,8.47z
                     M2.77,19.89c0.05-0.17,0.03-0.36-0.08-0.51c-0.91-1.32-1.38-2.87-1.38-4.47C1.31,10.54,4.86,7,9.22,7c4.36,0,7.91,3.55,7.91,7.91
                    c0,4.36-3.55,7.91-7.91,7.91c-1.61,0-3.15-0.48-4.47-1.39c-0.1-0.07-0.22-0.1-0.33-0.1c-0.06,0-0.12,0.01-0.18,0.03l-2.12,0.66
                    L2.77,19.89z"
                />
                <path
                    fill={color} 
                    d="M6.46,18.12c1.22,1.13,2.42,1.94,2.42,1.94c0.1,0.07,0.21,0.1,0.34,0.1c0.12,0,0.24-0.04,0.33-0.1
                    c0.01-0.01,1.2-0.81,2.43-1.95c1.69-1.56,2.55-2.93,2.55-4.06c0-1.97-1.49-3.58-3.32-3.58c-0.71,0-1.41,0.25-1.98,0.71
                    c-0.57-0.46-1.27-0.71-1.98-0.71c-1.83,0-3.32,1.61-3.32,3.58C3.91,15.18,4.77,16.55,6.46,18.12z M5.1,14.05
                    c0-1.32,0.96-2.39,2.14-2.39c0.58,0,1.13,0.26,1.53,0.73c0.23,0.26,0.67,0.26,0.9,0c0.41-0.47,0.95-0.73,1.53-0.73
                    c1.18,0,2.14,1.07,2.14,2.39c0,1.44-2.62,3.7-4.12,4.79C7.71,17.76,5.1,15.49,5.1,14.05z"
                />
                <path
                    fill={color} 
                    d="M9.22,8.83h0.02c0.33,0,0.59-0.27,0.59-0.59c0-0.33-0.27-0.59-0.61-0.59c-0.33,0-0.59,0.27-0.59,0.59
                    C8.63,8.56,8.89,8.83,9.22,8.83z"
                />
                <path
                    fill={color} 
                    d="M10.41,8.94c1.18,0.23,2.25,0.81,3.1,1.66c0.11,0.11,0.26,0.17,0.42,0.17c0.16,0,0.31-0.06,0.42-0.17
                    c0.23-0.23,0.23-0.61,0-0.84c-1.02-1.02-2.3-1.7-3.71-1.98c-0.16-0.03-0.31,0-0.45,0.09c-0.13,0.09-0.22,0.22-0.25,0.38
                    s0,0.31,0.09,0.45C10.12,8.82,10.26,8.91,10.41,8.94z"
                />
            </g>
            <g>
                <path
                    fill={color} 
                    d="M23.59,8.04c0.16-0.22,0.25-0.48,0.25-0.75c0-0.69-0.56-1.25-1.25-1.25h-2.01c0.18-0.63,0.26-1.39,0.26-1.77
                    V3.89c0-0.9-0.73-1.62-1.62-1.62h-0.37c-0.23,0-0.43,0.16-0.49,0.38l-0.2,0.82c-0.25,1.01-1.05,2.22-1.94,2.51
                    C16,5.56,15.58,5.29,15.1,5.29h-1.61c0.62,0.3,1.19,0.65,1.72,1.05c0.04,0.02,0.06,0.04,0.09,0.07c0.38,0.3,0.73,0.63,1.06,0.97
                    v-0.4C16.93,6.84,17.5,6.46,18,5.87c0.52-0.61,0.94-1.41,1.13-2.16l0.11-0.44c0.33,0.01,0.6,0.28,0.6,0.62v0.37
                    c0,0.47-0.14,1.34-0.31,1.77h-0.68c-0.28,0-0.5,0.22-0.5,0.5s0.23,0.5,0.5,0.5h3.74c0.14,0,0.25,0.11,0.25,0.25
                    c0,0.14-0.11,0.25-0.25,0.25h-0.75c-0.28,0-0.5,0.22-0.5,0.5c0,0.28,0.23,0.5,0.5,0.5h0.75c0.14,0,0.25,0.11,0.25,0.25
                    c0,0.14-0.11,0.25-0.25,0.25h-0.75c-0.28,0-0.5,0.22-0.5,0.5s0.23,0.5,0.5,0.5h0.75c0.14,0,0.25,0.11,0.25,0.25
                    s-0.11,0.25-0.25,0.25h-0.75c-0.28,0-0.5,0.22-0.5,0.5c0,0.28,0.23,0.5,0.5,0.5c0.14,0,0.25,0.11,0.25,0.25
                    c0,0.14-0.11,0.25-0.25,0.25h-2.92c0.09,0.33,0.17,0.67,0.23,1h2.68c0.69,0,1.25-0.56,1.25-1.25c0-0.11-0.01-0.22-0.04-0.33
                    c0.47-0.19,0.79-0.65,0.79-1.16c0-0.27-0.09-0.53-0.25-0.75c0.16-0.22,0.25-0.48,0.25-0.75C23.84,8.51,23.75,8.25,23.59,8.04z"
                />
                <path
                    fill={color} 
                    d="M19.59,1.78c0.28,0,0.5-0.22,0.5-0.5V0.5c0-0.28-0.23-0.5-0.5-0.5s-0.5,0.22-0.5,0.5v0.77
                    C19.09,1.55,19.31,1.78,19.59,1.78z"
                />
                <path
                    fill={color} 
                    d="M17.38,2.4c0.09,0.09,0.22,0.15,0.36,0.15c0.13,0,0.26-0.05,0.35-0.15c0.1-0.09,0.15-0.22,0.15-0.36
                    c0-0.13-0.05-0.26-0.15-0.35l-0.53-0.53c-0.2-0.2-0.51-0.19-0.71,0c-0.19,0.2-0.19,0.51,0,0.71L17.38,2.4z"
                />
                <path
                    fill={color} 
                    d="M21.44,2.54c0.13,0,0.26-0.05,0.35-0.15l0.53-0.53c0.19-0.2,0.19-0.51,0-0.71c-0.2-0.2-0.51-0.19-0.71,0
                    l-0.53,0.53c-0.19,0.2-0.19,0.51,0,0.71C21.18,2.49,21.31,2.54,21.44,2.54z"
                />
            </g>
        </svg>
    )
}
export default ComicsIcon;
