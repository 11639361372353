import React from 'react';

const MasterclassIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            version="1.1" 
            id="Capa_2" 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            viewBox="0 0 24 24"
        >
            <path 
                fill={color}
                className="cls-1" 
                d="M23.85,19.56c.18-.2.11-.32,0-.27a3.69,3.69,0,0,1-.58.48c.37-.29.61-.82.47-.82s-.17.16-.32.34-.5.33-.36.24a.65.65,0,0,0,.28-.42c.05-.17,0-.4-.11-.39s-.09.19-.17.42-.38.23-.52.23a.15.15,0,0,1-.14-.23c.05-.12.27-.18.62-.31s0-.19-.18-.17a3.12,3.12,0,0,1-.53.06c-.23,0-1.14,0-1.31-.1s-.23-.18-.59-.75a8.45,8.45,0,0,0-2.33-2.62c-.77-.73-1.28-2.92-1.8-4.6s-1.09-1.66-2.1-2a1.64,1.64,0,0,1-1.27-1.45,2.77,2.77,0,0,0,1.15-1.73c.69-.59.48-1.24.32-1.36.49-2.39-1.23-2.9-1.49-3C12.92-.12,12,0,12,0s-.92-.12-.92,1.11c-.26.11-2,.62-1.49,3-.16.12-.37.77.32,1.36a2.77,2.77,0,0,0,1.15,1.73A1.64,1.64,0,0,1,9.79,8.66c-1,.33-1.58.31-2.1,2s-1,3.87-1.8,4.6a8.45,8.45,0,0,0-2.33,2.62c-.36.57-.41.7-.59.75s-1.08.08-1.31.1a3.12,3.12,0,0,1-.53-.06c-.15,0-.52,0-.18.17s.57.19.62.31a.15.15,0,0,1-.14.23c-.14,0-.45,0-.52-.23s0-.42-.17-.42-.16.22-.11.39a.65.65,0,0,0,.28.42c.14.09-.21-.05-.36-.24S.36,19,.23,19s.1.53.47.82a3.69,3.69,0,0,1-.58-.48c-.08-.05-.15.07,0,.27s.25.43.58.5c0,.06,0,.06-.18.06a.85.85,0,0,0-.35.06c-.07.05,0,.1.46.17a8,8,0,0,0,1.06.07h.09A1.76,1.76,0,0,0,1,21.82c.06.83.94,1.86,4,2.12a32.38,32.38,0,0,0,7-.45,32.38,32.38,0,0,0,7,.45c3-.26,3.91-1.29,4-2.12a1.76,1.76,0,0,0-.77-1.4h.09a8,8,0,0,0,1.06-.07c.48-.07.53-.12.46-.17a.85.85,0,0,0-.35-.06c-.13,0-.17,0-.18-.06C23.6,20,23.67,19.76,23.85,19.56ZM9.3,16.84a7.66,7.66,0,0,0-.79,1.88c0,.06-.3.18-1.52.32a16.65,16.65,0,0,0-3.46.57l-.23.06a25.72,25.72,0,0,0,2.29-1.82c.87-.83,1.38-1.34,1.7-1.7a7.84,7.84,0,0,0,1.34-2.27c.45-1.14.52-1.39.52-1.39a15.63,15.63,0,0,1,.48,2.39A3.62,3.62,0,0,1,9.3,16.84Zm11.19,2.79A16.76,16.76,0,0,0,17,19.06c-1.23-.14-1.48-.25-1.53-.31a6.69,6.69,0,0,0-.79-1.88,3.69,3.69,0,0,1-.32-2,14.84,14.84,0,0,1,.47-2.39s.08.25.52,1.39a7.59,7.59,0,0,0,1.35,2.27c.31.36.83.88,1.7,1.7a27.23,27.23,0,0,0,2.28,1.83Z"
            />
        </svg>
    )
}
export default MasterclassIcon;
