import React from 'react';

const CoinsIcon= props =>{
    // const colorPrincipal = "#db972d";
    // const color = "#fae82f";
    const colorPrincipal = props.color || "#db972d";
    const color = props.colorPrincipal || "#fae82f";
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            fill="none"
            viewBox="0 0 20 17"
            className={props.className ? props.className : null}
        >
            <path
                fill={color}
                d="M14.256 14.063c0 1.42-2.92 2.571-6.522 2.571-3.602 0-6.522-1.15-6.522-2.57v-2.245h13.044v2.244z"
            />
            <path
                fill={colorPrincipal}
                d="M7.734 14.39c3.602 0 6.523-1.151 6.523-2.571 0-1.42-2.92-2.57-6.523-2.57-3.602 0-6.523 1.15-6.523 2.57s2.92 2.57 6.523 2.57z"
            />
            <path
                fill={color}
                d="M7.734 14.063c-3.14 0-5.693-1.006-5.693-2.244 0-1.237 2.554-2.244 5.693-2.244 3.14 0 5.694 1.007 5.694 2.244 0 1.238-2.555 2.244-5.694 2.244zm0-4.289c-2.86 0-5.188.917-5.188 2.045s2.327 2.045 5.188 2.045c2.86 0 5.188-.917 5.188-2.045s-2.327-2.045-5.188-2.045z"
            />
            <path
                fill={color}
                d="M7.547 13.21v-.245c-.778-.031-1.476-.153-2.094-.366l.621-.292c.477.164.967.26 1.473.292v-.65c-.641-.061-1.11-.14-1.409-.239-.298-.098-.447-.244-.447-.438 0-.193.172-.351.515-.475.344-.123.79-.19 1.341-.2v-.168h.545v.17c.624.018 1.214.102 1.77.252l-.553.309c-.375-.105-.78-.17-1.217-.195v.631h.025c.653.06 1.132.142 1.438.245.307.103.46.252.46.448s-.176.355-.528.478c-.352.123-.816.19-1.395.201v.242h-.545zm-.6-2.166c-.145.05-.217.113-.217.188s.057.134.17.178c.113.043.33.085.647.126v-.58c-.255.008-.456.038-.6.088zm1.792 1.471c.158-.051.238-.116.238-.194s-.063-.14-.187-.186c-.126-.046-.358-.09-.698-.13v.604c.272-.01.487-.042.647-.094z"
            />
            <path
                fill={color}
                d="M15.325 11.02c0 1.42-2.92 2.571-6.522 2.571-3.602 0-6.522-1.15-6.522-2.57V8.776h13.044v2.245z"
            />
            <path
                fill={colorPrincipal}
                d="M8.803 11.347c3.602 0 6.522-1.15 6.522-2.57s-2.92-2.571-6.522-2.571c-3.603 0-6.523 1.15-6.523 2.57s2.92 2.571 6.523 2.571z"
            />
            <path
                fill={color}
                d="M8.803 11.02c-3.14 0-5.693-1.006-5.693-2.244 0-1.237 2.554-2.244 5.693-2.244 3.14 0 5.693 1.007 5.693 2.244 0 1.238-2.554 2.245-5.693 2.245zm0-4.289c-2.86 0-5.188.918-5.188 2.045 0 1.128 2.327 2.046 5.188 2.046 2.86 0 5.188-.918 5.188-2.046 0-1.127-2.327-2.045-5.188-2.045z"
            />
            <path
                fill={color}
                d="M8.616 10.167v-.245c-.778-.031-1.476-.153-2.094-.365l.621-.292c.477.163.967.26 1.473.292v-.651c-.641-.06-1.111-.14-1.409-.239-.298-.098-.447-.244-.447-.437 0-.194.171-.352.515-.475.343-.123.79-.19 1.341-.201v-.168h.544v.17c.624.019 1.215.103 1.77.253l-.553.308c-.374-.105-.78-.17-1.217-.194v.63h.026c.653.06 1.132.142 1.438.245.307.103.46.253.46.448 0 .196-.176.355-.528.478-.352.123-.816.19-1.396.202v.241h-.544zm-.6-2.166c-.145.05-.217.113-.217.188s.056.134.17.178c.113.044.329.086.647.126v-.58c-.256.008-.456.038-.6.088zm1.791 1.472c.159-.052.239-.117.239-.195s-.063-.14-.187-.186c-.126-.046-.358-.089-.699-.13v.605c.273-.011.488-.043.647-.094z"
            />
            <path
                fill={color}
                d="M13.044 7.654c0 1.42-2.92 2.571-6.522 2.571C2.92 10.225 0 9.075 0 7.655V5.41h13.044v2.244z"
            />
            <path
                fill={colorPrincipal}
                d="M6.523 7.982c3.602 0 6.522-1.15 6.522-2.57S10.125 2.84 6.523 2.84C2.92 2.84 0 3.99 0 5.41s2.92 2.571 6.523 2.571z"
            />
            <path
                fill={color}
                d="M6.522 7.654C3.382 7.654.829 6.648.829 5.41c0-1.237 2.554-2.244 5.693-2.244 3.14 0 5.693 1.007 5.693 2.244 0 1.238-2.554 2.244-5.693 2.244zm0-4.289c-2.86 0-5.188.917-5.188 2.045S3.66 7.455 6.522 7.455c2.86 0 5.188-.917 5.188-2.045S9.383 3.365 6.522 3.365z"
            />
            <path
                fill={color}
                d="M6.335 6.8v-.244c-.778-.031-1.476-.153-2.094-.366l.621-.292c.477.164.967.26 1.473.292v-.65c-.641-.061-1.111-.14-1.409-.239-.298-.098-.447-.244-.447-.438 0-.193.171-.351.515-.475.343-.123.79-.19 1.341-.2v-.169h.544v.172c.625.018 1.215.101 1.77.251l-.553.309c-.374-.105-.78-.17-1.217-.195v.631h.026c.653.06 1.132.142 1.438.245.307.103.46.252.46.448s-.176.355-.528.478c-.352.123-.816.19-1.396.201v.242h-.544zm-.6-2.165c-.145.05-.217.113-.217.188s.056.134.17.178c.113.043.329.085.647.126v-.58c-.256.008-.456.038-.6.088zm1.791 1.471c.159-.051.239-.116.239-.194s-.063-.14-.187-.186c-.126-.046-.358-.09-.699-.13V6.2c.273-.01.488-.042.647-.094z"
            />
            <path
                fill={color}
                d="M15.848 4.815c0 1.42-2.92 2.57-6.521 2.57-3.602 0-6.522-1.15-6.522-2.57V2.571h13.043v2.244z"
            />
            <path
                fill={colorPrincipal}
                d="M9.325 5.142c3.603 0 6.523-1.151 6.523-2.571C15.848 1.15 12.928 0 9.325 0 5.723 0 2.802 1.151 2.802 2.57c0 1.42 2.92 2.572 6.523 2.572z"
            />
            <path
                fill={color}
                d="M9.326 4.815c-3.139 0-5.693-1.007-5.693-2.244 0-1.238 2.554-2.244 5.693-2.244 3.14 0 5.694 1.006 5.694 2.244 0 1.237-2.554 2.244-5.694 2.244zm0-4.29c-2.86 0-5.188.918-5.188 2.046 0 1.127 2.328 2.045 5.188 2.045 2.861 0 5.189-.918 5.189-2.045 0-1.128-2.328-2.045-5.189-2.045z"
            />
            <path
                fill={color}
                d="M9.14 3.961v-.244c-.778-.032-1.477-.154-2.095-.366l.622-.292c.476.163.967.26 1.472.292V2.7c-.641-.06-1.11-.14-1.409-.238-.297-.099-.446-.245-.446-.438 0-.194.17-.352.514-.475s.79-.19 1.341-.201V1.18h.545v.171c.624.018 1.214.102 1.77.252l-.553.309c-.375-.106-.78-.17-1.217-.195v.63h.026c.652.061 1.131.143 1.438.246.306.102.46.252.46.447 0 .196-.176.356-.528.479-.352.123-.817.19-1.396.2v.242h-.545zm-.601-2.165c-.145.05-.217.113-.217.188s.057.134.17.177c.113.044.33.086.647.126v-.58c-.255.009-.455.038-.6.089zm1.792 1.471c.158-.051.238-.116.238-.195 0-.078-.062-.14-.187-.186-.125-.046-.358-.089-.698-.129v.604c.272-.011.488-.043.647-.094z"
            />
            <path
                fill={colorPrincipal}
                d="M13.078 16.634c3.823 0 6.922-3.1 6.922-6.923S16.9 2.79 13.078 2.79c-3.823 0-6.922 3.1-6.922 6.922 0 3.823 3.099 6.923 6.922 6.923z"
            />
            <path
                fill={color}
                d="M13.079 15.755c-3.332 0-6.043-2.711-6.043-6.043 0-3.332 2.71-6.043 6.043-6.043 3.331 0 6.042 2.711 6.042 6.043 0 3.332-2.71 6.043-6.042 6.043zm0-11.55c-3.037 0-5.507 2.47-5.507 5.507 0 3.036 2.47 5.507 5.506 5.507 3.037 0 5.507-2.47 5.507-5.507 0-3.036-2.47-5.507-5.507-5.507z"
            />
            <path
                fill={color}
                d="M12.88 13.457v-.66c-.826-.084-1.567-.412-2.223-.984l.66-.786c.506.44 1.026.701 1.563.786V10.06c-.68-.162-1.18-.376-1.496-.641-.316-.265-.474-.658-.474-1.18 0-.52.182-.946.547-1.278.364-.331.838-.511 1.423-.542v-.451h.578v.46c.662.049 1.288.274 1.879.678l-.588.831c-.397-.283-.827-.457-1.291-.524v1.698h.027c.693.163 1.201.383 1.526.66.326.277.488.68.488 1.206 0 .527-.186.956-.56 1.287-.373.332-.867.512-1.481.542v.65h-.578zm-.637-5.832c-.154.136-.23.304-.23.506s.06.362.18.479c.12.117.35.23.687.339V7.386c-.271.024-.484.104-.637.24zm1.902 3.962c.168-.139.252-.313.252-.524 0-.21-.066-.378-.198-.502-.133-.123-.38-.239-.741-.347v1.625c.289-.03.517-.114.687-.252z"
            />
        </svg>
    )
}
export default CoinsIcon;
