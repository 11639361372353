import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ChatImagesElement from "./ChatImagesElement";
import './style.css'

class ChatImages extends Component{
    constructor(props) {
        super();
    }

    componentDidMount() {
    }

    playVideo = async (e) => {
        let video = e.target;
        if(video.paused) {
            video.play();
            video.nextSibling.classList.add("playing");
        }
        else{
            video.pause();
            video.nextSibling.classList.remove("playing");
        }
    }

    drawMedia(){
        let media = [...this.props.media];
        let length = media.length;
        let config = this.props.config
        return media.map((item, index) => {
            return (
                <ChatImagesElement
                    media={item}
                    length={length}
                    playVideo={this.playVideo}
                    key={index}
                    index={index}
                    config={config}
                    remove={this.props.remove}
                    uploading={this.props.uploading}
                />
            )
        });
    }

    render(){
        let media = [...this.props.media];
        return(
            <div
                className="v2-chat-image-preview"
                style={{
                    backgroundColor: this.props.config.color.principal
                }}
            >
                {(media.length > 1) ?
                    <Carousel
                        showStatus={false}
                        dynamicHeight={false}
                        emulateTouch={true}
                        showThumbs={false}
                        // onClickItem={()=>this.renderLightBox(item.media)}
                    >
                        {this.drawMedia()}
                    </Carousel>
                    :
                    <div className="only">
                        {this.drawMedia()}
                    </div>
                }
            </div>
        )
    }
}

export default ChatImages;
