import React, { Component } from "react";
import './style.css';

class ExpandHelp extends Component {
    constructor(props) {
        super(props);
        this.refButtonPopUp = React.createRef();
        this.showPopUpMessage = this.showPopUpMessage.bind(this);
        this.state={
            intervalEfect: null
        }
    }
    showPopUpMessage = () => {
        let divPopUp = this.refButtonPopUp.current;
        this.setState({
            intervalEfect: clearInterval(this.state.intervalEfect)
        });
        let intervalEfect = null;
        if (divPopUp.classList.contains('hidden')) {
            divPopUp.classList.remove('hidden');
            divPopUp.classList.add("show-effect");
            intervalEfect = setTimeout(() => {
                divPopUp.classList.remove("show-effect");
                divPopUp.classList.add("active");
            }, 500);

        }
        else {
            divPopUp.classList.add("hide-effect");
            intervalEfect = setTimeout(() => {
                divPopUp.classList.add("hidden");
                divPopUp.classList.remove("active");
                divPopUp.classList.remove("hide-effect");
            }, 500);
        }
        this.setState({
            intervalEfect: intervalEfect
        });
    }

    render(){
        let { activeBtnBackgroundColor, activeBtnColor, textBackgroundColor, textColor, text, label } = this.props;

        return (
            <div style={{ position: "relative" }}>
                <button
                    className='btn-radius btn-teampech pop-up-button'
                    style={{
                        backgroundColor: activeBtnBackgroundColor,
                        color: activeBtnColor,
                    }}
                    onClick={this.showPopUpMessage}
                >
                    ?
                </button>
                {label && <span>{label}</span>}
                <div className='pop-up-message hidden'
                    style={{
                        backgroundColor: textBackgroundColor || "#fff" ,
                        color: textColor || "#000"
                    }}
                    ref={this.refButtonPopUp}>
                        {text}
                </div>
            </div>
        );
    }
}
export default ExpandHelp;