import React, { Component } from "react";
import axios from "axios";
import { Preloader, Oval } from 'react-preloader-icon';
import Checkbox from "../../Checkbox";
import ExpandHelp from "../../ExpandHelp";
import './styles.css';

class Watermark extends Component {
    constructor(){
        super();
        this.state={
            watermark: true,
            loading: true,
            successMessage: null
        }
        this.updateWatermark = this.updateWatermark.bind(this);
    }
    getWatermarkStatus = () =>{
        axios.get("/api/v2/media/watermark")
        .then(resp=>{
            this.setState({ watermark: resp.data.watermark, loading: false });
        });

    }

    componentDidMount(){
        this.getWatermarkStatus();
    }

    updateWatermark = () => {
        this.setState({loading: true});
        axios.post("/api/v2/media/watermark")
            .then(resp => {
                let success = resp.data.success
                    ? this.props.dictionary.successChange
                    : this.props.dictionary.successChange

                this.setState({
                    watermark: resp.data.watermarkStatus,
                    loading: false,
                    successMessage: success
                });
                setTimeout(() => {
                    this.setState({
                        successMessage: null
                    });
                }, 2000);

            });

    }

    render() {
        let loadingEarning = <span className="loaderElement" >
            <Preloader
                use={Oval}
                size={26}
                strokeWidth={8}
                strokeColor={this.props.config.color.secondary}
                duration={800}
            />
        </span>;
        return (
            <div>
                <div className='cont-right-a'>
                    <ExpandHelp
                        activeBtnBackgroundColor={this.props.config.color.secondary}
                        activeBtnColor={this.props.config.color.secondaryText}
                        textBackgroundColor={this.props.config.color.principal}
                        textColor={this.props.config.color.principalText}
                        text={this.props.dictionary.watermarkHelp}
                    />
                </div>
                {this.state.loading
                    ? loadingEarning
                    : <Checkbox
                        id="watermark"
                        label={this.props.dictionary.watermark || "Watermark"}
                        name="watermark"
                        checked={!!this.state.watermark}
                        onChange={this.updateWatermark}
                    />
                }
                {this.state.successMessage && <p className='sucess-green'>{this.state.successMessage}</p>}
                <p>{this.props.dictionary.example || "Example"}:</p>
                <div className='watermark-content'>
                    <img
                        className='responsive-img'
                        style={{ "borderRadius": "0.7em" }}
                        src={this.props.config.storageURL + this.props.config.principalImage}
                        alt="watermark"
                    />
                    {!!this.state.watermark && <span>{this.props.config.hostname}</span>}
                </div>
            </div>
        );
    }

}
export default Watermark;