import React from 'react';
import '../Streaming.css';
import Star1 from './Star1';
import Star2 from './Star2';
import Star3 from './Star3';
import Star5 from './Star5';
import Star6 from './Star6';
import CoinIcon from '../../Svg/CoinIcon';
   

const AlertCoins  = props =>{
        return <div>
                {props.showAlertCoins && 
                        <div className="donations__stars">
                                <div className="stars">
                                        <Star1 
                                                className="star1" 
                                                width="12"
                                                color={props.config.color.secondary}
                                        />
                                        <Star2 
                                                className="star2" 
                                                width="20"
                                                height="19"
                                                color={props.config.color.secondary}
                                        />
                                        <Star3 className="star3" 
                                                color={props.config.color.secondary}
                                                width="20"
                                                height="19"
                                        />
                                        <Star5 
                                                className="star5" 
                                                color={props.config.color.secondary}
                                                width="20"
                                                height="19"
                                        />
                                        <Star6 
                                                color={props.config.color.secondary}
                                                className="star6" 
                                                width="12"
                                                height="11"
                                        />
                                        <CoinIcon 
                                                className="coin__icon__alert" 
                                                color={props.config.color.secondary}
                                                width="55"
                                        />
                                </div>
                                <div className="donation">
                               <strong>{props.user}</strong> {props.dictionary.hDonated} {props.coins}  {props.dictionary.coins}
                                </div>
                        </div>
                }
                </div>
}

export default AlertCoins;