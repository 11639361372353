import React, {Component} from 'react';

class Timer extends Component{
    constructor(props){
        super(props);
        this.state = {
            elapsed : 0,
        }
    }
    componentDidMount() {
        //this.tick()
        setInterval(() => {
            this.setState({elapsed: new Date() - this.props.start});
        }, 500);
    }
    setTime = () => {
        const time = this.state.elapsed;
        //console.log(time);
        const hrs = Math.floor(time / 3600000);
        const minutesRest = time % 3600000;
        const minutes = Math.floor(minutesRest / 60000);
        const secondsRest = minutesRest % 60000;
        const seconds = Math.floor(secondsRest / 1000);
        return(
            <p style={{color : this.props.color}}>{(hrs > 0) ? hrs.toString().padStart(2,"0")+" : " : "" }{minutes.toString().padStart(2,"0")} : {seconds.toString().padStart(2,"0")}</p>
        )
    }
    render(){
        return (
            <div className="timer">
                {this.setTime()}
            </div>
        )
    };
}
export default Timer;
