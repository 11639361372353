import React from 'react';

const CoinIcon= props=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={props.width} 
            height={props.height}
            fill="none" 
            viewBox="0 0 16 16"
            className={props.className ? props.className : null}
        >
            <path 
                fill={props.color}
                opacity = '0.2'
                d="M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z"
            />
            <path 
                fill={props.color}
                d="M8 14.984c-3.85 0-6.983-3.133-6.983-6.983 0-3.851 3.133-6.984 6.984-6.984 3.85 0 6.983 3.133 6.983 6.984 0 3.85-3.133 6.983-6.983 6.983zM8 1.637C4.493 1.637 1.638 4.49 1.638 8c0 3.509 2.855 6.364 6.364 6.364S14.365 11.51 14.365 8c0-3.51-2.855-6.364-6.364-6.364z"
            />
            <path 
                fill={props.color}
                d="M7.771 12.328v-.762c-.954-.097-1.81-.477-2.568-1.138l.762-.908c.585.508 1.186.81 1.806.909V8.402c-.786-.188-1.362-.435-1.728-.741-.365-.307-.548-.76-.548-1.363 0-.602.21-1.094.631-1.477.422-.383.97-.592 1.645-.627v-.522h.668v.533c.766.056 1.49.316 2.172.783l-.679.96c-.46-.327-.957-.528-1.493-.605v1.962h.032c.8.189 1.388.443 1.764.763.376.32.564.785.564 1.393 0 .61-.216 1.106-.648 1.488-.431.383-1.001.592-1.712.627v.751h-.668zM7.035 5.59c-.178.156-.266.352-.266.585 0 .233.07.417.209.553.138.136.403.266.793.392V5.312c-.313.028-.559.12-.736.277zm2.198 4.578c.194-.16.292-.361.292-.605s-.076-.437-.23-.58c-.153-.142-.439-.276-.856-.402v1.88c.334-.035.598-.133.794-.293z"
            />
        </svg>
    )
}
export default CoinIcon;
