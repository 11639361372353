import React from 'react';

const LifestyleIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            version="1.0" 
            xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height}
            viewBox="0 0 700.000000 700.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <metadata>
                Created by potrace 1.16, written by Peter Selinger 2001-2019
            </metadata>
            <g 
                transform="translate(0.000000,700.000000) scale(0.100000,-0.100000)"
                fill={color}
                stroke="none"
            >
                <path 
                    d="M4380 6804 c-133 -36 -298 -127 -372 -205 -36 -39 -98 -119 -121
                    -159 -33 -57 -82 -175 -90 -218 -13 -67 119 -62 -1579 -62 -1701 0 -1593 4
                    -1727 -62 -122 -60 -229 -185 -282 -328 l-24 -65 0 -2575 0 -2575 30 -85 c34
                    -97 33 -95 140 -204 85 -87 169 -136 263 -156 31 -7 921 -9 2628 -8 l2581 3
                    89 44 c63 31 109 63 157 109 74 72 104 118 145 220 l27 67 3 2000 2 2000 104
                    105 c56 58 125 134 152 170 27 36 51 67 54 70 31 29 180 301 221 405 27 65 86
                    246 98 297 18 77 41 268 41 344 0 92 -24 253 -51 335 -26 82 -87 194 -144 269
                    -97 126 -295 241 -483 279 -77 16 -279 14 -354 -4 -196 -45 -341 -126 -481
                    -268 l-67 -68 -63 63 c-142 146 -272 221 -462 268 -117 29 -319 26 -435 -6z
                    m372 -238 c104 -28 157 -52 243 -108 59 -39 167 -154 210 -225 53 -89 66 -98
                    135 -98 63 0 93 18 121 73 49 97 188 238 284 288 344 182 746 59 873 -266 104
                    -266 45 -650 -155 -1020 -145 -269 -384 -518 -753 -787 -47 -34 -90 -66 -96
                    -71 -38 -32 -262 -172 -275 -172 -30 0 -506 332 -559 390 -3 3 -32 27 -64 55
                    -78 65 -270 258 -321 320 -21 28 -42 52 -46 55 -18 14 -123 189 -172 287 -53
                    106 -108 257 -128 353 -17 81 -39 264 -39 325 0 56 19 174 35 220 68 193 210
                    327 403 380 65 18 239 18 304 1z m-983 -753 c11 -100 28 -195 50 -283 8 -33
                    69 -213 86 -252 7 -17 -80 -18 -1735 -18 l-1741 0 3 205 c2 146 7 208 16 217
                    6 6 12 20 12 30 0 22 39 85 71 115 36 32 107 67 164 79 36 9 470 12 1556 13
                    l1506 1 12 -107z m333 -890 c34 -50 85 -119 111 -152 l48 -61 -1605 0 -1605 0
                    -54 -24 c-61 -27 -118 -88 -135 -145 -17 -55 -17 -2887 -1 -2942 7 -21 34 -63
                    60 -92 39 -44 56 -56 90 -61 23 -4 1020 -7 2216 -6 2166 0 2174 0 2217 21 54
                    25 77 50 110 115 l26 52 0 473 c0 460 1 475 21 499 86 107 159 264 188 408 25
                    120 8 273 -40 366 -24 48 -102 131 -141 152 l-28 15 0 242 0 243 113 76 c130
                    89 281 199 285 210 2 4 8 8 13 8 6 0 8 -718 7 -1867 l-3 -1868 -27 -56 c-33
                    -67 -90 -121 -161 -154 l-52 -25 -2536 0 c-2844 0 -2585 -7 -2678 76 -29 26
                    -62 69 -78 102 l-28 57 -3 2212 -2 2213 1802 2 c992 2 1804 3 1805 3 0 0 30
                    -41 65 -92z m477 -511 c85 -72 147 -121 241 -190 47 -34 92 -68 100 -75 67
                    -54 377 -247 397 -247 10 0 13 -33 13 -159 l0 -159 -32 -7 c-46 -9 -118 -44
                    -150 -74 -25 -22 -29 -23 -45 -9 -35 30 -114 70 -165 84 -206 54 -422 -68
                    -486 -276 -25 -84 -22 -263 7 -360 30 -98 81 -207 132 -284 57 -84 195 -222
                    309 -309 160 -121 160 -122 215 -122 50 0 66 8 196 104 18 13 19 4 19 -313 l0
                    -326 -2115 0 -2115 0 0 1385 0 1385 1712 0 1711 0 56 -48z m314 -1086 c42 -17
                    77 -50 110 -103 58 -93 168 -96 223 -6 64 102 159 147 232 109 108 -56 123
                    -209 40 -412 -50 -124 -230 -327 -354 -399 l-31 -18 -59 44 c-113 83 -253 240
                    -307 344 -46 90 -73 224 -62 305 16 115 111 177 208 136z"
                />
                <path 
                    d="M965 5679 c-32 -28 -35 -36 -35 -86 0 -49 4 -59 34 -89 l34 -34 105
                    0 c98 0 108 2 136 25 40 34 56 84 41 130 -6 19 -25 46 -41 60 -28 23 -38 25
                    -134 25 -102 0 -105 -1 -140 -31z"
                />
                <path   
                    d="M1521 5675 c-55 -63 -53 -115 4 -172 l35 -35 111 4 111 3 29 33 c24
                    27 29 41 29 83 0 45 -4 55 -34 85 l-34 34 -110 0 -110 0 -31 -35z"
                />
                <path 
                    d="M2082 5681 c-50 -50 -52 -129 -4 -176 33 -34 54 -38 166 -33 84 3 97
                    6 120 27 54 51 51 138 -7 187 -26 22 -37 24 -137 24 -107 0 -109 0 -138 -29z"
                />
                <path 
                    d="M1204 1271 c-45 -12 -101 -48 -128 -84 -39 -52 -48 -109 -44 -290 l3
                    -165 30 -43 c17 -24 53 -57 80 -74 42 -26 58 -30 119 -30 69 0 76 2 191 65 66
                    36 133 72 149 80 64 33 126 131 126 200 0 88 -68 171 -190 234 -41 21 -102 53
                    -135 72 -65 36 -144 50 -201 35z"
                />
                <path 
                    d="M3162 1263 c-46 -22 -62 -59 -62 -143 l0 -70 -525 0 -524 0 -36 -35
                    c-30 -31 -35 -42 -35 -85 0 -60 33 -104 88 -120 23 -6 226 -10 533 -10 l497 0
                    0 -63 c0 -74 20 -120 62 -142 39 -20 78 -19 119 3 47 25 71 74 71 144 l0 58
                    1053 0 c687 0 1065 4 1089 10 56 16 88 60 88 122 0 44 -4 54 -34 84 l-34 34
                    -1080 0 -1080 0 -4 73 c-4 81 -22 116 -75 142 -40 19 -69 19 -111 -2z"
                />
                <path 
                    d="M2400 3857 c-97 -27 -184 -108 -220 -203 -19 -53 -20 -76 -20 -579 0
                    -504 1 -526 21 -579 27 -73 93 -145 166 -181 51 -25 69 -29 148 -29 60 0 102
                    5 125 15 29 13 500 317 535 346 6 4 60 41 120 80 174 114 219 168 244 294 10
                    46 9 69 -3 123 -20 83 -34 111 -83 161 -43 45 -705 490 -802 540 -44 22 -171
                    29 -231 12z m491 -479 c234 -157 368 -252 376 -268 19 -42 5 -78 -46 -115 -25
                    -19 -51 -39 -58 -45 -7 -5 -14 -10 -17 -10 -3 0 -140 -90 -305 -200 -165 -110
                    -313 -203 -329 -206 -31 -6 -69 8 -89 35 -18 23 -18 989 0 1012 18 23 49 38
                    78 39 19 0 141 -76 390 -242z"
                />
            </g>
        </svg>
    )
}
export default LifestyleIcon;
