import React from 'react';
import { Link } from 'react-router-dom';
import './style.css'


const HomeMaintenance = ({config, dictionary}) => {

    return ( <>
        <div className="navImg">
            <img 
                alt={config.nameTitle+" logo"} 
                className="navLogo" 
                src={config.storageURL+(config.secondLogo ? config.secondLogo : config.logo)}
            />
        </div>
        <div className="div-imgPrincipal">
                <img
                    alt={dictionary.profileImage}
                    src={config.storageURL + config.principalImage}
                    className={'imgPrincipal'}
                />
            </div>
        <div
            className="container main-container center-align "
            style={{
                zIndex: 0,
                backgroundColor: config.color.principal,
                color: config.color.principalText
            }}
        >
            <div className='maintenance-message'>
                <h5>{dictionary.maintenanceTitle}</h5>
                <div>
                    {dictionary.maintenanceMessage}
                </div>
            </div>
            
        </div>
        <Link to='/support'>
                <p style={{color: config.color.principalText}} className='contact-label'>
                    {dictionary.contactUs}
                </p>
        </Link>

                
    </>)
}

export default HomeMaintenance;
