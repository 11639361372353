import React, {Component} from 'react';
import Modal from '../Modal';

class ModalConfirm extends Component {
    render() {
        let navbar = document.getElementsByClassName("styles_content__1Phom");
        if (navbar && this.props.open)
            navbar[0].style.zIndex = 0;

        let config = this.props.config;
        return (
            <Modal
                id={(this.props.id) ? this.props.id : "confirm-modal"}
                open={this.props.open}
                closeAction={this.props.close}
                head={<h6>{this.props.title}</h6>}
                config={config}
                footer={
                    <div className='actions'>
                        <button
                            style={{
                                backgroundColor: config.color.secondary,
                                color: config.color.secondaryText,
                                marginTop: 0,
                                marginBottom: 20
                            }}
                            onClick={(e) => this.props.action(e)}
                            className="create-account-button log-btn"
                        >
                            {this.props.actionText}
                        </button>
                    </div>
                }
                height={this.props.height}
                top={this.props.top}
                middle={this.props.middle}
                levelModal={this.props.levelModal}
                levelContainer={this.props.levelContainer}
            >
                <div>
                    <p>
                        {this.props.message}
                    </p>
                </div>
            </Modal>
        );
    }

}

export default ModalConfirm;
