import React from "react";

const CameraNotFilledIcon = ({color, width, height}) => {
    return (
        <svg 
            version="1.0" 
            xmlns="http://www.w3.org/2000/svg"
            width= {width} 
            height= {height}
            viewBox="0 0 512.000000 417.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g 
                transform="translate(0.000000,417.000000) scale(0.100000,-0.100000)"
                fill= {color} 
                stroke="none"
            >
                <path d="M1815 4018 c-173 -33 -326 -185 -366 -363 l-12 -55 -376 0 c-423 0
                -466 -5 -591 -66 -103 -50 -211 -159 -263 -265 -71 -144 -68 -83 -65 -1437 l3
                -1217 23 -65 c67 -186 190 -306 392 -384 l55 -21 1952 -3 c2142 -3 2025 -6
                2156 57 78 37 183 132 237 214 22 34 53 98 68 142 l27 80 3 1194 c3 1336 4
                1302 -64 1440 -51 104 -161 214 -264 264 -125 62 -167 67 -596 67 l-381 0 -7
                49 c-22 167 -194 336 -375 371 -69 13 -1486 12 -1556 -2z m1549 -349 c43 -34
                50 -58 56 -184 4 -106 8 -124 29 -154 13 -18 42 -42 65 -52 38 -17 74 -19 516
                -19 296 0 489 -4 512 -10 59 -17 120 -69 151 -129 l27 -55 0 -1181 c0 -1296 2
                -1241 -60 -1317 -16 -20 -53 -48 -82 -62 l-52 -26 -1925 0 c-1916 0 -1926 1
                -1971 21 -55 25 -95 62 -123 114 l-22 40 -3 1193 c-1 806 1 1206 8 1232 15 57
                68 120 125 149 l49 26 507 5 506 5 36 28 c58 44 69 76 76 215 l6 124 33 29 32
                29 739 0 c732 0 739 0 765 -21z"/>
                <path d="M4142 3031 c-187 -65 -243 -311 -102 -449 75 -72 202 -98 291 -60 51
                22 116 84 141 132 32 63 36 159 8 224 -30 69 -105 137 -173 157 -65 19 -100
                19 -165 -4z"/>
                <path d="M2393 3026 c-159 -30 -333 -104 -470 -198 -99 -68 -256 -235 -322
                -342 -361 -583 -143 -1357 469 -1666 281 -141 620 -163 918 -59 286 101 520
                306 658 577 199 389 157 878 -103 1227 -167 223 -391 372 -673 447 -98 26
                -371 34 -477 14z m406 -357 c224 -52 428 -211 536 -419 71 -138 89 -219 90
                -390 0 -170 -13 -228 -80 -365 -105 -213 -304 -374 -540 -436 -113 -30 -298
                -30 -410 0 -378 101 -625 419 -625 805 0 369 237 689 587 796 141 43 283 46
                442 9z"/>
            </g>
        </svg>

    )
}

export default CameraNotFilledIcon;