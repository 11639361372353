import React from 'react';

const CorridosIcon= ({color, width, height, className = null})=>{
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            id="Capa_1" 
            className={className}
            viewBox="0 0 24 24"
        >
            <title>
                Icon Corridos
            </title>
            <path
                fill={color}
                d="M8.05,22.75,4.94,6.7l5.09-1h0l3.12,16-.73.14a.35.35,0,0,0-.27.4.34.34,0,0,0,.39.26l.89-.17a.53.53,0,0,0,.33-.22.51.51,0,0,0,.07-.38v-.07h0a.67.67,0,0,0,.34.09.63.63,0,0,0,.33-.09.64.64,0,0,0,.34.09.63.63,0,0,0,.33-.09.63.63,0,0,0,.33.09.67.67,0,0,0,.34-.09.35.35,0,0,0,.14.06l0,.15a.48.48,0,0,0,.08.38.5.5,0,0,0,.34.2l3.59.6h.08a.52.52,0,0,0,.51-.42l.17-1.06a.32.32,0,0,0-.27-.38.33.33,0,0,0-.38.27l-.15.9-3.29-.55L19.36,5.75l3.29.55L20.33,20.1a.33.33,0,0,0,.65.11L23.33,6.26a.52.52,0,0,0-.42-.59l-3.59-.6a.51.51,0,0,0-.59.42l-.07.43h-.1a1.29,1.29,0,0,0-1,.55h0a1,1,0,0,0-.86-.56,1.15,1.15,0,0,0-.95.56,1.64,1.64,0,0,0-.19-.25,1,1,0,0,0-1.43,0,1.64,1.64,0,0,0-.19.25A1.14,1.14,0,0,0,13,5.91a.91.91,0,0,0-.69.31,1.21,1.21,0,0,0-.17.25h0a1.27,1.27,0,0,0-1-.55.88.88,0,0,0-.37.08l-.1-.55a.52.52,0,0,0-.6-.41L4.48,6.11l-.68.13a.34.34,0,0,0,.12.66l.36-.07.36,1.82-3.19.62-.18-.93A.91.91,0,0,1,2,7.27l.51-.1a.34.34,0,0,0,.27-.39.33.33,0,0,0-.39-.26l-.52.1A1.57,1.57,0,0,0,.62,8.46L3.39,22.73A1.57,1.57,0,0,0,4.93,24a1.53,1.53,0,0,0,.3,0l5.89-1.14h0a.34.34,0,0,0,.27-.39.33.33,0,0,0-.4-.26l-2.94.57M3.23,18.4l-.13-.65,3.18-.61.13.65ZM3,17.1l-.12-.65L6,15.83l.13.65ZM5.9,15.18l-3.18.61-.13-.65,3.19-.61Zm-.25-1.31-3.18.62-.13-.65,3.18-.62Zm.89,4.57.12.65-3.18.62-.13-.65ZM5.4,12.57l-3.19.61-.12-.65,3.18-.61Zm-.26-1.31L2,11.88l-.13-.65L5,10.61l.06.35Zm-1.53,9.1,3.18-.62.07.34.06.31L3.73,21Zm1.15-11,.13.65-3.19.61-.12-.65Zm.35,14A.92.92,0,0,1,4,22.6l-.18-.94,3.19-.61.35,1.82ZM18.55,6.58,16.47,18.92,17.71,8h0C17.82,7.06,18.21,6.59,18.55,6.58Zm-1.85,0a.22.22,0,0,1,.19.08,1.73,1.73,0,0,1,.16,1.22h0l-1.5,13.22s0,0,0,0L16,7.94C16.06,7,16.4,6.58,16.7,6.58Zm-5.57,0a.62.62,0,0,1,.47.29A2.23,2.23,0,0,1,12,8l.2,1.77,1,8.5L10.92,6.78C11,6.58,11.08,6.58,11.13,6.58Zm3,14.53L12.87,9.9l-.23-2a1.74,1.74,0,0,1,.16-1.22A.22.22,0,0,1,13,6.58c.3,0,.64.42.68,1.36l.5,13.19Zm.69,0-.5-13.22c0-.82.18-1.13.28-1.23a.31.31,0,0,1,.24-.1.3.3,0,0,1,.23.1c.1.1.31.41.28,1.23h0L15,18.45l-.1,2.68Z"
            />
            <path
                fill={color}
                d="M19.47,15.87a1,1,0,0,0-1.16.82,1,1,0,0,0,.17.74,1,1,0,0,0,.64.41l.18,0a1,1,0,0,0,.57-.18,1,1,0,0,0,.41-.65,1,1,0,0,0-.16-.74A1,1,0,0,0,19.47,15.87Zm.15,1.05a.3.3,0,0,1-.13.21.36.36,0,0,1-.25.06.37.37,0,0,1-.22-.14A.31.31,0,0,1,19,16.8a.33.33,0,0,1,.33-.27h.05a.37.37,0,0,1,.22.14A.31.31,0,0,1,19.62,16.92Z"
            />
            <path
                fill={color}
                d="M20.77,14.26a1,1,0,0,0-.17-.75A1,1,0,0,0,20,13.1a1,1,0,0,0-.75.17,1,1,0,0,0-.41.64,1,1,0,0,0,.81,1.16l.17,0A1,1,0,0,0,20.77,14.26Zm-.66-.12a.33.33,0,0,1-.38.28.37.37,0,0,1-.22-.14.31.31,0,0,1-.05-.25.34.34,0,0,1,.13-.22.35.35,0,0,1,.19-.06h.06a.33.33,0,0,1,.27.38Z"
            />
            <path
                fill={color}
                d="M20.1,12.3h.17a1,1,0,0,0,.58-.18,1,1,0,0,0,.41-.64,1,1,0,0,0-.82-1.16.94.94,0,0,0-.74.17,1,1,0,0,0-.41.64,1,1,0,0,0,.16.75A1,1,0,0,0,20.1,12.3Zm-.16-1a.37.37,0,0,1,.14-.22.33.33,0,0,1,.19-.06h.06a.33.33,0,0,1,.13.6.31.31,0,0,1-.25.05.32.32,0,0,1-.21-.13A.39.39,0,0,1,19.94,11.26Z"
            />
            <path
                fill={color}
                d="M12.25,21.16a.39.39,0,0,0,.06-.25L9.57,6.82a.34.34,0,0,0-.39-.26l-3.13.61a.3.3,0,0,0-.22.14.31.31,0,0,0,0,.25L8.52,21.64a.33.33,0,0,0,.33.27h.06L12,21.3A.34.34,0,0,0,12.25,21.16Zm-3.14,0L6.5,7.76,9,7.28l2.61,13.43Z"
            />
            <path
                fill={color}
                d="M8.77,12.82a.34.34,0,0,0-.27.39l.43,2.17a.33.33,0,0,0,.32.27h.07a.33.33,0,0,0,.26-.39l-.42-2.18A.33.33,0,0,0,8.77,12.82Z"
            />
            <path
                fill={color}
                d="M21.82.05a.29.29,0,0,0-.22,0L19.54.52a.26.26,0,0,0-.19.25V2.61a.78.78,0,0,0-.89,1.18.77.77,0,0,0,.86.29.78.78,0,0,0,.54-.74V1L21.4.59V2.1a.77.77,0,0,0-.88,1.17.76.76,0,0,0,.86.29.77.77,0,0,0,.54-.73V.26a.28.28,0,0,0-.1-.21ZM19.09,3.6a.26.26,0,0,1-.24-.16.24.24,0,0,1,.06-.28.26.26,0,0,1,.28-.06.28.28,0,0,1,.16.24.26.26,0,0,1-.26.26Zm2.06-.52A.25.25,0,0,1,21,2.65a.26.26,0,0,1,.44.18.25.25,0,0,1-.25.25Zm0,0"
            />
            <path
                fill={color}
                d="M5.72,1a.31.31,0,0,0-.25,0l-2.4.6a.3.3,0,0,0-.23.29V4a.9.9,0,1,0,.6.85V2l1.8-.45V3.35a.9.9,0,1,0,.6.85v-3A.3.3,0,0,0,5.72,1ZM2.54,5.1a.32.32,0,0,1-.28-.19.31.31,0,0,1,.4-.39.31.31,0,0,1,.18.28.3.3,0,0,1-.3.3Zm2.4-.6a.32.32,0,0,1-.28-.19A.29.29,0,0,1,4.73,4a.29.29,0,0,1,.33-.07.31.31,0,0,1,.18.28.3.3,0,0,1-.3.3Zm0,0"
            />
            <path
                fill={color}
                d="M14.79.12,12.5.69a.29.29,0,0,0-.21.28V3a.86.86,0,0,0-1,.29.84.84,0,0,0,0,1,.86.86,0,0,0,1.56-.49V1.19L14.93.67a.29.29,0,0,0,.21-.34.29.29,0,0,0-.35-.21ZM12,4.11a.29.29,0,0,1-.26-.17.27.27,0,0,1,.06-.31.28.28,0,0,1,.31-.07.29.29,0,0,1,.18.27.28.28,0,0,1-.29.28Zm0,0"
            />
        </svg>
    )
}
export default CorridosIcon;
