import React from 'react';
import ReactDOM from 'react-dom';
import BackDrop from '../BackDrop/Backdrop';
import {CSSTransition} from 'react-transition-group';
import './ModalPortal.css'

import CloseIcon from './CloseIcon'


const ModalOverlay =  props =>{
        const  content =(
        <div className={`modal ${props.className}`} style={{background:props.blackModal?props.config.color.third:props.config.color.modal, color: props.blackModal?props.config.color.secondaryText:props.config.principalText}}>
            <header className={`modal__header ${props.headerClass}`}>
                <CloseIcon 
                     onClick={props.onCancel}
                     className="img"
                     whidth="28"
                     height="28"
                     fill={props.config.color.secondary}
                     stroke={props.config.color.secondaryText}
                />
            </header>
            <form onSubmit={props.onSubmit?props.onSubmit : event=>event.preventDefault}>
                <div className={`modal__content ${props.contenClass}`}>
                    {props.children}
                </div>
            </form>
        </div>
    );
    return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
}

const ModalPortal = props =>{
    return <React.Fragment>
        {props.show && <BackDrop onClick={props.onCancel} />}
        <CSSTransition 
            in={props.show} 
            mountOnEnter 
            unmountOnExit 
            timeout={600} 
            classNames="modal">
                <ModalOverlay {...props}/>
            </CSSTransition>
    </React.Fragment>
}

export default ModalPortal