import React from 'react';

const FoodPornIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            viewBox="0 0 24 24"
        >
            <g
                fill={color}
            >
                <path 
                    d="M12.51,14.07l0.68-0.68c0.13-0.13,0.13-0.35,0-0.48c-0.13-0.13-0.35-0.13-0.48,0l-0.68,0.68
                    c-0.13,0.13-0.13,0.35,0,0.48C12.17,14.2,12.38,14.2,12.51,14.07z"/>
                <path 
                    d="M4.4,14.07l0.68-0.68c0.13-0.13,0.13-0.35,0-0.48c-0.13-0.13-0.35-0.13-0.48,0l-0.68,0.68
                    c-0.13,0.13-0.13,0.35,0,0.48C4.05,14.2,4.27,14.2,4.4,14.07z"/>
                <path 
                    d="M10.82,12.71l0.68-0.68c0.13-0.13,0.13-0.35,0-0.48c-0.13-0.13-0.35-0.13-0.48,0l-0.68,0.68
                    c-0.13,0.13-0.13,0.35,0,0.48C10.48,12.85,10.69,12.85,10.82,12.71z"/>
                <path 
                    d="M6.77,12.71l0.68-0.68c0.13-0.13,0.13-0.35,0-0.48c-0.13-0.13-0.35-0.13-0.48,0l-0.68,0.68
                    c-0.13,0.13-0.13,0.35,0,0.48C6.42,12.85,6.63,12.85,6.77,12.71z"/>
                <path 
                    d="M8.46,14.07l0.68-0.68c0.13-0.13,0.13-0.35,0-0.48c-0.13-0.13-0.35-0.13-0.48,0l-0.68,0.68
                    c-0.13,0.13-0.13,0.35,0,0.48C8.11,14.2,8.32,14.2,8.46,14.07z"/>
                <path 
                    d="M16.53,18.9h-0.34v-1.09c0.01-0.01,0.03-0.02,0.04-0.04c0.22-0.2,0.68-0.33,0.98-0.33
                    c0.19,0,0.34-0.16,0.33-0.34c0-0.19-0.15-0.33-0.34-0.33c0,0,0,0,0,0c-0.29,0-0.68,0.08-1.01,0.24v-0.89
                    c0.41-0.17,0.69-0.59,0.63-1.06c-0.4-3.2-3.14-5.62-6.37-5.62H7.34c-3.23,0-5.97,2.42-6.37,5.62c-0.06,0.47,0.21,0.89,0.63,1.06
                    v0.87c-0.34-0.15-0.72-0.23-1.02-0.22c-0.19,0-0.34,0.16-0.33,0.34c0,0.19,0.15,0.33,0.34,0.33c0,0,0,0,0,0c0.3,0,0.77,0.12,1,0.33
                    c0,0,0.01,0.01,0.01,0.01v1.12H1.26c-0.56,0-1.01,0.45-1.01,1.01c0,0.5,0.37,0.92,0.85,1C1.01,21.12,1,21.35,1.08,21.56
                    c0.42,1.23,1.58,2.06,2.88,2.06h9.87c1.3,0,2.46-0.83,2.88-2.06c0.07-0.22,0.07-0.44-0.01-0.65c0.47-0.08,0.85-0.49,0.85-1
                    C17.54,19.35,17.09,18.9,16.53,18.9z M2.27,16.19h13.25v1.27c-0.43,0.24-0.91,0.17-1.31-0.19c-0.74-0.68-1.74-0.68-2.48,0
                    c-0.48,0.44-1.09,0.44-1.57,0c-0.74-0.68-1.74-0.68-2.48,0c-0.48,0.44-1.09,0.44-1.57,0c-0.74-0.68-1.74-0.68-2.48,0
                    c-0.41,0.38-0.9,0.43-1.34,0.17V16.19z M1.64,15.14c0.36-2.87,2.81-5.03,5.7-5.03h3.11c2.89,0,5.34,2.16,5.7,5.03
                    c0.02,0.2-0.13,0.37-0.32,0.38c0,0,0,0-0.01,0c-0.01,0-0.01,0-0.02,0H1.98C1.78,15.52,1.61,15.34,1.64,15.14z M2.27,18.18
                    c0.18,0.06,0.37,0.09,0.56,0.09c0.44,0,0.87-0.17,1.24-0.51c0.48-0.44,1.09-0.44,1.57,0c0.74,0.68,1.74,0.68,2.48,0
                    c0.48-0.44,1.09-0.44,1.57,0c0.74,0.68,1.74,0.68,2.48,0c0.48-0.44,1.09-0.44,1.57,0c0.52,0.47,1.17,0.62,1.77,0.42v0.7H2.27V18.18
                    z M16.07,21.35c-0.33,0.96-1.23,1.61-2.24,1.61H3.96c-1.01,0-1.92-0.65-2.24-1.61c-0.07-0.21,0.11-0.42,0.33-0.42h13.69
                    C15.96,20.92,16.14,21.14,16.07,21.35z M16.53,20.25H1.26c-0.19,0-0.34-0.15-0.34-0.34c0-0.19,0.15-0.34,0.34-0.34h15.28
                    c0.19,0,0.34,0.15,0.34,0.34C16.87,20.1,16.72,20.25,16.53,20.25z"/>
            </g>
            <g>
                <path 
                    d="M22.48,2.19c-2.65-1.1-5.44-1.65-8.31-1.65S8.51,1.09,5.86,2.18C5.59,2.3,5.35,2.46,5.15,2.66
                    c-0.61,0.61-0.83,1.56-0.48,2.4c0.24,0.57,0.69,0.99,1.22,1.2l1.07,2.18C7.24,8.42,7.53,8.41,7.83,8.4L6.86,6.42
                    C7.09,6.4,7.32,6.35,7.55,6.26c4.27-1.77,8.98-1.77,13.25,0c0.15,0.06,0.3,0.11,0.45,0.13l-0.49,1.04c-0.26-0.2-0.56-0.33-0.9-0.38
                    c-0.49-0.06-0.97,0.07-1.36,0.36c-0.07,0.05-0.13,0.1-0.18,0.16c-0.28,0.28-0.47,0.65-0.52,1.05c-0.06,0.49,0.07,0.97,0.36,1.36
                    c0.27,0.35,0.64,0.58,1.06,0.67l-1.77,3.7c0.16,0.32,0.32,0.74,0.02,1.84l4.68-9.83c0.66-0.16,1.24-0.62,1.52-1.3
                    C24.14,3.94,23.61,2.65,22.48,2.19z M19.59,9.89c-0.3-0.01-0.59-0.15-0.78-0.4C18.64,9.27,18.57,9,18.6,8.73
                    c0.04-0.27,0.18-0.51,0.39-0.68c0.22-0.17,0.49-0.24,0.76-0.2C20,7.88,20.23,8,20.39,8.19L19.59,9.89z M22.93,4.76
                    c-0.14,0.35-0.41,0.61-0.76,0.76c-0.35,0.14-0.73,0.14-1.07,0c-2.21-0.92-4.54-1.38-6.93-1.38c-2.39,0-4.72,0.46-6.93,1.38
                    c-0.71,0.3-1.53-0.04-1.83-0.76c-0.3-0.71,0.04-1.53,0.76-1.83c5.16-2.14,10.84-2.14,16.01,0c0.35,0.14,0.61,0.41,0.76,0.76
                    C23.07,4.03,23.07,4.41,22.93,4.76z"
                />
                <path 
                    d="M18.05,3.66c0.22,0.04,0.43-0.1,0.47-0.32c0.04-0.22-0.1-0.43-0.32-0.47c-0.14-0.03-0.28,0.02-0.37,0.11
                    c-0.05,0.05-0.09,0.12-0.11,0.2c-0.05,0.22,0.09,0.43,0.31,0.48C18.04,3.66,18.05,3.66,18.05,3.66z"
                />
                <path 
                    d="M19.18,3.93c0.76,0.21,1.52,0.47,2.26,0.77c0.21,0.09,0.44-0.01,0.53-0.22c0.09-0.21-0.01-0.44-0.22-0.53
                    c-0.76-0.32-1.56-0.59-2.35-0.81c-0.14-0.04-0.29,0-0.39,0.1c-0.05,0.05-0.08,0.11-0.1,0.18C18.84,3.65,18.97,3.88,19.18,3.93z"
                />
                <path 
                    d="M10.61,8.58c-0.05-0.32,0.03-0.65,0.27-0.88c0.03-0.03,0.07-0.06,0.1-0.09c0.22-0.17,0.49-0.24,0.76-0.2
                    c0.27,0.04,0.51,0.18,0.68,0.39c0.3,0.39,0.27,0.92-0.04,1.28c0.26,0.1,0.52,0.2,0.77,0.32c0.4-0.64,0.38-1.48-0.09-2.1
                    c-0.3-0.39-0.73-0.64-1.22-0.7c-0.49-0.06-0.97,0.07-1.36,0.36c-0.06,0.05-0.13,0.1-0.18,0.16C9.95,7.49,9.78,7.98,9.79,8.46
                    C10.07,8.49,10.34,8.53,10.61,8.58z"
                />
                <path 
                    d="M15.79,9.18l0.99-0.76c0.18-0.14,0.21-0.39,0.07-0.57c-0.14-0.18-0.39-0.21-0.57-0.07L15.3,8.54
                    c-0.01,0.01-0.03,0.02-0.04,0.04c-0.14,0.14-0.16,0.37-0.03,0.53C15.36,9.28,15.61,9.32,15.79,9.18z"
                />
                <path 
                    d="M14.79,7.14c0.18,0.14,0.43,0.1,0.57-0.07c0.14-0.18,0.1-0.43-0.07-0.57l-0.67-0.51
                    c-0.16-0.12-0.39-0.11-0.53,0.03c-0.01,0.01-0.02,0.03-0.04,0.04c-0.14,0.18-0.1,0.43,0.07,0.57L14.79,7.14z"
                />
            </g>
        </svg>

    )
}
export default FoodPornIcon;
