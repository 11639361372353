import React, {Component} from "react";
import './style.css';

class Checkbox extends Component{
    constructor(props) {
        super(props);
        this.handleCheckbox = this.handleCheckbox.bind(this);
    }

    componentDidMount() {
    }

    handleCheckbox = (e) => {
        let input = e.target.previousSibling;
        let checked = input.checked;
        input.checked = !checked;
        let name = input.name;
        if(this.props.onChange){
            this.props.onChange(name, !checked)
        }
    }

    render() {
        let id = (this.props.id) ? this.props.id : "CustomCheckbox";
        let name = (this.props.name) ? this.props.name : "";
        let message = (this.props.label) ? this.props.label : "";
        return(
            <div
                id={id}
                className="checkbox"
            >
                {(this.props.checked !== undefined) ?
                    <input
                        name={name}
                        type="checkbox"
                        checked={(this.props.checked) ? this.props.checked : false}
                        onChange={this.handleCheckbox}
                    />
                : this.props.defaultChecked !== undefined ?
                    <input
                        name={name}
                        type="checkbox"
                        defaultChecked={(this.props.defaultChecked) ? this.props.defaultChecked : false}
                    />
                :
                    <input
                        name={name}
                        type="checkbox"
                    />
                }

                <label
                    onClick={this.handleCheckbox}
                >
                    {message}
                </label>
            </div>
        )
    }
}
export default Checkbox;
