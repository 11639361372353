import React, { Component } from 'react';
import Modal from "../../../../Modal";
import './style.css';
import Icon from 'react-ionicons'
import CheckIcon from '../../../../Svg/CheckIcon';
import TipsIcon from '../../../../Svg/TipsIcon';

class ModalRequireMedia extends Component{
    constructor(props) {
        super(props);
        this.state = {
            style: {borderColor: this.props.config.color.secondary},
            color: this.props.config.color.secondary,
            itemid: 0
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.open) {
          return {
            itemid: 0
          };
        }
        return null;
    }

    requireMediaSelect = async (e, type) =>{
        e.preventDefault();
        
        this.setState({ 
            style: {
                backgroundColor: this.props.config.color.third,
                borderColor: this.props.config.color.secondary,
                color: this.props.config.color.thirdText
            },
            itemid: type,
            color: this.props.config.color.secondary
        })
        const mediaList = document.querySelectorAll('.buy-media-list');
        const eventMediaList = e.target.closest(".buy-media-list");
        const keyNoChange = eventMediaList.getAttribute('data-key');
        mediaList.forEach((item, index) => {
            if (item.getAttribute('data-key') === keyNoChange) {
                item.classList.add('select');
            }
            else{
                item.classList.remove('select');
            }
        });
        this.props.setRequestMedia(type)
    }

    drawPricesModal() {
        const prices = [...this.props.pricesmodal];
        return (<> 
            <li 
                className='buy-media-list'
                onClick={e=>this.props.donate()}
                key='tipsElement'
                style={{borderColor: this.props.config.color.secondary}}
            >
                <div className="buy-media-list-item-tips">
                    <button className="buy-media-list-button">
                        <TipsIcon width={'22px'} heigth={'22px'} color={this.props.config.color.secondary}/>
                    </button>
                    <span>
                        {this.props.dictionary.send}
                        {" "}
                        {this.props.dictionary.coins}
                    </span>
                </div>
            </li>
            {
                prices.map((item, index) => {
                    if(item.id === 2){
                        return null
                    }
                    let show = false;
                    let icon = "md-mic";
                    if (item.id === 3 && this.props.showModule["audio-message-req"]) {
                        show = true;
                        icon = "md-mic";
                    } else if (item.id === 4 && this.props.showModule["image-message-req"]) {
                        show = true;
                        icon = "md-image"
                    } else if (item.id === 5 && this.props.showModule["video-message-req"]) {
                        show = true;
                        icon = "md-videocam"
                    }
                    if (show) {
                        return (
                            <li key={index} className="buy-media-list" data-key={index}
                                onClick={(e) => this.requireMediaSelect(e, item.id)}
                                style={(this.state.itemid === item.id) ? this.state.style : {borderColor: this.props.config.color.secondary}}
                            >
                                <div className="buy-media-list-item-title">
                                    <button className="buy-media-list-button">
                                        <Icon icon={icon} color={this.state.itemid === item.id ? this.state.color : this.props.config.color.secondary}/>
                                    </button> 
                                    <span>
                                        {(item.id === 3) ? 
                                        this.props.dictionary.privateAudio : ((item.id === 4) ? 
                                        this.props.dictionary.privateImage : this.props.dictionary.privateVideo)} 
                                    </span>
                                </div>
                                <span className="buy-media-list-item-coins">
                                    {item.monedas} {this.props.dictionary.coins}
                                </span>
                            </li>
                        )
                    } else {
                        return null;
                    }
                })
            }
        </>)
    }

    render(){
        let coins = this.props.currentCoins;
        let dictionary = this.props.dictionary;
        let config = this.props.config;
        return(
            <div>
                <Modal
                    id="ModalRequireMedia"
                    head={<p>{this.props.dictionary.chooseAction}</p>} 
                    config={this.props.config}
                    footer={
                        (this.props.typeMultimediaToBuy) ?
                            <div className="actions">
                                <button
                                    className="buy-button"
                                    style={{
                                        backgroundColor: config.color.secondary,
                                        color: config.color.secondaryText
                                    }}
                                    onClick={(e) => this.props.requireMedia(e)}
                                >
                                    {dictionary.buy}
                                </button>
                            </div>
                            :
                            null

                    }
                    open={this.props.open}
                    closeAction={this.props.close}
                    levelModal={this.props.levelModal}
                    levelContainer={this.props.levelContainer}
                    height={this.props.height}
                    top={this.props.top}
                    middle={this.props.middle}
                >
                    <div>
                        <ul className="modal-list">
                            {this.drawPricesModal()}
                        </ul>
                    </div>

                </Modal>
            </div>
        )
    }
}

export default ModalRequireMedia;
