import React from 'react';
import ASMRIcon from './ASMRIcon';
import BeautyTipsIcon from './BeautyTipsIcon';
import BellezaIcon from "./BellezaIcon";
import ComicsIcon from './ComicsIcon';
import CorridosIcon from "./CorridosIcon";
import FitnessIcon from './FitnessIcon';
import FoodPornIcon from './FoodPornIcon';
import GalleryIcon from './GalleryIcon';
import LifestyleIcon from './LifestyleIcon';
import MusicIcon from "./MusicIcon";
import PlaticasIcon from "./PlaticasIcon";
import PMessageIcon from "./PMessageIcon";
import PodcastIcon from './PodcastIcon';
import SexSchoolIcon from './SexSchoolIcon';
import SexyIcon from './SexyIcon';
import StreamIcon from './StreamIcon';
import TravelIcon from './TravelIcon';
import VideosIcon from "./VideosIcon";
import VlogsIcon from './VlogsIcon';
import AllIcon from "./AllIcon";
import MasterclassIcon from "./MasterclassIcon";

const SectionIcon= ({iconName, color, width, height, className = null})=>{
    return(
        <div>
            {iconName === "ASMRIcon" ?
                <ASMRIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            :
                iconName === "BeautyTipsIcon" ?
                <BeautyTipsIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            :
                iconName === "ComicsIcon" ?
                <ComicsIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            :
                iconName === "CorridosIcon" ?
                <CorridosIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            :
                iconName === "FitnessIcon" ?
                <FitnessIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            :
                iconName === "FoodPornIcon" ?
                <FoodPornIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            :
                iconName === "GalleryIcon" ?
                <GalleryIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            :
                iconName === "LifestyleIcon" ?
                <LifestyleIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            :
                iconName === "PodcastIcon" ?
                <PodcastIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            :
                iconName === "SexSchoolIcon" ?
                <SexSchoolIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            :
                iconName === "SexyIcon" ?
                <SexyIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            :
                iconName === "StreamIcon" ?
                <StreamIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            :
                iconName === "TravelIcon" ?
                <TravelIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            :
                iconName === "VlogsIcon" ?
                <VlogsIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            :
                iconName === "BellezaIcon" ?
                    <BellezaIcon
                        color={color}
                        width={width}
                        height={height}
                        className={className}
                    />
            :
                iconName === "PlaticasIcon" ?
                    <PlaticasIcon
                        color={color}
                        width={width}
                        height={height}
                        className={className}
                    />
            :
                iconName === "MusicIcon" ?
                    <MusicIcon
                        color={color}
                        width={width}
                        height={height}
                        className={className}
                    />
            :
                iconName === "VideosIcon" ?
                    <VideosIcon
                        color={color}
                        width={width}
                        height={height}
                        className={className}
                    />
            :
                iconName === "PMessageIcon" ?
                    <PMessageIcon
                        color={color}
                        width={width}
                        height={height}
                        className={className}
                    />
            :
                iconName === "GeneralIcon" ?
                    <AllIcon
                        color={color}
                        width={width}
                        height={height}
                        className={className}
                    />
            :
                iconName === "MasterclassIcon" ?
                    <MasterclassIcon
                        color={color}
                        width={width}
                        height={height}
                        className={className}
                    />
            : null }
        </div>
    )
}
export default SectionIcon;
