import React, { Component } from 'react';
import axios from "axios";
import Icon from "react-ionicons";
import moment from "moment";
import userIcon from '../../../../img/generic-user-icon.jpg';
import DropdownList from "../../../DropdownList";

class ConversationItem extends Component {
    constructor(props){
        super(props);

        this.state = {
            showActions: false
        };
        this.toggleActions = this.toggleActions.bind(this);
    }

    toggleActions() {
        this.setState({showActions: !this.setState.showActions});
    }

    async deleteConUser(userId) {
        await axios.post('/api/v2/deleteConversationModel', {userId: userId}).then(resp => {
            window.location.href = '/messages';
        }).catch();

    }

    render(){

        let conversation = this.props.data;
        let {config, dictionary} = this.props;

        let syleMessageCount = {
            backgroundColor: config.color.secondary,
            color: config.color.chat.modelChatText,
        };

        let messageCount = conversation.lastMessage !== 'mdl'
            ? conversation.unansweredCount || 0
            : 0;

        let messageCountSpan = messageCount > 0
            ? <span><small style={syleMessageCount}>{messageCount}</small></span>
            : null;

        let cleanMessage = conversation.messageUser.includes('"material-icons">')
            ? conversation.messageUser
            : conversation.messageUser.replace(/<\/?[^>]+(>|$)/g, "");

        let userImage = conversation.image_profile
            ? config.storageURL + '/' + config.storage + '/profiles/' + conversation.image_profile
            : userIcon;

        let styleItem = {
            borderBottomColor: config.color.chat.modelChatText,
        };

        let accesChat = () => {
            let param = {
                userId: conversation.id_user,
                username: conversation.username,
                userImage: userImage,
                filter: this.props.filter
            }
            this.props.toElevate.onClickConversation(param);
        };
        let delConversation = (e) => { e.preventDefault(); this.deleteConUser(conversation.id_user)};

        let btnActions = <button type={"button"}
                                 className={"v2-chat-conversation-item-actions"}
                                 onClick={this.toggleActions}
                                 id="openSender">
                            <Icon
                                icon='ios-arrow-down'
                                fontSize="20px"
                                color={config.color.chat.modelChatText}
                            />
                        </button>

        let calendar = moment(conversation.getTime).calendar(null, {
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday]',
            lastWeek: '[Last] dddd',
            sameElse: 'DD/MM/YYYY'
        });
        if(calendar === "Today")
            calendar = moment(conversation.getTime).format('LT');
        return <li style={styleItem}
                   className={'v2-chat-conversation-item'}>
                    <div onClick={accesChat} className={'v2-chat-div-w90'}>
                        <div className={'v2-chat-half-left'}>
                            <img alt={"user-icon"} src={userImage} />
                            <span>{conversation.disgustingSolution
                                ? dictionary.myMassMsg
                                : conversation.username}</span>
                            <span>
                                {conversation.media ?
                                    <Icon
                                        className='dropdown-icon'
                                        icon={"md-attach"}
                                        fontSize="20px"
                                        color={this.props.config.color.principalText}
                                    />
                                    :
                                    null
                                }
                                {cleanMessage.includes('"material-icons">videocam') ?
                                    <Icon
                                        icon='md-videocam'
                                        fontSize="20px"
                                        color={config.color.principalText}
                                    />
                                : cleanMessage.includes('"material-icons">image') ?
                                    <Icon
                                        icon='md-image'
                                        fontSize="20px"
                                        color={config.color.principalText}
                                    />
                                : cleanMessage.includes('"material-icons">mic') ?
                                    <Icon
                                        icon='md-mic'
                                        fontSize="20px"
                                        color={config.color.principalText}
                                    />
                                : cleanMessage.includes('"material-icons">card_giftcard') ?
                                    <i
                                        className="material-icons"
                                        style={{
                                            color: config.color.principalText,
                                            fontSize: 20
                                        }}
                                    >
                                        card_giftcard
                                    </i>
                                : cleanMessage}
                            </span>
                        </div>
                        <div
                            className={'v2-chat-half-right'}
                        >
                            <span style={{color:config.color.secondary}}>{calendar}</span>
                            {messageCountSpan}
                        </div>
                    </div>
                    <div className={'v2-chat-div-w10'}>
                        <DropdownList items={[[
                            '',
                            '#',
                            'md-trash',
                            config.color.secondary,
                            delConversation
                        ]]}
                        trigger={btnActions} />
                    </div>
                </li>;
    }
}

export default ConversationItem;
