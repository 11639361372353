import React from 'react';

const LockIcon= ({color, width, height, className = null, title, description})=>{
    return(
        <svg   
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 18 22"
            className={className}
            role="img"
        >
            <title>{title || ""}</title>
            <desc>{description || ""}</desc>
            <path 
                fill={color}
                d="M16.015 9.397V6.694c0-1.86-.801-3.548-2.073-4.75C12.67.737 10.92 0 9 0 7.08 0 5.33.738 4.06 1.943c-1.273 1.203-2.074 2.89-2.073 4.75v2.704H0v9.265c0 1.456 1.191 2.647 2.647 2.647h12.706c1.456 0 2.647-1.191 2.647-2.647V9.397h-1.985zm-6.353 6.17v1.77H8.338v-1.77c-.394-.23-.661-.652-.661-1.14 0-.732.592-1.324 1.323-1.324s1.323.592 1.323 1.323c0 .489-.267.91-.661 1.14zm4.5-6.17H3.838V6.694c.001-1.325.563-2.52 1.494-3.406C6.264 2.406 7.558 1.853 9 1.853c1.442 0 2.736.553 3.668 1.435.931.886 1.493 2.082 1.494 3.406v2.703z"
                />
        </svg>
    )
}
export default LockIcon;
