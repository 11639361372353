import React, {Component} from "react";
import axios from "axios";
import Modal from "../../Modal";
import GetCoins from "../../GetCoins";
import NotAvailable from "../../NotAvailable";
import CoinsIcon from '../../Svg/CoinsIcon';
import "./newDonation.css";

class Donation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            handler: true,
            coins: 10,
            modalBuy: false,
            modalNotAvailable: false,
            currentCoins: 0,
        };
        this.handleCoinChange = this.handleCoinChange.bind(this);
        this.openModalBuy = this.openModalBuy.bind(this);
        this.openModalNotAvailable = this.openModalNotAvailable.bind(this);
    }

    componentDidMount() {
        this.getCoins();
    }

    componentDidUpdate(prevProps, prevState) {
        const {coins} = this.props;
        if (coins && coins !== prevState.currentCoins) {
            this.setState({currentCoins: coins});
        }
    }

    getCoins = async () => {
        const coins = await this.props.getCoins();
        this.setState({currentCoins: coins});
    };

    handleCoinChange(e) {
        let value = e.target.value;
        if (this.props.coinstHandler) this.props.coinstHandler(value);
        this.setState({coins: value});
    }

    closeModalBuy = () => {
        if (this.props.modalBuyHandler) this.props.modalBuyHandler(false);
        this.setState({modalBuy: false});
    };

    closeModalNotAvailable = () => {
        if (this.props.modalNotAvailableHandler)
            this.props.modalNotAvailableHandler(false);
        this.setState({modalNotAvailable: false});
    };
    openModalNotAvailable = () => {
        this.setState({modalNotAvailable: true});
    };
    openModalBuy = () => {
        this.setState({modalBuy: true});
    };
    doDonation = async (e) => {
        if (this.props.type === "message") {
            this.props.doDonation(true);
            this.props.closeRequireMedia();
        } else if (this.props.type === "streaming") {
            this.props.doDonation(
                this.state.coins,
                this.openModalBuy,
                this.openModalNotAvailable
            );
        } else {
            e.preventDefault();
            let coins = this.state.coins;
            let post = this.props.post;
            axios
                .post("/api/v2/spend/credit/donation/post", {
                    amount: coins,
                    postId: post.id_post,
                })
                .then((resp) => {
                    if (resp.data.valid) {
                        if (post.donations)
                            post.donations =
                                parseInt(post.donations) + parseInt(coins);
                        else post.donations = coins;
                        this.props.updateCoins(resp.data.newCoinCredit);
                        this.props.donate();
                    }
                    if (resp.data.error === "No credit") {
                        this.props.donate();
                        this.openModalBuy();
                    }
                    if (resp.data.error === "Precio not found") {
                        this.props.donate();
                        this.openModalNotAvailable();
                    }
                });
            
        }
    };

    render() {
        let coins = this.state.currentCoins;
        let dictionary = this.props.dictionary;
        let config = this.props.config;
        return (
            <div>
                <Modal
                    id="modalDonate"
                    head={
                        <div>
                            {dictionary.send} {dictionary.coins} 
                        </div>
                    }
                    config={this.props.config}
                    footer={
                        <div className="actions">
                            <button
                                style={{
                                    backgroundColor: config.color.secondary,
                                    color: config.color.secondaryText,
                                }}
                                onClick={(e) => this.doDonation(e)}
                            >
                                {dictionary.agree}
                            </button>
                        </div>
                    }
                    open={this.props.showModalDonate}
                    closeAction={this.props.donate}
                    levelModal={this.props.levelModal}
                    levelContainer={this.props.levelContainer}
                    height={this.props.height}
                    top={this.props.top}
                >
                    <div className="input-donate">
                        <div className="label-input-donate">
                            <CoinsIcon
                                color={config.color.secondary}
                                colorPrincipal={config.color.principal}
                                width={"21px"}
                                height={"21px"}
                            />
                            {dictionary.uHave}
                            <div 
                                className="coins-number"
                                style={{color: this.props.config.color.secondary}}
                            >
                                {coins}
                            </div>
                            {dictionary.coins}
                        </div>
                        
                        <div style={{textAlign: 'center'}}>
                            <input
                                value={this.state.coins}
                                onChange={this.handleCoinChange}
                                type="number"
                                className="input-donate-input"
                                style={{color: config.color.modalText}}
                            />
                            <sub>* {dictionary.writeCoinsToDonate}</sub>
                        </div>
                    </div>
                </Modal>
                <GetCoins
                    open={this.state.modalBuy || this.props.modalBuy}
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                    close={this.closeModalBuy}
                    levelModal={'second'}
                    levelContainer={'second'}
                    height={window.screen.width < 350 ? '65%' : '58%'}
                />
                <NotAvailable
                    open={
                        this.state.modalNotAvailable ||
                        this.props.modalNotAvailable
                    }
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                    close={this.closeModalNotAvailable}
                />
            </div>
        );
    }
}

export default Donation;
