import React from 'react';
import MediaboxIcon from './MediaboxIcon';

const MediaIcons= ({iconName, color, width, height, className = null})=>{
    return(
        <div>
            {iconName === "MediaboxIcon" ?
                <MediaboxIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            : null }
        </div>
    )
}
export default MediaIcons;
