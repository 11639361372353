import React, {Component} from 'react';
import MediaBox from "../../components/MediaBox";
import {isMobile} from 'react-device-detect';
import './style.css';

class NewFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (

            <div
                className="container main-container media-box"
                style={{
                    backgroundColor: this.props.config.color.principal,
                    color: this.props.config.color.principalText
                }}
            >
                <MediaBox
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                    isMobile={isMobile}
                />
            </div>
        )
    }
}

export default NewFeed;
