import React from 'react';
import UserIcon from '../../../Svg/UserIcon';
import {Link} from 'react-router-dom';

const HeadItems = ({config, dictionary, landingPage}) => {
    let modalLogo;
    if (config.secondLogo) {
        modalLogo = config.secondLogo;
    } else {
        modalLogo = config.logo;
    }
    return (
        <div>
            <button title={dictionary.login} className="loginMenu-btn">
                <Link to={"/login"}>
                    <UserIcon
                        color="#fff"
                        width={"27px"}
                        height={"27px"}
                        title={dictionary.login}
                    />
                </Link>
            </button>
            {landingPage === 2 ?
                <div
                    style={{
                        backgroundImage: 'url(' + config.storageURL + config.principalImage + ')'
                    }}
                    className='imgPrincipalBackground'
                    id="imgPrincipalBackground"
                >
                </div>
                :
                <div className="navImg">
                    <img alt={config.nameTitle+" logo"} className="navLogo" src={config.storageURL+modalLogo}/>
                </div>
            }
        </div>
    )
}
export default HeadItems;
