import React from 'react';

const ListIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            viewBox="0 0 173.000000 142.000000"
            className = {className}
        >
        <g 
            transform="translate(0.000000,142.000000) scale(0.100000,-0.100000)"
            fill={color}
            stroke="none"
        >
            <path 
                d="M104 1366 c-100 -61 -98 -206 2 -263 76 -44 159 -22 211 56 62 91 -7
                218 -124 228 -38 3 -56 -1 -89 -21z"
            />
            <path 
                d="M612 1275 c-39 -17 -66 -72 -57 -116 4 -16 20 -40 37 -54 l30 -25
                498 0 c536 0 539 0 566 51 30 59 9 119 -51 144 -47 20 -976 20 -1023 0z"
            />
            <path 
                d="M125 856 c-17 -7 -43 -28 -58 -45 -90 -108 -7 -262 133 -249 147 14
                190 190 68 281 -33 25 -104 31 -143 13z"
            />
            <path 
                d="M594 742 c-25 -20 -44 -53 -44 -77 0 -31 31 -82 56 -94 18 -8 173
                -11 529 -11 l504 0 26 23 c20 16 28 35 32 69 5 42 3 50 -25 78 l-30 30 -514 0
                c-471 0 -515 -2 -534 -18z"
            />
            <path 
                d="M121 328 c-58 -30 -83 -65 -88 -125 -8 -94 56 -163 152 -163 95 0
                160 70 152 163 -10 110 -121 174 -216 125z"
            />
            <path 
                d="M603 228 c-12 -6 -29 -26 -39 -46 -13 -29 -14 -42 -5 -72 21 -72 1
                -70 565 -70 341 -1 513 3 526 10 59 31 66 125 13 167 l-28 23 -505 -1 c-334 0
                -512 -4 -527 -11z"
            />
        </g>
    </svg>

    )
}
export default ListIcon;
