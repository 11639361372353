import React, {useEffect, useState} from "react";
import UserIcon from "../Svg/UserIcon";
import ProgressBar from "react-customizable-progressbar";
import moment from "moment";
import 'moment/locale/es'
import './style.css';

export const BonusCoinsDetails = ( {toElevate, dictionary, config }) => {

    const [canSellCoin, setCoins] = useState(false);

    useEffect(
        () => {
            async function fetchCanSell() {
                setCoins(await toElevate.canSellCoin);
            }
            fetchCanSell();
            moment.locale(dictionary.languageKey);
        },
        [toElevate.canSellCoin]
    );

    if(canSellCoin && toElevate  && toElevate.bonusActivity && toElevate.bonusActivity.endPeriod){

        let {endPeriod, level, maxLevel, coinsBonusNextLevel, percentage, nextBonusLevel, debts } = toElevate.bonusActivity;
        percentage = (percentage).toFixed(2);
        return (
            <div className='container-bonus-detail' style={{border: `1px solid ${config.color.principalText}30`}}>
                <div className={"title-list"} style={{backgroundColor: config.color.secondary, color: config.color.secondaryText}} >
                    <p>Bonus</p>
                </div>
                <div className='bonus-coins-progress-item'>
                    <div>
                        <ProgressBar
                            radius={40}
                            progress={percentage}
                            strokeWidth={7}
                            strokeColor={config.color.secondary}
                            strokeLinecap="square"
                            trackStrokeWidth={5}
                            trackStrokeColor={config.color.secondaryText}
                            className={"progress-bonus-coins"}
                            initialAnimation={true}
                            transition="1.5s ease 0.5s"
                            trackTransition="0s ease"
                            cut={120}
                            rotate={-210}
                            trackStrokeLinecap="square"
                            pointerRadius={0}
                        >
                            <div className="indicator-bonus">
                                <p className={'font-strong'}>{maxLevel ? 'Max' : dictionary.lvl+' '+level}</p>
                                <div className={"exp-date"}>Exp date: {moment(endPeriod).format('ll')}</div>
                            </div>
                        </ProgressBar>
                    </div>
                    <div className={"list-items"}>
                        {maxLevel
                            ?<div>
                                <div>{dictionary.bonusNextBuy}</div>
                                <div className={"font-strong"}>{nextBonusLevel.bonus_percentage}%</div>
                            </div>
                            :<div>
                                <div>{dictionary.GiftNextLvl}</div>
                                <div className={"font-strong"}>{coinsBonusNextLevel}</div>
                            </div>
                        }
                        {debts.num ?
                            <div style={{color: config.color.secondary, fontSize: 10}}>
                                <div>{dictionary.bonusAccumulated.replace('--coins--', debts.num)}</div>
                                <div>{dictionary.CoinObtainedNextBuy}</div>
                                <div className={"font-strong"}>{debts.amountCoins}</div>
                            </div>
                            :null
                        }
                    </div>
                </div>
            </div>
        );
    }
    else{
        return null;
    }


}