import React, {Component} from 'react';
import axios from 'axios';
import {Circle} from 'react-preloaders';
import moment from 'moment';
import ReactGA from 'react-ga';
import CoinIcon from '../../components/Svg/CoinIcon';
import EditProfileModal from "../../components/Profile/EditProfileModal";
import getParams from "../../components/SearchParams/SearchParams";
import Subscribe from '../../components/Buttons/Subscribe';
import ModalConfirmCancelation from "../../components/ModalConfirmCancelation";

class Membership extends Component {

    constructor(props) {
        super(props);
        this.fireTracking();
        this.state = {
            profileFeatures: null,
            membership: false,
            dictionary: [],
            config: [],
            history: [],
            displayMenu: false,
            showModal: false,
            picodes: [],
            promo: null,
            showModalCancel: false,
            accepted: false,
            picodeSelected: null,
        }
        this.setConfirmed = this.setConfirmed.bind(this);
    }

    componentDidMount = async () => {

        this.setState({dictionary: this.props.dictionary, config: this.props.config, benefits: true});
        const params = getParams();
        let promo = params.promo;
        if (promo) this.setState({promo: "/?promo=" + promo});
        if (!this.props.view) {
            new Promise((resolve) => {
                resolve(this.getLocation());
            })
                .then(async (keyCountry) => {
                    await this.setState({keyCountry: keyCountry, promo: promo});
                })
                .then(async () => {
                    await this.getPiCodes(this.state.keyCountry, this.state.promo);
                });
        }
        this.isConfirmed()
        await this.getProfileFeatures()
            .then(async profileFeatures => {
                await this.setState({profileFeatures: profileFeatures});
            }).catch(err => {
                throw Error(err);
            });
        this.hasCodeMedia();
        await this.getCoinsFeatures()
            .then(async history => {
                await this.setState({history: history});
            })
            .catch(err => {
                throw Error(err);
            });
    }

    hasCodeMedia = async () => {
        return await axios.get('/api/v2/model/have/code/coin')
            .then((res) => {
                this.setState({
                    showModule: res.data.haveCoin
                });
                return res.data.haveCoin;
            })
    }
    fireTracking = () => {
        ReactGA.pageview(window.location.pathname);
    }
    getCoinsFeatures = async () => {
        return await axios.post("/api/v2/coinsHistory")
            .then(res => {
                return res.data;
            })
            .catch(error => {
                console.log("BuyCoinsError", error);
            });
    };
    getProfileFeatures = async () => {
        return await axios.post('/api/v2/getUserDetails')
            .then(async res => {
                return res.data;
            })
            .catch(err => {
                throw Error(err);
            });
    }
    subscribeFeatures = () => {
        if (!this.state.profileFeatures.membershipCanceled) {
            const payday = moment(this.state.profileFeatures.payday_limit).format('ll');
            return (
                <div className="infoDollars">
                    <p className="v2-exp" style={{color: this.state.config.color.secondary}}>
                        * {this.props.dictionary.expire} {payday !== "Invalid date" ? payday : moment().format('ll')}
                    </p>
                    <div className="v2-exp">
                        <CoinIcon
                            color={this.state.config.color.secondary}
                            width={"15px"}
                            height={"15px"}
                            className="v2-coin"
                        />
                        {this.state.dictionary.membership}
                        <div>

                            {this.state.profileFeatures.priceMembership ?
                                "$ " + this.state.profileFeatures.priceMembership + " " + this.state.dictionary.dollars + " / "
                                : this.state.dictionary.dollars + " $0.00"}
                            {this.state.profileFeatures.payment_period}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <p>{this.props.dictionary.getMemFull}</p>
                </div>
            )
        }
    }
    showSelect = (e) => {
        e.preventDefault();
        const langPanel = document.getElementById('languageDiv');
        langPanel.style.display = "block";
        const langAsk = document.getElementById('langButton');
        langAsk.style.display = "none";
    }

    closeModalEditProfile = async () => {
        this.setState({showModal: false})
    }
    membershipCancel = async () => {
        await this.setState({showModal: true, modalAction: "membershipCancel"});
    }
    getLocation = async () => {
        return await axios.get('https://us-central1-private-social-media.cloudfunctions.net/locate')
            .then(res => {
                if (res.data.error)
                    return false;
                return res.data.country;
            });
    };
    getPiCodes = async (keyCountry, promo) => {
        axios.post('/api/v2/picode', {
            promo: promo,
            location: keyCountry
        })
            .then(res => {
                const detailsmembership = res.data;
                this.setState({
                    picodes: detailsmembership
                });
            });
    }

    isConfirmed = () => {
        const ls = localStorage.getItem('showModalCancel');
        const showModalCancel = !(!!ls);

        this.setState({
            showModalCancel
        });
        return true;
    }

    setConfirmed() {
        this.setState({
            showModalCancel: false,
            accepted: true,
        });
        setTimeout(() => {
            if (this.state.picodeSelected)
                window.location.href = this.state.picodeSelected;
        }, 600);
    }

    toggleModalConfirm = (picode) => {
        this.setState({picodeSelected: null});
        let navbar = document.getElementsByClassName("styles_content__1Phom");
        if (this.state.modalConfirm) {
            navbar[0].style.zIndex = 1;
        } else {
            navbar[0].style.zIndex = 0;
        }
        this.setState({modalConfirm: !this.state.modalConfirm, picodeSelected: picode});
    };
    renderHistoryTable = () => {
        if (this.state.history === false) {
            return (
                <tr>
                    <td colspan='3'>{this.props.dictionary.emptyHistory}</td>
                </tr>
            );
        } else {
            var copy = this.state.history;
            return copy.map((item, index) => {
                const chargeModule = (item.module);
                var modName = chargeModule.split("-", 1);
                const moduleMessage = modName + "Module";
                return (
                    <tr key={index}>
                        <td>{this.props.dictionary[moduleMessage]}</td>
                        <td>{-(item.amount)}</td>
                        <td>{moment(item.date).format('MMM DD, YY')}</td>
                    </tr>
                );
            });
        }
    };

    render() {
        if (this.state.profileFeatures === null)
            return (
                <div className="center-align" style={{paddingTop: 45}}>
                    <Circle background="transparent !important" color="#4285f4"
                            className="center-align"/>
                </div>);

        let view = this.props.view;
        const modalToView = this.state.showModalCancel
            ? <ModalConfirmCancelation
                open={this.state.modalConfirm}
                dictionary={this.props.dictionary}
                config={this.props.config}
                close={this.toggleModalConfirm}
                accepted={() => {
                    this.setConfirmed();
                }}
                height={'40%'}
            />
            : null;
        return (
            <>
                <div
                    className="container main-container redesign-profile-container"
                    style={{
                        backgroundColor: this.props.config.color.principal,
                        color: this.props.config.color.principalText
                    }}
                >
                    <div className="center-align">
                        <h5>{this.props.dictionary.membership || "Membership"}</h5>

                        {this.subscribeFeatures()}

                        <hr
                            style={{
                                backgroundColor: this.state.config.color.principalText,
                                opacity: 0.3
                            }}
                        />
                        {this.state.picodes ?
                            <Subscribe
                                picodes={this.state.picodes}
                                dictionary={this.props.dictionary}
                                config={this.props.config}
                                openModalConfirm={this.toggleModalConfirm}
                                confirmed={!this.state.showModalCancel}
                                statusModel={this.props.statusModel}
                            />
                            : null
                        }
                        {this.props.permission && this.props.permission === 1 ?
                            <div>
                                <h5 className="model-photos-title">
                                    {this.state.dictionary.bestPhotos}
                                </h5>
                                <div className="flex-same-height">
                                    {this.renderPhotos()}
                                </div>
                            </div>
                            : null}


                        {(this.state.profileFeatures.totalCoins && this.state.showModule && this.state.profileFeatures.permission !== 1) ?
                            <div
                                className='divtableCoins'
                            >
                                <table className='tableCoins'>
                                    <thead>
                                    <tr>
                                        <th
                                            style={{
                                                color: this.state.config.color.principalText
                                            }}
                                            className="th-first"
                                            data-field="nameItem"
                                        >
                                            {this.props.dictionary.article}
                                        </th>
                                        <th
                                            style={{
                                                color: this.state.config.color.principalText
                                            }}
                                            className="th-sec"
                                            data-field="amount"
                                        >
                                            {this.props.dictionary.coins}</th>
                                        <th
                                            style={{
                                                color: this.state.config.color.principalText
                                            }}
                                            className="th-third"
                                            data-field="datePurchase"
                                        >
                                            {this.props.dictionary.date}
                                        </th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {this.renderHistoryTable()}
                                    </tbody>
                                </table>
                            </div>
                            : null}

                        {(this.state.profileFeatures.membershipCanceled || (this.props.permission && this.props.permission === 1))
                            ?
                            null
                            :
                            <div className="v2-cancel-membership">
                                <button
                                    style={{
                                        backgroundColor: this.props.config.color.secondary
                                    }}
                                    className='v2-warningMembership'
                                    onClick={() => this.membershipCancel()}
                                >
                                    {this.props.dictionary.cancelMembership}
                                </button>
                            </div>
                        }
                    </div>
                </div>
                {!view ?
                    modalToView
                    : null}
                {this.state.showModal ?
                    <EditProfileModal
                        dictionary={this.props.dictionary}
                        config={this.props.config}
                        profileFeatures={this.state.profileFeatures}
                        closeModalEditProfile={this.closeModalEditProfile}
                        membershipCancel={this.membershipCancel}
                        showModal={this.state.showModal}
                        modalAction={this.state.modalAction}
                        permission={this.props.permission}
                        getPiCodes={this.props.getPiCodes}
                    />
                    : null
                }
            </>
        );
    }
}

export default Membership;
