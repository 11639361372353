import React from 'react';

const LogoutIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            fill={color}  
            viewBox="0 0 18 21"
            className = {className}
        >
            <g fill={color} opacity="1">
            <path 
                d="M9 20.05c-4.963 0-9-4.036-9-9C0 7.01 2.72 3.44 6.613 2.37c.398-.107.811.126.921.526.11.4-.125.812-.524.921-3.244.891-5.51 3.865-5.51 7.234 0 4.135 3.364 7.5 7.5 7.5s7.5-3.365 7.5-7.5c0-3.29-2.199-6.245-5.347-7.187-.397-.118-.622-.537-.503-.933.119-.397.538-.622.933-.504C15.361 3.558 18 7.104 18 11.051c0 4.963-4.037 9-9 9z"
            />
            <path 
                d="M9 9.75c-.414 0-.75-.335-.75-.75V.75C8.25.335 8.586 0 9 0s.75.335.75.75V9c0 .415-.336.75-.75.75z"
            />
            </g>
        </svg>
    )
}
export default LogoutIcon;
