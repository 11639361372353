import React, {Component} from 'react';
import Post from '../Post';
import FreePost from "../FreePost";
import DonatePost from "../Post/DonatePost";
import Modal from '../../Modal';
import MediaViewer from '../../MediaViewer';
import axios from "axios";
import cookie from "js-cookie";
import CustomProgressBar from "../../CustomProgressBar";
import ModalConfirmCancelation from "../../ModalConfirmCancelation";
import GetCoins from '../../GetCoins';

/**
 * props
 * posts = { array }
 * config = { object}
 * dictionary = { object}
 * model = { object }
 * view = { bool } => hasMembership
 * picodes = { array }
 * getCoins = { method }
 * updateOffset = { integer }
 * isSafari = { bool }
 * isIOS = { bool }
 * getProgress = { array }
 * updatePosts = { method(param) }
 */
class Posts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: null,
            postIndex: null,
            coins: 0,
            modalAction: null,
            permission: cookie.get("permission"),
            donateCoins: 0,
            showModal: false,
            showModalDonate: false,
            editingText: "",
            mediaOpened: [],
            openViewer: false,
            defaultOpen: 0,
            mediaId: null,
            disableDelete: false,
            modalConfirm: false,
            showModalCancel: false,
            accepted: false,
            picodeSelected: null,
            modalBuy: false
        };

        this.handleEditText = this.handleEditText.bind(this);
        this.setConfirmed = this.setConfirmed.bind(this);
        this.retry = this.retry.bind(this);
    }

    componentDidMount() {
        this.getCoins();
        this.hasCodeMedia();
        this.isConfirmed()
    }

    handleEditText(e) {
        let value = e.target.value;
        this.setState({editingText: value})
    }

    toggleModalConfirm = (picode) => {
        this.setState({picodeSelected: null});
        let navbar = document.getElementsByClassName("styles_content__1Phom");
        if (this.state.modalConfirm) {
            navbar[0].style.zIndex = 1;
        } else {
            navbar[0].style.zIndex = 0;
        }
        this.setState({modalConfirm: !this.state.modalConfirm, picodeSelected: picode});
    };

    isConfirmed = () => {
        const ls = localStorage.getItem('showModalCancel');
        const showModalCancel = !(!!ls);

        this.setState({
            showModalCancel
        });
        return true;
    }

    setConfirmed() {
        this.setState({
            showModalCancel: false,
            accepted: true,
        });
        setTimeout(() => {
            if (this.state.picodeSelected)
                window.location.href = this.state.picodeSelected;
        }, 600);
    }

    openViewer = async (e, post, index, only = false) => {
        if (only) {
            let video = e.target.parentElement.childNodes[0];
            if (video && e.target.tagName === "svg" && !video.paused) {
                video.pause();
                video.nextSibling.classList.remove("playing");
            }
        }

        let video;

        if(e && e.target){
            video = e.target.nodeName ==="VIDEO"
                ? e.target
                : e.target.previousElementSibling.nodeName ==="VIDEO"
                    ? e.target.previousElementSibling
                    : e.target.previousElementSibling.previousElementSibling.nodeName ==="VIDEO"
                        ? e.target.previousElementSibling.previousElementSibling
                        : null;
        }

        if(this.props.isIOS && video){
                video.webkitEnterFullscreen();
                if(video.paused) {
                    video.play();
                }
                video.nextSibling.classList.remove("playing");
                video.classList.remove("video-playing");
        }
        else{
            let sidenav = document.getElementsByClassName("styles_content__1Phom"); //put sidenav and notifymodal behind
            if (sidenav !== null && sidenav !== undefined) {
                sidenav[0].style.zIndex = 0;
            }
            document.getElementById("htmlmain").style.overflow = "hidden"
            this.setState({openViewer: true, mediaOpened: this.props.posts[post].media, defaultOpen: index, mediaId: post});
            let pushUpPosts = document.getElementById("postUp-btn");
            if (pushUpPosts !== null && pushUpPosts !== undefined) {
                pushUpPosts.style.zIndex = 0;
            }
        }
    }

    closeViewer = async () => {
        let mediaId = this.state.mediaId;
        let opened = this.state.defaultOpen;
        let viewer = document.getElementById("MediaViewer")
        let slidesViewer = viewer.getElementsByClassName('slide');
        slidesViewer = Array.from(slidesViewer);
        // eslint-disable-next-line
        slidesViewer.map((slide, index) => {
            if (slide.classList.contains("selected")) {
                opened = index;
            }
        })
        this.props.posts[mediaId].default = opened;
        let sidenav = document.getElementsByClassName("styles_content__1Phom");
        if (sidenav !== null && sidenav !== undefined) {
            sidenav[0].style.zIndex = 1;
        }
        document.getElementById("htmlmain").style.overflow = ""
        this.setState({openViewer: false, mediaOpened: [], mediaId: null});
        let pushUpPosts = document.getElementById("postUp-btn");
        if (pushUpPosts !== null && pushUpPosts !== undefined) {
            pushUpPosts.style.zIndex = 9;
        }
    }

    updateCoins = async (newCoins) => {
        this.setState({coins: newCoins});
    }

    getCoins = async () => {
        const coins = await this.props.getCoins();
        this.setState({coins: coins});
    }

    closeModalDonate = async () => {
        this.setState({showModalDonate: false})
    }

    donate = async (post, postIndex) => {
        if(this.state.coins > 0) 
            this.setState({showModalDonate: true, post: post, postIndex: postIndex})
        else this.setState({modalBuy: true})   
    }

    closeModalBuy = () => {
        this.setState({modalBuy: false})
    }

    edit = async (post, postIndex) => {
        this.setState({
            showModal: true,
            post: post,
            postIndex: postIndex,
            modalAction: "edit",
            editingText: post.post_name
        })
    }

    delete = async (post, postIndex) => {
        this.setState({showModal: true, post: post, postIndex: postIndex, modalAction: "delete"})
    }

    retry = async (post, postIndex) => {
        this.setState({showModal: true, post: post, postIndex: postIndex, modalAction: "retry"});
    }

    security = async (post, postIndex) => {
        this.setState({showModal: true, post: post, postIndex: postIndex, modalAction: "security"})
    }

    closeModalPost = async () => {
        this.closeModal();
        this.setState({post: null, postIndex: null})
    }

    hasCodeMedia = async () => {
        return await axios.get('/api/v2/model/have/code/coin')
            .then((res) => {
                this.setState({
                    showDonation: res.data.haveCoin
                });
            })
    }

    doEdit = async (e) => {
        e.preventDefault();
        let error;
        let post = this.state.post;
        let text = this.state.editingText
        if (!text) {
            this.setState({editingError: 'Please add a description'})
            error = true;
        }
        if (error) {
            return false;
        }
        const objPost = {
            idPost: post.id_post,
            textPost: text
        };
        await axios
            .post('/api/v2/editPost', objPost)
            .then(async res => {
                post.post_name = text;
                this.closeModal();
                this.setState({editingText: ""});
            });
    };
    closeModal = () => {
        let navbar = document.getElementsByClassName("styles_content__1Phom");
        if (navbar) {
            if (this.state.showModal) {
                navbar[0].style.zIndex = 1;
            } else {
                navbar[0].style.zIndex = 0;
            }
        }
        this.setState({showModal: false});
    }
    doDelete = async (e, errorOnPost = false) => {
        e.preventDefault();
        this.setState({disableDelete: true});
        let post = this.state.post;
        let index = this.state.postIndex;
        const objPost = {
            idPost: post.id_post,
            error: errorOnPost
        };
        const resDeletePost = await axios.post('/api/v2/deletePost', objPost)
            .then(res => {
                return res.data;
            });
        if (resDeletePost) {
            if (index > -1) {
                const posts = [...this.props.posts];
                posts.splice(index, 1);
                this.props.updatePosts(posts);
                this.closeModal();
                this.setState({disableDelete: false});
                this.props.updateOffset(-1)
            }
        } else {
            alert('Unexpected error, please try again or consult the site admin')
        }
    }

    doPublic = async (e) => {
        e.preventDefault();
        let post = this.state.post;
        let free = post.post_free === 1 ? 0 : 1
        const objPost = {
            idPost: post.id_post,
            idFree: free
        };
        await axios.post('/api/v2/setFreePost', objPost)
            .then(async res => {
                post.post_free = free;
                this.closeModal();
            });
    };

    doRetry = async (e) => {
        e.preventDefault();
        await this.doDelete(e, true);
        window.location.href = "/post";
    }

    drawPosts() {
        let posts = this.props.posts;
        let view = this.props.view;
        return posts.map((post, index) => {
            return (
                (view) ?
                    <Post
                        toElevate={this.props.toElevate}
                        post={post}
                        config={this.props.config}
                        dictionary={this.props.dictionary}
                        model={this.props.model}
                        view={view}
                        donate={this.donate}
                        edit={this.edit}
                        delete={this.delete}
                        retry={this.retry}
                        security={this.security}
                        index={index}
                        key={index}
                        showDonation={this.state.showDonation}
                        permission={this.state.permission}
                        openViewer={this.openViewer}
                        isSafari={this.props.isSafari}
                        isIOS={this.props.isIOS}
                    />
                    :
                    (index < 10) ?
                        <FreePost
                            post={post}
                            config={this.props.config}
                            dictionary={this.props.dictionary}
                            model={this.props.model}
                            view={view}
                            index={index}
                            key={index}
                            //Come from Containers/NewFeed
                            picodes={this.props.picodes}
                            permission={this.state.permission}
                            isSafari={this.props.isSafari}
                            isIOS={this.props.isIOS}
                            openModalConfirm={this.toggleModalConfirm}
                            // Modal only show after user confirmed your phone and still did not accept it
                            confirmed={!this.state.showModalCancel}
                            statusModel={this.props.statusModel}
                            toElevate={this.props.toElevate}
                        />
                        : null
            )
        });
    }

    drawModalPost() {
        let dictionary = this.props.dictionary;
        let post = this.state.post;
        let modalOptions = {
            edit: {
                button: dictionary.save,
                head: dictionary.editPostText,
                action: this.doEdit,
                body:
                    <div>
                        <textarea
                            value={this.state.editingText}
                            onChange={this.handleEditText}
                            style={{color: this.props.config.color.modalText}}
                        >
                        </textarea>
                        {this.state.editingError ? <p className="error">{this.state.editingError}</p> : ""}
                    </div>,
                optionsBtn: {
                    disabled: false
                }
            },
            delete: {
                button: "Delete",
                head: dictionary.delete,
                action: this.doDelete,
                body: <p>{dictionary.delPost}</p>,
                optionsBtn: {
                    disabled: this.state.disableDelete
                }
            },
            retry: {
                button: dictionary.retry || "Retry",
                head: dictionary.retry || "Retry",
                action: this.doRetry,
                body: <p>{dictionary.retryPost}</p>,
                optionsBtn: {
                    disabled: this.state.disableDelete
                }
            },
            security: {
                button: dictionary.save,
                head: post ? post.post_free ? dictionary.headerPrivate : dictionary.headerFree : "",
                action: this.doPublic,
                body: <p>{post ? post.post_free ? dictionary.bodyPrivate : dictionary.bodyFree : ""}</p>,
                optionsBtn: {
                    disabled: false
                }
            }
        }
        let option = this.state.modalAction;
        let config = this.props.config;
        return option && post ? (
            <Modal
                id="modalPost"
                open={this.state.showModal}
                closeAction={this.closeModalPost}
                head={<h4>{modalOptions[option].head}</h4>}
                config={this.props.config}
                footer={
                    <div className="actions">
                        <button
                            style={{
                                backgroundColor: config.color.secondary,
                                color: config.color.secondaryText,
                            }}
                            onClick={(e) => modalOptions[option].action(e)}
                            disabled={modalOptions[option].optionsBtn.disabled}
                        >
                            {modalOptions[option].button}
                        </button>
                    </div>
                }
                middle={this.props.middle}
            >
                {modalOptions[option].body}
            </Modal>
        ) : null;
    }

    renderProcessigVideo() {
        if (this.props.getProgress.length === 0) return;
        const copyGetProgress = [...this.props.getProgress];
        return copyGetProgress.map((item, index) => {
            return (
                <div key={index} className='center-align'>
                    <h5 style={{fontWeight: 200}}>{this.props.dictionary.processing}...</h5>
                    <CustomProgressBar
                        progress={item.percentage}
                        height="1.5em"
                        colorBack={this.props.config.color.secondaryText}
                        colorProgress={this.props.config.color.secondary}
                    />
                    <p style={{fontWeight: 200}}>{item.percentage}%</p>
                </div>
            )

        })
    }

    render() {
        let view = this.props.view;
        const modalToView = this.state.showModalCancel
            ? <ModalConfirmCancelation
                open={this.state.modalConfirm}
                picodeSelected={this.state.picodeSelected}
                dictionary={this.props.dictionary}
                config={this.props.config}
                close={this.toggleModalConfirm}
                accepted={() => {
                    this.setConfirmed();
                }}
                middle={true}
            />
            : null;
        let showDonation = this.state.showDonation;
        let permission = this.state.permission;
        if (this.props.posts.length === 0)
            return null;

        return (
            <div>
                {view
                    ? this.renderProcessigVideo()
                    : null
                }
                {this.drawPosts()}
                {view && showDonation
                    ? <DonatePost
                        toElevate={this.props.toElevate}
                        config={this.props.config}
                        dictionary={this.props.dictionary}
                        closeModalDonate={this.closeModalDonate}
                        post={this.state.post}
                        showModalDonate={this.state.showModalDonate}
                        currentCoins={this.state.coins}
                        updateCoins={this.updateCoins}
                        middle={true}
                        height={'48%'}
                        top={'52%'}
                    />
                    : null
                }
                {permission
                    ? this.drawModalPost()
                    : null
                }
                 <GetCoins
                    open={this.state.modalBuy}
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                    close={this.closeModalBuy}
                    middle={true}
                    height={'67%'}
                    top={'33%'}
                />
                <MediaViewer
                    open={this.state.openViewer}
                    media={this.state.mediaOpened}
                    closeAction={this.closeViewer}
                    config={this.props.config}
                    defaultOpen={this.state.defaultOpen}
                    isPosts={true}
                    toElevate={this.props.toElevate}
                />
                {!view
                    ? modalToView
                    : null
                }
            </div>
        )
    }
}

export default Posts;
