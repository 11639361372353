import React, { Component } from 'react';
import MediaElement from "./MediaElement";
import './style.css';

class Media extends Component{
    constructor(props) {
        super();
    }

    componentDidMount() {
    }

    playVideo = async (e) => {
        let video = e.target;
        if(video.paused) {
            video.play();
            video.nextSibling.classList.add("playing");
        }
        else{
            video.pause();
            video.nextSibling.classList.remove("playing");
        }
    }

    makeFullscreen= async(e) => {
        let icon = e.target;
        let playBtn = icon.previousSibling;
        let video = icon.previousSibling.previousSibling;
        var fullscreenDiv = document.getElementById("divfullscr");
        video.pause();
        video.nextSibling.classList.remove("playing");
        let videoCopy = video.cloneNode(true);
        let playBtnCopy = playBtn.cloneNode(true);
        
        fullscreenDiv.appendChild(videoCopy);
        playBtnCopy.classList.add("play-btn-full");
        videoCopy.after(playBtnCopy);
        videoCopy.addEventListener("click", this.playVideo);
        fullscreenDiv.style.display = 'block';

        var closeBtn = document.createElement("span");
        closeBtn.innerHTML = "&times;";  
        closeBtn.classList.add("close-fullscreen");
        closeBtn.addEventListener("click", this.closeFullscreen);
        fullscreenDiv.appendChild(closeBtn); 

        document.getElementById("htmlmain").style.overflow = "hidden";
    }

    closeFullscreen = async(e) => {
        var fullscreenDiv = document.getElementById("divfullscr");       
        fullscreenDiv.removeChild(fullscreenDiv.firstChild);
        fullscreenDiv.removeChild(fullscreenDiv.lastElementChild);
        fullscreenDiv.removeChild(fullscreenDiv.lastElementChild);
        fullscreenDiv.style.display = 'none';
        document.getElementById("htmlmain").style.overflow = "auto";
    }

    drawMedia(){
        let media = [...this.props.media];
        let length = media.length;
        let view = this.props.view;
        // eslint-disable-next-line
        return media.map((item, index) => {
            if(index < 6) {
                return (
                    <MediaElement
                        media={item}
                        length={length}
                        count={(index + 1)}
                        config={this.props.config}
                        view={view}
                        playVideo={this.playVideo}
                        picodes={(index === 0 && !view) || (this.props.indexPost === 0 && index === 0) ? this.props.picodes : false}
                        dictionary={this.props.dictionary}
                        key={index}
                        isSafari={this.props.isSafari}
                        isIOS={this.props.isIOS}
                        openModalConfirm={this.props.openModalConfirm}
                        confirmed={this.props.confirmed}
                        indexPost = {this.props.indexPost}
                        makeFullscreen = {this.makeFullscreen}
                        statusModel={this.props.statusModel}
                        name={this.props.name}
                        toElevate={this.props.toElevate}
                    />
                )
            }
        });
    }

    render(){
        if(!this.props.media.length) return null;
        return(
            <div className={(this.props.media.length > 1) ? "collage" : "only"}>
                {this.drawMedia()}
            </div>
        )
    }
}

export default Media;
