import React, {useEffect, useState, useRef} from 'react';
import '../Streaming/Streaming.css'
import "./StreamingAndroid.css";

import io from "socket.io-client";
import axios from 'axios';
import cookies from 'js-cookie';
import {WebRTCAdaptor} from "./js/webrtc_adaptor.js"
import moment from 'moment';

import CameraIcon from '../Svg/CameraIcon';
import EyeIcon from '../Svg/EyeIcon';
import PlayIcon from '../Svg/PlayIcon';

import ModalSaveStreaming from '../Streaming/ModalSaveStreaming';
import ChatStreaming from '../ChatStreaming/ChatStreaming';
import Spinner from '../Spinner/Spinner';
import SwitchCamera from '../Svg/SwitchCamera';
import ModalSwitchCamera from '../Streaming/ModalSwitchCamera/';
import toolbox from "../Toolbox/Toolbox";
import Details from '../Streaming/Details/Details';

const StreamingAndroid = props =>{
    
    const [title, setTitle] = useState('');
    const [buttonTransmission, setButtonTransmission] = useState(false);
    const [startCamera, setStartCamera] = useState(false);
    const [transmission, setTransmission] = useState(0);
    const [modelId, setModelId]  = useState(0);
    const [usersConnected, setUsersConnected] = useState(0);
    const [totalCoins, setTotalCoins] = useState(0);
    const [donators, setDonators] = useState([]);
    const [userJoined, setUserJoined] = useState('');
    const [userJoinedShow, setUserJoinedShow] = useState(false);
    const [messages, setMessages] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [user, setUser] = useState(false);
    const [pause, setPause] = useState(false);
    const [transmissionStart, setTransmissionStart] = useState(false);
    const [streamId, setStreamId] = useState(null);
    const [descriptionError, setDescriptionError] = useState('');
    const [openModalSwitchCamera, setOpenModalSwitchCamera] = useState(false);
    const [videoInput, setVideoIpunt] = useState([]);
    const [audioInput, setAudioInput] = useState([]);
    const [mobile, setMobile] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [currentStreaming, setCurrentSteaming] = useState(0);
    const [showTopDonators, setShowTopDonators] = useState(true);
    const [timer , setTimer] = useState(0);
    const [savingVideo, setSavingVideo] = useState(false);
    const [openUsersConnected, setOpenUsersConnected] = useState(false);
    const color =  props.config.color;   
    const maxVideoBitrateKbps = 900;
    const pc_config = {
        'iceServers' : [ {
        'urls' : 'stun:stun1.l.google.com:19302'
        } ]
    };
    const mediaConstraints = {
        video : true,
        audio : true
    };
    const sdpConstraints = {
        OfferToReceiveAudio : false,
        OfferToReceiveVideo : false
    };
    const token='';
    const websocketURL="wss://ant-usa-1.socialtechapps.com/WebRTCApp/websocket?rtmpForward=undefined";
    const autoRepublishEnabled = true;
    const localVideo = useRef();
    const wsConnection = useRef();
    const socketIO = useRef();
    const socketIoStreaming = useRef();
    const webRTCAdaptor = useRef(null);
    const autoRepublishIntervalJob = useRef(null);
    const pathProduction = "https://sockets.socialtechapps.com/";
    useEffect(()=>{
        localStorage.removeItem('fileName');
        currentTransmissionFn();
        LastStreaming();
        getModelId();
        const mobileL =  detectMob();
        setMobile(mobileL);
        getUserData();
    },[]);

    function detectMob() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
    
        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    };

    const currentTransmissionFn = async () => {
        const currentTransmission  = await axios.get('/api/v2/isCurrentStreaming')
        .then(async res => {
            if(res.data.streaming){
                setCurrentSteaming(res.data.streaming);
                return res.data.streaming;
            }else{
                return false;
            }
        })
        .catch(err => {
            toolbox.sendError(err, 6);
            console.log(err)
        });
        if(!currentTransmission){
            const updateLastStreaming =  await LastStreaming();
            const nowTime = new moment();
            const start = new moment(updateLastStreaming);
            const duration = moment.duration(nowTime.diff(start));
            let secondsLastStreaming = duration.asSeconds();
            if(secondsLastStreaming < 30) await timerFn(secondsLastStreaming);
        };
    };

    const LastStreaming = async  () =>{
        return await axios.get('/api/v2/LastStreaming')
        .then(res => {
                return res.data.updateTimeLastStreaming
        });
    };

    const timerFn = seconds =>{
        seconds = seconds - 1;
        setTimer(seconds);
        if(seconds >= 1) setTimeout(() => timerFn(seconds), 1000);
    };

    const pageReady = async () =>{
        let sidenav= document.getElementsByClassName("styles_content__1Phom"); //put sidenav and notifymodal behind
        if(sidenav!== null && sidenav !== undefined){
            sidenav[0].style.zIndex = 0;
        };
        await setStartCamera(true);
        await startWs();
        await initWebRTCAdaptor();
    };

    const initWebRTCAdaptor = (publishImmediately, autoRepublishEnabled) => {
        webRTCAdaptor.current = new WebRTCAdaptor({
          websocket_url : websocketURL,
          mediaConstraints : mediaConstraints,
          peerconnection_config : pc_config,
          sdp_constraints : sdpConstraints,
          localVideoId : "localVideo",
          debug:true,
          bandwidth:maxVideoBitrateKbps,
          callback : async (info, obj) => {
            if (info === "initialized") {
               setButtonTransmission(true);
              if (publishImmediately) {
                 webRTCAdaptor.current.publish(streamId, token)
              }
            } else if (info === "publish_started") {
                const streamLSStreamid = localStorage.getItem('fileName');
                     if(streamLSStreamid){
                        await saveTransmission();
                        await wsConnection.current.emit('saveDb', {start:true});
                     }else{
                        alert('Error, Try Again');
                         window.location='/streaming'
                    }


              if (autoRepublishEnabled && autoRepublishIntervalJob.current == null)
              {
                autoRepublishIntervalJob.current = setInterval( () => {
                   checkAndRepublishIfRequired();
                }, 3000);
              }
              webRTCAdaptor.current.enableStats(obj.streamId);
            } else if (info === "publish_finished") {
              //stream is being finished
            }
            else if (info === "available_devices") {
              obj.forEach(function(device){
                 if (device.kind === "videoinput") {
                    setVideoIpunt(videoInput=>[...videoInput, device]);
                 }
                 else if (device.kind === "audioinput"){
                    setAudioInput(audioInput=>[...audioInput, device]);
                 }
               });

            }else if(info === 'streamInformation'){
                // console.log(obj);
            }
            else if (info === "updated_stats") {
                console.log("Average outgoing bitrate " + obj.averageOutgoingBitrate + " kbits/sec"
                        + " Current outgoing bitrate: " + obj.currentOutgoingBitrate + " kbits/sec"
                        + " video source width: " + obj.resWidth + " video source height: " + obj.resHeight
                        + "frame width: " + obj.frameWidth + " frame height: " + obj.frameHeight
                        + " video packetLost: "  + obj.videoPacketsLost + " audio packetsLost: " + obj.audioPacketsLost
                        + " video RTT: " + obj.videoRoundTripTime + " audio RTT: " + obj.audioRoundTripTime
                        + " video jitter: " + obj.videoJitter + " audio jitter: " + obj.audioJitter);
            }
          },
          callbackError : (error, message) => {
            var errorMessage = JSON.stringify(error);
            if (typeof message != "undefined") {
              errorMessage = message;
            }
            if (error.indexOf("NotFoundError") !== -1) {
              errorMessage = props.dictionary.notFoundError;
              setButtonTransmission(false);
            }
            else if (error.indexOf("NotReadableError") !== -1 || error.indexOf("TrackStartError") !== -1) {
              errorMessage = props.dictionary.notReadableError;
              setButtonTransmission(false);
            }
            else if (error.indexOf("NotAllowedError") !== -1 || error.indexOf("PermissionDeniedError") !== -1) {
              errorMessage = props.dictionary.notAllowedError;
              setButtonTransmission(false);
            }
            else if (error.indexOf("TypeError") !== -1) {
              errorMessage = props.dictionary.typeError;
              setButtonTransmission(false);
            }
            else if (error.indexOf("WebSocketNotConnected") !== -1) {
              errorMessage = props.dictionary.lostConnection;
              saveVideo(1000);
            }
            alert(errorMessage);
          }
        });
    };

    async function startPublishing() {
        webRTCAdaptor.current.publish(streamId, token);
        setTransmissionStart(true);
        socketIoStreaming.current.emit('TRANSMITION_STREAMING', { transmition: true });
        setPause(true);
        setTimeout(()=>{
            webRTCAdaptor.current.changeBandwidth(1500,  streamId);
            assignVolume();
        }, 1000)
    };

    const stopPublishing = () => {
        if (autoRepublishIntervalJob.current != null) {
                clearInterval(autoRepublishIntervalJob.current);
                autoRepublishIntervalJob.current = null;
            }
            webRTCAdaptor.current.stop(streamId);
    };

     const checkAndRepublishIfRequired = () => {
        var iceState =  webRTCAdaptor.current.iceConnectionState(streamId);
        if (iceState === null || iceState === "failed" || iceState === "disconnected"){
            webRTCAdaptor.current.stop(streamId);
            webRTCAdaptor.current.closePeerConnection(streamId);
            webRTCAdaptor.current.closeWebSocket();
           initWebRTCAdaptor(true, autoRepublishEnabled);
        }
    };

    const getModelId= async () =>{
        await axios.get('/api/v2/get/modelId')
        .then(async res=>{
            setModelId(res.data.modelId);
        })
        .catch(err=>{
            toolbox.sendError(err, 6);
            console.log(err)
        })
    };

    const stopCamera = async (description = '', section = 0, save = false) => {
        if(transmission > 0){
            setOpenModal(false);
            setPause(true);
            stopPublishing();
            setSavingVideo(true);
            socketIO.current.emit('TRANSMITION_STREAMING', { transmition: false });
            if(save){
                const file = `${streamId}.mp4`;
                let error = false;
                if(!section){
                    alert(props.dictionary.sectionEmpty);
                    error = true;
                }
                if(!description || description.trim() === ''){
                        error = true;
                };
                if(!description || description.trim() === ''){
                    setDescriptionError(props.dictionary.addDescription);
                     error = true;
                }
                
                return await axios.post('/api/v2/streaming/create/post', {
                    description: description,
                    section: section,
                })
                .then(async res => {
                    setTimeout(()=>{
                        if(res.data.postInserted){
                            const urlEncodig = 'https://socialtechapps.com/api/v2/post/streaming/media';
                            const instance = axios.create({
                             baseURL: urlEncodig
                            });
                            const postId = res.data.postInserted;
                            instance.post(urlEncodig, {
                                hostname: window.location.hostname === 'localhost'
                                    ? 'myladydusha.com'
                                    : window.location.hostname,
                                idModel: cookies.get('username'),
                                token: cookies.get('token'),
                                postId: postId,
                                file: file,
                                ant : true
                            })
                            .then(async resp =>{
                                if(resp){
                                    if(res.data)await saveVideo(2000);
                                };
                                return;
                            });
                        }else{
                            axios.post('/api/v2/error/message/admin',{
                                error : res.data.Error
                                + ' ID Streaming :' + streamId
                                + ' Model :' +  window.location.hostname
                                + ' .Making Post'
                            });
                        }
                    }, 8000);   
                })
                .catch(error => {
                    toolbox.sendError(error, 6);
                    axios.post('/api/v2/error/message/admin',{
                        error : error
                        + ' ID Streaming :' + streamId
                        + ' Model :' +  window.location.hostname
                        + ' .Making Post'
                    });
                });
            }else{
                  saveVideo(1000);
                 return;
            };
        };
         saveVideo(1000);  
    };

    async function saveVideo(time){
        await localStorage.removeItem('streaming');
        await wsConnection.current.disconnect();
        if(socketIoStreaming.current !== undefined){
            await socketIoStreaming.current.emit('TRANSMITION_STREAMING', { transmition: false });
        }
        await localStorage.removeItem('fileName');
        setTimeout(async ()=>{
             window.location = '/';
        }, time);
    }

  const startWs = async (reconnect = false) => {
        const pathTelegram = 'https://live.socialtechapps.com/'
        wsConnection.current = io(
            pathTelegram,
            {
                "query": {
                    modelId: modelId,
                    fitness : false,
                    reconnect: reconnect
                },
                reconnectionAttempts: 3, 
                reconnection: true,
                reconnectionDelay: 5000,
                reconnectionDelayMax : 10000,
                reconnectionAttempts: Infinity,
                transports: ['websocket'],
                'force new connection': true,
            }
        ).connect();
        
        wsConnection.current.on('uuid', async id =>{
            if(id.fileName){
                await setStreamId(id.fileName);
                localStorage.setItem('fileName', id.fileName);
                await connectSocketIO(id.fileName);
                await setButtonTransmission(true);
            }
        }); 
        wsConnection.current.on('start', db =>{
            if(db.db) setPause(false);
        });
        wsConnection.current.on('disconnect', reason => {
            if(reason === 'transport error') {
                alert(props.dictionary.lostConnection);
                saveVideo(1000);
            }
        });
    };

    const connectSocketIO = async (streamID) => {
        const pathChat = 'https://livestap.com';
        socketIO.current = io(
            pathChat,
            {
                query: {
                    username: cookies.get("username"),
                    session_token: cookies.get("token"),
                    host: window.location.host,
                },
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax : 2000,
                reconnectionAttempts: Infinity
        
            }
        ).connect();
        const userLocal = {
            username: cookies.get('username'),
            room: streamID,
            image : user.image_profile
                ? props.config.storageURL
                     +'/'+props.config.storage
                     + '/profiles/'+user.image_profile
                : null,
            permission : cookies.get('permission')
        };
        socketIO.current.emit('joinRoom', userLocal);
        socketIO.current.on('message', async  (message, joined) =>{
            if(message.donation){
                if(message.donation && message.donation.coins>0){
                    const streaming = localStorage.getItem('streaming')
                    await getDonationStreaming(streaming);
                    await getDonators(streaming);
                }
            }
            if(!joined){

                setMessages(messages => [...messages, message]);
            }else{
                setTimeout(async ()=>{
                    await setUserJoined(message.username);
                    await setUserJoinedShow(true);
                    setTimeout(()=>{
                        setUserJoinedShow(false);
                    }, 1500)
                }, 1000);
            }
        });
        socketIO.current.on("reconnect_attempt", () => {
            socketIO.current.io.opts.query = {
                username: cookies.get("username"),
                session_token: cookies.get("token"),
                host: window.location.host,
            };
        });
        socketIO.current.on('chatMessage',async  (socketID,message) => {
            await setMessages(messages => [...messages, message]);
        });
        socketIO.current.on('roomUsers', ({room, users}) => {
            const result = users.filter( e => e.permission === 0);
            setUsersConnected(result.length);
        }); 
        socketIoStreaming.current =  await io(pathProduction, {
            path: "/api/v2/messages-sever/streaming",
            query: {
                username: cookies.get("username"),
                session_token: cookies.get("token"),
                host: window.location.host,
            },
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 2000,
            reconnectionAttempts: Infinity,
        });
    };

    const saveTransmission = async () => {
        return await axios.post('/api/v2/streaming',{name : title})
        .then( res => {
            if(res.data.Success){
                setTransmission(res.data.Success);
                localStorage.setItem('streaming', res.data.Success);
                setStartTime(res.data.startTime);
                return res.data.Success;
            }

        })
        .catch(err => {
            toolbox.sendError(err, 6);
            console.log(err);
        });
    };

    async function getDonationStreaming(thisTransmission){
        const params = await {
            streaming : thisTransmission || transmission
        }
        await axios.get('/api/v2/get/donation/streaming', {params})
            .then(resp =>{
                setTotalCoins(resp.data.coins || 0);
            });
    };

    const getDonators = async (streamingId) => {
        const id = streamingId !== 0 && streamingId;
        await axios.get('/api/v2/streaming_obs/top_donators',{
            params: {streamingId:id}
        })
        .then(res => {
            if(res.data)setDonators(res.data);
        })
        .catch(err=>{
            toolbox.sendError(err, 6);
            console.log(err)}
        );
    };

    const showModal =  async () => {
        setOpenModal(!openModal);
    };    

    const getUserData = async () => {
        const userResp =  await axios.post('/api/v2/getUserDetails')
            .then(async res => {
                return res.data
            })
            .catch(err => {
                toolbox.sendError(err, 6);
                console.log(err);
            });
        setTimeout(async ()=>{
            await setUser(userResp);
        }, 1000)
        
    };

    const getMessageFunc = (message) =>{
        let permission = cookies.get('permission');
        const data={
            username: cookies.get('username'),
            message: message,
            image : user.image_profile ? props.config.storageURL+'/'+props.config.storage + '/profiles/'+user.image_profile : null,
            permission,
            streaming: transmission,
            time : new Date(),
        };
        socketIO.current.emit('chatMessage', data);
    };

    function switchCamera(){
        setOpenModalSwitchCamera(!openModalSwitchCamera);
    };

    function switchVideoMode(videoId) {
          webRTCAdaptor.current.switchVideoCameraCapture(streamId, videoId);
          setOpenModalSwitchCamera(false);
    };

    function assignVolume(){
        webRTCAdaptor.current.currentVolume = 8
        if(webRTCAdaptor.current.soundOriginGainNode != null){
            webRTCAdaptor.current.soundOriginGainNode.gain.value = 8; // Any number between 0 and 1.
        }

        if(webRTCAdaptor.current.secondStreamGainNode != null){
            webRTCAdaptor.current.secondStreamGainNode.gain.value = 8; // Any number between 0 and 1.
        }
    };

    return(<div className="streaming-container" style={{height:!startCamera?'60vh':''}}>
        {!startCamera?
            <div className="col__steaming-90 margin__auto">
                {currentStreaming ?
                    <p>
                        {props.dictionary.currentTransmission}
                    </p>
                :<>
                    <input 
                        placeholder = {props.dictionary.titleStreaming}
                        className = "input__start__streaming"
                        type =  "text"
                        name="name-stream"
                        style = {{
                            backgroundColor:"transparent",
                            color :  color.principalText,
                            border : "solid 2px !important",
                            borderColor:  color.principal
                        }}
                        onKeyUp = {e => setTitle(e.target.value)}
                    />

                    <button
                        className="btn__start__streaming"
                        style = {{
                            background : (!user || (timer >= 1))? '#bcbcbc': color.secondary,
                            color: color.secondaryText
                        }}
                        disabled = {(!user || (timer >= 1))}
                        onClick = {()=>pageReady()}
                    >
                        {timer >= 1
                            ?
                                parseInt(timer)
                            :
                            <>
                                <CameraIcon
                                    className = "image__camera__streaming"
                                    width = "22px"
                                    height = "22px"
                                    color = {color.secondaryText}

                                />
                                &nbsp;&nbsp; <span className="text__btn__start__streaming">{props.dictionary.stcam}</span>
                            </>
                        }
                    </button>
                </>}
            </div>
        :<div className={(transmission ? "on" : "") + " transmition transmition-model"} id="transmition">
            {(videoInput.length > 1 && !transmissionStart) &&
                <span 
                    className = "switch__camera"
                    onClick = {switchCamera}
                >
                <SwitchCamera      
                        fillArrow={'#000000'}  
                        fillCamera={props.config.color.secondary}
                />
                </span>
            }
            <video playsInline
                ref={localVideo}
                id="localVideo"
                autoPlay
                muted
                className={(!transmission ?(mobile?'mobile ':'video '):'video') + (pause?'blur':'')}
                ></video>
            <div className="logo">
                <img src={props.config.storageURL+props.config.logo} className="responsive-img" alt={props.config.nameModel} />
            </div>
            {transmission === 0 ?
                <ul className={mobile?'mobile__actions': "actions"}>
                    <li className="mobile__actions__li">
                        <button
                            className="btn__cancel__streaming"
                            onClick={()=>stopCamera()}
                        >
                            {props.dictionary.close}
                        </button>
                    </li>
                    <li className="mobile__actions__li">
                        <button
                            className={!buttonTransmission?'btn__disabled':"btn__start__transmition"}
                            disabled={!buttonTransmission}
                            onClick={()=>startPublishing()}
                            style={{background: buttonTransmission?color.secondary:'rgba(204, 204, 204, 0.7)', color: color.secondaryText}}
                        >
                            {props.dictionary.startLive}
                        </button>
                    </li>
                </ul>
            :
            <>
                {!savingVideo &&
                <>
                    <div className="meta-info">
                        <div className="users__connected__transmition">
                            <div  className="btn__connected__transmition">
                                <div className="row__streaming-50" style={{color:props.config.color.secondary}} >
                                        <EyeIcon
                                        color={props.config.color.secondary}
                                        className="eye__icon"
                                        height="19px"
                                    />
                                </div>
                                <div className="row__streaming-50" style={{color:props.config.color.secondaryText}}>
                                    {usersConnected}
                                </div>
                            </div>
                        </div>
                        <div className="total__donations__transmition">
                            <div className="btn__donations__transmition" >
                                <div className="row__streaming-50" style={{position:'relative'}}>
                                    <span className="indicator__total__donations" style={{color:props.config.color.secondary}}> {totalCoins}</span>
                                </div>
                                <div className="row__streaming-50" style={{color:props.config.color.secondaryText}}>
                                    {props.dictionary.donations}
                                </div>

                            </div>
                        </div>
                        <div className="live__indicator__transmition">
                            <span className="btn__live__indicator">
                                <div className="row__streaming-50"
                                    onClick = {videoInput.length===1 ? () => console.log() : switchCamera}
                                >
                                {videoInput.length === 1 ?
                                    <PlayIcon
                                        color={props.config.color.secondary}
                                        className="play__icon"
                                    />
                                :<SwitchCamera
                                    fillArrow={props.config.color.principalText}
                                    fillCamera={props.config.color.secondary}
                                    className="play__icon"
                                />}
                                </div>
                                <div className="row__streaming-50">
                                {videoInput.length === 1 ?
                                    <span style={{color:props.config.color.secondaryText}}> {props.dictionary.live} </span>
                                :
                                    <span style={{color:props.config.color.secondaryText}}>{props.dictionary.switch}</span>
                                }
                                </div>
                            </span>
                        </div>
                        <div className="indicator__stop__transmition">
                            <ModalSaveStreaming
                                show={openModal}
                                onCancel={()=>showModal()}
                                config={props.config}
                                dictionary={props.dictionary}
                                sections={props.sections}
                                title={title}
                                descriptionError={descriptionError}
                                stopCamera ={stopCamera}
                                blackModal={true}
                            />
                            <button
                                onClick={()=>showModal()}
                                className="btn__stop__transmition"
                                style={{background: savingVideo?'#bcbcbc':props.config.color.secondary, color: props.config.color.secondaryText}}
                                disabled={savingVideo}
                                >
                                    {props.dictionary.stop}
                            </button>
                        </div>
                    </div>
                        <ChatStreaming 
                            config={props.config}
                            dictionary={props.dictionary}
                            getMessageFunc = {getMessageFunc}
                            user = {user}
                            isClient = {false}
                            messages={messages}
                            userJoined = {userJoined}
                            userJoinedShow = {userJoinedShow}
                            startTime = {startTime}
                        />
                        <Details 
                            donators = {donators}
                            obs = {false}
                            config = {props.config}
                            show = {showTopDonators}
                        />
                </>
                }
                {(pause || !transmissionStart) &&
                    <div className="reconnecting">
                        <Spinner />
                        <br />
                        {!pause ?
                            <span>{props.dictionary.conctStreaming}</span>
                        :
                            <span>{props.dictionary.svStreaming}</span>
                        }
                    </div>
                }
            </>
            }
             <ModalSwitchCamera 
                show={openModalSwitchCamera}
                onCancel={()=>switchCamera()}
                config={props.config}
                dictionary={props.dictionary}
                videoInput = {videoInput}
                header={''}
                footer={''}
                blackModal={true}
                switchVideoMode={switchVideoMode}
            />
        </div>}
    </div>)
};

export default StreamingAndroid;