import React from 'react';

const HomeIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 20 18"
            className = {className}
        >
            <path 
                fill={color} 
                d="M20 14.187V6.53c0-1.426-.834-2.72-2.132-3.31L11.505.326c-.956-.435-2.054-.435-3.01 0L2.132 3.219C.833 3.808 0 5.103 0 6.529v7.658c0 2.009 1.628 3.637 3.636 3.637h4.86v-6.46h3.009v6.46h4.859c2.008 0 3.636-1.628 3.636-3.637z"
            />
        </svg>
    )
}
export default HomeIcon;
