import React from 'react';

const DropDownIcon = props =>{
    return(<svg xmlns="http://www.w3.org/2000/svg" 
                width={props.width} 
                height={props.height} 
                fill="none" 
                className={props.className ? props.className : null}
                viewBox="0 0 20 20"
                onClick={props.onClick && props.onClick}
            >
                <circle 
                    cx="10" 
                    cy="10" 
                    r="10" 
                    fill={props.primary} 
                    transform="rotate(90 10 10)"
                />
                <path 
                    fill={props.secondary} 
                    stroke={props.secondary} 
                    strokeWidth=".5" 
                    d="M10.001 11.023L6.775 7.797c-.112-.112-.293-.112-.404 0-.112.111-.112.292 0 .404l3.428 3.428c.112.112.293.112.404 0l3.43-3.428c.109-.114.106-.295-.008-.404-.11-.107-.286-.107-.397 0l-3.227 3.226z"
                />
        </svg>
    )
}
export default DropDownIcon;