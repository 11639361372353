import React, {Component} from 'react';
import axios from 'axios';
import moment from 'moment';

class PanelSupport extends Component {
    constructor() {
        super()
        this.state = {
            user: '',
            details: '',
            usuarioNoValido: false
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.getUserDetails = this.getUserDetails.bind(this);
        this.keyPress = this.keyPress.bind(this);
    }

    changeHandler(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    getUserDetails() {
        axios.post('/api/v2/getDetailsByUsernameEmail', {user: this.state.user})
            .then(resp => {
                if (resp.data !== "Not Found") {
                    this.setState({details: resp.data, usuarioNoValido: false})
                }
            })
            .catch((err) => {

                this.setState({details: '', usuarioNoValido: true})
            })
    }

    keyPress(event) {
        if (event.key === 'Enter') {
            this.getUserDetails()
        }
    }

    canlendar = (time) => {
        let calendar =
            moment(time).calendar(null, {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'dddd',
                lastDay: '[Yesterday]',
                lastWeek: '[Last] dddd',
                sameElse: 'DD/MM/YYYY'
            });
        return calendar;
    }

    render() {

        return (
            <div
                className="container main-container center-align"
            >
                <h5>
                    {this.props.dictionary.panelSupport}
                </h5>
                <div className="input-profile">
                    <label className="profileLabel" htmlFor="input_1">
                        {this.props.dictionary.user}
                    </label>
                    <input
                        type="text"
                        name="user"
                        id="input_1"
                        placeholder={this.props.dictionary.user}
                        style={{color: this.props.config.color.principalText}}
                        defaultValue={this.state.user}
                        onKeyPress={this.keyPress}
                        onChange={(e) => this.changeHandler(e)}
                    />
                </div>
                <button
                    type="submit"
                    onClick={this.getUserDetails}
                    className="log-btn"
                    style={{
                        backgroundColor: this.props.config.color.secondary,
                        color: this.props.config.color.secondaryText,
                        marginTop: 0,
                        marginBottom: 20
                    }}
                >
                    {this.props.dictionary.getDetails}
                </button>
                {this.state.details && !this.state.details.sinHistoricoMembresia ?
                    <table>
                        <tbody>
                        <tr>
                            <th>{this.props.dictionary.name}</th>
                            <td>{this.state.details.username}</td>
                        </tr>
                        <tr>
                            <th>{this.props.dictionary.email}</th>
                            <td>{this.state.details.email}</td>
                        </tr>
                        <tr>
                            <th>{this.props.dictionary.typeMembership}</th>
                            <td>{this.state.details.payment_period}</td>
                        </tr>
                        <tr>
                            <th>{this.props.dictionary.membership}</th>
                            <td>{this.state.details.membership ? 'Yes' : 'No'}</td>
                        </tr>
                        <tr>
                            <th>{this.props.dictionary.firstPayment}</th>
                            <td>{this.canlendar(this.state.details.primerPago)}</td>
                        </tr>
                        <tr>
                            <th>{this.props.dictionary.paydayLimit}</th>
                            <td>{this.canlendar(this.state.details.payday_limit)}</td>
                        </tr>
                        <tr>
                            <th>{this.props.dictionary.receiveNotification}</th>
                            <td>{this.state.details.mail_notification_message ? 'active' : 'disabled'}</td>
                        </tr>
                        <tr>
                            <th>{this.props.dictionary.userSince}</th>
                            <td>{this.canlendar(this.state.details.usuarioDesde)}</td>
                        </tr>
                        </tbody>
                    </table>
                    : (this.state.details.sinHistoricoMembresia) ?
                        <table>
                            <tbody>
                            <tr>
                                <th>{this.props.dictionary.name}</th>
                                <td>{this.state.details.username}</td>
                            </tr>
                            <tr>
                                <th>{this.props.dictionary.email}</th>
                                <td>{this.state.details.email}</td>
                            </tr>
                            <tr>
                                <th>{this.props.dictionary.membership}</th>
                                <td>The user has never purchased the membership.</td>
                            </tr>
                            <tr>
                                <th>{this.props.dictionary.userSince}</th>
                                <td>{this.canlendar(this.state.details.usuarioDesde)}</td>
                            </tr>
                            </tbody>
                        </table>
                        : ''
                }
                {this.state.usuarioNoValido ? <h5>No data</h5> : ''}
            </div>
        )
    }
}

export default PanelSupport;
