import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Circle} from 'react-preloaders';
import axios from 'axios';
import ReactGA from 'react-ga';
import Toolboox from "./components/Toolbox/Toolbox";
import SideNav from "./components/Navigation/SideNav";
import Home from './containers/Home';
import Analytics from "./containers/Analytics";
import Messages from './containers/Chat';
import Terms from './containers/Terms';
import Privacy from './containers/Privacy';
import TermsCreator from './containers/TermsCreator';
import Support from './containers/Support';
import FAQ from './containers/Support/FAQ';
import CreateAccount from './containers/Support/Blog/CreateAccount';
import GetMembership from './containers/Support/Blog/GetMembership';
import ForgotPassword from './containers/Support/Blog/ForgotPassword';
import RecoverPassword from './containers/RecoverPassword';
import Compliances from './containers/Compliances';
import ChangePassword from './containers/ChangePassword';
import ChangePasswordSupport from './containers/Support/Blog/ChangePassword';
import CancelMembership from './containers/Support/Blog/CancelMembership';
import PanelSupport from './containers/PanelSupport';
import Login from './containers/Login';
import Register from './containers/Register';
import Settings from './containers/Settings';
import io from "socket.io-client";
import Push from 'push.js';
import MembershipPayment from "./containers/Support/Blog/MembershipPayment/MembershipPayment";

import Feed from './containers/NewFeed';
import NewPost from './containers/NewPost';
import MediaBox from "./containers/MediaBox";
import Profile from './containers/NewProfile';
import Membership from './containers/Membership';
import Activity from './containers/Activity';
import Purchases from './containers/Purchases';
import HomeInactive from './components/HomeInactive';
import HomeMaintenance from './components/HomeMaintenance';
import News from './containers/News';
import VideocamIcon from './components/Svg/VideocamIcon/VideocamIcon';

import cookies from "js-cookie";

import './App.css';
import './theme/colors.css';


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            loading: true,
            membership: false,
            permission: false,
            model: [],
            recoverMode: false,
            usrLanguage: false,
            config: [],
            dictionary: [],
            userActive: true,
            streaming: false,
            landingPage: 1,
            rating: null,
            isSafari: false,
            isIOS: false,
            description: null,
            statusModel: 1,
            coins: 0,
            canSellCoins: false,
            openStream: false,
            streamingInterval : null,
            update: false,
        };

        this.getCoins = this.getCoins.bind(this);
        this.getPiCodes = this.getPiCodes.bind(this);
        this.socketStream = null;
        this.toElevate = {
            pushNotification: this.notification.bind(this),
            getCoins: this.getCoins.bind(this),
            spendCoins: this.spendCoins.bind(this),
            confirmPurchase: this.getModalConfirmPurshase.bind(this),
            canSellCoin: new Promise(async (resolve) => {
                axios.get('/api/v2/model/have/code/coin')
                    .then((res) => {
                        return resolve(res.data.haveCoin);
                    })
                    .catch(err => resolve(false));

            }),
            setOpenStream: this.setOpenStream.bind(this),
            streamoff: this.streamOff.bind(this),
            streaming: this.state.streaming,
            forceUpdate: this.forceUpdate.bind(this),
        };
    }

    componentDidUpdate() {
        this.toElevate.streaming = this.state.streaming
    }

    componentDidMount() {
        this.isIOS();
        document.addEventListener('contextmenu', event => event.preventDefault());

        this.uploadFront()
            .then(() => {
                if (this.state.statusModel) {
                    axios.get('/api/v2/authenticated')
                        .then(res => {
                            if (res.data) {
                                this.socketStream = io(
                                    "https://sockets.socialtechapps.com/",
                                    {
                                        path: "/api/v2/messages-sever/streaming",
                                        query: {
                                            username: cookies.get("username"),
                                            session_token: cookies.get("token"),
                                            host: window.location.host,
                                        },
                                        reconnection: true,
                                        reconnectionDelay: 1000,
                                        reconnectionDelayMax: 2000,
                                        reconnectionAttempts: Infinity,
                                    },
                                    {secure: true}
                                );

                                this.socketStream.on("reconnect_attempt", () => {
                                    this.socketStream.io.opts.query = {
                                        username: cookies.get("username"),
                                        session_token: cookies.get("token"),
                                        host: window.location.host,
                                    };
                                });

                                this.socketStream.on('SHOW-STREAMING', (data, host) => {
                                    const currHost = window.location.host;
                                    if (host === currHost) {
                                        if (this.state.permission !== 1 && this.state.permission !== 4) {
                                            this.setState({streaming: data.transmition});
                                        }
                                    }

                                });

                                setTimeout(() => {
                                    this.isStreaming();
                                }, 7000);

                                if(!res.data.permission) {
                                    this.getBonusActivity();
                                    this.toElevate.canSellCoin
                                        .then(async canSellCoins => {
                                            if(canSellCoins) {
                                                const coins = await this.getCoins();
                                                this.setState({canSellCoins, coins});
                                            }
                                        })
                                }
                                this.toElevate.authenticated = true;
                                this.setState({
                                    authenticated: true,
                                    loading: false,
                                    membership: res.data.membership,
                                    permission: res.data.permission,
                                    model: res.data.model,
                                });
                            } else
                                this.setState({authenticated: false, loading: false});

                        }).catch(() => {
                            this.setState({authenticated: false, loading: false})
                        });

                    this.recoverMode();
                } else {
                    this.setState({authenticated: false, loading: false});
                }
                this.fireTracking();
                setTimeout(() => {
                    this.uploadBackground();
                }, 3000);
            });
    }

    /**
     *
     * @param keyCountry
     * @param promo
     * @returns {Promise<void>}
     */
    getPiCodes = async (keyCountry = null, promo = null) => {
        return new Promise((resolve) => {
            axios.post('/api/v2/picode', {
                promo: promo,
                location: keyCountry
            })
                .then(res => {
                    return resolve(res.data);
                });
        })
    }

    forceUpdate() {
        this.setState({update: !this.state.update})
    }

    setOpenStream = () => {
        this.setState({openStream: true})
    }

    streamOff = () => {
        this.setState({streaming: false})
    }

    getBonusActivity = async () => {
        return new Promise((resolve) => {
            axios.get('/api/v2/bonus/coins/user')
                .then(res => {
                    this.toElevate.bonusActivity = res.data;
                    return resolve(res.data);
                });
        })
    }

    // Maybe can make more things after
    spendCoins() {
        this.getCoins().then(coins => this.setState({coins}));
    }

    isSafari() {
        const safari = !!(navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') === -1 &&
            navigator.userAgent.indexOf('FxiOS') === -1);
        this.setState({
            isSafari: safari
        });

        return safari;
    }

    isIOS() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const isSafari = this.isSafari();
        const isIOS = !!((/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) || isSafari)
        this.setState({
            isIOS: isIOS
        });

        return isIOS;
    }

    /**
     * Initial configuration loaded from storage or request
     * @returns {Promise<void>}
     */
    async uploadFront() {
        const existUpdate = await axios.get('/api/v2/update/site/front')
            .then(resp => {
                this.setState({
                    landingPage: resp.data.landingPage.landingPage,
                    description: resp.data.landingPage.description ? resp.data.landingPage.description : null,
                    statusModel: resp.data.landingPage.statusModel,
                    rating: resp.data.rating ? resp.data.rating : null
                });
                return resp.data.lastUpdate;
            });

        const localUpdate = new Date(localStorage.getItem('lastUpdate'));
        const existConfig = !!localStorage.getItem('config');
        const existDictionary = !!localStorage.getItem('dictionary');

        if (existUpdate) {
            const lastUpdate = new Date(existUpdate);

            //Exist new update
            if (localUpdate < lastUpdate) {
                await this.setConfig();
                await this.getDictionary()
                localStorage.removeItem('footerData');
                localStorage.removeItem('metas');
                localStorage.setItem('lastUpdate', lastUpdate.toString());
            } else {
                if (!existConfig)
                    await this.setConfig();
                if (!existDictionary)
                    await this.getDictionary();

                let config = localStorage.getItem('config');
                if (config) {
                    config = JSON.parse(config);
                    if (!config.videoStorageURL){
                        config.videoStorageURL = config.storageURL;
                    }
                    if (window.location.hostname === "localhost") {
                        config.storageURL = config.videoStorageURL;
                    }
                    let dictionary = localStorage.getItem('dictionary');
                    dictionary = JSON.parse(dictionary);
                    //Maybe can refactor
                    document.getElementById("bodyMain").style.backgroundColor = config.color.navbar;

                    this.setState({config: config, dictionary: dictionary})
                }
            }
        }

    }

    fireTracking() {
        ReactGA.initialize(this.state.config.googleAnalytics);
    }

    setConfig = async () => {
        await axios.post('/api/v2/siteConfig')
            .then(async res => {
                if (res.data) {
                    localStorage.setItem('config', res.data.model_front_config);
                    let config = JSON.parse(res.data.model_front_config);
                    if (!config.videoStorageURL){
                        config.videoStorageURL = config.storageURL;
                    }
                    if (window.location.hostname === "localhost") {
                        config.storageURL = config.videoStorageURL;
                    }
                    this.setState({config: config});
                }
            })
    };

    getDictionary = async () => {
        await axios.post('/api/v2/diccionary')
            .then(async res => {
                let dictionary = JSON.parse(res.data.dictionary);
                dictionary.languageKey = res.data.language;
                const dictionaryLocalSt = JSON.stringify(dictionary);
                localStorage.setItem('dictionary', dictionaryLocalSt);
                await this.setState({dictionary: dictionary});
            });
    };

    searchUserLanguage = async () => {
        return await axios.get("/api/v2/userLanguage")
            .then(resp => {
                this.setState({usrLanguage: resp.data || false});
                return resp.data;
            });
    };

    uploadBackground() {
        let configP = localStorage.getItem('config');
        if (configP) {
            let configParse = JSON.parse(configP);
            setTimeout(() => {
                document.getElementById("bodyMain").style.backgroundColor = configParse.color.navbar;
            }, 1000);
        }
    }

    getCoins = async () => {
        return await axios.get('/api/v2/get/coins')
            .then(resp => {
                return resp.data.coins;
            }).catch(() => 0);

    };

    isStreaming = () => {
        axios.get('/api/v2/isCurrentStreaming').then((res) => {
            if (res.data.streaming) {
                this.setState({streaming: true})
                let sidenav = document.getElementsByClassName("styles_content__1Phom")[0]; //put sidenav and notifymodal behind
                if (sidenav !== null && sidenav !== undefined) {
                    sidenav.style.zIndex = 0;
                }
            } else {
                this.setState({streaming: false})
            }
        })
    };

    async notificationPermission() {
        return Push.Permission.request(() => console.log(Push.Permission.DEFAULT));
    }

    async getModalConfirmPurshase() {
        let data;
        let params = Toolboox.searchParams();
        if (params && params.ans) {
            if (params.ans[0] === "Y") {
                if (this.state.authenticated) {
                    if (((this.state.permission === 0 && this.state.membership) || this.state.permission === 4) && !params.x_parameters) {
                        data = {
                            "showModalPushase": true,
                            "modalAction": "newMembership",
                        };
                        return (data);

                    } else if (params.x_parameters && ((this.state.permission === 0 && this.state.membership) || this.state.permission === 4)) {
                        data = {
                            "showModalPushase": true,
                            "modalAction": "moreCoins",
                            "price": params.auth_amount,
                            "picode": params.x_parameters
                        };
                        return (data);
                    } else {
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYRETRY"
                        };
                        return (data);
                    }
                } else {
                    data = {
                        "showModalPushase": true,
                        "modalAction": "Error",
                        "errorText": "NMYRETRY"
                    };
                    return (data);
                }
            } else if (params.ans[0] === "N") {
                let nameError = params.ans.split("+");
                switch (nameError[0]) {
                    case "NMYNEGDB":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYNEGDB"
                        };
                        return (data); // eslint-disable-next-line
                        break;
                    case "NMYCEH":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYCEH"
                        };
                        return (data); // eslint-disable-next-line
                        break;
                    case "NDECLINED":
                    case "NDECLINEDE":
                    case "NCALLCENTER":
                    case "NDECLINEDINSFUNDS":
                    case "NMYINVAMT":
                    case "NDECLINEDCVV2":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NDECLINED"
                        };
                        return (data); // eslint-disable-next-line
                        break;
                    case "NMYBLOCKEDZIP":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYBLOCKEDZIP"
                        };
                        return (data); // eslint-disable-next-line
                        break;
                    case "NMYSERVNOTALLOWED":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYSERVNOTALLOWED"
                        };
                        return (data); // eslint-disable-next-line
                        break;
                    case "NMYDNVEL":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYDNVEL"
                        };
                        return (data); // eslint-disable-next-line
                        break;
                    case "NMYNOTACCEPTED":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYNOTACCEPTED"
                        };
                        return (data); // eslint-disable-next-line
                        break;
                    case "NMYDENIED":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYDENIED"
                        };
                        return (data); // eslint-disable-next-line
                        break;
                    case "NMYRETRY":
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYRETRY"
                        };
                        return (data); // eslint-disable-next-line
                        break;
                    default:
                        data = {
                            "showModalPushase": true,
                            "modalAction": "Error",
                            "errorText": "NMYRETRY"
                        };
                        return (data);
                }
            } else {
                let data = {"showModalPushase": false};
                return (data);
            }
        } else {
            let data = {"showModalPushase": false};
            return (data);

        }
    }

    async notification() {
        if (this.state.authenticated) {
            const ua = navigator.userAgent.toLowerCase();
            //Pay for it
            let isSafari = (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1);
            if (!isSafari) {
                await this.notificationPermission();
                if (Push.Permission.has()) {
                    // active
                    window.addEventListener('focus', () => {
                        this.setState({userActive: true})
                    });
                    // Inactive
                    window.addEventListener('blur', () => {
                        this.setState({userActive: false})
                    });
                    this.socket = io("https://sockets.socialtechapps.com/", {
                        path: "/api/v2/messages-sever/notification",
                        query: {
                            username: cookies.get("username"),
                            session_token: cookies.get("token"),
                            host: window.location.host,
                        },
                        reconnection: true,
                        reconnectionDelay: 1000,
                        reconnectionDelayMax: 2000,
                        reconnectionAttempts: Infinity,
                    });
                    this.socket.on("reconnect_attempt", () => {
                        this.socket.io.opts.query = {
                            username: cookies.get("username"),
                            session_token: cookies.get("token"),
                            host: window.location.host,
                        };
                    });
                    this.socket.on('RECEIVE_NOTIFICATION', (socketId, message) => {
                        if (window.location.pathname !== '/messages' || !this.state.userActive) {
                            sendNotification(message);
                        }
                    });
                    this.socketNotification = io(
                        "https://sockets.socialtechapps.com/",
                        {
                            path: "/api/v2/messages-sever/notificationDefault",
                            query: {
                                username: cookies.get("username"),
                                session_token: cookies.get("token"),
                                host: window.location.host,
                            },
                            reconnection: true,
                            reconnectionDelay: 1000,
                            reconnectionDelayMax: 2000,
                            reconnectionAttempts: Infinity,
                        }
                    );
                    this.socketNotification.on('RECEIVE_NOTIFICATION', (message, host) => {
                        const currHost = window.location.host;
                        if (host === currHost)
                            sendNotification(message);
                    });
                    const sendNotification = (message) => {
                        Push.create(this.state.config.nameModel, {
                            body: message,
                            icon: this.state.config.storageURL + this.state.config.logo,
                            timeout: 4000,
                            onClick: function () {
                                window.focus();
                                this.close();
                            }
                        });

                    };
                }
            }

        }

    }

    recoverMode = () => {
        const pathArray = window.location.pathname.split('/');
        if (pathArray[1] === 'recoverPassword') {
            const url_string = window.location.href;
            const url = new URL(url_string);
            const token = url.searchParams.get("token");
            axios.get('/api/v2/getTokenToRecoverPassword/' + token).then(res => {
                if (res.data.token) {
                    this.setState({
                        recoverMode: res.data
                    });
                }
            });
        }
    };

    renderObject = () => {
        const {authenticated, permission} = this.state;
        if (!authenticated)
            return (
                <Switch>
                    <Route exact path="/">
                        <Home isActive={this.state.statusModel}
                              description={this.state.description}
                              toElevate={this.toElevate}
                              dictionary={this.state.dictionary}
                              config={this.state.config}
                              landingPage={this.state.landingPage}
                              rating={this.state.rating}
                        />
                    </Route>
                    <Route exact path="/login">
                        <Login toElevate={this.toElevate}
                               dictionary={this.state.dictionary}
                               config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/privacy">
                        <Privacy toElevate={this.toElevate}
                                 dictionary={this.state.dictionary}
                                 config={this.state.config}
                        />
                    </Route>
                     <Route exact path="/termsCreator">
                        <TermsCreator 
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/register">
                        <Register toElevate={this.toElevate}
                                  dictionary={this.state.dictionary}
                                  config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/recoverPassword">
                        <RecoverPassword
                            toElevate={this.toElevate}
                            key={this.state.recoverMode.userId}
                            userToken={this.state.recoverMode}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>

                    <Route exact path="/terms">
                        <Terms toElevate={this.toElevate}
                               dictionary={this.state.dictionary}
                               config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support">
                        <Support
                            getPiCodes={this.getPiCodes}
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                            update={this.state.update}
                        />
                    </Route>
                    <Route exact path="/support/faq">
                        <FAQ
                            getPiCodes={this.getPiCodes}
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                        />
                    </Route>
                    <Route exact path="/support/blog/create_account">
                        <CreateAccount
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/get_membership">
                        <GetMembership
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/recover_password">
                        <ForgotPassword
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/change_password">
                        <ChangePasswordSupport
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/cancel_membership">
                        <CancelMembership
                            getPiCodes={this.getPiCodes}
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/membership_payment">
                        <MembershipPayment
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/compliances">
                        <Compliances
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route path="*">
                        <Home isActive={this.state.statusModel}
                              description={this.state.description}
                              toElevate={this.toElevate}
                              dictionary={this.state.dictionary}
                              config={this.state.config}
                              landingPage={this.state.landingPage}
                              rating={this.state.rating}
                        />
                    </Route> 
                </Switch>
            );
        // Normal user
        if (!permission) {
            return (
                <Switch>
                    <Route exact path="/">
                        <Feed
                            isSafari={this.state.isSafari}
                            isIOS={this.state.isIOS}
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            getCoins={this.getCoins}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                            statusModel={this.state.statusModel}
                        />
                    </Route>
                    <Route exact path="/messages">
                        <Messages
                            isSafari={this.state.isSafari}
                            isIOS={this.state.isIOS}
                            streaming={this.state.streaming}
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            model={this.state.model}
                            permission={this.state.permission}
                            view={!!this.state.membership}
                        />
                    </Route>
                    <Route exact path="/profile">
                        <Profile
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                        />
                    </Route>
                    <Route exact path="/membership">
                        <Membership
                            getPiCodes={this.getPiCodes}
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                            statusModel={this.state.statusModel}
                        />
                    </Route>
                    <Route exact path="/compliances">
                        <Compliances
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/changePassword">
                        <ChangePassword
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/purchases">
                        <Purchases
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                        />
                    </Route>
                    <Route exact path="/activity">
                        <Activity
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                        />
                    </Route>
                    <Route exact path="/terms">
                        <Terms toElevate={this.toElevate}
                               dictionary={this.state.dictionary}
                               config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/privacy">
                        <Privacy
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support">
                        <Support
                            getPiCodes={this.getPiCodes}
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                            update={this.state.update}
                        />
                    </Route>
                    <Route exact path="/support/faq">
                        <FAQ
                            getPiCodes={this.getPiCodes}
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                        />
                    </Route>
                    <Route exact path="/support/blog/create_account">
                        <CreateAccount
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/get_membership">
                        <GetMembership
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/recover_password">
                        <ForgotPassword
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/change_password">
                        <ChangePasswordSupport
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/cancel_membership">
                        <CancelMembership
                            getPiCodes={this.getPiCodes}
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/membership_payment">
                        <MembershipPayment
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route path="*">
                        <Feed
                            isSafari={this.state.isSafari}
                            isIOS={this.state.isIOS}
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            getCoins={this.getCoins}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                            statusModel={this.state.statusModel}
                        />
                    </Route>
                </Switch>
            );
        } else if (permission === 1 || permission === 4) {
            return (
                <Switch>
                    <Route exact path="/">
                        <Feed
                            isSafari={this.state.isSafari}
                            isIOS={this.state.isIOS}
                            streamingMdl={true}
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            getCoins={this.getCoins}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={true}
                            statusModel={this.state.statusModel}
                        />
                    </Route>
                    <Route exact path="/posts">
                        <Feed
                            isSafari={this.state.isSafari}
                            isIOS={this.state.isIOS}
                            streamingMdl={true}
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            getCoins={this.getCoins}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={true}
                            statusModel={this.state.statusModel}
                        />
                    </Route>
                    <Route exact path="/news">
                        <News
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={true}
                        />
                    </Route>
                    <Route exact path="/post">
                        <NewPost
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            getCoins={this.getCoins}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={true}
                        />
                    </Route>
                    <Route exact path="/mediaBox">
                        <MediaBox
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            getCoins={this.getCoins}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={true}
                        />
                    </Route>
                    <Route exact path="/messages">
                        <Messages
                            isSafari={this.state.isSafari}
                            isIOS={this.state.isIOS}
                            streaming={this.state.streaming}
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            model={this.state.model}
                            permission={this.state.permission}
                            view={!!this.state.membership}
                        />
                    </Route>
                    <Route exact path="/profile">
                        <Profile
                            toElevate={this.toElevate}
                            permission={this.state.permission}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                        />
                    </Route>
                    {permission === 4 ? (
                        <Route exact path="/purchases"
                               render={() => (
                                   <Purchases
                                       dictionary={this.state.dictionary}
                                       config={this.state.config}
                                       toElevate={this.toElevate}
                                       view={!!this.state.membership}
                                   />
                               )}
                        />
                    ) : (
                        ""
                    )}
                    <Route exact path="/activity">
                        <Activity
                            toElevate={this.toElevate}
                            permission={this.state.permission}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                        />
                    </Route>
                    <Route exact path="/analytics">
                        <Analytics
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                        />
                    </Route>
                    <Route exact path="/settings">
                        <Settings
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                        />
                    </Route>
                    <Route exact path="/terms">
                        <Terms dictionary={this.state.dictionary}
                               config={this.state.config}
                               toElevate={this.toElevate}/>
                    </Route>
                    <Route exact path="/privacy">
                        <Privacy dictionary={this.state.dictionary}
                                 config={this.state.config}
                                 toElevate={this.toElevate}/>
                    </Route>
                    <Route exact path="/termsCreator">
                        <TermsCreator
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            toElevate={this.toElevate}/>
                    </Route>
                    <Route exact path="/support">
                        <Support
                            getPiCodes={this.getPiCodes}
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                            update={this.state.update}
                        />
                    </Route>
                    <Route exact path="/support/faq">
                        <FAQ
                            getPiCodes={this.getPiCodes}
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                        />
                    </Route>
                    <Route exact path="/support/blog/create_account">
                        <CreateAccount
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/get_membership">
                        <GetMembership
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/recover_password">
                        <ForgotPassword
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/change_password">
                        <ChangePasswordSupport
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/cancel_membership">
                        <CancelMembership
                            getPiCodes={this.getPiCodes}
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/membership_payment">
                        <MembershipPayment
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/compliances">
                        <Compliances
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/changePassword">
                        <ChangePassword
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/panel/support">
                        <PanelSupport
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route path="*">
                        <Feed
                            isSafari={this.state.isSafari}
                            isIOS={this.state.isIOS}
                            streamingMdl={true}
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            getCoins={this.getCoins}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={true}
                            statusModel={this.state.statusModel}
                        />
                    </Route>
                </Switch>
            );
        } else if (permission === 2 || permission === 3 || permission === 5) {
            return (
                <Switch>
                    <Route exact path="/">
                        <Feed
                            isSafari={this.state.isSafari}
                            isIOS={this.state.isIOS}
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            getCoins={this.getCoins}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={true}
                            statusModel={this.state.statusModel}
                        />
                    </Route>
                    <Route exact path="/posts">
                        <Feed
                            isSafari={this.state.isSafari}
                            isIOS={this.state.isIOS}
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            getCoins={this.getCoins}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={true}
                            statusModel={this.state.statusModel}
                        />
                    </Route>
                    <Route exact path="/post">
                        <NewPost
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            getCoins={this.getCoins}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={true}
                        />
                    </Route>
                    <Route exact path="/mediaBox">
                        <MediaBox
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            getCoins={this.getCoins}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={true}
                        />
                    </Route>
                    <Route exact path="/messages">
                        <Messages
                            isSafari={this.state.isSafari}
                            isIOS={this.state.isIOS}
                            streaming={this.state.streaming}
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            model={this.state.model}
                            permission={this.state.permission}
                            view={true}
                        />
                    </Route>
                    <Route exact path="/profile">
                        <Profile
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={true}
                        />
                    </Route>

                    <Route exact path="/membership">
                        <Membership
                            getPiCodes={this.getPiCodes}
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                            statusModel={this.state.statusModel}
                        />
                    </Route>
                    <Route exact path="/purchases">
                        <Purchases
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                        />
                    </Route>
                    <Route exact path="/activity">
                        <Activity
                            toElevate={this.toElevate}
                            streaming={this.state.streaming}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            view={!!this.state.membership}
                        />
                    </Route>
                    <Route exact path="/terms">
                        <Terms
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}/>
                    </Route>
                    <Route exact path="/privacy">
                        <Privacy
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/termsCreator">
                        <TermsCreator
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support">
                        <Support
                            getPiCodes={this.getPiCodes}
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            update={this.state.update}
                        />
                    </Route>
                    <Route exact path="/support/faq">
                        <FAQ
                            getPiCodes={this.getPiCodes}
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/create_account">
                        <CreateAccount
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/get_membership">
                        <GetMembership
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/recover_password">
                        <ForgotPassword
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/change_password">
                        <ChangePasswordSupport
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/cancel_membership">
                        <CancelMembership
                            getPiCodes={this.getPiCodes}
                            toElevate={this.toElevate}
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                        />
                    </Route>
                    <Route exact path="/support/blog/membership_payment">
                        <MembershipPayment
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            toElevate={this.toElevate}
                        />
                    </Route>
                    <Route exact path="/compliances">
                        <Compliances
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            toElevate={this.toElevate}
                        />
                    </Route>
                    <Route exact path="/changePassword">
                        <ChangePassword
                            dictionary={this.state.dictionary}
                            config={this.state.config}
                            toElevate={this.toElevate}
                        />
                    </Route>
                    {this.state.permission === 2 ? (
                        <Route exact path="/analytics">
                            <Analytics
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                toElevate={this.toElevate}
                                view={true}
                            />
                        </Route>
                    ) : (
                        ""
                    )}
                    {this.state.permission === 2 ? (
                        <Route exact path="/panel/support"
                               render={() => (
                                   <PanelSupport
                                       toElevate={this.toElevate}
                                       dictionary={this.state.dictionary}
                                       config={this.state.config}
                                   />
                               )}
                        />
                    ) : ("")}
                    <Route exact render={() =>
                        this.state.authenticated ? (
                            <Feed
                                isSafari={this.state.isSafari}
                                isIOS={this.state.isIOS}
                                toElevate={this.toElevate}
                                streaming={this.state.streaming}
                                getCoins={this.getCoins}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={true}
                                statusModel={this.state.statusModel}
                            />
                        ) : (
                            <Home
                                isActive={this.state.statusModel}
                                description={this.state.description}
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                landingPage={this.state.landingPage}
                                rating={this.state.rating}
                            />
                        )
                    }
                    />
                </Switch>
            );
        }
    }

    openLink() {
        window.location = '/streaming';
    }

    render() {
        const {authenticated, loading, dictionary, config, landingPage, permission, statusModel, streaming, openStream, streamingInterval} = this.state;

        const intervalFunction = () => {
            this.isStreaming();
        }

        const stopInterval = () => {
            clearInterval(streamingInterval);
        }

        if(streaming && !openStream && permission !== 1) {
            if(!streamingInterval)
                this.setState({streamingInterval: setInterval(intervalFunction, 12000)});
        } else stopInterval()

        if (statusModel === 0 && !loading)
            return (
              <div className="center-align" style={{paddingTop: 45}}>
                  <HomeInactive/>
              </div>
            )
        else if(statusModel === 3 && !loading)
            return (
                <Router>
                    <div className="redesign" style={{backgroundColor: config.color.navbar}}>
                        <Route exact path="/">
                            <div className="center-align" style={{paddingTop: 45}}>
                                <HomeMaintenance config={config} dictionary={dictionary}/>
                            </div>
                        </Route>
                        <Route exact path="/support/">
                            <Support
                                getPiCodes={this.getPiCodes}
                                toElevate={this.toElevate}
                                dictionary={this.state.dictionary}
                                config={this.state.config}
                                view={!!this.state.membership}
                                statusModel={statusModel}
                                update={this.state.update}
                            />
                        </Route>
                    </div>
                </Router>
            )

        if (loading
            || dictionary.length === 0
            || config.length === 0)
            return (
                <div className="center-align" style={{paddingTop: 45}}>
                    <Circle
                        customLoading={(loading
                            || dictionary.length === 0
                            || config.length === 0)}
                        color="#4285f4"
                        className="center-align"
                    />
                </div>
            );

        const widthLiveSpan = this.state.dictionary.languageKey === "en" ? '75px' : '100px'
        return (
            <Router>
                <div className="redesign" style={{backgroundColor: config.color.navbar}}>
                    <SideNav
                        toElevate={this.toElevate}
                        dictionary={dictionary}
                        config={config}
                        streaming={streaming}
                        coins={this.state.coins}
                        canSellCoins={this.state.canSellCoins}
                        landingPage={landingPage}
                        permission={permission}
                        authenticated={authenticated}
                        notification={
                            streaming
                                ? {
                                    link: "/streaming",
                                    message: "Model is streaming NOW!",
                                }
                                : null
                        }
                    />

                    {
                        streaming && (this.state.permission !== 1 && this.state.permission !== 4) ? 
                        <div className='live-button' onClick={this.openLink}>
                            <div className='blink'>
                                <div style={{backgroundColor: this.state.config.color.principal, width: widthLiveSpan}} className='live-button-background'>

                                </div>
                                <div className='live-button-content'>
                                    <VideocamIcon 
                                        width={"20px"}
                                        height={"20px"}
                                        color="#e80f0f"
                                    />
                                    <div style={{color: this.state.config.color.principalText}} className='margin-live'>
                                        {this.state.dictionary.liveSpan}
                                    </div>
                                </div>
                            </div>
                            
                        </div> : null
                    }
                    {this.renderObject()}
                </div>
            </Router>
        );
    }
}

export default App;
