import React, {Component} from 'react';
import {Circle} from 'react-preloaders';

class MembershipPayment extends Component {
    render() {
        if (this.props.dictionary.length === 0 || this.props.config.length === 0)
            return <div className="center-align" style={{paddingTop: 45}}>
                <Circle color="#4285f4" className="center-align"/>
            </div>;
        return (
            <div
                id="container-support"
                className="container main-container center-align"
                style={{
                    backgroundColor: this.props.config.color.principal,
                    color: this.props.config.color.principalText
                }}
            >
                <h5>{this.props.dictionary.FAQ.q8}</h5>
                <div className="faq-steps ">
                    <p>
                        {this.props.dictionary.FAQ.q8p1}
                    </p>
                    <ol>
                        <li>
                            {this.props.dictionary.FAQ.q8p2}
                        </li>
                        <li>
                            {this.props.dictionary.FAQ.q8p3}
                        </li>
                    </ol>
                    <p>
                        {this.props.dictionary.FAQ.q8p4}
                    </p>
                    <ol>
                        <li>
                            {this.props.dictionary.FAQ.q8p5}
                        </li>
                        <li>
                            {this.props.dictionary.FAQ.q8p6}
                        </li>
                        <li>
                            {this.props.dictionary.FAQ.q8p7}
                            <p>
                                {this.props.dictionary.FAQ.q8p8}
                            </p>
                            <ol>
                                <li>
                                    {this.props.dictionary.FAQ.q8p9}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q8p10}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q8p11}
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
        )
    }
}

export default MembershipPayment;
