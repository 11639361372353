import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const videoElements = [];
let config, folderStorage = null;

const onVideoClick = (e, index) => {
    e.preventDefault();
    if(videoElements[index] && videoElements[index].current){
        if(videoElements[index].current.paused){
            //si esta pausado y no tiene audio, activar el audio
            videoElements[index].current.classList.add("video-playing");
            if(videoElements[index].current.muted)
                videoElements[index].current.muted = false;

            videoElements[index].current.play();
        }
        else if(videoElements[index].current.muted){
            //si no tiene audio y da clic activa el audio
            videoElements[index].current.muted = false;
        }
        else{
            videoElements[index].current.pause(); //si tiene audio y no esta esta pausado, lo pausa
            videoElements[index].current.classList.remove("video-playing");
        }
    }
};

const pauseVideoPlaying = ()=>{
    let playingVideo = document.querySelector(".video-playing");
    if(playingVideo){
        playingVideo.pause();
        playingVideo.classList.remove("video-playing");
    }
}

const createItemMedia = (item, index) =>{
    videoElements.push(React.createRef());
    if(!item.videoName)
        return <div key={index}>
            <img className="responsive-image-accordion" src={ config.storageURL + folderStorage + item.imageName } alt="accordion-media"/>
        </div>;
    else
        return <div key={index} >
            <video
                ref={videoElements[index]}
                onClick={e => onVideoClick(e,index)}
                preload="metadata"
                autoPlay
                muted
                poster={ config.storageURL + folderStorage +item.imageName}
                className="responsive-video video-playing"
                controlsList="nodownload"
                playsInline
            >
                <source
                    src={config.storageURL + folderStorage + item.videoName}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </div>;
};



export const AccordionMedia = (props) =>{
    config = props.config;
    folderStorage = props.folderStorage;
    if(props.media && props.media.length > 1)
        return (
            <Carousel
                showStatus={false}
                dynamicHeight={false}
                emulateTouch={true}
                showThumbs={false}
                autoPlay={false}
                onChange={pauseVideoPlaying}
            >
               {props.media.map((item,index)=>createItemMedia(item,index))}
            </Carousel>
        );
    else if(props.media && props.media.length===1)
        return <div>{props.media.map((item,index)=>createItemMedia(item,index))}</div>;
    return null;
};
