import React from "react";
const MediaBoxNotFilledIcon = ({color, width, height}) => {
    return(
        <svg 
            version="1.0" 
            xmlns="http://www.w3.org/2000/svg" 
            width={width}
            height={height}
            viewBox="0 0 86.000000 86.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g 
                transform="translate(0.000000,86.000000) scale(0.100000,-0.100000)"
                fill={color} 
                stroke="none"
            >
                <path d="M142 839 c-48 -14 -106 -77 -121 -131 -24 -86 5 -165 80 -215 40 -26
                50 -28 171 -31 l128 -4 0 119 c0 103 -3 125 -22 163 -42 83 -146 127 -236 99z
                m129 -64 c52 -27 69 -68 69 -167 l0 -88 -85 0 c-99 0 -136 14 -165 63 -72 118
                58 255 181 192z"/>
                <path d="M607 836 c-50 -18 -82 -49 -107 -101 -17 -34 -20 -62 -20 -158 l0
                -117 118 0 c140 0 184 16 231 81 28 38 31 50 31 114 0 63 -3 77 -29 112 -49
                68 -146 98 -224 69z m134 -61 c90 -47 90 -193 0 -240 -20 -10 -58 -15 -116
                -15 l-85 0 0 85 c0 58 5 96 15 116 32 62 121 87 186 54z"/>
                <path d="M120 358 c-26 -14 -57 -41 -75 -66 -26 -37 -30 -53 -30 -107 0 -54 4
                -69 30 -106 42 -58 85 -79 165 -79 78 0 116 20 157 81 26 40 28 50 31 171 l4
                128 -119 0 c-103 0 -125 -3 -163 -22z m220 -125 c0 -100 -17 -141 -69 -168
                -120 -61 -247 66 -186 186 27 52 68 69 168 69 l87 0 0 -87z"/>
                <path d="M480 263 c0 -141 16 -185 81 -232 38 -28 50 -31 114 -31 63 0 77 3
                112 29 53 38 73 84 73 163 0 76 -20 115 -81 155 -40 26 -50 28 -171 31 l-128
                4 0 -119z m267 37 c84 -51 84 -179 0 -230 -63 -39 -150 -19 -187 43 -16 25
                -20 50 -20 120 l0 87 88 0 c69 0 94 -4 119 -20z"/>
            </g>
        </svg>
    )
}

export default MediaBoxNotFilledIcon