import React, {Component} from 'react';
import Icon from "react-ionicons";
import FullscreenIcon from '../../Svg/FullscreenIcon';
import AudioPlayer from "react-h5-audio-player";
import "./CarouselElement";
import "../../Carousel/style.css"

class CarouselElement extends Component {

    componentDidMount() {
        const video = document.querySelector('video');
        if (video) {
            video.controls = "";
            video.addEventListener('pause', e => {
                video.controls = "";
            });
        }
        setTimeout(this.loadLazy, 120);
    }

    loadLazy() {
        let elems = document.querySelectorAll('[data-lazy]');
        if(elems.length > 0){
            elems.forEach((elem, index) => {
                let lazy = elem.getAttribute('data-lazy')
                if(elem.tagName === 'VIDEO')
                    elem.poster = lazy;
                else
                    elem.src = lazy;

                elem.removeAttribute('data-lazy');
            });
        }
    }

    handleImageLoaded() {
        this.props.imageStatus();
    }

    renderMedia() {
        const {media, config, view, index, isMessages, messageRoute, isBlur} = this.props;

        const originUri = config.storageURL + '/' + config.storage;
        let baseUri = media.mediaBox
            ? originUri + "/message/model/"
            : originUri + "/posts/img/";

        let img = (view) ?
            (media.mediaBox)
                ? baseUri + media.post_media_img
                : baseUri + media.post_media_img
            :
            media.post_media_img;

        let video = (media.mediaBox) ?
            config.videoStorageURL + '/' + config.storage + "/message/model/" + media.post_media_video
            : config.videoStorageURL + '/' + config.storage + "/posts/video/" + media.post_media_video;

        let audio = config.storageURL + '/' + config.storage + "/posts/audio/" + media.post_media_audio;

        if(isMessages) {
            baseUri = originUri + "/message/" + messageRoute + "/";
            img = baseUri + media.img;
            video = baseUri + media.video;
            audio = baseUri + media.audio;
        }

        if(isBlur){
            img = media.blurMedia;
        }

        if (media.post_media_video !== null && this.props.length === 1) {
            if (media.post_media_video === "loading") {
                return (
                    <img
                        alt={this.props.name}
                        src={"https://storage.googleapis.com/private-social-media.appspot.com/icons/SD-default-image.png"}
                        className={"responsive-image no-select"}
                        onLoad={this.handleImageLoaded.bind(this)}
                    />
                )
            }
            this.props.clicVideo[index] = React.createRef();
            return (
                <video
                    ref={this.props.clicVideo[index]}
                    preload="metadata"
                    poster={img}
                    className="responsive-video carouselVideo no-select"
                    onLoadStart={this.handleImageLoaded.bind(this)}
                    controlsList="nodownload"
                    playsInline>
                    <source
                        src={video}
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
            )
        } else if (media.post_media_img !== null) {
            if (media.post_media_img === "loading") {
                return (
                    <img
                        alt={this.props.name}
                        src={"https://storage.googleapis.com/private-social-media.appspot.com/icons/SD-default-image.png"}
                        className={"responsive-image no-select"}
                        onLoad={this.handleImageLoaded.bind(this)}
                    />
                )
            }
            this.props.openViewerImage[index] = React.createRef();
            return (
                <img
                    ref={this.props.openViewerImage[index]}
                    alt={this.props.name}
                    data-lazy={img}
                    src={null}
                    onLoad={this.handleImageLoaded.bind(this)}
                    className="responsive-img"
                />
            )

        } else if (media.post_media_audio !== null) {
            return (
                <div className="audio">
                    <AudioPlayer
                        src={audio}
                        volume={0.5}
                    />
                </div>
            )
        }
    }

    render() {
        let media = this.props.media;
        let post = this.props.post;
        let index = this.props.index;
        return (
            <div>
                {this.renderMedia()}
                {media.video || media.post_media_video ?
                    <Icon
                        icon="md-arrow-dropright-circle"
                        color="rgba(255,255,255,0.5)"
                        className="play-video"
                    />
                    : null
                }
                {this.props.length === 1 && (media.video || media.post_media_video) ?
                    <FullscreenIcon
                        color="rgba(255,255,255,0.7)"
                        id="fullscreenBtn"
                        className="expand"
                        clickFunction={(e) => {
                            this.props.openViewer(e, post, index, true)
                        }}
                    />
                    :
                    null
                }
            </div>
        )
    }
}

export default CarouselElement;
