import React, { Component } from 'react';
import Icon from "react-ionicons";
import ProgressBar from "react-customizable-progressbar";

class ChatImagesElement extends Component{
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
    }

    renderMedia(){
        let media = this.props.media;
        //let config = this.props.config;
        //let index = this.props.index;
        if(media.type === "video") {
            return(
                <video
                    onClick={(e) => {this.props.playVideo(e)} }
                    preload="yes"
                    poster={(media.method === "mediabox") ?
                        media.poster
                        :
                        ""
                    }
                    className=" responsive-video"
                    controlsList="nodownload"
                >
                    <source
                        src={(media.method === "mediabox") ?
                            media.file
                            :
                            URL.createObjectURL(media.file)
                        }
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
            )
        }
        else{
            return(
                <img
                    alt=""
                    src={(media.method === "mediabox") ?
                        media.file
                        :
                        URL.createObjectURL(media.file)
                    }
                    className="responsive-img"/>
            )
        }
    }

    render(){
        let media = this.props.media;
        let config = this.props.config;
        let index = this.props.index;
        if(!media) return null;
        return(
            <div>
                {this.renderMedia()}
                {media.type === "video" ?
                    <Icon
                        icon="md-arrow-dropright-circle"
                        fontSize="4em"
                        color="rgba(255,255,255,0.5)"
                        className="play-video"
                    />
                    : null
                }
                <span
                    className="close"
                    style={{
                        backgroundColor : config.color.secondary,
                        color: config.color.secondaryText
                    }}
                    onClick={() => this.props.remove(index)}
                >
                    <Icon
                        icon="md-close"
                        fontSize="2em"
                        color={config.color.secondaryText}
                    />
                </span>
                {(this.props.uploading)
                    ? <ProgressBar
                        radius={20}
                        progress={parseFloat(media.progress)}
                        strokeWidth={5}
                        strokeColor={config.color.secondary}
                        strokeLinecap="square"
                        trackStrokeWidth={5}
                    >
                        <div className="indicator">
                            <div>{parseFloat(media.progress)}%</div>
                        </div>
                    </ProgressBar>
                    : null
                }
            </div>
        )
    }
}

export default ChatImagesElement;
