import React from 'react';
import {
    Link
  } from 'react-router-dom';

const SideItems= ({dictionary, open, activeSection, forceUpdate})=>{
    return(
        <div>
            <ul className="itemNoLogged">
                <li className={activeSection==="/" ? "active-item" : ""} >
                    <Link onClick={() => open(false)} to='/'>
                        {dictionary.home}
                    </Link>
                </li>
                <li className={activeSection==="/terms" ? "active-item" : ""} >
                    <Link onClick={() => open(false)} to='/terms'>
                        {dictionary.terms}
                    </Link>
                </li>
                <li className={activeSection==="/privacy" ? "active-item" : ""} >
                    <Link onClick={() => open(false)} to='/privacy'>
                        {dictionary.privacy}
                    </Link>
                </li>
                <li className={activeSection==="/termsCreator" ? "active-item" : ""} >
                    <Link onClick={() => open(false)} to='/termsCreator'>
                        {dictionary.termsCreator}
                    </Link>
                </li>
                <li className={activeSection==="/support/" ? "active-item" : ""} >
                    <Link onClick={() => {open(false); forceUpdate()}} to='/support/'>
                        {dictionary.support}
                    </Link>
                </li>
                <li className={activeSection==="/support/faq" ? "active-item" : ""} >
                    <Link onClick={() => {open(false)}} to='/support/faq'>
                        F.A.Q.
                    </Link>
                </li>
                <li className={activeSection==="/support?subject=report" ? "active-item" : ""} >
                    <Link onClick={() =>{open(false); forceUpdate()}} to='/support?subject=report'>
                        {dictionary.reportContent}
                    </Link>
                </li>
            </ul>
        </div>
    )
}
export default SideItems;
