import React from 'react';

const VideosIcon= ({color, width, height, className = null})=>{
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            id="Capa_1"
            data-name="Capa 1"
        >
            <path fill={color} d="M19.9,13.18v-9A2.14,2.14,0,0,0,17.76,2H2.14A2.15,2.15,0,0,0,0,4.18V16a2.14,2.14,0,0,0,2.15,2.14H14.9a4.56,4.56,0,1,0,5-5ZM.93,16V6.42H2.38a.47.47,0,1,0,0-.93H.93V4.18A1.21,1.21,0,0,1,2,3c.18,0-.82,0,15.81,0A1.21,1.21,0,0,1,19,4.18V5.49H6.13a.47.47,0,0,0,0,.93H19v6.76a4.57,4.57,0,0,0-4.07,4.06H2.14A1.21,1.21,0,0,1,.93,16Zm18.51,5.29a3.62,3.62,0,1,1,3.62-3.62,3.63,3.63,0,0,1-3.62,3.62Zm0,0"
            />
            <path fill={color} d="M4.26,5.49h0A.47.47,0,1,0,4.73,6a.47.47,0,0,0-.47-.47Zm0,0"
            />
            <path fill={color} d="M15.79,4.7h1.45a.47.47,0,1,0,0-.94H15.79a.47.47,0,0,0,0,.94Zm0,0"
            />
            <path fill={color} d="M14.44,14.48H13.6a1.5,1.5,0,0,0-1.43-1.07,1.48,1.48,0,0,0-1.43,1.07H5.1a.47.47,0,1,0,0,.93h5.67a1.48,1.48,0,0,0,2.79,0h.88a.47.47,0,1,0,0-.93Zm-2.27,1a.56.56,0,1,1,.55-.55.56.56,0,0,1-.55.55Zm0,0"
            />
            <path fill={color} d="M2.31,13.63a.47.47,0,0,0-.47.47v1.6a.47.47,0,1,0,.94,0V14.1a.47.47,0,0,0-.47-.47Zm0,0"
            />
            <path fill={color} d="M3.7,13.63a.47.47,0,0,0-.47.47v1.6a.47.47,0,1,0,.94,0V14.1a.47.47,0,0,0-.47-.47Zm0,0"
            />
            <path fill={color} d="M9.66,13.3l2.72-1.76a1.3,1.3,0,0,0,0-2.18L9.66,7.6a1.3,1.3,0,0,0-2,1.09v3.52a1.29,1.29,0,0,0,2,1.09ZM8.6,8.69a.36.36,0,0,1,.55-.3l2.72,1.76a.36.36,0,0,1,0,.6L9.15,12.52a.36.36,0,0,1-.55-.31Zm0,0"
            />
            <path fill={color} d="M17.77,20.15a1,1,0,0,0,1-1v-1.6h1.93V18a.91.91,0,0,0-.32-.06,1,1,0,0,0-.89.59,1,1,0,1,0,1.85.37V16a.32.32,0,0,0-.32-.32H18.42a.32.32,0,0,0-.32.32v2.31a1,1,0,1,0-.33,1.87Zm2.58-1a.31.31,0,0,1-.3-.2.33.33,0,0,1,.07-.35.34.34,0,0,1,.35-.07.33.33,0,0,1,.2.3.32.32,0,0,1-.32.32Zm.32-2.89v.64H18.74v-.64Zm-2.9,2.57a.33.33,0,0,1,.3.2.32.32,0,0,1-.42.42.32.32,0,0,1,.12-.62Zm0,0"
            />
        </svg>
    )
}
export default VideosIcon;
