import React, { Component } from 'react';
import MediaBoxElement from "../MediaBoxElement";
import {Link} from "react-router-dom";
import axios from "axios";
import './style.css';
import Icon from "react-ionicons";

class MediaBox extends Component{
    constructor(props) {
        super(props);
        this.state = {
            mediaBox : [],
            choosen : []
        }
    }

    componentDidMount() {
        this.getMediaBox()
    }

    playVideo = async (e) => {
        let video = e.target;
        if(video.paused) {
            video.play();
            video.nextSibling.classList.add("playing");
        }
        else{
            video.pause();
            video.nextSibling.classList.remove("playing");
        }
    }

    chooseImage = (index, remove = false) => {
        let choosen = [...this.state.choosen];
        if(remove){
            let pos = choosen.indexOf(index);
            if (pos > -1) {
                choosen.splice(pos, 1);
            }
        }else{
            choosen.push(index)
        }
        this.setState({choosen : choosen})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.handler !== undefined && prevProps.handler !== this.props.handler){
            this.getMediaBox()
        }
    }

    getMediaBox = async () => {
        let post = this.props.post ? 1 : 0;
        let user = this.props.userToSend ? this.props.userToSend : null
        await axios.get('/api/v2/stored/media',{
            params : {
                post : post,
                user : user
            }
        }).then(async (resp) => {
            let mediaBox = resp.data;
            this.setState({mediaBox : mediaBox})
        }).catch(err => {

        })
    }

    close = (e) => {
        let id = (this.props.id ? this.props.id : "mediaBoxList")
        document.getElementById(id).classList.remove("open")
    }

    drawMediaBox(media) {
        let config = this.props.config;
        let dictionary = this.props.dictionary;
        let choosen = [...this.state.choosen];
        let limit = this.props.limit !== undefined ? this.props.limit : false
        if(media.length === 0){
            return(
                <div>
                    <Link
                        style={{ backgroundColor: config.color.secondary, color: config.color.secondaryText }}
                        className="more-content"
                        to={'/Mediabox'}
                    >
                        {dictionary.goToMediaBox}
                    </Link>
                    <p className="mediabox-list-error">
                        {dictionary.noMediaBoxContent}
                    </p>
                </div>

            )
        }
        return media.map( (el, index) => {
            let isChoosen = (choosen.indexOf(index) > -1) ? true : false;
            if(limit){
                if(limit === "img" && el.video){
                    return null
                }
                if(limit === "video" && !el.video){
                    return null
                }
            }
            return(
                <MediaBoxElement
                    media={el}
                    index={index}
                    key={index}
                    config={config}
                    dictionary={dictionary}
                    playVideo={this.playVideo}
                    choose={this.chooseImage}
                    isChoosen={isChoosen}
                />
            )
        })
    }

    add = (e) => {
        let getImages = []
        let mediaBox = [...this.state.mediaBox];
        let choosen = [...this.state.choosen];
        let config = this.props.config;
        for (let el of choosen) {
            let file = mediaBox[el];
            getImages.push({
                file : (file.video) ?
                    config.storageURL + '/' + config.storage + "/message/model/" + file.video
                    :
                    config.storageURL + '/' + config.storage + "/message/model/" + file.image,
                poster : (file.video) ? config.storageURL + '/' + config.storage + "/message/model/" + file.image : null,
                type : file.typeFormat,
                method: "mediabox",
                mediaBoxId : file.id,
                progress : 0
            });
        }
        this.props.getChoosen(getImages);
        this.close();
        this.setState({choosen : []})
    }

    render(){
        let config = this.props.config;
        let media = this.state.mediaBox;
        let id = (this.props.id ? this.props.id : "mediaBoxList")
        return(
            <div
                className="mediabox mediabox-list"
                style={{backgroundColor: config.color.modal}}
                id={id}
            >
                <div className="mediabox-list-close" style={{backgroundColor: config.color.modal}}>
                    <Icon
                        icon="ios-arrow-down"
                        className="arrowClose"
                        fontSize="2em"
                        color={config.color.modalText}
                        onClick={(e) => this.close(e)}
                    />
                </div>

                {this.drawMediaBox(media)}
                <div className="mediabox-list-send" style={{backgroundColor: config.color.modal}}>
                    {(media.length > 0) ?
                        <button
                            style={{backgroundColor: config.color.secondary, color: config.color.secondaryText}}
                            onClick={(e) => this.add(e)}
                        >
                            {this.props.dictionary.add}
                        </button>
                        : null
                    }
                </div>
            </div>
        )
    }
}

export default MediaBox;
