import React, {Component} from 'react';
import axios from 'axios';
import {Circle} from 'react-preloaders';
import ReactGA from 'react-ga';
import userIcon from '../../img/generic-user-icon.jpg';
import CoinsIcon from '../../components/Svg/CoinsIcon';
import CameraIcon from '../../components/Svg/CameraIcon';
import LikeIcon from '../../components/Svg/LikeIcon';
import GetCoins from '../../components/GetCoins';
import ReactCountryFlag from "react-country-flag"
import Ionicon from 'react-ionicons';
import PersonIcon from '../../components/Svg/PersonIcon';
import PhoneIcon from '../../components/Svg/PhoneIcon';
import {Link} from 'react-router-dom';
import Cropper from 'react-easy-crop';
import getCroppedImg from "../../components/Cropper/cropImage";
import ImageIcon from '../../components/Svg/ImageIcon';
import {Oval, Preloader} from "react-preloader-icon";
import cookie from 'js-cookie';
import NavigationPrompt from "react-router-navigation-prompt";
import Modal from '../../components/Modal';
import Checkbox from "../../components/Checkbox";
import Saving from "../../components/Saving";
import toolbox from '../../components/Toolbox/Toolbox';
import {BonusCoinsDetails} from "../../components/BonusCoinsDetails/BonusCoinsDetails";
import './NewProfile.css';

class NewProfile extends Component {

    constructor(props) {
        super(props);
        this.fireTracking();
        this.state = {
            language: 'select',
            profileFeatures: null,
            membership: false,
            dictionary: [],
            config: [],
            langList: [],
            location: '',
            currentLanguage: '',
            displayMenu: false,
            bestPictures: [],
            languageModel: false,
            countries: [],
            country: '',
            code: '',
            zoom: 1,
            setZoom: 1,
            crop: {x: 0, y: 0},
            canceled: false,
            modalAction: '',
            description: '',
            alias: '',
            phoneNumber: '',
            canceling: false,
            loading: false,
            imageSrc: null,
            errorPhone: false,
            croppedAreaPixels: null,
            showModalCoins: false,
            checkedNotifPhone: null,
            checkedValue: null,
            changeData: false,
            savedImage: false,
            imageProfileSaved: null
        }

        this.numberRegex = /^[0-9]+$/;
        this.handleCheck = this.handleCheck.bind(this);
        this.handleCheckPhone = this.handleCheckPhone.bind(this);
        this.uploadHandler = this.uploadHandler.bind(this);
        this.showSelect = this.showSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showDropMenu = this.showDropMenu.bind(this);
        this.hideDropMenu = this.hideDropMenu.bind(this);
        this.getLocalDictionary = this.getLocalDictionary.bind(this);
        this.getLang = this.getLang.bind(this);
        this.justNumbers = this.justNumbers.bind(this);

        /////////////////   Crop   /////////////////////
        this.getImage = this.getImage.bind(this);
        this.onZoomChange = this.onZoomChange.bind(this);
        this.onCropChange = this.onCropChange.bind(this);
        this.onCropComplete = this.onCropComplete.bind(this);
        this.onZoomChangeBar = this.onZoomChangeBar.bind(this);
        this.showCroppedImage = this.showCroppedImage.bind(this);

    }

    componentDidMount = () => {
        this.setState({dictionary: this.props.dictionary, config: this.props.config, benefits: true});
        this.getProfileFeatures()
            .then(async profileFeatures => {
                this.setState({
                    profileFeatures: profileFeatures
                });
                this.setState({country: this.state.profileFeatures.countryCode ? this.state.profileFeatures.countryCode : "US"});
                this.setState({code: this.state.profileFeatures.phoneCode ? this.state.profileFeatures.phoneCode : "1"});
                this.setState({description: this.state.profileFeatures.description});
                this.setState({alias: this.state.profileFeatures.alias});
                this.setState({phoneNumber: this.state.profileFeatures.phoneNumber});
                this.setState({checkedNotifPhone: this.state.profileFeatures.phone_notification});
                this.setState({checkedValue: this.state.profileFeatures.mail_notification_message});
            }).catch(err => {
            toolbox.sendError(err);
        });

        if (this.props.permission && this.props.permission === 1) {
            this.getCounts()
                .then(counts => {
                    this.setState({
                        images: counts[0].images,
                        videos: counts[0].videos,
                        likes: counts[0].likes,
                        bestPictures: [{'img': counts[0].photoOne, 'likes': counts[0].countOne},
                            {'img': counts[0].photoTwo, 'likes': counts[0].countTwo},
                            {'img': counts[0].photoThree, 'likes': counts[0].countThree}]
                    });
                }).catch(err => console.log(err));
            this.getCountries();
        }
        this.hasCodeMedia();
        const location = this.getLocation();
        this.dictList(location)
            .then(async resp => {
                this.setState({
                    langList: resp.data,
                    location: location
                });
            }).catch(err => {
            toolbox.sendError(err);
        });
        let languageChange = localStorage.getItem('dictionary');
        this.getLocalDictionary(languageChange)
            .then(async lang => {
                if (lang) {
                    await this.setState({currentLanguage: lang.code});
                } else {
                    this.getLang();
                }
            });
    }

    validateFormat(format) {
        const mediaType = format.split('/')[0];
        return mediaType === 'image';
    }

    getCountries() {
        axios.get('/api/v2/countries').then(async (res) => {
            if (res.data.Success) {
                this.setState({
                    countries: res.data.countries
                })
            }
        }).catch(e => {
            this.setState({
                countries: []
            })
        })
    }

    getLang() {
        axios.post('/api/v2/diccionary')
            .then(res => {
                if (res.data.language === 'es') {
                    this.setState({currentLanguage: 'MX'});
                } else if (res.data.language === 'en') {
                    this.setState({currentLanguage: 'US'});
                }
            });
    };

    getCounts = async () => {
        return await axios.get('/api/v2/get/home/counts')
            .then(async res => {
                return res.data;
            })
            .catch(err => {
                console.log("BuyCoinsError", err)
            });
    }
    hasCodeMedia = async () => {
        let canSellCoins = await this.props.toElevate.canSellCoin;
        this.setState({
            showModule: canSellCoins
        });
    }
    fireTracking = () => {
        ReactGA.pageview(window.location.pathname);
    }
    getLocation = async () => {
        return await axios.get('https://us-central1-private-social-media.cloudfunctions.net/locate')
            .then(res => {
                if (res.data.error) return false;
                return res.data.country;
            });
    };

    getLocalDictionary(languageChange) {
        const extract = JSON.parse(languageChange).languageKey;
        const dict =
            {
                extract: extract
            }
        return axios.post('/api/v2/get/localDictionary', dict)
            .then(res => {
                return res.data;
            })
            .catch(err => {
                console.log(err)
            });
    }

    dictList = async (location) => {
        return await axios.post("/api/v2/dictionaryLangList")
            .then(res => {
                return res;
            })
            .catch(error => {
                console.log("BuyCoinsError", error);
            });
    };
    getCoinsFeatures = async () => {
        return await axios.post("/api/v2/coinsHistory")
            .then(res => {
                return res.data;
            })
            .catch(error => {
                console.log("BuyCoinsError", error);
            });
    };
    getProfileFeatures = async () => {
        return await axios.post('/api/v2/getUserDetails')
            .then(async res => {
                return res.data;
            })
            .catch(err => {
                toolbox.sendError(err);
            });
    }
    showSelect = (e) => {
        e.preventDefault();
        const langPanel = document.getElementById('languageDiv');
        langPanel.style.display = "block";
        const langAsk = document.getElementById('langButton');
        langAsk.style.display = "none";
    }

    showDropMenu(event) {
        event.preventDefault();
        this.setState({displayMenu: true}, () => {
            document.addEventListener('click', this.hideDropMenu);
        });
    }

    hideDropMenu() {
        this.setState({displayMenu: false}, () => {
            document.removeEventListener('click', this.hideDropMenu);
        });
    }

    renderLanguages = () => {
        if (this.state.langList !== undefined) {
            if (this.state.langList.length === 0) return null;
            let copy = this.state.langList;
            return copy.map((item, index) => {
                return (
                    <li
                        key={index}
                        onClick={e => this.handleChange(e, item.lenguaje, index)}
                        value={item.lenguaje}
                        className="langText"
                        style={{backgroundColor: this.props.config.color.principal}}
                    >
                        {item.name} &nbsp;
                        <ReactCountryFlag
                            countryCode={item.code}
                            style={{
                                fontSize: '2em'
                            }}
                        />
                    </li>
                );
            });
        }
    }

    handleCheckPhone = async (e) => {
        await this.setState({
            checkedNotifPhone: !this.state.checkedNotifPhone,
            changeData: true
        })
    }

    handleChange = async (e, lenguaje, index) => {
        e.preventDefault();
        const key = index;
        const value = lenguaje;
        await this.setState(
            {
                [key]: value
            });
        const selected = {
            lang: value
        }
        return await axios.post('/api/v2/setListLanguage', selected)
            .then(async res => {
                localStorage.setItem('language', 'changed');
                localStorage.setItem('dictionary', res.data.dictionary);
                const dictionary = JSON.parse(res.data.dictionary);
                await this.setState({dictionary: dictionary});
                window.location.reload();
            });
    }
    renderPhotos = () => {
        if (this.state.bestPictures.length === 0) return null;
        var photos = this.state.bestPictures;
        return photos.map((item, index) => {
            return (
                <div key={index}
                     className='grid-gallery'
                     style={{backgroundImage: "url(" + this.state.config.storageURL + '/' + this.state.config.storage + item.img + ")"}}
                >
                    <LikeIcon
                        color={this.state.config.color.secondary}
                        width={"19px"}
                        height={"19px"}
                        className="svgLike"
                    />
                    {item.likes > 999 ?
                        toolbox.kFormatter(item.likes)
                        :
                        item.likes
                    }
                </div>
            );

        });
    }
    editProfile = async () => {
        await this.setState({showModal: true, modalAction: "editProfile"});
    }
    closeModalEditProfile = async () => {
        this.setState({showModal: false})
    }
    membershipCancel = async () => {
        await this.setState({showModal: true, modalAction: "membershipCancel"});
    }
    buyCoins = async () => {
        await this.setState({showModalCoins: true, modalAction: "buyCoins"});
    }
    closeModalCoins = async () => {
        this.setState({showModalCoins: false, modalAction: ''})
    }
    changeHandlerCode = async (e) => {
        let values = e.target.value.split("+");
        this.setState({
            country: values[0],
            code: values[1],
            changeData: true
        });
    }

    changeAlias = async () => {
        const alias = {
            alias: this.state.alias
        };
        return await axios.post('/api/v2/newAlias', alias)
            .then(async res => {
                return res.data;

            })
            .catch(err => {
                toolbox.sendError(err);
            });
    }

    changeDesc = async () => {
        const description = {
            description: this.state.description
        };
        return await axios.post('/api/v2/newDescription', description)
            .then(async res => {
                return res.data;

            })
            .catch(err => {
                toolbox.sendError(err);
            });
    }

    changeCheckbox = async (checkedValueFlag, type) => {
        const checkedValue = {
            checkedValue: checkedValueFlag,
            typeNotification: type
        };
        return await axios.post('/api/v2/MailNotifications', checkedValue)
            .then(async res => {
                return res.data;
            })
            .catch(err => {
                toolbox.sendError(err);
            });
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    changePhone = async (phoneModel) => {
        const phone = {
            phone: phoneModel,
            country: this.state.country,
            code: this.state.code
        };
        return await axios.post('/api/v2/newphoneNumber', phone)
            .then(async res => {
                return res.data;
            })
            .catch(err => {
                toolbox.sendError(err);
            });
    }

    uploadHandler = async () => {
        let error = false;
        var checkedValue;
        var checkedNotifPhone;
        var phonemodel = document.getElementById("input_phone");
        if (phonemodel) {
            phonemodel.classList.remove("sign-error");
        }
        this.setState({
            errorPhone: false,
            changeData: false
        });

        //Alias
        if (this.state.alias !== this.state.profileFeatures.alias) {
            this.setState({
                loading: true
            });
            await this.changeAlias();
            this.setState({
                loading: false
            });
        }

        //Description
        if (this.state.description !== this.state.profileFeatures.description) {
            this.setState({
                loading: true
            });
            await this.changeDesc();
            this.setState({
                loading: false
            });
        }

        //Checkbox mail notifications
        if (this.state.checkedValue !== this.state.profileFeatures.mail_notification_message) {
            checkedValue = this.state.checkedValue ? 1 : 0;
            this.setState({
                loading: true
            });
            await this.setStateAsync({checkedValue: checkedValue});
            await this.changeCheckbox(checkedValue, "mailNotifications");
            this.setState({
                loading: false
            });
        }

        //phoneNumber
        if ((this.state.phoneNumber !== this.state.profileFeatures.phoneNumber) || (this.state.code !== this.state.profileFeatures.phoneCode)) {

            if (this.state.phoneNumber !== null && this.state.phoneNumber.length < 3) {
                document.getElementById("input_phone").classList.add("sign-error");
                document.getElementById("input_phone").focus();
                await this.setState({errorPhone: this.state.dictionary.phoneError});
                error = true;
            } else {
                this.setState({
                    loading: true
                });
                await this.changePhone(this.state.phoneNumber);
                this.setState({
                    loading: false
                });
            }
        }

        if (error) return;

        //Checkbox model phone notifications
        if (this.state.checkedNotifPhone !== this.state.profileFeatures.phone_notification) {

            checkedNotifPhone = this.state.checkedNotifPhone ? 1 : 0;
            this.setState({
                loading: true
            });
            await this.setStateAsync({checkedNotifPhone: checkedNotifPhone});
            await this.changeCheckbox(checkedNotifPhone, "phoneNotifications");
            this.setState({
                loading: false
            });
        }

        //Image Profile
        if (this.state.imageSrc) {
            const croppedImage = await getCroppedImg(
                this.state.imageSrc,
                this.state.croppedAreaPixels
            );
            let file = new File([croppedImage], this.state.nameImputFile + ".png", {
                type: "image/png",
                lastModified: Date.now()
            });
            if (file && this.validateFormat(file.type)) {
                const formData = new FormData();
                formData.append("file", file, file.name);
                formData.append('username', cookie.get('username'));
                formData.append('hostname', window.location.hostname === 'localhost' ? 'myladydusha.com' : window.location.hostname);
                formData.append('token', cookie.get('token'));
                await this.setState({loading: true});
                await axios.post("https://socialtechapps.com/api/v2/changeImageProfile", formData)
                    .then((resp) => {
                        this.setState({loading: false, showcrop: false, savedImage: true, imageProfileSaved: file});
                        return resp.data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }

    PhoneView() {
        return (
            <div>
                {this.state.profileFeatures.permission === 1 ?
                    <div style={{'text-align': 'left', 'margin-top': '15px'}}>
                        <label className="profileLabel" htmlFor="input_phone">
                            <PhoneIcon
                                color={"rgb(92, 92, 92)"}
                                width={"20px"}
                                height={"20px"}
                            />
                            {this.state.dictionary.phone}
                        </label>
                        <div className="input-profile phone-group">
                            <div className="select-code">
                                <ReactCountryFlag
                                    className="emojiFlag"
                                    countryCode={this.state.country}
                                    style={{
                                        fontSize: '1.5em',
                                        lineHeight: '1.5em',
                                    }}
                                />
                                {" +" + this.state.code}
                            </div>
                            <select
                                name="country"
                                id="country"
                                className={"country-phone"}
                                style={{color: this.props.config.color.principalText}}
                                onChange={this.changeHandlerCode}
                                value={this.state.country + "+" + this.state.code}
                            >
                                {this.drawCountries()}
                            </select>
                            <input
                                onKeyUp={this.justNumbers}
                                style={{color: this.props.config.color.principalText}}
                                type="text"
                                onChange={this.changeHandler}
                                className="register-inputs validate"
                                name="phoneNumber"
                                id="input_phone"
                                maxLength="15"
                                value={this.state.phoneNumber}
                                placeholder={this.state.dictionary.maxXVlimit}
                            >
                            </input>
                            {this.state.errorPhone ?
                                <div className="redesign-error-class">{this.state.errorPhone}</div>
                                : null}
                        </div>
                    </div>
                    : null}
            </div>
        );
    }

    getImage = async e => {
        const file = e.target.files[0];
        const name = e.target.name;
        const imageDataUrl = await readFile(e.target.files[0]);
        this.setState({
            imageSrc: imageDataUrl,
            showcrop: true,
            nameImputFile: name,
            selectedFile: file,
            zoom: 1,
            crop: {x: 0, y: 0},
            aspect: 3 / 3,
        });
    }

    onZoomChange = zoom => {
        this.setState({zoom})
    }

    onZoomChangeBar = (e, zoom) => {
        e.preventDefault();
        this.setState({zoom: e.target.value})
    }

    onCropChange = crop => {
        this.setState({crop})
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({croppedAreaPixels})
    }

    showCroppedImage = async () => {
        const croppedImage = await getCroppedImg(
            this.state.imageSrc,
            this.state.croppedAreaPixels
        );
        const inputFile = document.getElementsByName(this.state.nameImputFile)[0];
        const img = inputFile.closest(".card-body").previousSibling;
        let file = new File([croppedImage], this.state.nameImputFile + ".png", {
            type: "image/png",
            lastModified: Date.now()
        });
        this.state.updloadedFiles[this.state.nameImputFile] = file;
        img.setAttribute("src", URL.createObjectURL(croppedImage));
        //await this.setState({showcrop : false})

    }

    changeNotification() {
        return (
            <div className="input-profile">
                <div className="changePwd-model">
                    <Link
                        to='/changePassword'
                        style={{
                            color: this.props.config.color.secondary
                        }}
                    >
                        {this.state.profileFeatures.changePassword}
                    </Link>
                </div>
            </div>
        );
    }

    changeHandler = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value,
            changeData: true
        });
    }

    handleCheck = async (e) => {
        await this.setState({
            checkedValue: !this.state.checkedValue,
            changeData: true
        })
    }

    drawCountries() {
        let countries = [...this.state.countries];
        return countries.map((el, index) => {
            return (
                <option key={index} value={el.code + "+" + el.phone}>+{el.phone} {el.name}</option>
            )
        })
    }

    justNumbers(e) {
        let value = e.target.value;
        let name = e.target.name;
        if (!this.numberRegex.test(value)) {
            value = value.replace(/\D/g, '')
            this.setState({[name]: value});
        }
    }

    save() {
        return (
            <div className='center-align actions' style={{marginTop: '20px'}}>
                {
                    this.state.canceled ?
                        null
                        :
                        <button
                            style={{
                                backgroundColor: this.props.config.color.secondary,
                                color: this.props.config.color.secondaryText,
                                marginTop: 0,
                                marginBottom: 20
                            }}
                            disabled={this.state.disabled}
                            onClick={this.uploadHandler}
                            className="create-account-button log-btn"
                        >
                            {(this.state.canceling || this.state.loading) ?
                                <Preloader
                                    use={Oval}
                                    size={26}
                                    strokeWidth={8}
                                    strokeColor={this.props.config.color.secondaryText}
                                    duration={800}
                                />
                                : this.props.dictionary.saveChange
                            }
                        </button>
                }
            </div>
        );
    }

    render() {

        if (this.state.profileFeatures === null)
            return (
            <div className="center-align" style={{paddingTop: 45}}>
                <Circle background="transparent !important" color="#4285f4" className="center-align"/>
            </div>);

        return (
            <>
                <div className="container main-container redesign-profile-container"
                    style={{
                        backgroundColor: this.props.config.color.principal,
                        color: this.props.config.color.principalText
                    }}
                >
                    <div className="center-align">
                        {<div className="v2-lang-div">
                            <div className="dropdownLang">
                                <div
                                    className="dropdown-button"
                                    onClick={this.showDropMenu}
                                >
                                    <div
                                        id='languageDiv'
                                        className='v2-selectLanguage'
                                    >
                                        <ReactCountryFlag
                                            countryCode={this.state.currentLanguage}
                                            style={{
                                                fontSize: "2.2em",
                                                lineHeight: "1.2em",
                                                paddingRight: 10
                                            }}
                                        />
                                    </div>
                                </div>
                                {this.state.displayMenu ?
                                    <ul
                                        name='language'
                                        id='lang-simple'
                                        style={{
                                            color: this.props.config.color.principalText,
                                            backgroundColor: this.props.config.color.principal
                                        }}
                                    >
                                        {this.renderLanguages()}
                                    </ul>
                                    : null}
                            </div>
                        </div>}
                        <ul className="count-info">
                            <div style={{'display': 'inline'}}>
                                <li>
                                    <div>
                                        <img
                                            alt="profile"
                                            src={this.state.savedImage
                                                ? URL.createObjectURL(this.state.imageProfileSaved)
                                                : this.state.profileFeatures.image_profile
                                                    ? this.props.config.storageURL + '/' + this.props.config.storage + '/profiles/' + this.state.profileFeatures.image_profile
                                                    : userIcon
                                            }
                                            height="150px"
                                            className="v2-circle-photo"
                                        />
                                    </div>
                                    <div className='change-profile-icon'>
                                        <label className="imgProfileChange" htmlFor="mainImage">
                                            <ImageIcon
                                                color={"rgb(92, 92, 92)"}
                                                width={"45px"}
                                                height={"35px"}
                                            />
                                            &nbsp;{this.state.profileFeatures.changeProfilePic}
                                        </label>
                                        <input
                                            type="file"
                                            onChange={this.getImage}
                                            name="file"
                                            id="mainImage"
                                            className="register-inputs inputSelectImgProfile"
                                            disabled={this.state.disabled}
                                        />
                                    </div>
                                </li>
                            </div>
                        </ul>
                        {this.state.showcrop ?
                            <div className='profileCrop' style={{'margin-bottom': '40px'}}>
                                <div className='slidecontainer'>
                                    <Cropper
                                        dictionary={this.props.dictionary}
                                        image={this.state.imageSrc}
                                        crop={this.state.crop}
                                        zoom={this.state.zoom}
                                        aspect={this.state.aspect}
                                        cropShape='round'
                                        onCropChange={this.onCropChange}
                                        onCropComplete={this.onCropComplete}
                                        onZoomChange={this.onZoomChange}
                                    />
                                </div>
                                <div className="slideFix">
                                    <input type="range" min="1" max="3" step="0.1" name='zoomScroll'
                                           value={this.state.zoom} className="slider" id="myRange"
                                           onChange={(e, zoom) => this.onZoomChangeBar(e, zoom)}/>
                                </div>
                            </div>
                            : null}
                        <h5 className='profile-name'>
                            {this.state.alias ? this.state.alias : this.state.profileFeatures.username}
                        </h5>

                        {this.props.permission && this.props.permission === 1 ?
                            ''
                            :
                            <h6>{this.state.dictionary.userTag}</h6>
                        }

                        <ul className="count-info">
                            {(this.state.profileFeatures.permission === 1) ?
                                <div style={{display: 'inline'}}>
                                    <li>
                                        <CameraIcon
                                            color={this.state.config.color.secondary}
                                            width={"20px"}
                                            height={"20px"}
                                        />
                                        <div>
                                            {this.state.images > 999 ?
                                                toolbox.kFormatter(this.state.images)
                                                : this.state.images}
                                        </div>
                                    </li>
                                </div>
                                : null}
                            <li>
                                <LikeIcon
                                    color={this.state.config.color.secondary}
                                    width={"20px"}
                                    height={"20px"}
                                />
                                <div>
                                    {(this.state.profileFeatures.permission === 1)
                                        ?
                                        this.state.profileFeatures.modelLikes > 999
                                            ?
                                            toolbox.kFormatter(this.state.profileFeatures.modelLikes)
                                            : this.state.profileFeatures.modelLikes

                                        :
                                        this.state.profileFeatures.totalLikes > 999
                                            ?
                                            toolbox.kFormatter(this.state.profileFeatures.totalLikes)
                                            : this.state.profileFeatures.totalLikes
                                    }
                                </div>
                            </li>
                            <li title={this.state.profileFeatures.totalCoins ? this.state.profileFeatures.totalCoins : 0}>
                                {(this.state.profileFeatures.permission === 0 || this.state.profileFeatures.permission === 4) ?
                                    <button
                                        onClick={() => (this.props.view) ? this.buyCoins() : null}
                                        className="v2-buycoin-btn">
                                        <CoinsIcon
                                            color={this.state.config.color.secondary}
                                            colorPrincipal={this.state.config.color.principal}
                                            width={"25px"}
                                            height={"25px"}
                                        />
                                    </button>
                                    :
                                    <CoinsIcon
                                        color={this.state.config.color.secondary}
                                        width={"25px"}
                                        height={"25px"}
                                        colorPrincipal={this.state.config.color.principal}
                                    />
                                }
                                <div>
                                    {(!this.state.profileFeatures.totalCoins) ?
                                        '0'
                                        : toolbox.numberWithCommas(this.state.profileFeatures.totalCoins)}
                                </div>
                            </li>
                        </ul>
                        {(this.state.profileFeatures.permission === 1) ?
                            <div className="input-profile" style={{'text-align': 'left'}}>
                                <label className="profileLabel" htmlFor="input_3">
                                    <Ionicon
                                        icon='ios-paper'
                                        fontSize="25px"
                                        color="rgb(92, 92, 92)"
                                    />
                                    {this.state.dictionary.description}
                                </label>
                                <textarea
                                    style={{color: this.props.config.color.principalText}}
                                    onChange={this.changeHandler}
                                    className="register-inputs validate"
                                    name="description"
                                    id="input_3"
                                    maxLength="255"
                                    defaultValue={this.state.description}
                                    value={this.state.description}
                                    placeholder="description"
                                    validate="true"
                                    disabled={this.state.disabled}>
                                </textarea>
                            </div>
                            : <BonusCoinsDetails {...this.props} />
                        }
                        <div className="input-profile" style={{textAlign: 'left'}}>
                            <label className="profileLabel" htmlFor="input_1">
                                <PersonIcon
                                    color={"rgb(92, 92, 92)"}
                                    width={"20px"}
                                    height={"20px"}
                                />
                                Alias
                            </label>
                            <input
                                style={{color: this.props.config.color.principalText}}
                                type="text"
                                onChange={this.changeHandler}
                                className="register-inputs validate"
                                name="alias"
                                id="input_1"
                                minLength="4"
                                maxLength="40"
                                defaultValue={this.state.alias}
                                placeholder="alias"
                                validate="true"
                                disabled={this.state.disabled}>
                            </input>
                        </div>
                        {this.PhoneView()}

                        {(this.state.profileFeatures.permission === 1) ?
                            <div className="input-profile" style={{textAlign: 'left', marginBottom: 20}}>
                                <Checkbox
                                    id="checkbox-phone"
                                    name='phone_notification'
                                    checked={this.state.checkedNotifPhone}
                                    onChange={this.handleCheckPhone}
                                    label={this.props.dictionary.notifPhone}
                                    value="1"
                                />
                            </div>
                            : null
                        }
                        {(this.state.profileFeatures.mail_notification_message !== false) && (this.state.profileFeatures.mail_notification_message !== undefined) && (this.state.profileFeatures.mail_notification_message !== null) ?
                            <div className="input-profile checkboxProfile"
                                 style={{textAlign: 'left', marginBottom: 20}}>
                                <Checkbox
                                    id="checkbox-profile"
                                    name='checkedValue'
                                    checked={this.state.checkedValue}
                                    onChange={this.handleCheck}
                                    label={this.props.dictionary.receiveMails}
                                    value="1"
                                />
                            </div>
                            : null}
                        {this.props.permission && this.props.permission === 1 ?
                            <div>
                                <div>
                                    <h5 className="model-photos-title">
                                        {this.state.dictionary.bestPhotos}
                                    </h5>
                                    <div className="flex-same-height">
                                        {this.renderPhotos()}
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        <div className="input-profile" style={{marginTop: '20px'}}>
                            <div className="changePwd-model">
                                <Link
                                    to='/changePassword'
                                    style={{
                                        color: this.props.config.color.secondary
                                    }}
                                >
                                    {this.props.dictionary.changePassword}
                                </Link>
                            </div>
                        </div>
                        {this.save()}
                    </div>
                </div>
                {this.state.showModalCoins ?
                    <GetCoins
                        open={this.state.showModalCoins}
                        dictionary={this.props.dictionary}
                        config={this.props.config}
                        close={this.closeModalCoins}
                    />
                    :
                    null
                }
                <NavigationPrompt when={this.state.changeData}>
                    {({onConfirm, onCancel}) => (
                        <Modal
                            id={"ModalEditProfile"}
                            open={true}
                            closeAction={onCancel}
                            head={""}
                            config={this.props.config}
                            footer={
                                <ul className='actions'>
                                    <li>
                                        <button
                                            onClick={onCancel}
                                            className='btn btn-optionsNotify'
                                            style={{
                                                backgroundColor: this.props.config.color.secondary,
                                                color: this.props.config.color.secondaryText
                                            }}
                                        >
                                            {this.props.dictionary.cancel}
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            onClick={onConfirm}
                                            className='btn btn-optionsNotify'
                                            style={{
                                                backgroundColor: this.props.config.color.secondary,
                                                color: this.props.config.color.secondaryText
                                            }}
                                        >
                                            {this.props.dictionary.confirm}
                                        </button>
                                    </li>
                                </ul>
                            }
                        >
                            <p>{this.props.dictionary.confirmChangeEdit}</p>
                        </Modal>

                    )}
                </NavigationPrompt>
                {this.state.loading ?
                    <Saving
                        config={this.props.config}
                        id="savingMassive"
                        saving={this.state.loading}
                        loading={false}
                        progress={null}
                    />
                    :
                    null
                }
            </>
        );
    }
}


function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    })
}

export default NewProfile;
