import React, {Component} from 'react';
import axios from 'axios';
import toolbox from '../../components/Toolbox/Toolbox';

import AccordionList from "../../components/Accordion/Accordion";


export default class News extends Component {

    constructor(props) {
        super(props);
        this.state = {
            news: [],
            loading: true
        }
        this.eventUpdateStatus = this.eventUpdateStatus.bind(this);
    }


    componentDidMount() {
        this.getNews();
    }

    getNews() {
        axios
            .get('/api/v2/news/get/')
            .then(resp => {
                let news = [];
                console.log(resp.data.newsResponse);
                if (resp.data && resp.data.newsResponse)
                    news = resp.data.newsResponse

                this.setState({
                    loading: false,
                    news
                });

            })
            .catch(err => {
                toolbox.sendError(err);
                this.setState({
                    loading: false
                });
            });
    }

    async eventUpdateStatus(index) {
        if (!index || (index && !index.length)) {
            return false;
        }
        let news = this.state.news;
        let key = index[0] - 1;
        if (news[key].status === 2) return false;
        let idNew = news[key].id;

        return await axios
            .post('/api/v2/news/update/log/status/read', {idNew})
            .then(resp => {
                if (resp && resp.data && resp.data.updateResponse) {
                    news[key].status = resp.data.status;
                    this.setState({
                        news: news
                    });
                }
                return true;
            })
            .catch(err => {
                toolbox.sendError(err);
                return false;
            });
    }

    render() {

        const color = {
            color: this.props.config.color.principalText,
        };

        let message = <p style={color}>{this.props.dictionary.noNews}</p>
        let content = null

        if (this.state.news.length > 0) {
            message = null;
            content = <AccordionList
                list={this.state.news}
                config={this.props.config}
                loading={this.state.loading}
                folderStorage={"/private-social-media.appspot.com/News/"}
                clickEvent={this.eventUpdateStatus}
            />
        }

        return (
            <div
                className="container main-container center-align"
                style={{
                    zIndex: 0,
                    backgroundColor: this.props.config.color.principal
                }}
            >
                <h5 style={color}>
                    {this.props.dictionary.news || "News no dictionary"}
                </h5>
                {message}
                {content}
            </div>
        )
    }
}
