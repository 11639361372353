import axios from "axios";
import React, {useEffect, useState} from "react";
import {Oval, Preloader} from 'react-preloader-icon';
import Ionicon from "react-ionicons";
import './style.css'

const ReportContent = (props) => { 
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [url, setUrl] = useState('');
    const [subject, setSubject] = useState('')
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [urlError, setUrlError] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [counter, setCounter] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(true);

    useEffect(() => {
        if(counter > 0) {
            cleanSpaces();
            validateFields();
        }
    }, [name, email, url, message])

    useEffect(() => {
        setSubject(props.dictionary.reportContent)
        if(props.logged) {
            setName(props.name);
            setEmail(props.email)
        }
    },[])

    const submit = async () => {
        setCounter(1);
        
        if(validateFields()) return;
        else {
            let data = {
                name,
                email,
                message,
                subject,
                link: url
            }
            if(!loading){
                setLoading(true);
                axios.post('/api/v2/reportContent', data)
                .then(res => {
                    if(res.data.success) {
                        data = {
                            ...data,
                            idModel: res.data.idModel
                        }
                        axios.post('/api/v2/support', data)
                        .then( resp => {
                            setSuccess(true);
                        }).catch(e => setError(true));
                    }
                    else setError(true);
                    setLoading(false);
                }).catch(e => {
                    setError(true);
                    setLoading(false);
                });
                setShowForm(false);
                setCounter(0);
            } 
        }     
    }

    const cleanSpaces = () => {
        setName(cleanMiddleSpaces(name));
        setMessage(cleanMiddleSpaces(message));
    }

    // This function deletes the spaces when there are more than two spaces between the words; also it deletes the spaces before and after the sentence or the content
    const cleanMiddleSpaces = (text) => {
        const justLetters = text.split(' ');
        let sentence = '';

        for(let i = 0; i < justLetters.length; i++) {
            if(justLetters[i] !== '') {
                if(i===0) sentence = justLetters[i];
                else sentence = sentence + ' ' + justLetters[i]

                if(i===justLetters.length-1) sentence = sentence.trim();
            }
        }
        return sentence;
    }

    // Validate if there is no spaces in the URL
    const validateUrl = (text) => {
        const justLetters = text.split(' ');
        if(justLetters.length > 1) return true;
        else return false;
    }

    const validateFields = () => {
        let error = false;
        document.getElementById("input_0").classList.remove("sign-error");
        document.getElementById("input_1").classList.remove("sign-error");
        document.getElementById("input_2").classList.remove("sign-error");
        document.getElementById("input_3").classList.remove("sign-error");

        if (name.length < 3) {
            document.getElementById("input_0").classList.add("sign-error");
            setNameError(props.dictionary.nombreError);
            error = true;
        } else setNameError('');
        if (email.length < 4 || !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            document.getElementById("input_1").classList.add("sign-error");
            setEmailError(props.dictionary.emailError);
            error = true;
        } else setEmailError('');
        if (url.length < 4 || validateUrl(url)) {
            document.getElementById("input_2").classList.add("sign-error");
            setUrlError(props.dictionary.urlError);
            error = true;
        } else setUrlError('');
        if (message.length < 20) {
            document.getElementById("input_3").classList.add("sign-error");
            setMessageError(props.dictionary.messageError);
            error = true;
        } else setMessageError('');
        
        if (error) return true;
        else return false;
    }

    const changeHandler = (e) => {
        if(e.target.name === 'name') setName(e.target.value);
        if(e.target.name === 'email') setEmail(e.target.value);
        if(e.target.name === 'url') setUrl(e.target.value);
        if(e.target.name === 'message') setMessage(e.target.value);
    }

    const showFormAgain = () => {
        setShowForm(true);
        setSuccess(false);
        setError(false);

        if(!props.logged) {
            setName('');
            setEmail('');
        }
        
        setUrl('');
        setMessage('');

    }

    const form = () => {
        return <>
            <div className="input-field">
                <label htmlFor="input_0" className="input-label">
                    <Ionicon
                        icon='ios-person'
                        fontSize="25px"
                        color="rgb(92, 92, 92)"
                        className='margin-right'
                    />
                    {props.dictionary.name}
                </label>
                <input
                    id="input_0"
                    type="text"
                    name='name'
                    maxLength="50"
                    className={(props.logged ? "disabled-style" : "") + " register-inputs"}
                    placeholder={props.dictionary.name}
                    onChange={changeHandler}
                    value={props.logged ? props.name : undefined}
                    disabled={props.logged ? true : false}
                />
                {nameError ?
                    <div className="log-error-class">{nameError}</div>
                    : null}
            </div>
            <div className="input-field">
                <label htmlFor="input_1" className="input-label">
                    <Ionicon
                        icon='ios-mail'
                        fontSize="25px"
                        color="rgb(92, 92, 92)"
                        className='margin-right'
                    />
                    {props.dictionary.email}
                </label>
                <input
                    type="email"
                    maxLength="120"
                    id="input_1"
                    name='email'
                    className={(props.logged ? "disabled-style" : "") + " register-inputs"}
                    placeholder={props.dictionary.email}
                    onChange={changeHandler}
                    value={props.logged ? props.email : undefined}
                    disabled={props.logged ? true : false}
                />
                {emailError ?
                    <div className="log-error-class">{emailError}</div>
                    : null}
            </div>
            <div className="input-field">
                <label htmlFor="input_2" className="input-label">
                    <Ionicon
                        icon='md-link'
                        fontSize="25px"
                        color="rgb(92, 92, 92)"
                        className='margin-right'
                    />
                    {props.dictionary.url}
                </label>
                <input
                    type="text"
                    id="input_2"
                    name='url'
                    className="register-inputs"
                    maxLength='250'
                    placeholder={props.dictionary.url}
                    onChange={changeHandler}
                />
                {urlError ?
                    <div className="log-error-class">{urlError}</div>
                    : null}
            </div>
            <div className="input-field">
                <label htmlFor="input_3" className="input-label">
                    <Ionicon
                        icon='ios-create'
                        fontSize="25px"
                        color="rgb(92, 92, 92)"
                        className='margin-right'
                    />
                    {props.dictionary.message}
                </label>
                <textarea
                    id="input_3"
                    className={"textarea-border textareaFilled"} 
                    maxLength="250"
                    name='message'
                    onChange={changeHandler}
                    placeholder={props.dictionary.message}
                    style={props.isCompliances ? {borderColor: props.config.color.principal} : {}}
                />
                {messageError ?
                    <div className="log-error-class">{messageError}</div>
                    : null}
            </div>
            <div className="center-align">
                <button
                    onClick={submit}
                    style={{
                        backgroundColor: props.config.color.secondary,
                        color: props.config.color.secondaryText
                    }}
                    className={success ? "log-btn large-send-button disable-button" : "log-btn large-send-button"}
                    disabled={success ? true : false}
                >
                    {props.dictionary.send}
                </button>
            </div>
        </>
    }

    const successMessage = () => {
        return <>
            <div className="summarized-info">
                <div className="summarized-info-line">
                    <div style={{color: props.config.color.secondary}}>
                        {props.dictionary.subject}:
                    </div> 
                    <div style={{color: props.config.color.secondaryText}} className="summarized-info-margin">
                        {subject}
                    </div>
                </div>
                <div className="summarized-info-line">
                    <div style={{color: props.config.color.secondary}}>
                        {props.dictionary.name}:
                    </div> 
                    <div style={{color: props.config.color.secondaryText}} className="summarized-info-margin">
                        {name}
                    </div>
                </div>
                <div className="summarized-info-line">
                    <div style={{color: props.config.color.secondary}}>
                        {props.dictionary.email}:
                    </div> 
                    <div style={{color: props.config.color.secondaryText}} className="summarized-info-margin">
                        {email}
                    </div>
                </div>
                <div className="summarized-info-text">
                    <div style={{color: props.config.color.secondary}}>
                        {props.dictionary.message}:
                    </div> 
                    <div style={{color: props.config.color.secondaryText}}>
                        {message}
                    </div>
                </div>
                <div className="summarized-info-text">
                    <div style={{color: props.config.color.secondary}}>
                        {props.dictionary.url}:
                    </div> 
                    <div style={{color: props.config.color.secondaryText}}>
                        {url}
                    </div>
                </div>
            </div>
            <span className="response" style={{color: props.config.color.secondaryText}}>
                {props.dictionary.responseSuccessReportingContent}
            </span>
            <button
                    onClick={showFormAgain}
                    style={{
                        backgroundColor: props.config.color.secondary,
                        color: props.config.color.secondaryText
                    }}
                    className="log-btn large-send-button"
                >
                    {props.dictionary.sendAnotherRequest}
            </button>
        </>
    }

    const errorMessage = () => {
        return <>
             <span className="response" style={{color: props.config.color.principalText}}>
                {props.dictionary.responseError}
            </span>
            <button
                    onClick={showFormAgain}
                    style={{
                        backgroundColor: props.config.color.secondary,
                        color: props.config.color.secondaryText
                    }}
                    className="log-btn large-send-button"
                >
                    {props.dictionary.tryAgain}
            </button>
        </>
    }

    const loadingAnimation = () => {
        return <div className="preloader-center">
            <Preloader
                use={Oval}
                size={50}
                strokeWidth={6}
                strokeColor={props.config.color.secondary}
                duration={1000}
            />
        </div>
    }

    return <>
        {showForm ? form() : null}
        {success ? successMessage() : null} 
        {error ? errorMessage() : null}
        {loading ? loadingAnimation() : null}
    </>;

}


export default ReportContent;
