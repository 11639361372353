import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import Ionicon from "react-ionicons";
import moment from 'moment';

import React from "react";

import {Circle} from "react-preloaders";

import './style.css';

import { AccordionMedia } from "./AccordionMedia/AccordionMedia";



function renderElements(config, list, iconName, folderStorage){


    const topBorder = {
        "color" : config.color.principalText,
        "backgroundColor" : config.color.principal,

    };

    const bottomBorder = {
        "color" : config.color.principalText,
        "border-bottom": "1px solid rgb(205, 213, 227)",
        "backgroundColor" : config.color.principal,
    };

    const collapsibleHeaderStyle = {
        "border-bottom": "1px solid rgb(205, 213, 227)",
        "backgroundColor" : config.color.principal,
        "color" : config.color.principalText
    };

    let collapsibleItemStyle = {
        "backgroundColor" : config.color.principal,
        "border": "1px solid rgb(205, 213, 227)",
        "marginBottom": 10,
        "borderBottomLeftRadius": 12,
        "borderBottomRightRadius": 12
    };

    const oneElement = {
        "color" : config.color.principalText,
        "backgroundColor" : config.color.principal
    };

    let sizeList = list.length - 1;

    if(!list.length) return <div className="center-align">No hay noticias por el momento</div>;
    return list.map((element, index) =>{

        let styleHeader;
        if(sizeList >= 1){

            styleHeader = !index
                ? collapsibleHeaderStyle
                : index === sizeList
                    ? topBorder
                    : bottomBorder
        }
        else if (!sizeList){
            //si solo hay un elemento en la lista
            styleHeader = oneElement;
            collapsibleItemStyle = {
                "backgroundColor" : config.color.principal,
                "border-top": "1px solid rgb(205, 213, 227)",
                "marginBottom": 10,
            }
        }
        if(element.title &&  element.description){
            return (
                <AccordionItem key={index} uuid={index+1}>
                    <AccordionItemHeading>
                        <AccordionItemButton
                            style={styleHeader}
                        >
                            {element.status === 1 && <span className="red-space" style={{backgroundColor: config.color.secondary}}>&nbsp;</span>}
                            <Ionicon
                                icon={iconName}
                                fontSize="25px"
                                color="rgb(92, 92, 92)"
                                className="icon-ask"
                                style={{backgroundColor: config.color.principal}}
                            />
                            {element.title}
                            {element.date && <span className="date-accordion">{moment(element.date).format('ll')}</span>}
                        </AccordionItemButton>

                    </AccordionItemHeading>
                    <AccordionItemPanel style={collapsibleItemStyle}>
                        <p>{element.description}</p>
                        <AccordionMedia config={config} media={element.media} folderStorage={folderStorage} />
                    </AccordionItemPanel>
                </AccordionItem>
            );
        }
        else return <div>No content</div>;
    }
    );
}

//folderStorage /storageModel or project/typeFolder/FileFolder example: /lady-dusha-web-test/posts/img/
 const AccordionList = ({
                            config,
                            list,
                            folderStorage='',
                            iconName='md-paper',
                            expanded= false,
                            loading=false,
                            clickEvent=null
 }) =>{
    return (
        <Accordion className="redesign-accordion" allowMultipleExpanded={expanded} allowZeroExpanded={true}  onChange={(uuid) => clickEvent &&  clickEvent(uuid)}>
            {loading
                ? <div className="loaderElement large" ><Circle background={"transparent !important"} color={config.color.secondary} /></div>
                : renderElements(config, list, iconName, folderStorage)
            }
        </Accordion>
    );
};

export default AccordionList;