
import React from 'react';

const CloseIcon = props => {
    return <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width={props.width}
        height={props.height} 
        fill="none" 
        viewBox="0 0 28 28"
        className={props.className ? props.className : ""}    
        onClick={props.onClick && props.onClick}
    >
        <rect 
            width={props.width} 
            height={props.height} 
            fill={props.fill} 
            opacity="0.3" 
            rx="8"
        />
        <path 
            stroke={props.stroke} 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth="2" 
            d="M18.118 9.882l-8.235 8.236M9.883 9.882l8.235 8.236"
        />
    </svg>
}

export default CloseIcon;