import React from 'react';
import './Details.css';

const Details = props =>{
    function renderObject(){
        if(props.donators.length>0){
            return props.donators.map((res, i)=>{
                return(<div key={i} className="btn__details">
                        <div className='row__streaming-50 position__relative'>
                          <span className="user__donator"> {res.username} </span>
                        </div>
                        <div className='row__streaming-50' style={{color:props.config.color.secondary}}>
                            {res.coinsDonados}
                        </div>
                    </div>
                )
            });
        }
    }


    return (
            <div className={props.show?'details':'hidden'}>                                
                                <div style={{marginTop:props.client?'-35px':'16px'}}>
                                    {renderObject()}
                                </div>
             </div>
    )
}

export default Details;