import React from 'react';

const Star5= props=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={props.width} 
            height={props.height}
            fill="none" 
            viewBox="0 0 20 19"
            className={props.className ? props.className : null}
        >
            <path 
                fill={props.color} 
                d="M10 0l2.245 6.91h7.266l-5.878 4.27 2.245 6.91L10 13.82l-5.878 4.27 2.245-6.91L.49 6.91h7.266L10 0z" 
            />
        </svg>
    )
}
export default Star5;