import React from 'react';

const AddImageIcon= ({color, width, height, className = null,lineColor})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 45 33"
        >
            <path 
                fill={color}
                d="M5.93 7.648c0 1.57 1.277 2.848 2.847 2.848s2.848-1.278 2.848-2.848c0-1.57-1.277-2.847-2.848-2.847-1.57 0-2.847 1.277-2.847 2.847z"
            />
            <path 
                fill={color}
                fillRule="evenodd" 
                d="M6.353 0C2.844 0 0 2.844 0 6.353v13.765c0 .541.068 1.067.195 1.568L6.54 17.47c1.215-.807 2.988-.816 4.215-.02l4.233 2.745L26.31 11.34c.654-.512 1.56-.784 2.485-.748.925.038 1.79.38 2.375.942L36 16.177V6.353C36 2.844 33.156 0 29.647 0H6.353zM3.912 7.648c0-2.683 2.183-4.865 4.865-4.865 2.683 0 4.866 2.182 4.866 4.865 0 2.683-2.183 4.866-4.866 4.866-2.682 0-4.865-2.183-4.865-4.866z" 
                clipRule="evenodd"
            />
            <path 
                fill={color}
                d="M36 19.243l-6.75-6.491c-.195-.188-.448-.225-.58-.23-.134-.006-.39.01-.609.182l-12.12 9.48c-.452.354-1.174.38-1.666.061l-5.1-3.308c-.3-.195-.734-.193-1.032.005l-7.076 4.702c1.14 1.704 3.082 2.827 5.286 2.827h23.294c3.509 0 6.353-2.845 6.353-6.353v-.875z"
            />
            <circle 
                cx="34.9" 
                cy="22.899" 
                r="6.5" 
                fill={color}
                stroke={lineColor}
                transform="rotate(-45 34.9 22.9)"
            />
            <path 
                stroke={lineColor}
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="1.3" 
                d="M34.9 26.2v-3.3m0-3.3v3.3m0 0h-3.3m3.3 0h3.3"
            />
        </svg>
    )
}
export default AddImageIcon;
