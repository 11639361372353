import React, { Component } from 'react';
import './StreamingClient.css';
import io from "socket.io-client";
import axios from 'axios';
import ReactPlayer from 'react-player';
import { isMobile } from 'react-device-detect';
import Donation from "../Coin/NewDonation/Donation";
import AlertCoins from '../Streaming/AlertCoins/AlertCoins';
import BlackScreen  from '../BlackScreen/BlackScreen';

import PlayIcon from '../Svg/PlayIcon';
import DropDownIcon from '../Svg/DropDownIcon';
import EyeIcon from '../Svg/EyeIcon';
import ArrowLeftIcon  from  '../Svg/ArrowLeftICon';
//import BubbleBackground from '../Svg/BubbleBackground';
import VideoIcon from '../Svg/VideoIcon';
import cookies from "js-cookie";
import ChatStreaming from '../ChatStreaming/ChatStreaming';
import toolbox from '../Toolbox/Toolbox';
import Details from '../Streaming/Details/Details';
// import DonationsStreaming from '../DonationsStreaming/DonationsStreaming';

var socket;

class StreamingClient extends Component {
    constructor(props){
        super(props);

        this.donate=this.donate.bind(this);
        this.openModalCoin = this.openModalCoin.bind(this);
        this.state = {
            messages: [],
            transmition: false,
            initiator: true,
            streamUrl: null,
            localStream: null,
            user: null,
            coins: 0,
            canSellCoins: false,
            permission: false,
            streaming: null,
            totalCoins: 0,
            showConversation: true,
            modelId: null,
            fullscreen: false,
            muted: true,
            play: false,
            usersConnected: 0,
            showModalDonate: false,
            showAlertCoins:false,
            coinsAlert:0,
            userNameCoins:null,
            showEmojis: false,
            conversation: '',
            startTime: '',
            roomId: '',
            donators: [],
        };
    }
    componentDidUpdate(prevProps, prevState) {
        if(this.props.streaming !== prevProps.streaming){
            if(this.props.streaming){
                this.isStreamingNow();
            }
            else{
                setTimeout( () => {
                    this.setState({transmition : false, streaming : null});
                    window.location.href='/';
                }, 2000);
            }
        }
    }
    async  componentDidMount(){
        await this.getUserData();
        await this.getModelId();
        await this.isStreamingNow();
        this.setState({
            permission: cookies.get('permission')
        });
        this.getCoins();
        const canSellCoinPermision = parseInt(cookies.get('permission'));
        if(canSellCoinPermision && canSellCoinPermision !== 1){
            this.canSellCoin();
        }
        this.disableTouch();
        let sidenav= document.getElementsByClassName("styles_content__1Phom"); //put sidenav and notifymodal behind
        if(sidenav!== null && sidenav !== undefined){
            sidenav[0].style.zIndex = 0;
        }
        const zIndexContainer = document.getElementsByClassName('container')[0];
        zIndexContainer.style.zIndex=1;
    };
   //disable touch
    disableTouch(){
        console.log(document, 'documento')
        window.addEventListener('load', function() {
            var maybePreventPullToRefresh = false ;
            var touchstartHandler = function(e) {
                if( e.touches.length !== 1 ) {
                    return ;
                }
                maybePreventPullToRefresh = (window.pageYOffset === 0) ;
            };
            var touchmoveHandler = function(e) {

            if (maybePreventPullToRefresh) {
                    maybePreventPullToRefresh = false ;
                    e.preventDefault() ;
                    return ;
                }
            };
            document.addEventListener('touchstart', touchstartHandler, false) ;
            document.addEventListener('touchmove', touchmoveHandler, false) ;
        }) ;
    };

    getModelId = async () =>{
        await axios.get('/api/v2/get/modelId')
        .then(resp=>{
            this.setState({
                modelId : resp.data.modelId,
            });
            return resp.data.modelId;
        })
        .catch(err => {toolbox.sendError(err, 6);})
    };

    canSellCoin = async () => {
        await axios.get('/api/v2/model/have/code/coin')
            .then((res)=>{
                this.setState({canSellCoins : res.data.haveCoin});
            });
    };

    getCoins = async () =>{
        await axios.get('/api/v2/get/coins')
            .then(resp =>{
                this.setState({coins : resp.data.coins})
            });
        return true;
    };

    getDonationStreaming = async (streaming) =>{
        await axios.get('/api/v2/get/donation/streaming', {
            params : {
                streaming: streaming
            }
        })
        .then(resp =>{
            this.setState({
                totalCoins: resp.data.coins || 0
            });
        });
    };

    getDonators = async (streamingId) => {
        const id = streamingId !== 0 && streamingId;
        await axios.get('/api/v2/streaming_obs/top_donators',{
            params: {streamingId:id}
        })
        .then(res => {
            if(res.data)this.setState({donators:res.data});
        })
        .catch(err=>{
            toolbox.sendError(err, 6);
            console.log(err)}
        );
    };

    showModal = (e) => {
        e.preventDefault();
        document.getElementById("buy-coin-btn-modal").click();
    }
    sleep(delay) {
        var start = new Date().getTime();
        while (new Date().getTime() < start + delay);
    }
    isStreamingNowChange = async () => {
        await axios.get('/api/v2/isCurrentStreaming')
            .then( async res => {
                if(res.data.streaming && this.state.streaming !== res.data.streaming){
                    this.getDonationStreaming(res.data.streaming);
                    this.getDonators(res.data.streaming);
                    this.setState({
                        transmition : true,
                        streaming : res.data.streaming,
                        streamUrl:this.state.streamUrl ==='' && `https://tpcontent.com/WebRTCApp/streams/${res.data.file}.m3u8`,
                        startTime: this.state.startTime === '' && res.data.startTime,
                        roomId: res.data.file
                    });
                    setTimeout(()=>{this.connectSocketIo()},400);
                    return true;
                }
                else if(!res.data.streaming){
                    this.props.toElevate.streamOff();
                    this.setState({
                        transmition : false,
                        streaming : null,
                        startTime:'',
                        roomId: '',
                        streamUrl: '', 
                        messages: []
                    });
                    if(socket){
                        socket.disconnect();
                        socket = null;
                    }
                    return false;
                }
                return false;
            });
    }
    isStreamingNow = async () => {
        await axios.get('/api/v2/isCurrentStreaming').then( async res => {
            if(res.data.streaming){
                this.getDonationStreaming(res.data.streaming);
                this.getDonators(res.data.streaming);
                this.setState({
                    transmition : true, streaming : res.data.streaming,
                    streamUrl: `https://tpcontent.com/WebRTCApp/streams/${res.data.file}.m3u8`,
                    startTime: this.state.startTime || res.data.startTime,
                    roomId: res.data.file
                });
                await this.connectSocketIo();
                await this.sleep(200);
                const playsVideo = document.querySelector('.content-play-custom');
                if(playsVideo){
                    const videoSize = this.player;
                    if(isMobile){
                        videoSize.wrapper.firstChild.addEventListener( "loadeddata", function (e) {
                            var width = this.videoWidth,
                            height = this.videoHeight;
                            if(width<height){

                                const backPlay = document.querySelector('.background-button-play');
                                if(backPlay){
                                    backPlay.classList.add('back-play-btn-full');
                                }
                                playsVideo.classList.remove('content-play-custom');
                                playsVideo.classList.add('content-play-custom-full');
                                videoSize.wrapper.classList.remove('video-cli')
                                videoSize.wrapper.firstChild.classList.add('fullscreen-auto');

                            }
                        }, false);
                    }
                    this.unMute();
                    playsVideo.addEventListener('click', async (e)=>{
                        if(!this.state.play){
                            e.target.muted=false;
                            this.setState({
                                play: true,
                                muted: false
                            });
                        }
                    });
                }
                setInterval(()=>{
                      this.isStreamingNowChange();
                }, 12000);
            }
            else{
                this.setState({transmition : false, streaming : null})
            }
        })
    }
    ref = player => {
        this.player = player;
      }
    unMute = async () =>{
        const videoStreaming = document.querySelector("#streaming-video-cli > video");
        if(videoStreaming){
            if(videoStreaming.muted){
                videoStreaming.muted = await false;
                this.setState({
                    muted : false
                });
            }
            else{
                videoStreaming.muted=true;
                this.setState({
                    muted : true
                });
            }
        }
    }
    getUserData = async () => {
        await axios.post('/api/v2/getUserDetails')
        .then(async res => {
            this.setState({user : res.data});
        })
        .catch(err => {
            toolbox.sendError(err, 6);
            console.log(err);
        });
    }
    fullscreen = async () => {
        let fullscreen = this.state.fullscreen;
        this.setState({fullscreen : !fullscreen})
    }
    donate(coinsToDonate, noCredit=false, error=false){
        const messageData= `${this.props.dictionary.hDonated} ${coinsToDonate} ${this.props.dictionary.coins}`;
        axios.post('/api/v2/spend/credit/donation/streaming', {
            amount : coinsToDonate,
            streaming : this.state.streaming
        })
        .then(resp => {
            if(resp.data.valid){
                this.setState({coins: resp.data.newCoinCredit});
                this.props.toElevate.spendCoins();
                this.getMessage(messageData, {
                    coins : coinsToDonate,
                    username: cookies.get('username')
                });
            }
            if(resp.data.error==="No credit" && noCredit)
                noCredit();
            if(resp.data.error==="Precio not found" && error)
                error();

        });
        this.openModalCoin();
    }
    openModalCoin(){
        this.setState({
            showModalDonate: !this.state.showModalDonate,
        });
    }  
    connectSocketIo = async () => {
       const pathChat = 'https://livestap.com';
        socket = await io(
            pathChat,
            {
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax : 2000,
                reconnectionAttempts: Infinity

            }).connect();

        const userLocal = {
            username : cookies.get('username'),
            room: this.state.roomId,
            image: this.state.user.image_profile
                    ? this.props.config.storageURL
                    +'/'+this.props.config.storage
                    + '/profiles/'+this.state.user.image_profile
                : null,
            permission : cookies.get('permission') || 0
        };
        socket.emit('joinRoom', userLocal);
        socket.on('message', async (message, joined) => {
            if(message.donation && message.donation.coins>0){
                const donations = this.state.totalCoins + parseInt(message.donation.coins);
                this.setState({
                    totalCoins: donations
                });
                await this.getDonators(this.state.streaming);
            }
            if(!joined){
                let messages = [...this.state.messages];
                this.setState({messages: [...messages, message]});
            }
        });
       socket.on('roomUsers', ({room, users}) => {
            const result = users.filter( e => e.permission === 0);
            this.setState({usersConnected: result.length})
        });
    };
    getMessage = async (message, donation = false)=>{
        const data={
            username: cookies.get('username'),
            message: message,
            model_message : 0,
            user_message : 1,
            streaming: this.state.streaming,
            image : this.state.user.image_profile ? this.props.config.storageURL+'/'+this.props.config.storage + '/profiles/'+this.state.user.image_profile : null,
            donation : donation,
            time: new Date()
        };
        socket.emit('chatMessage', data);

        if(donation){
            await axios.post('/api/v2/streaming/chat',data).then( (res) => {
                data["time"] = res.data.time
                this.setState({
                    // showAlertCoins : donation.coins >= 200 && true,
                    userNameCoins: donation.username,
                    coinsAlert: donation.coins
                });
            });
        }else {
            this.setState({conversation: ''});
        }

    }
    escapeHtml(string){
        const clearHTML = /(<script(\s|\S)*?<\/script>)|(<style(\s|\S)*?<\/style>)|(<!--(\s|\S)*?-->)|(<\/?(\s|\S)*?>)/g;
        const hasHtml = clearHTML.exec(string);
        if(hasHtml)
            return false;
        return true
    }
    sendMessage = async (e) => {
        e.preventDefault();
        const value = document.getElementById("input-streaming").value;
        if(value.trim()!==''){
            if(this.escapeHtml(value))
                this.getMessage(value);
            else
                document.getElementById("input-streaming").value='';
        }
    }
    messageHAndler = async (e)=>{
        if(this.state.conversation.length<80){
            this.setState({
                conversation: e.target.value
            });
        }
        if(e.keyCode===13){
            e.preventDefault();
            if(e.target.value.trim()!==''){
                if(this.escapeHtml(e.target.value))
                    this.getMessage(e.target.value);
                else
                    document.getElementById(this.state.conversation).value='';
            }
        }

    };
    addEmoji = (emoji)=>{
        this.setState({
             conversation: this.state.conversation.concat(emoji)
         })
     }

    returnPosts = (e) => {
        e.preventDefault();
        window.location.href="/";
    }
    hideConversations = (e) =>{
        e.preventDefault();
        this.setState({showConversation : !this.state.showConversation});
    }

    openEmojis(e){
        e.preventDefault();
        this.setState({showEmojis:!this.state.showEmojis});
    }
    playVideo(){
        this.setState({play: true});
    }

    render() {
        return (
            <div className="streaming-container" style={!this.state.localStream?{paddingBottom:'100%'}:{paddingBottom:'0%'}}>
                <div
                    className="post-desc post-media-content"
                    style={{
                        color: this.props.config.color.principalText,
                    }}
                >
                </div>
                {(this.state.transmition && this.props.view) ? (
                    <div
                        style={{background:this.props.config.color.third, color: this.props.config.color.secondaryText}}
                        className={(this.state.transmition ? "on-cli" : "") + " transmition"}
                        id="transmition"
                    >
                        {this.state.modelId ? (
                             <div
                                onClick={()=>this.playVideo()}
                                className="content-play-custom"
                             >
                                {!this.state.play  &&
                                    <div className="background-button-play">
                                        <VideoIcon
                                            color={"rgba(255,255,255,0.5)"}
                                            width={"100px"}
                                            height={"100px"}
                                            className="play-custom-btn play-btn"

                                        />
                                    </div>
                                }
                                <ReactPlayer
                                    id="streaming-video-cli"
                                    className={this.state.isMobile?'':(this.state.showConversation ? "video-cli": "full")}
                                    url={this.state.streamUrl}
                                    muted
                                    playing
                                    playsinline
                                    volume={1}
                                    ref={this.ref}
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.showConversation ?
                            <>
                                <div className="logo" >
                                    <img
                                        src={
                                            this.props.config.storageURL +
                                            this.props.config.logo
                                        }
                                        className="responsive-img"
                                        alt={this.props.config.nameModel}
                                    />
                                </div>
                                <div className="meta-info-cli" >
                                <div
                                        className="arrow__left__icon"
                                        onClick={(e) => this.returnPosts(e)}
                                    >
                                        <ArrowLeftIcon
                                            color={this.props.config.color.secondaryText}
                                            width="15"
                                            height="16"
                                        />
                                </div>
                                <div className="users__connected__transmition__cli" style={{color:this.props.config.color.secondaryText}}>
                                        <EyeIcon
                                                color={this.props.config.color.secondary}
                                                className="eye__icon__cli"
                                                height="19px"
                                        />&nbsp;{this.state.usersConnected}
                                </div>
                                <div className="total__donations__transmition__cli"  style={{color:this.props.config.color.secondaryText}}>
                                    {this.props.dictionary.donations}:
                                        <span className="normal__orange__text">
                                            &nbsp;{this.state.totalCoins}
                                        </span>
                                </div>
                                <div className="live__indicator__transmition__cli"  style={{color:this.props.config.color.secondaryText}}>
                                        <PlayIcon
                                            width="17"
                                            height="14"
                                            color={this.props.config.color.secondary}
                                        /> {this.props.dictionary.live}
                                    </div>
                                </div>
                                <Details
                                    config = {this.props.config}
                                    obs = {false}
                                    donators = {this.state.donators}
                                    client= {true}
                                    show = {this.state.showConversation}
                                />
                                <ChatStreaming
                                    config = {this.props.config}
                                    dictionary = {this.props.dictionary}
                                    user ={this.state.user}
                                    isClient = {true}
                                    messages = {this.state.messages}
                                    startTime = {this.state.startTime}
                                    getMessageFunc = {this.getMessage}
                                    openModalCoin = {this.openModalCoin}
                                    hideConversations = {this.hideConversations}

                                />
                                <AlertCoins
                                    showAlertCoins={this.state.showAlertCoins}
                                    user ={this.state.userNameCoins}
                                    coins ={this.state.coinsAlert}
                                    config = {this.props.config}
                                    dictionary={this.props.dictionary}
                                />
                            </>
                        :
                            <DropDownIcon
                                primary={this.props.config.color.secondary}
                                secondary={this.props.config.color.secondaryText}
                                className={
                                    !this.state.showConversation
                                        ? "dropdown__show__conversation show"
                                        : "hide"
                                }
                                onClick={(e)=>this.hideConversations(e)}
                            />
                        }
                        <Donation
                            dictionary={this.props.dictionary}
                            config={this.props.config}
                            coins={this.state.coins}
                            type="streaming"
                            showModalDonate={this.state.showModalDonate}
                            getCoins={this.props.toElevate.getCoins}
                            donate={this.openModalCoin}
                            doDonation={this.donate}
                        />
                    </div>
                ) : (
                    <p>{this.props.dictionary.messageNoTrans}</p>
                )}
                {this.state.transmition && !this.props.view && 
                    <BlackScreen 
                        title = {'PREMIUM Content'}
                        color = {this.props.config.color}
                    />
                }
                
                {/* <DonationsStreaming 
                    dictionary={this.props.dictionary}
                    show={this.state.showModalDonate}
                    onCancel={this.openModalCoin}
                    config={this.props.config}
                    title={''}
                    blackModal={true}
                /> */}
            </div>
        );
    }
}
export default StreamingClient;
