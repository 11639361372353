import React from 'react';

const Star5= props=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={props.width} 
            height={props.height}
            fill="none" 
            viewBox="0 0 20 19"
            className={props.className ? props.className : null}
        >
            <path 
                fill={props.color} 
                d="M6 0l1.347 4.146h4.36L8.18 6.708l1.347 4.146L6 8.292l-3.527 2.562L3.82 6.708.294 4.146h4.359L6 0z" 
            />
        </svg>
    )
}
export default Star5;