import React from 'react';

const PlayIcon= props =>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={props.width} 
            height={props.height}
            fill="none" 
            viewBox="0 0 17 14"
            className={props.className ? props.className : null}
            onClick={props.onClick&&props.onClick}
        >
            <path 
                stroke={props.color} 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="1.6"
                d="M15.238 3v8c0 1.105-.895 2-2 2H3c-1.105 0-2-.895-2-2V3c0-1.105.895-2 2-2h10.239c1.104 0 1.999.895 1.999 2z" 
            />
            <path 
                fill={props.color} 
                d="M7.523 4.836l2.771 1.639c.399.236.399.814 0 1.05L7.523 9.164c-.407.241-.92-.053-.92-.525V5.361c0-.473.514-.766.92-.525z"/>
        </svg>
    )
}
export default PlayIcon;