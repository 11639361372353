import React, {Component} from "react";
import axios from "axios";
import cookie from "js-cookie";
import moment from "moment";
import DaySales from "./DaySales/DaySales";
import SelecPeriod from "./Sales/SelectPeriod";
import Ventas from '../../components/Analytics/Ventas';
import Sitio from '../../components/Analytics/Sitio';
import "./style.css";
import SwitchBtn from "./Sales/SwitchBtn";

class Analytics extends Component {
    constructor() {
        super();
        this.formatMoney = this.formatMoney.bind(this);
        this.state = {
            username: cookie.get("username"),
            token: cookie.get("token"),
            percentage: 0,
            tab: 0,
            period: {
                end: moment().format("YYYY-MM-DD"),
                start: moment().startOf("month").format("YYYY-MM-DD")
            },
            totalMemberships: 0,
            baseUri: window.location.hostname === "localhost"
                ? 'http://localhost:5000'
                : 'https://cms.socialtechapps.com',
            loadingTotalMemberships: true
        };
    }

    componentDidMount() {
        this.getPercentage();
    }

    getTotalMemberships = (total) => {
        this.setState({
            totalMemberships: total,
            loadingTotalMemberships: false
        })
    }
    getPercentage = () => {
        axios
            .get(this.state.baseUri + "/api/get/model/fee/earning", {
                params: {
                    url:
                        (window.location.hostname === "localhost" || window.location.hostname === "beta.myladydusha.com")
                            ? "yuliettclub.com"
                            : window.location.hostname,
                },
            })
            .then((resp) => {
                this.setState({
                    percentage: resp.data.modelPercentage,
                });
            });
    };
    changeRange = async (inicio, fin) => {
        this.setState({
            period: {
                start: inicio.format("YYYY-MM-DD"),
                end: fin.format("YYYY-MM-DD")
            },
            sortDate: true
        });
    };
    changeTab = (tab) => {
        this.setState({tab: tab})
    }

    render() {
        let dictionary = this.props.dictionary;
        let config = this.props.config;
        return (
            <div className="analytics">
                <DaySales
                    percentage={this.state.percentage || 0}
                    username={this.state.username}
                    token={this.state.token}
                    dictionary={dictionary}
                    formatMoney={this.formatMoney}
                    config={config}
                    totalMemberships={this.state.totalMemberships}
                    loadingTotalMemberships={this.state.loadingTotalMemberships}
                />
                <div
                    style={{
                        backgroundColor: config.color.principal,
                        color: config.color.principalText
                    }}
                    className="container main-container"
                >
                    <p style={{textAlign:'left'}} className="title-content">
                        {dictionary.period}
                    </p>
                    <SelecPeriod
                        dictionary={dictionary}
                        config={config}
                        changeRange={this.changeRange}
                    />
                    <SwitchBtn
                        dictionary={dictionary}
                        config={config}
                        tab={this.state.tab}
                        changeTab={this.changeTab}
                    />
                    <Ventas
                        dictionary={dictionary}
                        config={config}
                        period={this.state.period}
                        percentage={this.state.percentage || 0}
                        formatMoney={this.formatMoney}
                        show={(this.state.tab === 0)}
                    />
                    <Sitio
                        dictionary={dictionary}
                        config={config.color}
                        period={this.state.period}
                        getTotalMemberships={this.getTotalMemberships}
                        show={(this.state.tab === 1)}
                    />
                </div>
            </div>
        );
    }

    formatMoney = (n, c, d, t) => {
        c = isNaN((c = Math.abs(c))) ? 2 : c;
        d = d === undefined ? "." : d;
        t = t === undefined ? "," : t;
        if (isNaN(n)) {
            return n;
        }
        let s = n < 0 ? "-" : "";
        let i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
        let k = i.length;
        let j = k > 3 ? k % 3 : 0;
        return (
            s +" $"+
            (j ? i.substr(0, j) + t : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
            (c
                ? d +
                Math.abs(n - i)
                    .toFixed(c)
                    .slice(2)
                : "")
        );
    };
}

export default Analytics;
