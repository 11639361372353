import React, { Component } from 'react';
import axios from 'axios';
import { Cube } from 'react-preloaders';
import toolbox from '../Toolbox/Toolbox';
import './styleInactive.css';

class HomeInactive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            talents: [],
            config: []
        };
    }

    componentDidMount() {
        this.getListOfActiveTalents();
    }

    getListOfActiveTalents(){
        return axios.get('/api/v2/home/get/activeModels')
            .then(res => {
                this.setState({
                    talents: res.data.talents,
                    dictionary:  res.data.dictionary
                });
            })
            .catch(err => {
                toolbox.sendError(err);
            });
    }

    renderTalents(){
        var copy = this.state.talents;
        return copy.map((item, index) => {
            let itemConfig = typeof item.frontConfig === "string" ? JSON.parse(item.frontConfig) : item.frontConfig;
            return (
                <li key={index}>
                    <a href={"https://" + item.hostname}>
                        <img 
                            src={itemConfig.storageURL+itemConfig.principalImage} 
                            alt="talent"
                            className="talent-img"
                        />
                        <span>{item.username}</span>
                    </a>
                </li>
            );
        });
    }


    render() {
        if(this.state.talents.length === 0 ) return <div> <Cube background="transparent !important" color="#4285f4" className="center-align"/></div>;

        return (
            <div
                className="container main-container center-align "
                style={{
                    backgroundColor: "rgb(37 37 37)",
                    color: "rgb(243, 243, 243)"
                }}
            >
                <h6 className="inactive-title">
                    {this.state.dictionary.disabledSite}
                </h6>

                <div className="talents-container">
                    <div className="title-talent">{this.state.dictionary.subtitleDisabled}</div>
                    <ul>
                        {this.renderTalents()}
                    </ul>
                </div>
                <p>
                    Powered by <a className="teamlink" href="https://teampeach.app">Team Peach</a>
                    <img
                        src="https://tpcontent.com/private-social-media.appspot.com/icons/logoteam.png"
                        className="peach-icon"
                        alt="peach"
                    />
                </p>
            </div>
        );
    }
}

export default HomeInactive;
