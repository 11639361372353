import React from 'react';

const MoreIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 4 17"
            className={className}
        >
            <circle 
                cx="2" 
                cy="2" 
                r="2" 
                fill={color}
            />
            <circle 
                cx="2" 
                cy="8.4" 
                r="2" 
                fill={color}
            />
            <circle 
                cx="2" 
                cy="14.8" 
                r="2" 
                fill={color}
            />
        </svg>


    )
}
export default MoreIcon;
