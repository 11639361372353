import React from 'react';

const PlusIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 512.000000 512.000000"
            className={className}
        >
            <g 
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill={color}
                stroke="none"
            >
                <path 
                    d="M2495 4786 c-37 -17 -70 -52 -84 -89 -8 -19 -11 -360 -11 -1083 l0
                    -1054 -1069 -2 c-1063 -3 -1070 -3 -1097 -24 -53 -39 -69 -71 -69 -134 0 -63
                    16 -95 69 -134 27 -21 34 -21 1097 -24 l1069 -2 2 -1069 c3 -1063 3 -1070 24
                    -1097 39 -53 71 -69 134 -69 63 0 95 16 134 69 21 27 21 34 24 1097 l2 1069
                    1069 2 c1063 3 1070 3 1097 24 53 39 69 71 69 134 0 63 -16 95 -69 134 -27 21
                    -34 21 -1097 24 l-1069 2 -2 1069 c-3 1063 -3 1070 -24 1097 -11 15 -32 37
                    -46 47 -34 25 -113 32 -153 13z"
                />
            </g>
        </svg>
    )
}
export default PlusIcon;
