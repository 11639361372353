import React from 'react';

const PersonIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 11 17"
        >
            <path 
                fill={color} 
                d="M10.792 14.693c-.063.041-.125.082-.189.122-.178.11-.36.215-.545.313-.04.022-.082.042-.123.063-.426.219-.866.406-1.319.558l-.048.017c-.237.078-.477.148-.72.208h-.002c-.245.061-.493.111-.742.153-.007 0-.014.002-.02.004-.236.038-.472.065-.71.086l-.126.01c-.235.017-.47.029-.708.029-.24 0-.478-.012-.716-.03-.041-.003-.082-.005-.123-.01-.24-.02-.478-.049-.715-.087l-.031-.006c-.5-.084-.992-.207-1.471-.367l-.045-.015c-.237-.081-.473-.17-.703-.27l-.005-.002c-.219-.094-.433-.198-.645-.308l-.083-.043c-.193-.104-.382-.215-.568-.331L.27 14.68c-1.157-.757 1.732-1.64 2.47-2.043.514-.28.833-.818.833-1.404V9.872l-.087-.105c-.008-.01-.826-1.003-1.135-2.35l-.034-.15-.129-.083c-.182-.117-.29-.314-.29-.526v-1.34c0-.176.074-.34.21-.462l.125-.113V2.637l-.004-.05c0-.008-.112-.918.528-1.648C3.304.316 4.24 0 5.54 0c1.296 0 2.23.314 2.778.932.64.723.534 1.649.534 1.656l-.004 2.156.125.112c.136.123.21.287.21.463v1.34c0 .27-.183.514-.446.595l-.188.058-.06.187c-.223.693-.54 1.333-.944 1.902-.099.14-.195.264-.278.36l-.094.106v1.398c0 .61.339 1.158.884 1.43.634.317 3.77 1.324 2.735 1.998z" 
                opacity="1"
            />
        </svg>
    )
}
export default PersonIcon;
