import React from 'react';

const PodcastIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            version="1.0" 
            xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height} 
            viewBox="0 0 700.000000 700.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <metadata>
                Created by potrace 1.16, written by Peter Selinger 2001-2019
            </metadata>
            <g 
                transform="translate(0.000000,700.000000) scale(0.100000,-0.100000)"
                fill={color}
                stroke="none"
            >
                <path 
                    d="M3365 6830 c-211 -24 -419 -104 -593 -227 -193 -138 -370 -377 -446
                    -605 -65 -196 -61 -91 -61 -1523 0 -1408 -3 -1337 55 -1515 63 -193 152 -340
                    290 -481 142 -145 269 -227 475 -309 250 -100 633 -90 885 22 178 79 269 140
                    394 261 189 184 290 369 358 657 10 44 13 327 13 1360 l0 1305 -23 95 c-58
                    240 -164 430 -330 597 -178 180 -391 295 -641 347 -88 18 -284 27 -376 16z
                    m330 -300 c22 -5 54 -12 70 -16 45 -10 211 -89 274 -131 68 -45 184 -159 237
                    -233 50 -69 104 -175 133 -261 44 -132 51 -245 51 -821 l0 -538 -961 0 -960 0
                    4 583 c3 367 9 604 16 642 26 139 83 276 164 395 44 64 172 189 238 233 122
                    81 291 144 429 161 52 6 259 -3 305 -14z m765 -2739 c0 -599 -11 -682 -115
                    -889 -44 -87 -132 -200 -211 -273 -283 -257 -720 -314 -1067 -138 -169 86
                    -322 240 -412 412 -101 196 -106 233 -112 840 l-5 497 961 0 961 0 0 -449z"
                />
                <path 
                    d="M3114 5851 c-93 -56 -100 -180 -15 -240 28 -20 40 -21 401 -21 361 0
                    373 1 401 21 63 45 79 136 33 196 -48 63 -46 63 -436 63 -329 0 -355 -1 -384
                    -19z"
                />
                <path 
                    d="M3124 5371 c-11 -5 -34 -25 -52 -45 -41 -47 -44 -112 -9 -163 42 -62
                    48 -63 437 -63 303 0 357 2 386 16 46 22 74 70 74 128 0 41 -5 52 -39 89 l-40
                    42 -368 2 c-202 1 -377 -2 -389 -6z"
                />
                <path 
                    d="M3994 3926 c-51 -22 -84 -74 -84 -132 0 -89 55 -144 144 -144 38 0
                    52 6 85 35 84 76 65 198 -38 241 -41 17 -67 17 -107 0z"
                />
                <path 
                    d="M3949 3441 c-32 -31 -39 -46 -39 -77 0 -56 -26 -157 -54 -212 -34
                    -66 -120 -146 -191 -178 -38 -18 -86 -30 -130 -34 -85 -7 -122 -24 -152 -69
                    -29 -43 -31 -113 -4 -157 30 -50 81 -68 170 -61 171 13 304 71 425 186 122
                    116 193 258 215 429 12 91 0 136 -46 179 -31 30 -40 33 -95 33 -57 0 -63 -2
                    -99 -39z"
                />
                <path 
                    d="M1789 5743 c-14 -2 -41 -20 -60 -39 l-34 -35 -3 -937 c-2 -927 -2
                    -937 18 -970 13 -21 38 -41 67 -54 40 -18 51 -19 87 -8 22 7 55 28 73 47 l33
                    34 0 940 0 940 -37 39 c-39 40 -83 53 -144 43z"
                />
                <path 
                    d="M5129 5743 c-14 -2 -44 -22 -65 -44 l-39 -40 -3 -910 c-2 -604 1
                    -923 8 -949 13 -48 65 -95 115 -104 51 -10 120 26 146 74 19 34 19 71 17 967
                    l-3 932 -29 30 c-27 28 -84 53 -111 49 -5 0 -22 -3 -36 -5z"
                />
                <path 
                    d="M1232 5273 c-18 -9 -43 -30 -55 -47 -22 -30 -22 -38 -25 -366 -2
                    -184 0 -350 3 -368 8 -43 65 -99 111 -108 54 -10 109 11 141 53 l28 36 0 363
                    c0 351 -1 363 -21 391 -42 58 -119 77 -182 46z"
                />
                <path 
                    d="M5631 5272 c-19 -10 -43 -34 -53 -53 -17 -32 -18 -60 -16 -397 l3
                    -364 37 -34 c34 -31 43 -34 98 -34 52 0 64 4 94 31 20 17 40 47 46 67 6 22 10
                    171 10 357 0 313 -1 322 -22 365 -38 74 -124 101 -197 62z"
                />
                <path 
                    d="M1741 3453 c-64 -51 -73 -109 -46 -313 26 -193 47 -283 101 -428 66
                    -175 172 -366 278 -497 55 -68 252 -265 301 -301 22 -16 42 -32 45 -35 14 -18
                    192 -126 279 -169 89 -45 353 -140 387 -140 12 0 14 -54 14 -319 l0 -318 -70
                    -7 c-152 -15 -282 -79 -397 -195 -121 -122 -181 -254 -190 -417 l-6 -102 41
                    -43 41 -44 981 0 981 0 41 44 41 43 -5 102 c-5 80 -12 117 -36 178 -94 242
                    -307 410 -552 434 l-70 7 0 318 c0 265 2 319 14 319 34 0 298 95 387 140 87
                    43 265 151 279 169 3 3 23 19 45 35 64 47 276 264 336 346 71 96 114 169 178
                    298 91 187 132 331 166 582 20 156 19 215 -5 265 -25 52 -64 75 -127 75 -97 0
                    -143 -58 -143 -179 0 -187 -71 -464 -165 -646 -43 -83 -102 -183 -115 -195 -3
                    -3 -18 -23 -35 -45 -200 -270 -532 -488 -850 -559 -163 -36 -197 -40 -365 -40
                    -167 0 -200 4 -365 40 -152 34 -331 114 -486 217 -144 96 -269 214 -364 342
                    -17 22 -32 42 -36 45 -13 12 -98 161 -132 232 -36 76 -84 211 -106 298 -17 68
                    -41 256 -41 322 0 112 -48 168 -145 168 -39 0 -57 -6 -84 -27z m1869 -2223 l0
                    -300 -110 0 -110 0 0 300 0 300 110 0 110 0 0 -300z m343 -594 c41 -8 94 -24
                    118 -36 53 -28 131 -105 158 -159 l21 -41 -745 0 c-613 0 -745 2 -745 13 0 24
                    61 106 104 141 108 88 149 94 628 95 297 1 404 -2 461 -13z"
                />
            </g>
        </svg>
    )
}
export default PodcastIcon;
