import React, {Component} from 'react';
import {Circle} from 'react-preloaders';

class ForgotPassword extends Component {
    render() {
        if (this.props.dictionary.length === 0 || this.props.config.length === 0)
            return <div className="center-align" style={{paddingTop: 45}}>
                <Circle color="#4285f4" className="center-align"/>
            </div>;

        return (
            <div
                id="container-support"
                className="container main-container center-align"
                style={{
                    backgroundColor: this.props.config.color.principal,
                    color: this.props.config.color.principalText
                }}
            >
                <h5>{this.props.dictionary.FAQ.q3}</h5>
                <div className="faq-steps ">
                    <ol>
                        <li>
                            {this.props.dictionary.FAQ.q3p1}
                        </li>
                        <li>
                            {this.props.dictionary.FAQ.q3p2}
                        </li>
                        <li>
                            {this.props.dictionary.FAQ.q3p3}
                        </li>
                        <li>
                            {this.props.dictionary.FAQ.q3p4}
                        </li>
                        <li>
                            {this.props.dictionary.FAQ.q3p5}
                        </li>
                    </ol>
                </div>
            </div>
        )
    }
}

export default ForgotPassword;
