import React, {Component} from 'react';
import SectionIcon from '../../../Svg/SectionIcon';

class Section extends Component {
    render() {
        const {config, section, dictionary, active} = this.props;
        const activeColor = active
            ? config.color.secondary
            : config.color.principalText;
        const sectionIcon = section.iconType
            ? <SectionIcon iconName={section.iconType}
                           color={activeColor}
                           width={"25px"}
                           height={"25px"}
            />
            :
            <img
                src={section.icon}
                title={section.sectionName}
                alt={section.sectionName}
            />;

        return (
            <li onClick={() => this.props.changeSection(section.sectionName)}>
                <div className="section-cell">
                    {sectionIcon}
                    <span style={{color: activeColor}}>
                            {dictionary[section.sectionName.toLocaleLowerCase()] || section.sectionName}
                    </span>
                </div>

            </li>
        )
    }
}

export default Section;
