import React from 'react';

const SupportIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 512.000000 512.000000"
            className={className ? className : null}
        >
            <g 
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill={color} 
                stroke="none"
            >
                <path 
                    d="M2410 5110 c-243 -34 -475 -120 -662 -244 -377 -251 -580 -607 -605
                    -1058 -6 -110 -9 -124 -28 -140 -13 -10 -30 -33 -39 -53 -13 -28 -23 -35 -45
                    -35 -78 0 -189 -53 -263 -126 -240 -235 -104 -656 230 -715 58 -10 63 -14 84
                    -53 29 -52 96 -89 150 -83 55 7 110 38 135 75 31 47 35 109 31 532 -3 394 -3
                    395 -49 446 -19 20 -19 24 -4 90 80 351 256 616 524 789 206 133 422 196 676
                    197 158 0 292 -21 429 -69 186 -65 324 -151 462 -288 171 -170 286 -387 339
                    -635 13 -63 13 -65 -11 -93 -13 -16 -27 -41 -32 -55 -10 -36 -9 -817 1 -864 4
                    -21 22 -51 39 -68 l31 -31 -6 -102 c-11 -179 -49 -279 -137 -361 -88 -82 -200
                    -124 -609 -226 -201 -50 -204 -51 -225 -32 -94 84 -247 26 -277 -105 -17 -75
                    26 -158 98 -189 42 -18 117 -18 152 1 31 16 76 65 85 92 5 14 55 30 218 72
                    422 108 570 168 694 284 122 114 173 248 174 454 0 99 1 103 26 119 14 10 34
                    34 44 55 18 34 25 38 67 44 219 30 363 196 363 419 0 202 -110 357 -289 410
                    -30 9 -69 16 -87 16 -28 0 -36 6 -50 35 -9 20 -27 44 -40 54 -21 17 -24 27
                    -24 91 0 491 -322 996 -774 1215 -199 97 -353 134 -581 140 -82 2 -179 0 -215
                    -5z"
                />
                <path 
                    d="M2381 4539 c-81 -14 -228 -66 -301 -106 -264 -144 -429 -366 -520
                    -698 -18 -66 -40 -263 -40 -358 0 -515 248 -1085 583 -1338 78 -59 212 -131
                    268 -144 22 -6 28 -1 44 34 25 56 95 125 159 156 109 53 241 40 349 -36 l58
                    -40 37 27 c76 56 193 187 266 296 242 365 357 844 301 1258 -54 404 -270 718
                    -598 870 -131 61 -228 82 -397 86 -85 1 -179 -1 -209 -7z"
                />
                <path 
                    d="M1770 1749 c-369 -47 -737 -245 -968 -521 -157 -188 -236 -338 -342
                    -653 -69 -204 -71 -212 -68 -291 3 -65 9 -90 31 -126 32 -56 86 -104 146 -132
                    l46 -21 1945 0 1945 0 46 21 c60 28 114 76 146 132 22 36 28 61 31 126 3 79 2
                    87 -68 291 -134 396 -276 625 -508 818 -249 208 -546 333 -859 362 -71 7 -73
                    6 -120 -27 -27 -19 -67 -45 -90 -58 -24 -13 -43 -28 -43 -34 0 -6 -16 -34 -36
                    -63 -73 -106 -208 -165 -332 -145 -66 11 -132 40 -169 74 -13 13 -55 26 -115
                    38 -161 31 -286 83 -422 174 -73 49 -79 50 -196 35z"
                />
            </g>
        </svg>
    )
}
export default SupportIcon;