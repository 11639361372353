import React, { Component } from 'react';
import userIcon from "../../../../img/generic-user-icon.jpg";
import CoinsIcon from '../../../../components/Svg/CoinsIcon';
import LeftArrowIcon from '../../../../components/Svg/LeftArrowNotBgIcon';
import Icon from "react-ionicons";

class ChatTitle extends Component {
    constructor(props) {
        super(props);
        this.onClickBack = this.onClickBack.bind(this);
    }

    onClickBack() {
        this.props.toElevate.showConversation(this.props.filterConversation);
    }

    componentDidMount() {}

    render() {
        let profileImg = this.props.image || userIcon;
        let profileName = this.props.name || "username";
        let btnDonateStyle = {
            backgroundColor: this.props.config.color.secondary,
        };
        let titleHeaderStyle = {
            borderBottomColor: this.props.config.color.secondary,
        };
        let txtStyle = {
            color: this.props.config.color.principalText
        };
        let btnBack = this.props.showBackBtn ? (
            <button
                onClick={this.onClickBack}
                className="v3-chat-back-btn"
            >
                <LeftArrowIcon
                    color={this.props.config.color.secondary}
                    width={"18px"}
                    height={"18px"}
                />
            </button>
        ) : (
            ""
        );
        return (

            <div style={titleHeaderStyle} className={"v3-chat-title-container"}>
                {btnBack}
                <div className="v3-chat-title-data-container">
                    <img alt={"profileImage"} src={profileImg} className="v3-image-usrname"/>
                    <div style={txtStyle} className="v3-title-username">
                        {profileName}
                    </div>
                </div>
                {!this.props.isModel ? 
                    <div 
                        className="v3-chat-title-coins" 
                        style={{backgroundColor: this.props.config.color.secondary}}
                        onClick={this.props.donate}
                    >
                        <div className="v3-chat-title-coins-text" style={{color: this.props.config.color.secondaryText}}>
                            {this.props.coins}
                        </div>
                        <div className="v3-chat-title-coins-icon">
                            <CoinsIcon
                                color={this.props.config.color.secondary}
                                colorPrincipal={this.props.config.color.principal}
                                width={'18px'}
                                height={'18px'}
                            />
                        </div> 
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}

export default ChatTitle;
