import React, {Component} from 'react';
import Modal from '../Modal';
import axios from 'axios';
import './style.css';

class ConfirmPurchaseModal extends Component {
    constructor(props){
        super(props);
        this.state={
            type : "membership",
            showModalPushase: false,
            modalAction: null,
            coinsPurchased: null,
            currentCoins: null,
            errorText: null,
            picode: null,
            price: null
        }
        this.closePopUp = this.closePopUp.bind(this);
        this.getPurchaseCoins = this.getPurchaseCoins.bind(this);
    }

    componentDidMount = () => {
        this.props.toElevate.confirmPurchase()
        .then(res =>{
            this.setState({
                showModalPushase: res.showModalPushase,
                modalAction: res.modalAction,
                errorText: res.errorText,
                price: res.price,
                picode: res.picode
            });
            if(res.modalAction === "moreCoins"){
                this.props.toElevate.getCoins()
                .then(res =>{
                    this.setState({
                        currentCoins: res,
                    });
                });
                this.getPurchaseCoins(res.price,res.picode);
            }
        });
        
    }

    getPurchaseCoins = (price,picode) =>{
        const data= {
            price: price,
            picode: picode
        }
        return axios.post('/api/v2/getBoughtCoins', data)
            .then(res => {
                this.setState({
                    coinsPurchased: res.data.num_coins,
                });
            });
    }

    closePopUp = async() =>{
        await this.setState({showModalPushase: false});   
    }

    drawModalPurchases(){
        let dictionary = this.props.dictionary;       
        let modalOptions = {
            newMembership : {
                button : dictionary.close,
                head : dictionary.membershipPurchased,
                action : this.closePopUp,
                body :
                    <div style={{textAlign: "center"}}>
                        {dictionary.membershipPurchasedMsg}
                    </div>
            },
            moreCoins : {
                button : dictionary.close,
                head : dictionary.updatedCoin,
                action : this.closePopUp,
                body : 
                    <div style={{textAlign: "center"}}>
                        {dictionary.updatedCoinMsg.updateOne} {this.state.coinsPurchased} {dictionary.updatedCoinMsg.updateTwo} {this.state.currentCoins}.
                    </div>
            },
            Error : {
                button : dictionary.close,
                head : dictionary.purchaseError,
                action : this.closePopUp,
                body : 
                    <div style={{textAlign: "center"}}>
                        {dictionary[this.state.errorText]}
                    </div>
            }
        }
        let option = this.state.modalAction ? this.state.modalAction : "Error";
        let config = this.props.config;
        let styleHead = (this.state.modalAction==="Error") ? "ErrorPurchase-color" : '';
        return (
            option ?
                <Modal
                    id={"ModalPurchases"}
                    open={this.state.showModalPushase}
                    closeAction={this.closePopUp}
                    head={<h4 className={styleHead}>{modalOptions[option].head}</h4>}
                    config={config}
                    footer={
                        <div className='center-align actions'>
                        {option === "buyCoins" && this.state.catalogCoin.length===0 ?
                            null
                        :
                            this.state.canceled ?
                                null
                            :
                                <button
                                    style={{
                                        backgroundColor:config.color.secondary,
                                        color: config.color.secondaryText
                                        }}
                                    disabled={this.state.disabled}
                                    onClick={(e) => modalOptions[option].action(e)}
                                    className="purchase-style create-account-button log-btn"
                                >
                                    
                                    {modalOptions[option].button ? 
                                        modalOptions[option].button 
                                    : 
                                        dictionary.close
                                    }
                                </button>
                        }
                        </div>
                    }
                    height={this.props.height}
                    top={this.props.top}
                    middle={this.props.middle}
                    levelModal={this.props.levelModal}
                    levelContainer={this.props.levelContainer}
                >
                    {modalOptions[option].body ? 
                        modalOptions[option].body 
                    : 
                        dictionary.purchaseError
                    }
                </Modal>
                :
                null
        )
    }
    render() {
        return(
            <div className="modal-ConfirmPurchase">
                {this.state.showModalPushase ?
                    this.drawModalPurchases()
                : null }
            </div>

        )
    }
}
export default ConfirmPurchaseModal;
