import React, {Component} from 'react';
import Publish from "../../components/Publish";
import "react-datepicker/dist/react-datepicker.css";
import {isMobile} from 'react-device-detect';
import './style.css';

class NewFeed extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container main-container"
                 style={{
                     backgroundColor: this.props.config.color.principal,
                     color: this.props.config.color.principalText
                 }}
            >
                <Publish
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                    isMobile={isMobile}
                />
            </div>
        )
    }
}

export default NewFeed;
