import React, {Component} from 'react';
import cookie from "js-cookie";
import './style.css';

class Subscribe extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: cookie.get('username'),
            email: cookie.get('email')
        }
    }

    subscribe = (picode) => {
        //EPOCH
        let config = this.props.config;
        let username = this.state.username;
        let email = this.state.email;
        let subscribeCode;

        //EPOCH
        if(picode.payment_processor === 1){
            subscribeCode = "https://wnu.com/secure/services/?api=join&selected_type=PP&pi_code="
                + picode.pi_code + "&reseller=a&no_userpass=Y&username="
                + username + "&pi_returnurl=https%3A%2F%2F"
                + config.hostname + "&email="
                + email;
        }
        //CCBILL
        else {
            // https://sandbox-api.ccbill.com
            subscribeCode = "https://api.ccbill.com/wap-frontflex/flexforms/"
                + picode.pi_code + "?uname="
                + username + "&hostname="
                + config.hostname;
        }

        if (this.props.confirmed) {
            window.location.href = subscribeCode;
        } else {
            this.props.openModalConfirm(subscribeCode)
        }
    };

    periodMembership = (period) => {
        let dictionary = this.props.dictionary;
        if (period === "week") {
            return dictionary.weekly;
        } else if (period === "month") {
            return dictionary.monthly;
        } else if (period === "day") {
            return dictionary.per24Hours;
        } else if (period === "2 day") {
            return dictionary.per48Hours;
        } else if (period === "3 day") {
            return dictionary.per3days;
        } else if (period === "30 day") {
            return this.props.dictionary.per30days;
        }
    };

    drawSubscription(picode, main = true) {
        let config = this.props.config;
        return (
            (this.props.statusModel !== 2) ?
                <button
                    onClick={(e) => this.subscribe(picode)}
                    style={{
                        backgroundColor: (main) ? config.color.secondary : "#595959",
                        color: (main) ? config.color.secondaryText : "#FFF"
                    }}
                    className={main ? "main" : "promo"}
                >
                    ${picode.price
                + " " + picode.currency
                + " " + this.periodMembership(picode.payment_period)}
                </button>
            : null
        )
    }

    promoMessage(picode, mainpicode) {
        let dictionary = this.props.dictionary;
        return (
            <p>
                {dictionary.languageKey === 'es'
                    ?
                    `Al comprar la membresia de ${picode.price
                    + " " + picode.currency}, automáticamente al
                                pasar las ${this.periodMembership(picode.payment_period)}, se le cobrará la subscripción de
                                ${mainpicode.price + " " + mainpicode.currency
                    + " " + this.periodMembership(mainpicode.payment_period)}
                                . A menos que cancele su mebresía`
                    :
                    `After ${this.periodMembership(picode.payment_period)} of the purchase of ${picode.price
                    + " " + picode.currency} membership, you will be charged automatically the
                                subscription of ${mainpicode.price + " "
                    + mainpicode.currency
                    + " " + this.periodMembership(mainpicode.payment_period)}
                                . Unless you cancel your membership`
                }
            </p>
        )
    }

    render() {
        let picodes = [...this.props.picodes];
        if (!picodes || !picodes.length) return false;
        return (
            <div className="subscribe">
                {
                    picodes.length > 1 ?
                        <div className="promo">
                            {this.drawSubscription(picodes[1], false)}
                            {this.promoMessage(picodes[1], picodes[0])}
                        </div>
                        :
                        null
                }
                {this.drawSubscription(picodes[0])}
                <p className="premium">PREMIUM Content</p>
            </div>
        )
    }
}

export default Subscribe;
