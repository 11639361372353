import React, {Component} from "react";
import './style.css';
import Checkbox from "../Checkbox";
import DatePicker from "react-datepicker/es";
import moment from "moment";
import Carousel from "./Carousel";
import Promise from "bluebird";
import MediaBoxList from '../MediaBox/MediaBoxList';
import axios from "axios";
import Saving from "../Saving";
import images from '../Images';
import ImageIcon from '../Svg/ImageIcon';
import MediaBoxIcon from '../Svg/MediaBoxIcon';
import UploadFiles from "../UploadFiles/";
import ModalConfirm from "../ModalConfirm";
// eslint-disable-next-line
let hasVideo =false;
class Publish extends Component{
    constructor(props) {
        super(props);
        this.state = {
            startDate : moment().add(1, 'hours').format("YYYY-MM-DDTHH:00"),
            files : [],
            filesUploading : 0,
            idMediaBox : "mediaBoxPublish",
            postSection:[],
            section : null,
            scheduleTime : null,
            openDatePicker : false,
            publicPost: false,
            description: null,
            sectionError : false,
            descriptionError : false,
            disabled : false,
            saving : false,
            uploading : false,
            progress : 0,
            progressSlice : 0,
            postIdIserted : null,
            fileEncodingMessage: null
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.axiosMedia = this.axiosMedia.bind(this);
    }

    componentDidMount() {
        this.getAllSection()
    }

    changeHandler(e) {
        this.setState({ [e.target.name]: e.target.value});
    }

    removeMedia = (index) => {
        let files = [...this.state.files];
        files.splice(index, 1);
        this.setState({files : files})
    }

    handleCheck = (name, checked) => {
        this.setState({
            [name] : checked
        })
    }

    openDatePicker = () => {
        this.setState({openDatePicker : !this.state.openDatePicker})
    }
    changeTimeValue = (e) => {
        let value = e.target.value;
        if(!moment(value).isValid()){
            this.setState({scheduleTime : null})
        }
        else{
            this.setState({scheduleTime : moment(value).format("YYYY-MM-DDTHH:00")})
        }
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    getAllSection=async()=>{
        await axios.post('/api/v2/getAllSectionByModel')
            .then(async res => {
                if(res.data)
                {
                    this.setState({postSection: res.data});
                    if(res.data.length===1){
                        this.setState({section: res.data[0].idSection});
                    }
                    return res.data
                }
            })
            .catch(err => {
                console.log(err);
            });

    }

    openMediaBox = () => {
        document.getElementById(this.state.idMediaBox).classList.add("open")
    }

    setDate = async (date) => {
        this.setState({
            startDate : moment(date).format("YYYY-MM-DDTHH:mm"),
            scheduleTime : moment(date).format("YYYY-MM-DDTHH:mm")
        });
    }

    getChoosen = async (arr) => {
        let files = [...this.state.files];
        let newFiles = files.concat(arr);
        this.setState({files : newFiles});
    }
    hideModal = () =>{
        this.setState({ fileEncodingMessage: null });
    }
    fileChangedHandler = async (e) => {
        const files = e.target.files;
        await this.setState({filesUploading: files.length})
        let currFiles = this.state.files;
        await new Promise.map(files, async (file, i) => {
            let typeFormat = file ? images.validateFormat(file.type) : '';
            if (typeFormat === 'image') {
                if (file.type.match('image/jpeg.*')) {
                    let blob = await images.rotateImage(file, i);
                    currFiles.push({file : blob, type : typeFormat, method : "upload", progress : 0});
                } else {
                    currFiles.push({file : file, type : typeFormat, method : "upload", progress : 0});
                }
            }
            else if(typeFormat === 'video'){
                hasVideo=true;
                currFiles.push({file : file, type : typeFormat, method : "upload", progress : 0});
            }
        }).then( async () => {
            this.setState({
                files : currFiles
            })
        })
    }

    uploadHandler = async(e) => {
        try {
            e.preventDefault();
            this.setState({sectionError : false, descriptionError : false});
            let error;
            let section = this.state.section;
            let description = this.state.description;
            if(!section || section === "0"){
                this.setState({sectionError : true});
                error = true;
            }
            if(!description){
                this.setState({descriptionError: true});
                error = true;
            }
            if(error)
            {
                return false;
            }
            this.setState({disabled: true, saving : true});

            if(!this.state.postIdIserted){
                const postId = await this.makePost();
                await this.setStateAsync({postIdIserted : postId.postInserted});
            }
            let copyFiles = [...this.state.files];

            if(copyFiles.length > 0){
                this.setState({progressSlice : 100 / copyFiles.length})
                this.setState({uploading : true})
                await Promise.map(copyFiles, async (item, index) => {
                    if(item.method === "mediabox"){
                        await this.mediaBoxMedia(item.mediaBoxId, this.state.postIdIserted, index);
                    }
                    else {
                        let options = {
                            index,
                            folder: '/posts/',
                            type: "post",
                            idInserted: this.state.postIdIserted,
                            description: this.state.description,
                            filesLength: copyFiles.length,
                            schedule: !!this.state.scheduleTime
                        };
                        // eslint-disable-next-line
                        const upload = await UploadFiles.uploadFiles(item.file, options ,this.axiosMedia);
                    }
                }).then( async () => {
                    await this.setStateAsync({
                        uploading: false,
                        disabled: false,
                        saving: false,
                        filesUploading: 0,
                        fileEncodingMessage: "Tus archivos se subieron correctamente, estan siendo procesados",
                        files: [],
                        description: "",
                        publicPost: false,
                        postIdIserted: null
                    });
                    setTimeout(() => {
                        this.setState({fileEncodingMessage: null});
                    }, 10000);
                });
            }
        }
        catch (e) {}
    }

    makePost = async () =>{
        return await axios.post('/api/v2/create/post',
            {
                description: this.state.description,
                section: this.state.section,
                publicPost : this.state.publicPost ? '1' : '0',
                datePost : (this.state.scheduleTime) ? moment(this.state.scheduleTime).utc().format("YYYY-MM-DDTHH:mm") : null
            })
            .then(resp => {return resp.data});
    }

    mediaBoxMedia = async (mediabox, postId, index) => {
        return await axios.post('/api/v2/create/post/media/mediabox', {
            mediabox:  mediabox,
            postId : postId,
            order : index,
            total : this.state.files.length,
            description: this.state.description,
            schedule: !!this.state.scheduleTime
        })
            .then(resp =>{
                let storageImages = [...this.state.files];
                storageImages[index]["progress"]=100;
                this.setState({files : storageImages});
                return true
            })
            .catch(err=>{
                return false
            })
    }

    axiosMedia = (url,file, option) => {
        try {
            return axios.put(url, file, {
                onUploadProgress: progressEvent =>
                {
                    let storageImages = [...this.state.files];
                    let progress= Math.floor((progressEvent.loaded / progressEvent.total)*100);
                    storageImages[option.index]["progress"]=progress;
                    this.setState({files : storageImages});
                },
                headers: {
                    'Content-Type': file.type
                }

            })
                .then(async (resp)=>{
                    if(resp){
                        this.setState({ uploading: false })
                        return true;
                    }
                })
                .catch((err)=>{});
        }
        catch (e) {}
    }

    getSectionPost=async (section)=>{
        const postSection = [...this.state.postSection];
        return new Promise((resolver) => {
            //QUIERO VER ESTO WE
            // console.log(postSection)
            const firstFind = postSection.find(({idSection}) => parseInt(idSection) === parseInt(section));
            return resolver(firstFind.name);
        })
    }

    drawCanvas(size){
        let canvas = [];
        for(let i = 0; i < size; i++){
            canvas.push(<canvas key={i} id={"canvasToRotate" + i} className="hide"></canvas>)
        }
        return canvas;
    }

    renderCarousel(){
        if(this.state.files && this.state.files.length){
            let files = [...this.state.files];
            let config = this.props.config;
            return (
                <Carousel
                    media={files}
                    config={config}
                    remove={this.removeMedia}
                />
            )
        }
        return null;
    }

    renderSelect(){
        if (!this.state.postSection.length) return ;
        const postSection = this.state.postSection;
        let items = [];
        postSection.map((item, index) => {
            return items.push(<option key={index} value={item.idSection}>{item.name}</option>)
        });
        return items;
    }

    calcProgress = () => {
        let files = [...this.state.files];
        let length = files.length;
        let progress = 0;
        files.map( async (el) => {
            progress = progress + el.progress;
        })
        return progress/length;
    }

    render() {
        let dictionary = this.props.dictionary;
        let config = this.props.config;
        let startDate = this.state.startDate;
        let scheduleTime = this.state.scheduleTime;
        let progress = 0;
        if(this.state.uploading){
            progress = this.calcProgress()
        }
        return(
            <div id="publish" className="publish">
                <MediaBoxList
                    config={config}
                    dictionary={dictionary}
                    id={this.state.idMediaBox}
                    getChoosen={this.getChoosen}
                    post={true}
                />
                <h5 style={{ color: config.color.principalText}}>{dictionary.newPost}</h5>
                {this.drawCanvas(this.state.filesUploading)}
                <Checkbox
                    id="isPublish"
                    label={dictionary.freePost}
                    name="publicPost"
                    checked={this.state.publicPost}
                    onChange={this.handleCheck}
                />
                {this.state.postSection.length > 1 ?
                    <div className="select">
                        <select
                            style={{ color: config.color.principalText }}
                            name="section"
                            id="section"
                            className={(this.state.sectionError) ? "error" : ""}
                            onChange={this.changeHandler}
                        >
                            <option value="0" style={{color:"rgba(35, 35, 35, 0.5)"}}>{dictionary.seloption}</option>
                            {this.renderSelect()}
                        </select>
                    </div>
                    : null
                }
                <textarea
                    name="description"
                    id="description"
                    className={(this.state.descriptionError) ? "error" : ""}
                    rows="5"
                    onChange={this.changeHandler}
                    value={this.state.description || ''}
                    placeholder={dictionary.description}
                >
                </textarea>
                {this.renderCarousel()}
                <ul className="actions">
                    <li>
                        <button
                            className="button"
                            style={{border: "1px solid " + config.color.principalText, color: config.color.principalText}}
                        >
                            <ImageIcon
                                color={config.color.principalText}
                                width={"25px"}
                                height={"25px"}
                            />
                            <span>{dictionary.gallery}</span>
                        </button>
                        {this.props.isMobile ?
                            <input
                                type="file"
                                name="fileStorage"
                                id="fileStorage"
                                onChange={(e) => {
                                    this.fileChangedHandler(e)
                                }}
                                accept="image/*, video/*"
                            />
                            :
                            <input
                                type="file"
                                name="fileStorage"
                                id="fileStorage"
                                multiple
                                onChange={(e) => {
                                    this.fileChangedHandler(e)
                                }}
                                accept="image/*, video/*"
                            />
                        }
                    </li>
                    <li>
                        <button
                            className="button"
                            style={{border: "1px solid " + config.color.principalText, color: config.color.principalText}}
                            onClick={(e) => this.openMediaBox(e)}
                        >
                            <MediaBoxIcon
                                color={config.color.principalText}
                                width={"20px"}
                                height={"20px"}
                            />
                            <span>MediaBox</span>
                        </button>
                    </li>
                </ul>
                <p>*{dictionary.schedulePost}</p>
                <div
                    className="datepicker-trigger"
                    onClick={() => this.openDatePicker()}
                >
                    <input
                        type="button"
                        name="date"
                        className="btn-dates"
                        value={(scheduleTime) ? moment(scheduleTime).format("MMMM DD, YYYY h:mm a") : dictionary.dateTime}
                        onChange={(e) => this.changeTimeValue(e)}
                        placeholder={dictionary.dateTime}
                        style={(scheduleTime) ? {} : {color:'#232323',opacity:0.5}}
                    />
                    <div
                        className={(this.state.openDatePicker) ? "trigger-btn open" : "trigger-btn"}
                    ></div>
                </div>
                {(this.state.openDatePicker) ?
                    <DatePicker
                        s={12}
                        selected={
                            new Date(startDate)
                        }
                        onChange={(date) =>
                            this.setDate(date)
                        }
                        showTimeSelect
                        minDate={new Date()}
                        timeFormat="h:mm aa"
                        timeIntervals={10}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        inline
                    />
                    : null
                }
                <button
                    disabled={this.state.disabled}
                    className="button"
                    style={{ backgroundColor: config.color.secondary, color: config.color.secondaryText }}
                    onClick={(e) => this.uploadHandler(e)}
                >
                    {dictionary.publicPost}
                </button>
                {this.state.saving ?
                    <Saving
                        config={config}
                        id="savingPost"
                        saving={this.state.saving && !this.state.uploading}
                        loading={this.state.uploading}
                        progress={progress}
                        titleSaving="Procesando Video"
                    />
                    :
                    null
                }

                <ModalConfirm
                    open={!!this.state.fileEncodingMessage}
                    title={"Post updated"}
                    message={this.state.fileEncodingMessage}
                    config={config}
                    actionText={"Ir a posts"}
                    close={this.hideModal}
                    action={()=>{window.location.href='/'}}
                />

            </div>
        )
    }
}
export default Publish;

