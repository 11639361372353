import React, {Component} from 'react';
import cookie from 'js-cookie';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga';
import axios from 'axios';
import {Circle} from 'react-preloaders';
import Ionicon from 'react-ionicons'
import SupportIcon from '../../components/Svg/SupportIcon';
import SupportModal from '../../components/Support/SupportModal';
import ReportContent from '../../components/Support/ReportContent';
import LeftArrowIcon from '../../components/Svg/LeftArrowNotBgIcon';
import '../Login/styleLogin.css';
import './style.css';
import FooterCompliances from '../../components/Footer/FooterCompliances';

class Support extends Component {
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.changeHandlerSelect = this.changeHandlerSelect.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.state = {
            name: cookie.get('username') || '',
            email: cookie.get('email') || '',
            subject: '',
            subjectOther: '',
            message: '',
            pageSupport: false,
            nombreError: false,
            emailError: false,
            subjectError: false,
            messageError: false,
            classMessageSend: '',
            showModal: false,
            showForm: false,
            showReportingForm : false
        };
        this.fireTracking();
    }

    fireTracking = () => {
        ReactGA.pageview(window.location.pathname);
    }

    componentDidUpdate(prevProps) {
        console.log(this.props.update, prevProps.update);
        if (this.props.update !== prevProps.update) {
            this.changeSectionSupport();
        }
    }
    
    componentDidMount() {
        this.changeSectionSupport();
    }

    changeSectionSupport = () => {
        let url = window.location.href;
        let urlSplit = url.split("?");
        if (urlSplit.length > 1) {
            let subject = urlSplit[1].split("=");
            if (subject[0] === "subject" && subject[1] === "report") {
                this.setState({subject: this.props.dictionary.reportContent, showReportingForm: true});
            }
        } else {
            this.setState({showReportingForm: false, subject: ''});
        }
    }
    
    changeHandler(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    changeHandlerSelect(e) {
        if (e.target.selectedIndex === 0) {
            return;
        } else if (e.target.selectedIndex === 8) { // for reporting content
            this.setState({[e.target.name]: e.target.value, showForm: true, showReportingForm: false});
        } else if(e.target.selectedIndex === 7) { // for other subjects
            this.setState({[e.target.name]: e.target.value, showReportingForm: true, showForm: false});
        } else {
            this.setState({
                [e.target.name]: e.target.value,
                showForm: false,
                showReportingForm: false,
                showModal: true,
                modalAction: "support",
                optionSupp: e.target.selectedIndex
            });
        }
    }

    closeModal = async () => {
        this.setState({showModal: false})
    }
    openOther = async () => {
        this.setState({subject: this.props.dictionary.FAQ.q6, showModal: false, showForm: true})
    }
    supportMe = async () => {
        let error = false;
        document.getElementById("input_0").classList.remove("sign-error");
        document.getElementById("input_1").classList.remove("sign-error");
        document.getElementById("input_2").classList.remove("sign-error");
        document.getElementById("input_3").classList.remove("sign-error");

        this.setState({
            nombreError: false,
            emailError: false,
            subjectError: false,
            messageError: false
        });
        if (this.state.name.length < 3) {
            document.getElementById("input_0").classList.add("sign-error");
            document.getElementById("input_0").focus();
            await this.setState({nombreError: this.props.dictionary.nombreError});
            error = true;
        }
        if (this.state.email.length < 3 || !this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            document.getElementById("input_1").classList.add("sign-error");
            document.getElementById("input_1").focus();
            await this.setState({emailError: this.props.dictionary.emailError});
            error = true;
        }
        if (this.state.subjectOther.length < 4) {
            document.getElementById("input_2").classList.add("sign-error");
            document.getElementById("input_2").focus();
            await this.setState({subjectError: this.props.dictionary.obligatory});
            error = true;
        }
        if (this.state.message.length < 20) {
            document.getElementById("input_3").classList.add("sign-error");
            document.getElementById("input_3").focus();
            await this.setState({messageError: this.props.dictionary.messageError});
            error = true;
        }
        if (error) return;
        const support = {
            name: this.state.name,
            email: this.state.email,
            subject: this.state.subjectOther,
            message: this.state.message
        }
        axios.post('/api/v2/support', support)
            .then(res => {
                document.getElementById("btnSendSupport").disabled = true;
                document.getElementById("btnSendSupport").classList.add("disable-button");
                this.setState({classMessageSend: 'hide'});
                setTimeout(() => {
                    window.location.href = '/';
                }, 6000);
            })
    }

    keyPress(event) {
        if (event.key === 'Enter') {
            this.supportMe()
        }
    }

    showSupportPage = () => {
        const flag = this.state.pageSupport;
        this.setState({recoverMode: !flag});
    }

    drawFAQ() {
        let subjects = [
            {'id': "q1", 'title': this.props.dictionary.FAQ.q1},
            {'id': "q8", 'title': this.props.dictionary.FAQ.q8},
            {'id': "q2", 'title': this.props.dictionary.FAQ.q2},
            {'id': "q3", 'title': this.props.dictionary.FAQ.q3},
            {'id': "q4", 'title': this.props.dictionary.FAQ.q4}, 
            {'id': "q5", 'title': this.props.dictionary.FAQ.q5},
            {'id': "q9", 'title': this.props.dictionary.reportContent},
            {'id': "q6", 'title': this.props.dictionary.FAQ.q6}
        ];
        return subjects.map((el, index) => {
            return (
                <option key={index + 1} value={el.title}>{el.title}</option>
            )
        })
    }

    renderObject() {
        if (this.props.dictionary.length === 0 || this.props.config.length === 0)
            return <div className="center-align" style={{paddingTop: 45}}>
                <Circle color="#4285f4" className="center-align"/>
            </div>;
        let logged = cookie.get('token') && this.props.toElevate && this.props.toElevate.authenticated;

        return (
            <div className="redesign-log-in" style={{
                color: this.props.config.color.principalText,
            }}>
                <div className={this.state.classMessageSend + "message-container"}>
                    {this.props.statusModel && this.props.statusModel === 3 ? 
                    <Link to='/' className='back-button-support'>
                        <LeftArrowIcon
                            color={this.props.config.color.secondary}
                            width={"18px"}
                            height={"18px"}
                        />
                    </Link> : null}
                    <h5 className={"center-align"}>
                        <SupportIcon
                            color={this.props.config.color.principalText}
                            width={"2em"}
                            height={"2em"}
                        />
                    </h5>
                    <h5 className="sup-title left-align">
                        {this.props.dictionary.siteProblemTitle}
                    </h5>
                    <div className="input-field-login">
                        <label htmlFor="input_subject">
                            <Ionicon
                                icon='md-create'
                                fontSize="25px"
                                color="rgb(92, 92, 92)"
                            />
                            {this.props.dictionary.subject}
                        </label>
                        <select
                            name='subject'
                            id="input_subject"
                            onChange={this.changeHandlerSelect}
                            value={this.state.subject}
                            className="center-align register-inputs"
                            onKeyPress={this.keyPress}
                            type="text"
                            style={{
                                color: this.props.config.color.principalText
                            }}
                        >
                            <option value={0}>{this.props.dictionary.seloption}</option>
                            {this.drawFAQ()}
                        </select>
                    </div>
                    {this.state.showReportingForm ? 
                        <ReportContent
                            toElevate={this.props.toElevate}
                            config={this.props.config}
                            dictionary={this.props.dictionary}
                            logged={logged ? true : false}
                            name={logged ? this.state.name : null}
                            email={logged ? this.state.email : null}
                        />
                        :
                        null
                    }
                    {this.state.showForm ?
                        <div>
                            <div className="input-field-login">
                                <input
                                    id="input_2"
                                    placeholder={this.props.dictionary.subject}
                                    className="new-subject center-align register-inputs"
                                    onKeyPress={this.keyPress}
                                    type="text"
                                    maxLength="120"
                                    name='subjectOther'
                                    onChange={this.changeHandler}
                                    value={this.state.subjectOther}
                                    _/>
                                {this.state.subjectError ?
                                    <div className="log-error-class">{this.state.subjectError}</div>
                                    : null}
                            </div>
                            <div className="input-field-login">
                                <label htmlFor="input_0">
                                    <Ionicon
                                        icon='ios-person'
                                        fontSize="25px"
                                        color="rgb(92, 92, 92)"
                                    />
                                    {this.props.dictionary.name}
                                </label>
                                <input
                                    id="input_0"
                                    type="text"
                                    name='name'
                                    maxLength="50"
                                    className={(logged ? "disabled-style" : "") + " center-align register-inputs"}
                                    onKeyPress={this.keyPress}
                                    placeholder={this.props.dictionary.name}
                                    onChange={this.changeHandler}
                                    value={logged ? this.state.name : null}
                                    disabled={logged ? true : false}
                                />
                                {this.state.nombreError ?
                                    <div className="log-error-class">{this.state.nombreError}</div>
                                    : null}
                            </div>
                            <div className="input-field-login">
                                <label htmlFor="input_1">
                                    <Ionicon
                                        icon='ios-mail'
                                        fontSize="25px"
                                        color="rgb(92, 92, 92)"
                                    />
                                    {this.props.dictionary.email}
                                </label>
                                <input
                                    type="email"
                                    maxLength="120"
                                    id="input_1"
                                    name='email'
                                    value={logged ? this.state.email : null}
                                    onKeyPress={this.keyPress}
                                    className="center-align register-inputs"
                                    placeholder={this.props.dictionary.email}
                                    onChange={this.changeHandler}
                                />
                                {this.state.emailError ?
                                    <div className="log-error-class">{this.state.emailError}</div>
                                    : null}
                            </div>
                            <div className="input-field-login">
                                <label htmlFor="input_3">
                                    <Ionicon
                                        icon='ios-create'
                                        fontSize="25px"
                                        color="rgb(92, 92, 92)"
                                    />
                                    {this.props.dictionary.message}
                                </label>
                                <textarea
                                    id="input_3"
                                    onKeyPress={this.keyPress}
                                    className="textarea-border textareaFilled"
                                    maxLength="250"
                                    name='message'
                                    onChange={this.changeHandler}
                                    value={this.state.message}
                                    placeholder={this.props.dictionary.message}
                                />
                                {this.state.messageError ?
                                    <div className="log-error-class">{this.state.messageError}</div>
                                    : null}
                            </div>
                            <div className="center-align">
                                <button
                                    onClick={this.supportMe}
                                    style={{
                                        backgroundColor: this.props.config.color.secondary,
                                        color: this.props.config.color.secondaryText
                                    }}
                                    className="log-btn large-send-button"
                                    id="btnSendSupport"
                                >
                                    {this.props.dictionary.send}
                                </button>
                            </div>
                            <div
                                style={{
                                    color: this.props.config.color.secondary
                                }}
                                className={!this.state.classMessageSend ? 'hidemensajeSuccess' : 'center success-support'}
                            >
                                {this.props.dictionary.mensajeSuccess}
                            </div>
                            {this.props.statusModel && this.props.statusModel === 3 ? 
                                null : 
                                    <Link to="/support/faq">
                                    <button
                                        style={{
                                            color: this.props.config.color.principalText,
                                            borderColor: this.props.config.color.principalText
                                        }}
                                        className="faq-btn"
                                    >
                                        {this.props.dictionary.frequentQA}
                                    </button>
                                </Link>
                            }
                        </div>
                        : null}
                </div>

                <h6
                    className="sup-title left-align payment"
                >
                    {this.props.dictionary.paymentProblemTitle}
                    {/*this.props.dictionary.billingProblem*/}
                </h6>
                {this.renderPaymentContact()}
            </div>
        )
    }

    renderPaymentContact() {
        return (
            <div style={{textAlign: "left"}}>

                <div>{this.props.dictionary.chargesMadeBy} <strong>Epoch</strong> {this.props.dictionary.visit}:</div>
                <div>
                    <a
                        className='link-text'
                        target="_blank"
                        rel="noreferrer"
                        href='https://epoch.com/'
                        style={{color: this.props.config.color.secondary}}
                    >
                        https://epoch.com
                    </a>
                </div>
                <br/>
                <div>{this.props.dictionary.chargesMadeBy} <strong>CCBill</strong> {this.props.dictionary.visit}:</div>
                <div>
                    <a
                        className='link-text center-align'
                        target="_blank"
                        rel="noreferrer"
                        href='https://support.ccbill.com'
                        style={{color: this.props.config.color.secondary}}
                    >
                        https://support.ccbill.com
                    </a>
                </div>


                <ul className="list-contact-info">
                    <li><strong>{this.props.dictionary.CCBillContInf}</strong></li>
                    <li>{this.props.dictionary.emailAdress}: <a className='link-text'
                                                                style={{color: this.props.config.color.secondary}}
                                                                href="mailto:support@ccbill.com">support@ccbill.com</a>
                    </li>
                    <li>{this.props.dictionary.phoneNumber}: <a className='link-text'
                                                                style={{color: this.props.config.color.secondary}}
                                                                href="tel:+18885969279">+1 (888) 596-9279</a></li>
                </ul>
                <ul className="list-contact-info">
                    <li><strong>{this.props.dictionary.companyInfo}</strong></li>
                    <li>Team Peach LLC</li>
                    <li>Middletown, Delaware</li>
                    <li>United States</li>
                </ul>

                <div>
                    <Link
                        className='link-text center-align'
                        style={{color: this.props.config.color.secondary}}
                        to='/privacy'
                    >
                        {this.props.dictionary.privacy}
                    </Link>
                </div>
                <div>
                    <Link
                        className='link-text center-align'
                        style={{color: this.props.config.color.secondary}}
                        to='/terms'
                    >
                        {this.props.dictionary.terms}
                    </Link>
                </div>
                <div className='margin-footer-compliances'>
                    <FooterCompliances dictionary={this.props.dictionary} color={this.props.config.color}/>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div
                    id="container-support"
                    className="container main-container center-align"
                    style={{
                        zIndex: 0,
                        backgroundColor: this.props.config.color.principal
                    }}
                >
                    {this.renderObject()}
                </div>
                {this.state.showModal ?
                    <SupportModal
                        dictionary={this.props.dictionary}
                        config={this.props.config}
                        changeHandlerSelect={this.changeHandlerSelect}
                        closeModal={this.closeModal}
                        subject={this.state.subject}
                        optionSupp={this.state.optionSupp}
                        showModal={this.state.showModal}
                        modalAction={this.state.modalAction}
                        openOther={this.openOther}
                        getPiCodes={this.props.getPiCodes}
                    />
                    : null}
            </>
        )
    }
}

export default Support;
