import React from 'react';

const AllIcon = ({ color, width, height, className = null }) => {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            fill="none"
            viewBox="0 0 24 24"
            width={width}
            height={height}
        >
            <path
                fill={color}
                className="st1"
                d="M6.04,21h12V3h-12V21z M0.04,18.6h4.8V5.4h-4.8V18.6z M19.24,5.4v13.2h4.8V5.4H19.24z"
            />
        </svg>
    )
}
export default AllIcon;
