import React, { useState, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import Emojis from '../Streaming/Emojis/Emojis';
import SendIcon from '../Svg/SendIcon';
import userIcon from '../../img/generic-user-icon.jpg';
import CoinIcon from '../Svg/CoinIcon';
import './ChatStreaming.css';
import CoinsIcon from '../Svg/CoinsIcon';
import DropDownIcon from '../Svg/DropDownIcon';
import LogInIcon from '../Svg/LogInIcon';
import moment from 'moment';

const ChatStreaming =  props =>{
    const [showEmojis, setShowEmojis] = useState(!isMobile?true:false);
    const [conversation, setConversation] = useState('');
    const [showMessages, setShowMessages] = useState(false);
    const  [messages] = [props.messages];
    const chatBox = useRef(); 
    let  scroll;
    
    useEffect (()=>{
             scrollUp();
            return ()=>{}
    },[messages]);

    const addEmoji = (emoji) =>{
        setConversation(conversation.concat(emoji))
    }

    function scrollUp() {
        scroll = document.getElementsByClassName('chat__box')[0];
        if(scroll){
            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }
    }

    const messageHandler = (e) =>{
        setConversation(e.target.value);
            if(conversation.length <= 80){
                setConversation(e.target.value);
                if(e.keyCode===13){
                    e.preventDefault();
                    if(e.target.value.trim()!==''){
                        props.getMessageFunc(conversation);
                        setConversation('');
                    };
                };
            }
    }

    const sendMessage = (e) => {
        e.preventDefault();
        const value = document.getElementById("input-streaming-chat").value;
        if(value.trim()!==''){
            props.getMessageFunc(value);
            setConversation('');
        }
    }

    function onScrollListener(){
        let scroll = Math.round(((chatBox.current.scrollHeight - chatBox.current.offsetHeight) - chatBox.current.scrollTop));
        setShowMessages(scroll > 10 ? true : false);
    }

    function renderMessages(){
        if(props.messages){
         return props.messages.map( (message, index) => {
             if(message){
                if(message.username !== 'model_bot'){
                    const now = new moment(message.time);
                    const start = new moment(props.startTime);
                    const time = moment.utc(moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(start,"DD/MM/YYYY HH:mm:ss"))).format("mm:ss");

                    return <div className="stream-message" 
                                    style={{
                                            color:message.permission? props.config.color.secondary:'',
                                            // opacity:!showMessages ? (index < props.messages.length - 4 ? 0.2 : 1) : 1
                                        }} 
                                    key={index}
                            >
                                <div className="message__img">
                                  <img src={message.image ? message.image : userIcon} />
                                </div>
                                <div className={message.permission?"message__msg__model":'message__msg'} style={{color:message.donation?'84ff86':'fff'}}>
                                    <div className="msg__header" >
                                        <span style={{color:message.permission? props.config.color.secondary:''}}>{message.username}</span> &nbsp; 
                                            <span style={{color:'red'}}>&#183;</span> &nbsp;
                                            <span className="msg__time"style={{color:message.permission? props.config.color.secondary:''}}> {time}</span>
                                        </div>
                                        <div className="msg__body">
                                            {message.donation ? <CoinIcon className="coin__icon" color={props.config.color.secondary} height="19"/>:''}
                                            <span style={{color:message.permission?props.config.color.secondary:''}}> {message.message} </span>
                                        </div>
                                </div>
                            </div>
                }
            }
         });
        }
    }

    const renderJoined = () => {
        return <p className="user__joined" style={{color:props.config.color.principalText}}>
                  {props.userJoinedShow && <React.Fragment>
                    <LogInIcon 
                        width={'13px'}
                        fill={props.config.color.principalText}
                    />
                    
                        &nbsp; {props.userJoined} has joined
                    </React.Fragment>
                    }
                </p>           
    }
    
    
    return(
            <div className="chat">
                <div id="chat__box" 
                    className={"chat__box "+(showEmojis?'chat__box__bottom__117':'chat__box__bottom__81')}
                    ref={chatBox}
                    onScroll={()=>onScrollListener()}
                >
                    {renderMessages()}
                    {/* {renderJoined()} */}
                </div>
                <div className="chat__footer">
                    {props.isClient && 
                        <div className="col__streaming-15">
                            <span
                                className="span__donate__icon"
                                onClick={(e) => props.openModalCoin()}
                            >
                            <CoinsIcon
                                color={props.config.color.secondary}
                                colorPrincipal={props.config.color.principal}
                                width={"21px"}
                                height={"21px"}
                                idModal="modalCoinNav"
                                showModal={null}
                                className="donate__icon"
                            />
                            </span>
                        </div>
                    }
                    <div 
                        className={(props.isClient?"col__streaming-70":'col__streaming-90')+' position__relative'}
                    >
                        <input 
                            id="input-streaming-chat"
                            type="text"
                            placeholder={props.dictionary.write}
                            onChange={(e)=>messageHandler(e)}
                            onKeyDown={(e)=>messageHandler(e)}
                            maxLength={120}
                            style={{
                                backgroundColor: conversation.length >= 80 && 'red',
                                color:props.config.color.principalText,
                            }}
                            value={conversation}
                        />
                                <SendIcon
                                    onClick={(e) => sendMessage(e)}
                                    className={props.isClie?'send__icon__cli':'send__icon'}
                                    color={props.config.color.secondary}
                                    width="20px"
                                    height="20px"
                                />
                    </div>
                    {props.isClient &&
                        <div className="col__streaming-15">
                            <DropDownIcon
                                width="20"
                                height="20"
                                primary={props.config.color.secondary}
                                secondary={props.config.color.secondaryText}
                                className="dropdown__icon"
                                onClick={(e)=>{props.hideConversations(e)}}
                            />
                        </div>
                    }
                </div>
                {!isMobile && 
                   <Emojis
                            className="btn__emojis"
                            show={showEmojis}
                            onClick={()=>setShowEmojis(!showEmojis)}
                            config={props.config}
                            addEmoji={addEmoji}
                        />
                }
            </div>
    )

};


export default ChatStreaming;