import React, { Component } from 'react';
import Icon from "react-ionicons";

class CarouselElement extends Component{

    renderMedia(){
        let media = this.props.media;
        if(media.type === "video") {
            return(
                <video
                    onClick={(e) => {this.props.playVideo(e)} }
                    preload="yes"
                    poster={(media.method === "mediabox" || media.method === "saved") ?
                        media.poster
                        :
                        ""
                    }
                    className="responsive-video carouselVideo"
                    controlsList="nodownload"
                >
                    <source
                        src={(media.method === "mediabox" || media.method === "saved") ?
                            media.file
                            :
                            URL.createObjectURL(media.file)
                        }
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
            )
        }
        else{
            return(
                <img
                    alt=""
                    src={(media.method === "mediabox" || media.method === "saved") ?
                        media.file
                        :
                        URL.createObjectURL(media.file)
                    }
                    className="responsive-img"/>
            )
        }
    }

    render(){
        let media = this.props.media;
        let config = this.props.config;
        let index = this.props.index;
        let id = this.props.id;
        return(
            (media.file) ?
                <div>
                    {this.renderMedia()}
                    {media.type === "video" ?
                        <Icon
                            icon="md-arrow-dropright-circle"
                            fontSize="4em"
                            color="rgba(255,255,255,0.5)"
                            className="playvideo-event play-video"
                            onClick={(e) => {this.props.playVideo(e)} }
                        />
                        : null
                    }
                    <span
                        className="close"
                        style={{
                            backgroundColor : config.color.secondary,
                            color: config.color.secondaryText
                        }}
                        onClick={() => (id !== null) ? this.props.remove(index, id) : this.props.remove(index)}
                    >
                        <Icon
                            icon="md-close"
                            fontSize="2em"
                            color={config.color.secondaryText}
                        />
                    </span>
                </div>
                : null
        )
    }
}

export default CarouselElement;
