import React, {Component} from 'react';
import Media from './Media'
import './style.css'
import ShowMore from 'react-show-more';
import LikeIcon from '../../Svg/LikeIcon';
import CoinsIcon from '../../Svg/CoinsIcon';
import Promise from 'bluebird';

class FreePost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: true
        }
    }

    componentDidMount() {
        this.validMedia();
    }

    formatNumber(number) {
        if (number < 1000) {
            return number.toString();
        }
        if (number < 1000000) {
            return Math.floor((number / 1000) * 10) / 10 + "K"
        }
        return Math.floor((number / 1000000) * 10) / 10 + "M"
    }

    validMedia = async () => {
        let media = this.props.post.media;
        let valid = true;
        await new Promise.map(media, (el) => {
            if (!el.post_media_img) {
                valid = false;
            }
        })
        this.setState({valid: valid})
    }

    render() {
        let post = this.props.post;
        let media = this.props.post.media;
        let config = this.props.config;
        let view = this.props.view;
        let valid = this.state.valid;
        let index = this.props.index;
        if (valid) {
            return (
                <div className="post free" style={{color: config.color.principalText}}>
                    <p className="content">
                        <ShowMore
                            lines={3}
                            more={this.props.dictionary.seemore}
                            less={this.props.dictionary.seeless}
                        >
                            {post.post_name}
                        </ShowMore>
                    </p>
                    <Media
                        media={media}
                        config={config}
                        view={(view || post.post_free)}
                        picodes={this.props.picodes}
                        dictionary={this.props.dictionary}
                        isSafari={this.props.isSafari}
                        isIOS={this.props.isIOS}
                        indexPost={index}
                        openModalConfirm={this.props.openModalConfirm}
                        confirmed={this.props.confirmed}
                        statusModel={this.props.statusModel}
                        name={post.post_name}
                        toElevate={this.props.toElevate}
                    />
                    <span
                        className="like-post"
                        style={{
                            color: config.color.principalText
                        }}
                    >
                        <LikeIcon
                            color={config.color.secondary}
                            width={"17px"}
                            height={"17px"}
                        />
                        {this.formatNumber((post.likes) ? post.likes : 0)}
                    </span>
                    <span
                        className="donate-post"
                        style={{
                            color: config.color.principalText
                        }}
                    >
                        <CoinsIcon
                            width={"21px"}
                            height={"21px"}
                        />
                        {this.formatNumber((post.donations) ? post.donations : 0)}
                    </span>
                </div>
            )
        }
        return null
    }
}

export default FreePost;
