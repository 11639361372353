import React, { Component } from 'react';
import Subscribe from '../../../../Buttons/Subscribe'
import VideoIcon from '../../../../Svg/VideoIcon';
import LockIcon from '../../../../Svg/LockIcon';
import PlusIcon from '../../../../Svg/PlusIcon';
import FullscreenIcon from '../../../../Svg/FullscreenIcon';

import MediaViewer from "../../../../MediaViewer";

class MediaElement extends Component{
    constructor(props) {
        super(props);
        this.state = {
            openViewer : false,
            defaultOpen : 0,
            mediaPosition : {
                1 : { 1 : {top: 0, left : 0, height : "auto", width : "100%" }},
                2 : {
                    1 : { top : 0, left : 0, height : "100%", width : "50%"},
                    2 : { top : 0, left : "50%", height : "100%", width : "50%" }
                },
                3 : {
                    1 : { top : 0, left : 0, height : "100%", width : "50%"},
                    2 : { top : 0, left : "50%", height : "50%", width : "50%" },
                    3 : { top : "50%", left : "50%", height : "50%", width : "50%" }
                },
                4 : {
                    1 : { top : 0, left : 0, height : "100%", width : "66.67%"},
                    2 : { top : 0, left : "66.67%", height : "33.33%", width : "33.33%" },
                    3 : { top : "33.33%", left : "66.67%", height : "33.33%", width : "33.33%" },
                    4 : { top : "66.66%", left : "66.67%", height : "33.33%", width : "33.33%" }
                },
                5 : {
                    1 : { top : 0, left : 0, height : "66.67%", width : "66.67%"},
                    2 : { top : "66.67%", left : 0, height : "33.33%", width : "66.67%"},
                    3 : { top : 0, left : "66.67%", height : "33.33%", width : "33.33%" },
                    4 : { top : "33.33%", left : "66.67%", height : "33.33%", width : "33.33%" },
                    5 : { top : "66.66%", left : "66.67%", height : "33.33%", width : "33.33%" }
                },
                6: {
                    1 : { top : 0, left : 0, height : "66.67%", width : "66.67%"},
                    2 : { top : 0, left : "66.67%", height : "33.33%", width : "33.33%" },
                    3 : { top : "33.33%", left : "66.67%", height : "33.33%", width : "33.33%" },
                    4 : { top : "66.67%", left : 0, height : "33.33%", width : "33.33%" },
                    5 : { top : "66.67%", left : "33.33%", height : "33.33%", width : "33.33%" },
                    6 : { top : "66.67%", left : "66.67%", height : "33.33%", width : "33.33%" }
                }
            },
            bigMediaPosition : {
                1 : { top : 0, left : 0, height : "66.67%", width : "66.67%"},
                2 : { top : 0, left : "66.67%", height : "33.33%", width : "33.33%" },
                3 : { top : "33.33%", left : "66.67%", height : "33.33%", width : "33.33%" },
                4 : { top : "66.67%", left : 0, height : "33.33%", width : "33.33%" },
                5 : { top : "66.67%", left : "33.33%", height : "33.33%", width : "33.33%" },
                6 : { top : "66.67%", left : "66.67%", height : "33.33%", width : "33.33%" }
            },
        }
    }

    componentDidMount() {

    }
    openViewer = async(e, media, index) => {
        let sidenav= document.getElementsByClassName("styles_content__1Phom"); //put sidenav and notifymodal behind
        if(sidenav!== null && sidenav !== undefined){
            sidenav[0].style.zIndex = 0;
        }
        await this.setState({openViewer: true, mediaOpened : [media], defaultOpen : index});
    }
    closeViewer = async () => {
        let sidenav= document.getElementsByClassName("styles_content__1Phom");
        if(sidenav!== null && sidenav !== undefined){
            sidenav[0].style.zIndex = 1;
        }
        this.setState({openViewer: false, mediaOpened : []});
    }

    renderMedia(){
        let media = this.props.media;
        let config = this.props.config;
        let view = this.props.view;
        let indexPost = this.props.indexPost;
        let img = (indexPost === 0 ) ?
            (media.blurMedia ? media.blurMedia : media.post_media_img)
        :
            (view) ?
                (media.mediaBox) ?
                    config.storageURL + '/' + config.storage + "/message/model/" + media.post_media_img
                :
                    config.storageURL + '/' + config.storage + "/posts/img/" + media.post_media_img
            : media.post_media_img;
        let video = (media.mediaBox) ?
            config.storageURL + '/' + config.storage + "/message/model/" + media.post_media_video
            :
            config.storageURL + '/' + config.storage + "/posts/video/" + media.post_media_video;
        if(media.post_media_video !== null && view) {
            return (
                <video
                    onClick={(this.props.length >= 7 && this.props.count === 6) ? '' : (e) => {this.props.playVideo(e)} }
                    preload="metadata"
                    poster={ img }
                    className=" responsive-video freePostVideo"
                    controlsList="nodownload"
                    playsInline
                >
                    <source
                        src={video}
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
            )
        }
        else {
            return (
                <img
                    alt={this.props.name}
                    src={ img }
                    className="responsive-img"
                    onClick={
                        (e) => this.openViewer(e, media, indexPost)
                    }
                />
            )
        }
    }

    render(){
        let media = this.props.media;
        let style = (this.state.mediaPosition[this.props.length] !== undefined && this.state.mediaPosition[this.props.length] !== null) ? this.state.mediaPosition[this.props.length][this.props.count] : this.state.bigMediaPosition[this.props.count];
        if(!this.state.mediaPosition || !this.props.length)
            return null;
        return(
            <div style={style}>
                {this.renderMedia()}
                {media.post_media_video !== null && !(this.props.length >= 7 && this.props.count === 6) ?
                    <VideoIcon
                        color={"rgba(255,255,255,0.5)"}
                        width={"50px"}
                        height={"50px"}
                        className="play-video playvideo-freeposts"
                        id="video-icon"
                    />
                : null }
                {(media.post_media_video !== null && this.props.view) && !(this.props.length >= 7 && this.props.count === 6) ?
                    <FullscreenIcon
                        color="#fff"
                        width="20px"
                        height="20px"
                        id="fullscreenBtn"
                        className="fullscreen-btn"
                        clickFunction={(e) => {this.props.makeFullscreen(e)} }
                    />
                : null }
                {!this.props.view ?
                    <LockIcon
                        color={"#fff"}
                        width={"20px"}
                        height={"20px"}
                        className="lock-media"
                    />
                :
                    null
                }
                {this.props.picodes ?
                    <Subscribe
                        picodes={this.props.picodes}
                        dictionary={this.props.dictionary}
                        config={this.props.config}
                        openModalConfirm={this.props.openModalConfirm}
                        confirmed={this.props.confirmed}
                        statusActiveModel={this.state.statusActiveModel}
                        statusModel={this.props.statusModel}
                    />
                    : null
                }
                {this.props.length >= 7 && this.props.count === 6 ?
                    <PlusIcon
                        color={"rgba(255,255,255,0.5)"}
                        width={"60px"}
                        height={"60px"}
                        className="more-content"
                    />
                : null }
                {this.state.openViewer && media.blurMedia && media.post_media_img !== null && media.post_media_video === null ?
                    <MediaViewer
                        open={this.state.openViewer}
                        media={this.state.mediaOpened}
                        closeAction={this.closeViewer}
                        config={this.props.config}
                        defaultOpen={this.state.defaultOpen}
                        name={this.props.name}
                        toElevate={this.props.toElevate}
                    />
                : null }
            </div>
        )
    }
}

export default MediaElement;
