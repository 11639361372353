import React, { Component } from 'react';
import Modal from "../../../../Modal";

class ModalConfirmBroadcast extends Component{
    constructor(props) {
        super(props);
        this.state = {
            currentCoins : 0
        }
    }

    componentDidMount() {
        this.getCoins();
    }

    getBroadcast = async () => {
        this.props.getBroadcast(this.props.messageBroadcast.id)
        this.props.close()
    }

    componentDidUpdate(prevProps, prevState) {
        const { totalCoins } = this.props;
        if (totalCoins && totalCoins !== prevState.currentCoins) {
            this.setState({ currentCoins: totalCoins });
        }
    }

    getCoins = async () => {
        const coins = await this.props.getCoins();
        this.setState({ currentCoins: coins });
    };

    gotoBuy = async () => {
        this.props.close();
        this.props.openBuyCoins();
    }

    render(){
        let dictionary = this.props.dictionary;
        let config = this.props.config;
        let totalCoins = this.state.currentCoins;
        let price = this.props.messageBroadcast ? this.props.messageBroadcast.price : 0;
        return(
            <div>
                <Modal
                    id="ModalConfirmBroadcast"
                    head={null}
                    config={this.props.config}
                    footer={
                        <div className="actions">
                            {totalCoins >= price ?
                                <button
                                    style={{
                                        backgroundColor: config.color.secondary,
                                        color: config.color.secondaryText
                                    }}
                                    onClick={() => this.getBroadcast()}
                                >
                                    {dictionary.getIt}
                                </button>
                                :
                                <button
                                    style={{
                                        backgroundColor: config.color.secondary,
                                        color: config.color.secondaryText
                                    }}
                                    onClick={() => this.gotoBuy()}
                                >
                                    {dictionary.buy} {dictionary.coins}
                                </button>
                            }
                        </div>

                    }
                    open={this.props.open}
                    closeAction={this.props.close}
                    height={this.props.height}
                    top={this.props.top}
                    middle={this.props.middle}
                    levelModal={this.props.levelModal}
                    levelContainer={this.props.levelContainer}
                >
                    <div>
                        {totalCoins >= price ?
                            <p>
                                {dictionary.messageBroadcastAdvice.replace('--coins--', price)}
                            </p>
                            :
                            <p>
                                {dictionary.notEnoughCoins}
                            </p>
                        }
                    </div>

                </Modal>
            </div>
        )
    }
}

export default ModalConfirmBroadcast;
