import React, {Component} from 'react';
import ReactGA from 'react-ga';
import {Circle} from 'react-preloaders';

class TermsCreator extends Component {
    fireTracking = () => {
        ReactGA.pageview(window.location.pathname);
    }

    render() {
        if (this.props.config.length === 0) return <div className="center-align" style={{paddingTop: 45}}><Circle
            background="transparent !important" color="#4285f4" className="center-align"/></div>;
        return(
            <div
                className="container main-container text-justify"
                style={{
                    zIndex: 0,
                    backgroundColor: this.props.config.color.principal,
                    color: this.props.config.color.principalText
                }}
            >
                <h4 className='center'>Creator Terms of Use</h4>
                <p>
                    This Terms of Use Agreement (the “Agreement” or “Terms”) is entered into and legally binding on the date electronically accepted by you (“Effective Date”).  This Agreement is by and between Team Peach, LLC (“Company” or “we”), and you (“Talent,” “you” or “your”) (each a “party” and collectively the “parties”). Company offers an individualized membership based web application experience (the “Web-App”) in which you can (i) offer membership to your Web-App users (“Subscribers”) and (ii) earn income providing content, rewards, and perks to your users. You agree to abide by the following terms in order to use the Web-App services.
                </p>
                <p>
                    BY SETTING UP AN ACCOUNT, OR BY USING THE WEB-APP, YOU SIGNIFY THAT YOU AGREE TO THE FOLLOWING TERMS, WHICH MAY BE AMENDED AT ANY TIME BY THE COMPANY.  ANY AMENDED TERMS WILL BE AUTOMATICALLY EFFECTIVE IMMEDIATELY AFTER THEY ARE SENT TO YOU. The Company reserves the right to terminate or suspend your use of the Web-App if you do not comply with this Agreement or any other Web-App policy or procedure, or for any other reason the Company determines, in its sole discretion. 
                </p>
                <p>
                    <strong>1. Term and Use of Web-App</strong>
                </p>
                <p>
                    Company has provided the Web-App for your individual, commercial use and solely for the purpose of releasing various media and content (“Content”) to paid Subscribers on your Web-App. The term of use shall be thirty (30) days from the Effective Date of these Terms (the “Term”). The Term shall automatically extend for thirty (30) day intervals unless notice of termination is provided to the Company no less than thirty (30) days in advance (“Notice of Termination”), sent to the following addresses: Legal@teampeach.app. The Notice of Termination shall only be effective if delivered in writing, thirty (30) days prior to termination (to avoid the incurrence of Company damages as it relates to Subscriber Web-App commitments), and so long as your Web-App has generated no less than five hundred US Dollars ($500.00) (“Minimum Threshold”). If your Web-App has not met the Minimum Threshold, you grant us the right to continue use of the Web-App. You shall continue to receive payments every two (2) weeks until the Minimum Threshold is reached, at which point, we will permanently delete any Content from the Web-App. Notwithstanding the foregoing, Company reserves the right to terminate your Web-App at any time, for any reason.   
                </p>
                <p>
                    Any use of the Web-App or the Content not specifically described in these Terms is strictly prohibited. As it pertains to your use of the Web-App, you may not, either directly or indirectly:
                </p>
                <p>
                    a. Modify, republish, redistribute, delete, resell, sublicense, publicly perform, cache by proxy the Web-App without the express written permission of the Company or the applicable rights holder;
                </p>
                <p>
                    b. Reverse engineer, decompile, disassemble, merge, copy, use, disclose, rent, lease, loan, sell, sublicense or transfer the underlying source code or structure or sequence of the Web-App Technology (“Web-App Technology”) or delete or alter author attributes or copyright notices provided by the Company;
                </p>
                <p>
                    Use any network monitoring or discovery software to determine the site architecture, or extract information about usage or users;
                </p>
                <p>
                    d. Reformat or frame any portion of the Web-App;
                </p>
                <p>
                    e. Use any device, software or routine that interferes with the proper working of the Web-App, or otherwise attempt to interfere with the proper working of the Web-App;
                </p>
                <p>
                    f. Violate these Terms, applicable law or the rights of others; or disrupt or interfere with the security of, or otherwise cause harm to, the Web-App or Company.
                </p>
                <p>
                    Any permitted use of the Web-App does not extend to using the Web-App or Content for any illegal purpose, or to transmit to or through the Web-App or to or through any service any illegal, harmful, threatening, defamatory, obscene, hateful, or other objectionable material of any kind, or to interfere with, abuse or otherwise violate the legal rights of any third party using the Web-App or Content, and does not violate any card association standards.
                </p>
                <p>
                    Without limiting other remedies, the Company may limit, suspend, or terminate our service and user accounts, prohibit access to our Web-App, remove hosted content, and take technical and legal steps to keep users off the Web-App, if we think that they are creating problems, possible legal liabilities, or acting inconsistently with the letter or spirit of our policies. We also reserve the right to cancel unconfirmed accounts.
                </p>
                <p>
                    <strong>2. Content License & Co-Authored Content</strong>
                </p>
                <p>
                    By using the Web-App, uploading Content to or submitting any materials for use on the Web-App, you grant and warrant that the owner of such rights has expressly granted Web-App a perpetual, royalty-free, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from and distribute such materials and/or information generated through use of the Web-App or incorporate such materials and/or information generated through use of the Web-App into any form, medium, or technology now known or later developed throughout the universe. These rights shall be irrevocable during the Term of this Agreement. 
                </p>
                <p>
                    Additionally, and as it relates to any Content which shows any other person or people:
                </p>
                <p>
                    a. If you upload or deliver Content which shows anyone else other than or in addition to you (even if that person cannot be identified from the Content) ("Co-Authored Content"), you warrant (which means you make a legally enforceable promise) that each individual shown in any Co-Authored Content uploaded to your account:
                </p>
                <p>
                    i. has given his or her express, prior and fully informed, written consent to his or her appearance in the Co-Authored Content; and
                </p>
                <p>
                    ii. has consented to the Co-Authored Content in which he or she appears, being posted and distributed on the Web-App; and 
                </p>
                <p>
                    iii. has consented to the potential downloading by other users of the Co-Authored Content in which he or she appears. 
                </p>
                <p>
                    b. In addition to the confirmations in section 2(a) above, you agree that if you upload Co-Authored Content where the other person or people appearing in the Content can be identified from the Content, you will have verified (and will be able to provide supporting documents upon request) the identity and age of all people appearing in the Co-Authored Content, to ensure that all persons depicted are adults. If you post Co-Authored Content on your Web-App, we may require you to provide valid and complete legal information for all individuals which appear in the Co-Authored Content. If you fail to provide any information or documentation requested by us upon our request, we may delete the Co-Authored Content, restrict your rights and permissions to post on the Web-App, terminate your account, and/or withhold all or any portion of earnings earned but not yet paid out to you.
                </p>
                <p>
                    c. You must not upload any Content containing any image, photo, video or audio of anyone else other than or addition to you unless such person has given their consent, as defined in section 2(a) above.
                </p>
                <p>
                    d. If any Co-Authored Content is a work of joint authorship, you are solely responsible for obtaining any required licenses or consents from any other joint authors of the Content which are sufficient to permit such Content to be uploaded to and made available on Web-App.
                </p>
                <p>
                    e. You agree that we will only pay you related to your Web-App earnings. You are solely responsible for dividing and distributing any revenue generated from the Co-Authored Content between the creators shown in such Co-Authored Content, if applicable. Any such revenue-sharing agreement shall be an independent, private agreement between you and your creators, and we are not responsible for providing or enforcing any such agreements.
                </p>
                <p>
                    f. You agree to release us from and not to make any claims against us arising from Co-Authored Content. You agree that all claims arising from Co-Authored Content shall be handled solely by you and those who appeared in the Co-Authored Content (as applicable).
                </p>
                <p>
                    <strong>3. Compensation</strong>
                </p>
                <p>
                    You will be paid <b>your assigned percentage</b> of all Net Receipts received directly from the Web-App. “Net Receipts” shall be defined as the sum of all earnings, after deductions and reasonable expense reimbursement to the Company, including but not limited to payment processing fees, chargebacks, refunds, and adjustments made by the Company in its sole discretion. All payments are made after a thirty (30) day cycle in order to have a money reserve for any refunds, chargebacks or any accounting inaccuracy within the Web-App. Once the Web-App is launched, you will receive the initial payment the following month. The payment to you will correspond to the revenue that was generated during the first two (2) weeks following launch of the Web-App. You shall receive payment every two (2) weeks thereafter, via wire transfer. If the wire transfer is made in U.S. Dollars, and you desire to receive payment in a foreign currency, payment shall be made without any additional fee. If payment is received in a foreign currency, and you desire to receive payment in U.S. Dollars, a fee shall be deducted by the Company. You may be required to submit additional legal information to the Company, such as a W-9 if you are resident in the United States of America, or a W-8BEN if you are not a U.S. citizen or other U.S. person. The exact information required will depend on your country of residence. You are agreeing to these Terms as, and shall continue to be, an independent contractor. All Services shall be performed only by you. You shall be responsible for providing, at your sole expense, and in your name, unemployment, disability, worker's compensation and other insurance, as needed or necessary.
                </p>
                <p>
                    <strong>4. Warranty and Liability</strong>
                </p>
                <p>
                    The Company makes no representations regarding the availability and performance of the Web-App. You hereby acknowledge that any use of the Web-App and reliance upon Company shall be at your sole risk.
                </p>
                <p>
                    THE WEB-APP AND CONTENT ARE PROVIDED BY COMPANY ON AN "AS IS" BASIS AND AS AVAILABLE, WITHOUT ANY WARRANTY OR REPRESENTATION OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, COMPANY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, ACCURACY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND THE IMPLIED CONDITIONS OF SATISFACTORY QUALITY AND ACCEPTANCE AS WELL AS ANY LOCAL JURISDICTIONAL ANALOGUES TO THE ABOVE AND OTHER IMPLIED OR STATUTORY WARRANTIES. WE MAKE NO REPRESENTATIONS THAT THE COMPANY OR WEB-APP WILL MEET YOUR REQUIREMENTS AND WE DO NOT GUARANTEE THAT ANY CONTENT, INFORMATION, SOFTWARE OR OTHER MATERIAL ACCESSIBLE THROUGH THE WEB-APP WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                </p>
                <p>
                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL COMPANY OR ITS DIRECTORS, OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, DISTRIBUTORS OR CONTRACTORS (COLLECTIVELY, THE "COMPANY PARTIES") BE LIABLE FOR ANY COMPENSATORY, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR SPECIAL DAMAGES, LOSS OF DATA, INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY, OR ANY CLAIMS OF YOU OR OTHER THIRD PARTIES WHATSOEVER WITH RESPECT TO THE WEB-APP REGARDLESS OF THE LEGAL THEORY ON WHICH THE CLAIM IS BASED, WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, WARRANTY, OR OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, COMPANY AND THE COMPANY PARTIES SHALL NOT BE LIABLE FOR ANY LOSSES OR DAMAGES WHATSOEVER RESULTING FROM ANY EVENTS BEYOND OUR REASONABLE CONTROL, INCLUDING BUT NOT LIMITED TO, FAILURE OF THE INTERNET OR ANY VIRUS, DELAY OR INTERRUPTION IN OPERATION OR SERVICE OR FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM AN ACT OF GOD, COMMUNICATIONS FAILURE, THEFT OR OTHERWISE. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF COMPANY AND THE COMPANY PARTIES (JOINTLY) ARISING OUT OF OR RELATING TO THESE SITE TERMS OR YOUR USE OF THE WEB-APP EXCEED THE GREATER OF (I) THE AMOUNT PAID, IF ANY, BY YOU TO US OR (II) $100. THE LIMITATIONS SET FORTH IN THESE SITE TERMS WILL NOT LIMIT OR EXCLUDE LIABILITY FOR OUR GROSS NEGLIGENCE, FRAUD, OR INTENTIONAL, MALICIOUS OR RECKLESS MISCONDUCT, OR FOR PERSONAL INJURY OR PROPERTY DAMAGE CAUSED BY YOUR USE OF THE WEB-APP.
                </p>
                <p>
                    <strong>5. Indemnity.</strong>
                </p>
                <p>
                    You agree to indemnify, defend, and hold harmless Company and the Company Parties from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys’ fees) that such parties may incur as a result of or arising from your violation or breach of any terms under this Agreement. We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you and, in such case; you agree to cooperate with our defense of such claim.
                </p>
                <p>
                    <strong>6. Modification.</strong>
                </p>
                <p>
                    We reserve the right to modify these Terms at any time. If you do not agree to the changes, you may discontinue using the Web-App. Your continued use of any Web-App after any such changes take effect constitutes your acceptance of such changes. Each time you submit Content to your Web-App, you reaffirm your acceptance of any new Terms imposed by the Company related to your Web-App.
                </p>
                <p>
                    <strong>7. Applicable Law and Venue.</strong>
                </p>
                <p>
                    THIS AGREEMENT AND THE TERMS OF SALE AND TRANSFER OF TITLE OF YOUR PRODUCT ARE GOVERNED BY AND CONSTRUED IN ACCORDANCE WITH ARIZONA LAW AND TO THE EXTENT APPLICABLE, THE LAWS OF THE UNITED STATES. NO CONFLICT OF LAWS OR PROVISIONS OF ANY JURISDICTION WILL APPLY TO THESE TERMS AND CONDITIONS. BY SIGNING BELOW, YOU AGREE THAT ANY ACTION AT LAW OR INEQUITY ARISING OUT OF OR RELATING TO THESE TERMS WILL BE FILED ONLY IN STATE OR FEDERAL COURT LOCATED IN THE COUNTY OF MARICOPA, ARIZONA, AND YOU HEREBY IRREVOCABLY AND UNCONDITIONALLY CONSENT AND SUBMIT TO THE EXCLUSIVE JURISDICTION OF SUCH COURT OVER ANY SUIT, ACTION, OR PROCEEDING ARISING OUT OF THESE TERMS. ALL DISPUTES MUST BE ADJUDICATED IN THE ENGLISH LANGUAGE. In any action arising hereunder or any separate action pertaining to the validity of these Terms, the prevailing party shall be awarded reasonable attorney's fees and costs, both in the trial court and on appeal. 
                </p>
                <p>
                    <strong>8. Disclaimer</strong>
                </p>
                <p>
                    Company agrees to make every effort to ensure the information presented in, on or through the Web-App is accurate, and that the Web-App is in normal operation at all times, however, because Company outsources servers through third-party companies, should an issue arise with the Web-App as a result of a third-party company, Company shall not be liable for any arising damages incurred to Talent as a consequence. Company has no control over said third party companies, and makes no guarantee to the consistency, quality, or legality of the Web-App, nor protection against any loss or damage to you.  
                </p>
                <p>
                    <strong>9. Default</strong>
                </p>
                <p>
                    The occurrence of any of the following shall constitute a material default under this Agreement: (a.) The failure to make a required payment when due; (b.) The insolvency or bankruptcy of either party; (c.) The subjection of any of either party's property to any levy, seizure, general assignment for the benefit of creditors, application or sale for or by any creditor or government agency. (d.) The failure to make available or deliver the Content in the time and manner provided for in these Terms; (e.) The failure to comply with the SCHEDULE A attached to this Agreement; (f.) you generate 0.90% of chargeback on the website; and (g.) you fail to substantially perform any provision, term, or condition of these Terms. 
                </p>
                <p>
                    A party shall only be considered in default following written notice from the non-defaulting party. The notice shall describe with sufficient detail the nature of the default. The party receiving such notice shall have five (5) calendar days from the effective date of such notice to cure the default(s). Unless waived in writing by a party providing notice, the failure to cure the default(s) within such time period shall result in the automatic termination of these Terms, and permit the non-defaulting party to seek redress in a court of competent jurisdiction.
                </p>
                <p>
                    <strong>10. Force Majeure</strong>
                </p>
                <p>
                    Neither party is liable for any breach of these Terms that are caused by a matter beyond reasonable control, including acts of God, fire, lightning, explosion, war, flood, industrial disputes, sabotage, extremely severe weather, or acts of local or central Government or other competent authorities.
                </p>
                <p>
                    <strong>11. Notices</strong>
                </p>
                <p>
                    All applicable notices pursuant to these Terms shall be given to the other party as follows: 
                </p>
                <p>
                    <strong>If to Team Peach, LLC:</strong>
                </p>
                <p>
                    Krystle M. Delgado, Esq . <br/>
                    DELGADO ENTERTAINMENT LAW, PLLC <br/>
                    3295 North Drinkwater Blvd., Suite 9 <br/>
                    Scottsdale, Arizona 85251 <br/>
                    <a href="krystle@delgadoentertainmentlaw.com">krystle@delgadoentertainmentlaw.com</a>
                </p>
                <p>
                    If to Talent:
                </p>
                <p>
                    <i>
                        Talent’s contact information (i.e., email and physical address) shall be provided to Company electronically on the Effective Date. Talent shall reasonably inform Company of any change to Talent’s current physical address and/or email address within five (5) calendar days of the applicable change.  
                    </i>
                </p>
                <p>
                    <strong>12. Severability</strong>
                </p>
                <p>
                    If any provision of these Terms is held to be invalid or unenforceable for any reason, the remaining provisions will continue to be valid and enforceable. 
                </p>
                <p>
                    <strong>13. Amendment</strong>
                </p>
                <p>
                    These Terms may be modified or amended in writing by mutual agreement between the parties, if the writing is signed by the party obligated under the amendment.
                </p>
                <p>
                    <strong>14. Assignment</strong>
                </p>
                <p>
                    Neither party may assign or transfer these Terms without the prior written consent of the non-assigning party, which approval shall not be unreasonably withheld.
                </p>
                <p>
                    <strong>SIGNATURES</strong>
                </p>
                <p>
                    <strong>TALENT</strong> <br/>
                    <i>Signed electronically by Talent</i>
                </p>
                <p>
                    <strong>COMPANY</strong> <br/>
                    <i>Team Peach LLC</i>
                </p>
                <p>
                    YOU AGREE YOU HAVE READ THESE TERMS CAREFULLY, UNDERSTAND WHAT YOU ARE AGREEING TO, AND HAVE BEEN PROVIDED AN OPPORTUNITY TO CONSULT WITH INDEPENDENT COUNSEL.
                </p>
                <p>
                    <strong>SCHEDULE A: <br/> Talent Guidelines</strong>
                </p>
                <p>
                    You agree to following guidelines in order to specify the minimum requirements to achieve the intended purchase of the above-noted Terms. 
                </p>
                <p>
                    You understand that although the Web-App focuses on exclusive content that attracts the attention of its public, it must also seek value added content for the audience, which may include but is not limited to: exercise routines, dietary guidelines, vlogs (short videos showing daily activities), trips, advice, etc. You will decide the price of the Web-App’s access membership, taking into consideration our recommendation and information that will be suggested, based on previous success cases and with the best intentions for your Web-App. You will approve the design of the Web-App, as well as any modification that is done within the site, taking into consideration the changes that we may recommend.
                </p>
                <p>
                    You agrees to upload five (5) exclusive photographs and/or videos in order to launch the Web-App, referring as “exclusive” to all material that is nor will be published, under any circumstance, on any social network.
                </p>
                <p>
                    Likewise, you agree to send us an additional fifteen (15) exclusive photographs and/or videos to be used as needed by us, under our sole discretion. 
                </p>
                <p>
                    We will undertake to:
                </p>
                <ul className="undertake-agree">
                    <li>Explain the functioning of the Web-App</li>
                    <li>Explain the method, form and days on which payments will be made.</li>
                    <li>Notify of any inconvenience with the Web-App.</li>
                    <li>Notify any changes and updates of the Web-App.</li>
                    <li>Resolve questions and concerns, and offer customer support for the Web-App.</li>
                    <li>Make all the corresponding payments in favor of you.</li>
                    <li>Send proof of payment.</li>
                </ul>
            </div>
        )
    }
}

export default TermsCreator;