import React from 'react';

const ASMRIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            viewBox="0 0 24 24" 
        >
            <g>
                <path 
                    fill={color} 
                    d="M22.39,6c-1.6-2.78-4.19-4.76-7.28-5.59c-2.94-0.79-6-0.44-8.68,0.97l0-0.01L6,1.61c0,0-0.01,0-0.01,0
                    L5.59,1.85l0,0.01C3.03,3.47,1.2,5.96,0.41,8.9c-0.83,3.09-0.4,6.33,1.2,9.1s4.19,4.76,7.28,5.59C9.92,23.86,10.97,24,12.02,24
                    c2.08,0,4.15-0.56,5.98-1.61c2.78-1.6,4.76-4.19,5.59-7.28C24.41,12.01,23.99,8.78,22.39,6z M21.5,14.55
                    c-0.68,2.54-2.31,4.66-4.58,5.97c-2.28,1.31-4.93,1.66-7.46,0.98c-2.54-0.68-4.66-2.31-5.97-4.58c-1.31-2.27-1.66-4.93-0.98-7.46
                    C3.18,6.92,4.81,4.8,7.08,3.48c1.5-0.86,3.19-1.32,4.9-1.32c0.86,0,1.72,0.11,2.56,0.34c2.54,0.68,4.66,2.31,5.97,4.58
                    C21.83,9.36,22.18,12.01,21.5,14.55z"
                />
                <path 
                    fill={color} 
                    d="M17.76,10.5l-7.4-4.27c-0.54-0.32-1.19-0.32-1.73,0C8.08,6.54,7.76,7.1,7.76,7.73v8.55
                    c0,0.63,0.32,1.19,0.87,1.5c0.27,0.15,0.57,0.24,0.87,0.24c0.3,0,0.6-0.08,0.87-0.24l7.4-4.27c0.54-0.31,0.87-0.88,0.87-1.5
                    C18.63,11.37,18.31,10.81,17.76,10.5z M16.05,12l-6.13,3.54V8.46L16.05,12z"
                />
                <path 
                    fill={color} 
                    d="M12.78,3.14c-0.29-0.04-0.57,0.04-0.8,0.21c-0.23,0.17-0.38,0.43-0.42,0.71c-0.08,0.59,0.33,1.13,0.92,1.22
                    c1.88,0.26,3.62,1.26,4.78,2.76c0.21,0.27,0.52,0.42,0.85,0.42c0.24,0,0.47-0.08,0.66-0.23c0.23-0.18,0.37-0.43,0.41-0.72
                    c0.04-0.29-0.04-0.57-0.22-0.8C17.47,4.78,15.21,3.48,12.78,3.14z"
                />
            </g>
        </svg>
    )
}
export default ASMRIcon;
