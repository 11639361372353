import React from 'react';

const VideocamIcon= ({color, width, height, className = null, title, description})=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 512 512"
            width={width}
            height={height}
            fill={color}
            className = {className}
        >
            <title>{title || ""}</title>
            <desc>{description || ""}</desc>
            <path d="M464 384.39a32 32 0 01-13-2.77 15.77 15.77 0 01-2.71-1.54l-82.71-58.22A32 32 0 01352 295.7v-79.4a32 32 0 0113.58-26.16l82.71-58.22a15.77 15.77 0 012.71-1.54 32 32 0 0145 29.24v192.76a32 32 0 01-32 32zM268 400H84a68.07 68.07 0 01-68-68V180a68.07 68.07 0 0168-68h184.48A67.6 67.6 0 01336 179.52V332a68.07 68.07 0 01-68 68z"/>
        </svg>


    )
}
export default VideocamIcon;
