import React, {Component} from 'react';
import axios from 'axios';
import {Circle} from 'react-preloaders';
import {Link} from 'react-router-dom';
import VideoIcon from '../../Svg/VideoIcon';
import Carousel from "react-multi-carousel";
import userIcon from '../../../img/generic-user-icon.jpg';
import "react-multi-carousel/lib/styles.css";
import './styleFour.css';

class HomeFour extends Component {
    constructor(props) {
        super(props);

        this.state = {
            benefits: true,
            loading: true,
            posts: [],
            songs: [],
            modelImage: ''
        };
    }

    componentDidMount() {
        let imgPrincipal = document.getElementById("imgPrincipalBackground");
        if (imgPrincipal)
            imgPrincipal.style.height = '480px';

        this.getHistories()
            .then(history => {
                this.setState({
                    posts: history
                });
            })
            .catch(err => console.log(err));

        this.getSongs()
            .then(song => {
                this.setState({
                    songs: song
                });
            })
            .catch(err => console.log(err));

        this.modelDetails()
            .then(modelImage => {
                const srcImage = this.props.config.storageURL
                    + '/' + this.props.config.storage
                    + '/profiles/'
                    + modelImage;
                this.setState({
                    modelImage: srcImage
                });
            });
    };

    getHistories() {
        return axios.get('/api/v2/get/home/histories')
            .then(res => {
                if(res.status === 200)
                    return res.data;
                return [{blurred: ''}];
            })
            .catch(err => {
                console.log(err);
                return [{blurred: ''}];
            });
    };

    getSongs() {
        return axios.get('/api/v2/get/home/songs')
            .then(res => {
                if(res.status === 200)
                    return res.data;
                return [{blurred: ''}];
            })
            .catch(err => {
                console.log(err)
                return [{blurred: ''}];
            });
    };

    modelDetails = async () => {
        return await axios.post('/api/v2/modelInfoHome')
            .then(res => {
                if(res.status === 200)
                    return res.data.modelImage || '';

                return '';
            })
            .catch(err => {
                console.log(err)
                return '';
            });
    };

    renderHistories() {
        if (this.state.posts.length === 0) return null;
        let post = this.state.posts;
        return post.map((item, index) => {
            return (
                <div
                    key={index}
                    className="history"
                    style={{
                        backgroundImage: "url(" + item.blurred + ")"
                    }}
                >
                    <img alt="profile"
                         src={this.state.modelImage}/>
                </div>
            )
        });
    }

    renderSongs() {
        if (this.state.songs.length === 0)
            return null;

        let song = this.state.songs;
        return song.filter(item => item ?? false).map((item, index) => {
            let imgPost = item.post_media_box
                ? this.props.config.storageURL + '/' + this.props.config.storage + "/message/model/" + item.img
                : this.props.config.storageURL + '/' + this.props.config.storage + "/posts/img/" + item.img;

            if(item.post_free !== 1)
                imgPost = item.blurred;

            return (
                <div
                    key={index}
                    className="song-item"
                    style={{
                        backgroundImage: "url(" + imgPost + ")"
                    }}>
                    <button
                        className="play-customBtn"
                        style={{top: '30%'}}>
                        <VideoIcon
                            color={"#fff"}
                            width={"50px"}
                            height={"50px"}
                        />
                    </button>
                </div>
            )

        });
    }


    render() {

        const responsive = {
            desktop: {
                breakpoint: {max: 3000, min: 1024},
                items: 4,
                slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
                breakpoint: {max: 1024, min: 464},
                items: 4,
                slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
                breakpoint: {max: 464, min: 0},
                items: 3,
                slidesToSlide: 1 // optional, default to 1.
            }
        };

        if (this.props.dictionary.length === 0 || this.props.config.length === 0)
            return <div className="center-align" style={{paddingTop: 45}}>
                <Circle color="#4285f4" className="center-align"/>
            </div>;

        return (

            <div
                id="homefour"
                className="container container-four main-container center-align "
                style={{
                    zIndex: 0,
                    backgroundColor: this.props.config.color.principal,
                    color: this.props.config.color.principalText
                }}
            >
                <p className="profile-modelNameFour">
                    <span
                        style={{
                            backgroundColor: this.props.config.color.principal,
                            opacity: '0.7'
                        }}
                    >{this.props.config.nameTitle}</span>
                </p>
                <div className="register-options">
                    <Link
                        to={"/login"}
                        style={{
                            color: this.props.config.color.thirdText
                        }}
                    >
                        <button
                            style={{
                                marginBottom: 20,
                                backgroundColor: this.props.config.color.third,
                                color: this.props.config.color.secondaryText
                            }}
                            className="subcribe-now-button login-btn"
                            waves="light"
                        >

                            {this.props.dictionary.login}
                        </button>
                    </Link>
                    <Link
                        to={"/register"}
                        style={{
                            color: this.props.config.color.secondaryText
                        }}
                    >
                        <button
                            style={{
                                paddingTop: 0,
                                backgroundColor: this.props.config.color.secondary,
                                color: this.props.config.color.secondaryText
                            }}
                            className="subcribe-now-button"
                        >

                            {this.props.dictionary.subscribeText}

                        </button>
                    </Link>
                </div>


                {this.props.description ?
                    <p className="desc-landing-two">
                        {this.props.description}
                    </p>
                :
                    <p className="benefit-text">
                        {this.props.dictionary.joinClub[0]}
                        <br/>
                        {this.props.dictionary.joinClub[1]}
                    </p>
                }

                <h6 className="title-section">
                    {this.props.dictionary.post}
                </h6>
                <div className="benefits-contain">
                    {this.props.dictionary.followYourArtist}
                </div>
                {(this.state.posts.length === 0) ?
                    <div className="center-align" style={{paddingTop: 45}}>
                        <Circle
                            background="transparent !important"
                            color="#4285f4"
                            className="center-align"
                        />
                    </div>
                    :
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        arrows={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={1500}
                        keyBoardControl={true}
                        transitionDuration={1000}
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                    >
                        {this.renderHistories()}
                    </Carousel>
                }


                <h6 className="title-section">
                    {this.props.dictionary.songs}
                </h6>
                <div className="benefits-contain">
                    {this.props.dictionary.songsInfo}
                </div>
                <div className="songs">
                    {this.renderSongs()}
                </div>

                <h6 className="title-section">
                    {this.props.dictionary.messages}
                </h6>
                <div className="benefits-contain">
                    {this.props.dictionary.contPrivate}
                </div>
                <div className="messages-contain">
                    <div className="msg-item-left">
                        <img
                            alt="profile"
                            src={this.state.modelImage}
                        />
                        <div
                            className="text-msg"
                            style={{
                                backgroundColor: this.props.config.color.third,
                                color: this.props.config.color.secondaryText
                            }}
                        >
                            {this.props.dictionary.msgExample[0]}
                        </div>
                    </div>
                    <div className="msg-item-right">

                        <div
                            className="text-msg-right"
                            style={{
                                backgroundColor: this.props.config.color.secondary
                            }}
                        >
                            {this.props.dictionary.msgExample[1]} 😍
                        </div>
                        <img
                            alt="profile"
                            src={userIcon}
                        />
                    </div>
                    <div className="msg-item-left">
                        <img
                            alt="profile"
                            src={this.state.modelImage}
                        />
                        <div
                            className="text-msg"
                            style={{
                                backgroundColor: this.props.config.color.third,
                                color: this.props.config.color.secondaryText
                            }}
                        >
                                <span
                                    role="img"
                                    aria-label="smiley"
                                >
                                    😊
                                </span>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

export default HomeFour;
