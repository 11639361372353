import React from 'react';

const MediaBoxIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 16 16"
            className = {className}
        >
            <g opacity="1">
                <path 
                    fill={color}
                    stroke={color}
                    strokeWidth="1.5" 
                    d="M6.797 3.899C6.797 2.298 5.5 1 3.9 1 2.298 1 1 2.298 1 3.899c0 1.6 1.298 2.898 2.899 2.898h2.898V3.9zM6.797 12.101C6.797 13.702 5.5 15 3.9 15 2.298 15 1 13.702 1 12.101c0-1.6 1.298-2.898 2.899-2.898h2.898V12.1zM9.203 3.899C9.203 2.298 10.5 1 12.1 1 13.702 1 15 2.298 15 3.899c0 1.6-1.298 2.898-2.899 2.898H9.203V3.9zM9.203 12.101C9.203 13.702 10.5 15 12.1 15 13.702 15 15 13.702 15 12.101c0-1.6-1.298-2.898-2.899-2.898H9.203V12.1z"
                />
            </g>
        </svg>
    )
}
export default MediaBoxIcon;
