import React, { Component } from 'react';
import Icon from "react-ionicons";

class PurchaseBoxElement extends Component{

    componentDidMount(){
        let root = document.documentElement;
        root.style.setProperty('--color-audio-animation', this.props.config.color.secondary + "66");
        root.style.setProperty('--color-audio-anim-fadeTwo', this.props.config.color.secondary + "26");
        root.style.setProperty('--color-audio-anim-fadeThree', this.props.config.color.secondary + "8C");
        root.style.setProperty('--color-audio-anim-fadeFour', this.props.config.color.secondary + "40");
    }

    renderMedia(){
        let route = "model";
        let media = this.props.media;
        let index = this.props.index;
        let config = this.props.config;
        route = this.props.config.storageURL + '/' + this.props.config.storage + "/message/" + route + "/";
        if (media.video) {
            return (
                <>
                    <video
                        preload="yes"
                        poster={config.storageURL + '/' + config.storage + "/message/model/" + media.image}
                        className="responsive-video imgMediaBox no-select"
                        controlsList="nodownload"
                    >
                        <source
                            src={config.storageURL + '/' + config.storage + "/message/model/" + media.video}
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                    <div
                        style={{ position : "absolute", left:0, top:0, right:0, bottom:0, zIndex:1 }}
                        onClick={(this.props.openViewer) ?
                            (e) => this.props.openViewer(e, media, index)
                            : null
                        }
                    >
                    </div>
                </>

            )
        } else if (media.audio){
            return <div className="wrapper"
                >
                <div 
                    title="Listen" 
                    id="a-audio-container" 
                    className="player-controls"
                    onClick={this.props.playAudio}
                >
                    <Icon
                        icon="md-arrow-dropright-circle"
                        fontSize="4em"
                        className="audio-icon"
                        color="rgba(255,255,255,0.7)"
                    />
                    <img
                        alt="profile"
                        src={config.storageURL + config.principalImage}
                        height="150px"
                        className="model-picture no-select"
                    />
                </div>
                <audio 
                    className="audio-purchased"
                    key={index}
                    id="player"
                    autoPlay={false}
                    volume={0.7}
                    src={route + media.audio}
                    type="audio/mp3"
                >
                    Your browser does not support the <code>audio</code> tag.
               </audio>
            </div>;
        } else {
            return (
                <>
                    <img
                        alt="media"
                        src={config.storageURL + '/' + config.storage + "/message/model/" + media.image}
                        className="responsive-img no-select"
                    />
                    <div
                        style={{ position : "absolute", left:0, top:0, right:0, bottom:0, zIndex:1 }}
                        onClick={(this.props.openViewer) ?
                            (e) => this.props.openViewer(e, media, index)
                            : null
                        }
                    >
                    </div>
                </>
            )
        }
    }

    render(){
        let media = this.props.media;
        return(
            <div
                className={(this.props.openViewer) ? (media.audio ? "openable audio-element" : "openable mediabox-element") : "mediabox-element"}
            >
                {this.renderMedia()}
                { media.video ?
                    <Icon
                        fontSize="4em"
                        className="play-video"
                        color="rgba(255,255,255,0.5)"
                        icon="md-arrow-dropright-circle"
                    />
                : null }
            </div>
        )
    }
}

export default PurchaseBoxElement;
