import React from 'react';

const PrivacySpanish = ({ hostname }) => {
    return (
        <div>
            <h3 className='center'>Políticas de Privacidad</h3>
            <p>
                Bienvenido a nuestras políticas de privacidad (en lo sucesivo se denominará “Políticas de Privacidad”). Nosotros respetamos la privacidad de nuestros miembros y usuarios del servicio de {hostname} (en lo sucesivo se denominará el “Sitio”). Hemos creado estas políticas de privacidad para informarle como nosotros recopilamos, guardamos, y utilizamos información como resultado del uso del Sitio y cualquiera de sus servicios. Nuestros servicios no están previstos para ninguna persona menor de 18 años, tal y como se describe en la sección IV de este mismo documento. Nosotros no ofrecemos nuestros servicios a sabiendas de, o con la finalidad de recopilar información personal de ninguna persona menor de 18 años, o cualquier persona utilizando una identidad falsa. Estas Políticas de Privacidad son una incorporación a nuestros Términos de Servicio (en lo sucesivo se denominará “Términos”), y en conjunto con dichos Términos controlan su relación con el Sitio, estos términos están incorporados expresamente en este mismo documento mediante esta referencia. Estas Políticas de Privacidad y cualquier disputa relativa a la misma, deberá ser regida por los procedimientos resolutorios por disputa establecidos en los Términos en este mismo documento.
            </p>
            <p>
                Estas Políticas de Privacidad podrían ser actualizadas cada cierto tiempo, sin embargo actualmente se encuentran como aparecen en la “última actualización” de la fecha que aparece en la parte superior izquierda de este documento de Políticas de Privacidad. Nosotros haremos de su conocimiento cada vez que realicemos algún cambio material en lo referente a nuestras Políticas de Privacidad. Cualquier cambio será realizado de acuerdo a las disposiciones enlistadas a continuación. Dicho lo anterior, usted deberá revisar dichas Políticas periódicamente.
            </p>
            <p><strong>I.- La Información de Identificación Personal (en los sucesivo se denominará IIP) que Recopilamos y Utilizamos</strong></p>
            <p>
                Al utilizar el Sitio y/o sus servicios, reconoce y está de acuerdo en que recopilemos su IIP tal y como se estipula posteriormente cuando usted crea una cuenta en el Sitio. La IIP es proporcionada por usted y es recopilada cuando usted utiliza nuestro Sitio, adquiere una suscripción, o vende una suscripción. El Sitio no utiliza ni comparte su IIP con terceras partes excepto por las aquí descritas.
            </p>
            <p>
                El Sitio podrá incluir enlaces a sitios web terceros. A excepción de como se establece en este documento, nosotros no compartimos ninguna IIP con estos sitios web. El Sitio no controla, promociona, o supervisa estos sitios web terceros y dichos sitios pudieran recabar IIP cuando la persona hace clic o visita un sitio web tercero. Estas Políticas de Privacidad no controlan su interacción con sitios web terceros y usted deberá consultar con estos sitios web terceros con respecto a sus propias políticas de privacidad, así como sus términos de servicio.
            </p>
            <p>
                Nosotros recopilamos la siguiente información: (1) la información que usted voluntariamente nos proporcione, (2) la información que nosotros recabamos cuando usted utiliza nuestros servicios, e (3) información que pudiésemos obtener de terceras partes como resultado de la utilización del Sitio. Adicionalmente, en circunstancias limitadas, también podríamos solicitarle identificación gubernamental.
            </p>
            <p>
                Con la finalidad de abrir una cuenta con nosotros y utilizar nuestros servicios, usted nos revelara: (1) Su nombre, (2) dirección física, (3) dirección de correo electrónico, (4) fecha de nacimiento, (5) una copia válida y vigente de su pasaporte o alguna otra identificación vigente, avalada y verificada por el gobierno (únicamente bajo nuestra petición).
            </p>
            <p>
                Nos reservamos el derecho de solicitarle información adicional que pudiera estar públicamente visible, tales como una foto de perfil, nombre de su perfil,  o alguna otra información identificativa.
            </p>
            <p>
                <strong>II.- Cómo Utilizaremos Su IIP</strong>
            </p>
            <p>
                Su IIP es utilizada por nosotros de manera interna, y nos permite facilitar transacciones, proveer y proporcionarle acceso a su perfil, contenido, y suscripciones de su elección. También nos permite contactarle y responder a sus posibles peticiones o comentarios.
            </p>
            <p>
                También utilizamos la IIP de forma interna para crear, desarrollar, y mejorar nuestras políticas de monitoreo de fraude y/o robo. De igual manera su IIP será utilizada internamente para auditorias y análisis con la finalidad de mantener los servicios del Sitio funcionando tan fluidamente como nos sea posible.
            </p>
            <p>
                El Sitio pudiera utilizar su IIP misma que usted nos proporcionó en conjunto con terceras partes que estén bajo contrato con el Sitio, como pueden ser nuestros procesadores de pago y vendedores, quienes le aseguran el acceso a sus suscripciones, contenido, y perfil. El Sitio solo provee su IIP únicamente hasta el punto indispensable para poderle asegurar la provisión de dichos servicios. El Sitio jamás proporcionará su IIP a terceras partes con fines promocionales o de mercadeo.
            </p>
            <p>
                Del mismo modo, utilizamos su IIP, incluyendo su fecha de nacimiento, para asegurarnos que usted tiene la edad adecuada para acceder al Sitio y al diferente contenido disponible mediante los dueños de un perfil en el Sitio.
            </p>
            <p>
                En circunstancias limitadas, podríamos utilizar su IIP para contactarlo y hacerle llegar notificaciones importantes, incluyendo, más no limitados, a advertencias por fraudes, cambios en los Términos y/o estas Políticas de Privacidad. Aunque usted podría optar por excluirnos de algunas modalidades de comunicación, usted no podrá declinar a comunicarse con nosotros cuando se trate de temas con respecto a cambios en los Términos o estas Políticas de Privacidad, ya que estas forman la base de su relación con nosotros y rigen nuestras interacciones.
            </p>
            <p>
                Si es propietario de un perfil, parte de la IIP que nos proporcionó puede ser investigable por los mismos suscriptores y usuarios del Sitio. La información investigable de su IIP será limitada a su nombre de usuario y/o su primer nombre y apellido.
            </p>
            <p>
                Nosotros pudiéramos crear información anónima (en lo sucesivo se le denominara “Información Anónima”) registro (los registros excluyen su IIP), tales como detalles acerca de cómo ha utilizado nuestros servicios, información de su dispositivo, tales como: su tipo de navegador y lenguaje, tiempos de acceso, páginas visitadas, o su dirección IP (la cual definimos a continuación) para ayudarnos a llevar a cabo algunos análisis los cuales a su vez, nos ayudan a monitorear y mejorar el Sitio. Nos reservamos todos los derechos de utilizar la Información Anónima con cualquier otro propósito, y a revelar Información Anónima a terceras partes a nuestra entera discreción.
            </p>
            <p>
                Es importante mencionar que podríamos acceder, utilizar, y liberar su IIP para cumplir con una orden de la corte, citación, procesos legales, litigio, y/o alguna solicitud de alguna agencia gubernamental o autoridades, las cuales nos requieran revelar su IIP. También podríamos revelar su información si determináramos que la revelación de dicha información es necesaria para cumplir con alguna ley aplicable o aclarar alguna sospecha de violación a la ley.
            </p>
            <p>
                Si nosotros decidiéramos fusionarnos, vender o re organizar el Sitio de alguna manera, pudiéramos transferir o entregar su IIP la tercera parte relevante en ese momento. Dichas transferencias son ordinarias como parte de cualquier otra transferencia de negocios.
            </p>
            <p>
                Además de las instancias nombradas explícitamente en estas Políticas de Privacidad, usted recibirá una notificación cuando su IIP pueda ser transferida a una tercera parte, y se le proveerá la oportunidad de declinar a dar su consentimiento para transferir dicha información a esta tercera parte.
            </p>
            <p>
                <strong>III.-  Uso de Cookies y Otras Tecnologías</strong>
            </p>
            <p>
                El Sitio, sus productos, servicios, y comunicaciones podrían utilizar cookies y otras tecnologías como etiquetas de pixel o imágenes rastreadoras así como contadores de visitas. Las Cookies son una forma de tecnología las cuales contienen información electrónica que puede ser utilizada para ayudarnos a personalizar el uso de nuestro sitio web al informarnos que partes de dicho sitio web usted ha utilizado o visitado previamente, mantener un historial y un registro de su acceso a el Sitio, y/o almacenar información requerida por un usuario de forma regular. El Sitio utiliza Cookies para recordar su IIP para crear una experiencia a su medida, incluyendo el que usted no necesite estar proporcionando repetidamente su información, y/o nombre de usuario y contraseña cada vez que desee acceder a una nueva página en el Sitio. Nosotros consideramos la información recabada por las cookies y tecnologías similares como información impersonal. Si, de acuerdo con las leyes locales, el Protocolo de Internet (en lo sucesivo denominado “IP”), las direcciones o la información identificatoria similar se consideran información personal identificable, trataremos también dicha información como información identificable.
            </p>
            <p>
                Las cookies pueden ser desactivadas cambiando la configuración correspondiente en su navegador de internet. Es importante mencionar que algunas partes del Sitio, o los servicios prestados por el Sitio pudieran no estar accesibles una vez que se desactive el uso de cookies.
            </p>
            <p>
                Alguna de la información que recabamos es recopilada y guardada automáticamente en archivos de registro. Nosotros podríamos utilizar los análisis de terceras partes que utilizan cookies para recabar información identificable impersonal en relación al uso del sitio web por parte de usted.
            </p>
            <p>
                <strong>IV.- Niños Menores de 18 Años</strong>
            </p>
            <p>
                Los niños menores de 18 años tienen estrictamente prohibido utilizar la página web del Sitio. Nosotros no recabaremos ninguna IIP intencionalmente de los niños menores de 18 años. De acuerdo con los Términos del Sitio, les recomendamos a los padres de familia monitorear las actividades que llevan a cabo sus hijos menores de edad al momento de estar en línea y a enseñarles, jamás revelar su IIP mientras estén conectados o en línea. Si nosotros somos conscientes o notamos que un niño menor de 18 años nos ha proporcionado su información personal sin el consentimiento parental, nosotros tomaremos medidas para remover dicha información y rescindir la cuenta del menor inmediatamente.
            </p>
            <p>
                <strong>V.- Autoexcluirse a Mantener Comunicación con Nosotros</strong>
            </p>
            <p>
                Como se mencionó previamente, usted tiene la opción de autoexcluirse de recibir publicidad o comunicación  publicitaria de nuestra parte. Si usted desea ejercer esta opción y autoexcluirse de dicha publicidad o comunicación  publicitaria, por favor contáctenos en support@agenciadiso.freshdesk.com para que de esta manera podamos brindarle instrucciones para descontinuar dicha publicidad.
            </p>
            <p>
                <strong>VI.- Almacenamiento y Aseguramiento de su IIP</strong>
            </p>
            <p>
                Nosotros nos empeñamos en proteger la seguridad de la IIP que usted nos proveyó al emplear medidas razonables para asegurarnos que su información permanece protegida. Desafortunadamente, la transmisión de información vía Internet no es completamente segura. Nosotros utilizamos dispositivos comercialmente razonables, físicos, gerenciales y técnicos para salvaguardar y preservar la integridad y la seguridad de su IIP. Nosotros, sin embargo, no podemos garantizar o prometer la seguridad de ninguna información que sea transmitida o compartida por usted en el Sitio, por lo cual al hacer lo anterior lo hace bajo su propio riesgo.
            </p>
            <p>
                Si usted cierra su cuenta, la información personal relacionada con su cuenta y/o su información de pago será borrada después de 5 meses, sujeta a la Sección VII a continuación. Los registros financieros serán conservados por 7 años, se nos requiere hacer lo anterior por ley en lo concerniente a los detalles de pago realizados por usted. Este párrafo cumple con las disposiciones del artículo 13(2)(b & c) GDPR, si el uso del Sitio ocurre dentro de la Unión Europea.
            </p>
            <p>
                <strong>VII.- Información Que Usted Puede Accesar</strong>
            </p>
            <p>
                Cualquier preocupación concerniente al tipo y eficiencia de la IIP recopilada por nosotros, o si usted desea revisar o corregir cualquier información que usted nos haya proporcionado, o si usted desea que su IIP sea depurada, eliminada o de alguna forma borrada de nuestros registros, nos puede contactar en support@agenciadiso.freshdesk.com Nos reservamos el derecho a no cumplir con cualquier solicitud que pueda ser irrazonablemente irrealizable, repetitiva, o que pudiera infringir la privacidad de otros. Adicionalmente, nosotros pudiéramos retener información acerca de  usted para los propósitos autorizados bajo estas Políticas de Privacidad, a menos que estén prohibidas por ley.  Por ejemplo, pudiéramos retener información para prevenir, investigar, o identificar un delito o acto inmoral que esté relacionado con el uso del Sitio por usted, o para cumplir con alguna obligación legal.
            </p>
            <p>
                <strong>VIII.- Condiciones, Uso, y Modificaciones</strong>
            </p>
            <p>
                Al acceder al Sitio o utilizar cualquiera de los servicios allí ofrecidos, su uso o cualquier disputa relacionada con o como consecuencia de dicho uso son controladas por estas Políticas de Privacidad y los Términos de Servicio, incluyendo, pero no limitándose a, limitación de daños, arbitraje, exención de demanda colectiva.
            </p>
            <p>
                Si tiene alguna pregunta o preocupación con relación a su IIP por favor no dude en contactarnos en la siguiente dirección support@agenciadiso.freshdesk.com y nosotros le resolveremos.
            </p>
            <p>
                Como se mencionó en nuestros Términos, podríamos actualizar periódicamente nuestros Términos o estas Políticas de Privacidad. El derecho a enmendar los Términos y estas Políticas de Privacidad incluyen el derecho a modificar, agregar, o remover cláusulas a nuestra entera discreción, y sin tener que notificarle. Excepto a menos que así lo indique en los Términos o en estas Políticas de Privacidad, todos los términos deberán ser efectivos inmediatamente después de ser publicados en el Sitio. A pesar de nuestros esfuerzos por proporcionarle notificaciones razonables de cualquier cambio en los Términos o Políticas de Privacidad, usted deberá revisar los Términos y las Políticas de Privacidad periódicamente.
            </p>
            <p>
                Cualquier cambio realizado a los Términos y a las Políticas de Privacidad no aplicará retroactivamente, y no aplicará a ninguna disputa en la cual ambas partes hayan tenido notificaciones reales en la fecha o previas a la fecha de cambios o enmendaduras.
            </p>
            <p>
                <strong>&copy; 2020 Todos los derechos reservados</strong>
            </p>
        </div>
    );
};
export default PrivacySpanish;
