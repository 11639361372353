import React, { Component } from 'react';

class ViewerElement extends Component{

    renderMedia() {
        let media = this.props.media;
        let mediaBoxIdMessage = media.mediaBox ? media.mediaBox : false;
        let mediaBoxId = media.id ? media.id : false;
        let mediaImg = media.post_media_img ? media.post_media_img : media.image;
        let mediaVideo = media.post_media_video ? media.post_media_video : media.video;
        let user = media.user ? media.user : 'model';
        let config = this.props.config;
        let img = (mediaBoxIdMessage || mediaBoxId) ?
            config.storageURL + '/' + config.storage + "/message/" + user +"/" + mediaImg
            :
            config.storageURL + '/' + config.storage + "/posts/img/" + mediaImg;

        let video = (mediaBoxIdMessage || mediaBoxId) ?
            config.videoStorageURL + '/' + config.storage + "/message/" + user +"/" + mediaVideo
            :
            config.videoStorageURL + '/' + config.storage + "/posts/video/" + mediaVideo;
        if(mediaVideo) {
            return(
                <>
                    <video
                        controls={true}
                        preload="metadata"
                        poster={img}
                        className="responsive-video MediaViewerVideo no-select"
                        controlsList="nodownload"
                        src={video}
                        autoPlay={true}
                    >
                        <source
                            src={video}
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </>

            )
        }
        else{
            return(
                <>
                    <img
                        alt={this.props.name}
                        src={img}
                        className="responsive-img no-select"/>
                    <div
                        style={{ position : "absolute", left:0, top:0, right:0, bottom:0, zIndex:1 }}
                    >
                    </div>
                </>
            )
        }
    }

    render(){
        return(
            <div className={"mediaContainer" + (this.props.selected ? "selected" : "")}>
                {this.renderMedia()}
            </div>
        )
    }
}

export default ViewerElement;
