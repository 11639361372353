import piexif from "piexifjs";
import Promise from "bluebird";
import { browserName, osName } from 'react-device-detect';

class Images{

    constructor() {
        this.ios = false;
        this.safari = false;
        this.isIOS()
        this.state = {
            browser : null,
            device : null
        }
    }

    isSafari(){
        let safari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') === -1 &&
            navigator.userAgent.indexOf('FxiOS') === -1;
            this.safari = safari;
        return safari;
    }

    isIOS() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const saf = this.isSafari();
        if ((/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) || saf) {
            this.ios = true;
            return true;
        }
    }

    async rotateImage(file, index) {
        if(osName === "iOS" || osName === "Mac OS" || browserName !== "Firefox" || osName === "Windows" ){
            //alert("si")
            return file;
        }
        /*if(this.ios){
            return file
        }*/
        let reader = new FileReader();
        reader.onload = async (e) => {
            let exif = piexif.load(e.target.result);
            let image = new Image();
            image.onload = async () => {
                var orientation = exif["0th"][piexif.ImageIFD.Orientation];
                var canvas = document.getElementById("canvasToRotate" + index);
                canvas.width = image.width;
                canvas.height = image.height;
                var ctx = canvas.getContext("2d");
                var x = 0;
                var y = 0;
                ctx.save();
                if (orientation === 2) {
                    x = -canvas.width;
                    ctx.scale(-1, 1);
                } else if (orientation === 3) {
                    x = -canvas.width;
                    y = -canvas.height;
                    ctx.scale(-1, -1);
                } else if (orientation === 4) {
                    y = -canvas.height;
                    ctx.scale(1, -1);
                } else if (orientation === 5) {
                    canvas.width = image.height;
                    canvas.height = image.width;
                    ctx.translate(canvas.width, canvas.height / canvas.width);
                    ctx.rotate(Math.PI / 2);
                    y = -canvas.width;
                    ctx.scale(1, -1);
                } else if (orientation === 6) {
                    canvas.width = image.height;
                    canvas.height = image.width;
                    ctx.translate(canvas.width, canvas.height / canvas.width);
                    ctx.rotate(Math.PI / 2);
                } else if (orientation === 7) {
                    canvas.width = image.height;
                    canvas.height = image.width;
                    ctx.translate(canvas.width, canvas.height / canvas.width);
                    ctx.rotate(Math.PI / 2);
                    x = -canvas.height;
                    ctx.scale(-1, 1);
                } else if (orientation === 8) {
                    canvas.width = image.height;
                    canvas.height = image.width;
                    ctx.translate(canvas.width, canvas.height / canvas.width);
                    ctx.rotate(Math.PI / 2);
                    x = -canvas.height;
                    y = -canvas.width;
                    ctx.scale(-1, -1);
                }
                await new Promise(resolve => {ctx.drawImage(image, x, y);});
                ctx.restore();


            };
            await new Promise( resolve => {image.src = e.target.result;});
            /*var dv = new DataView(e.target.result);
            var offset = 0, recess = 0;
            var pieces = [];
            var i = 0;
            if (dv.getUint16(offset) == 0xffd8){
                offset += 2;
                var app1 = dv.getUint16(offset);
                offset += 2;
                while (offset < dv.byteLength){
                    console.log(offset, '0x'+app1.toString(16), recess);
                    if (app1 == 0xffe1){

                        pieces[i] = {recess:recess,offset:offset-2};
                        recess = offset + dv.getUint16(offset);
                        i++;
                    }
                    else if (app1 == 0xffda){
                        break;
                    }
                    offset += dv.getUint16(offset);
                    var app1 = dv.getUint16(offset);
                    offset += 2;
                }
                if (pieces.length > 0){
                    var newPieces = [];
                    pieces.forEach(function(v){
                        newPieces.push(e.target.result.slice(v.recess, v.offset));
                    }, this);
                    newPieces.push(e.target.result.slice(recess));
                    var br = new Blob(newPieces, {type: 'image/jpeg'});
                    //window.open(URL.createObjectURL(br), "_blank", "toolbar=yes, scrollbars=yes, resizable=yes, top=500, left=500, width=400, height=400");
                }
            }*/
        };
        return await new Promise( resolve => {
            //reader.readAsArrayBuffer(file);
            reader.readAsDataURL(file)
            setTimeout(()=>{
                const canvas = document.getElementById("canvasToRotate" + index);
                canvas.toBlob( async (blob) => {
                    return  resolve(blob)
                });
            },1200);
        });

    }

    validateFormat(format){
        const mediaType = format.split('/')[0];
        if(mediaType === 'video' || mediaType === 'image' || mediaType ==='audio')
        {
            return mediaType;
        }
        else{
            return false
        }
    }

}
export default new Images();
