import React, { Component } from "react";
import axios from "axios";
import ExpandHelp from "../../ExpandHelp";
import './styles.css';
import ImageIcon from "../../Svg/ImageIcon";
import MediaBoxIcon from "../../Svg/MediaBoxIcon";
import ModalConfirm from "../../ModalConfirm";
import Carousel from "../../Publish/Carousel";
import Promise from "bluebird";
import MediaBoxList from "../../MediaBox/MediaBoxList";
import Saving from "../../Saving";
import CoinIcon from "../../Svg/CoinIcon";
import UploadFiles from "../../UploadFiles";

class WelcomeMessage extends Component {
    constructor() {
        super();
        this.state = {
            messages: [
                {
                    content : '',
                    media : [],
                    error : null,
                    price : 0
                },{
                    content : '',
                    media : [],
                    error : null,
                    price : 0
                }
            ],
            loading: true,
            toDelete: null,
            openModal: false,
            idMediaBox: "mediaBoxMassiveMessage",
            toMediaBox: null,
            toUpload: 0,
            progressSlice: 100,
            uploading: false
        }

        this.fileInputFirst = React.createRef()
        this.fileInputSecond = React.createRef()

        this.closeDeleteModal = this.closeDeleteModal.bind(this)

        this.axiosMedia = this.axiosMedia.bind(this);
    }

    componentDidMount() {
        // eslint-disable-next-line
        let msg = this.getMessages().then(resp => {
            this.setState({
                messages: resp,
                loading: false
            });
        });
    }

    openMediaBox = (id) => {
        this.setState({
            toMediaBox: id
        })
        let mediaboxElement = document.getElementById(this.state.idMediaBox);
        if(mediaboxElement)
            mediaboxElement.classList.add("open")
    }

    getChoosen = (arr) => {
        let actualMesages = [...this.state.messages];
        let id = this.state.toMediaBox;
        let currFiles = [];
        if (!actualMesages[id]) {
            actualMesages[id] = {
                content : '',
                media : [],
                error : null,
                price : 0
            }
        }
        if(actualMesages[id].media === null){
            actualMesages[id].media = []
        }
        currFiles = actualMesages[id].media;
        if(arr && arr.length){
            let newFiles = currFiles.concat(arr);
            actualMesages[id].media = newFiles;
        }

        this.setState({
            messages: actualMesages
        })
    }

    getMessages = async () => {
        return await axios.get("/api/v2/settings/messages")
            .then(resp => {

                let messages = resp.data.messages ? JSON.parse(resp.data.messages) : [];
                return messages
            });

    }

    renderCarousel(files, id) {
        let config = this.props.config;
        if (!files || !files.length) {
            return null;
        }
        return (
            <Carousel
                media={files}
                config={config}
                remove={this.removeMedia}
                id={id}
            />
        )
    }

    removeMedia = (index, id) => {
        let actualMesages = [...this.state.messages];
        let files = actualMesages[id].media;
        files.splice(index, 1);
        this.setState({
            messages: actualMesages
        })
    }

    openDeleteModal(id){
        var navbar = document.getElementsByClassName("styles_content__1Phom");
        if (navbar){
            navbar[0].style.zIndex = 0;
            this.setState({
                toDelete: id,
                openModal: true
            });
        }

    }

    closeDeleteModal(){
        var navbar = document.getElementsByClassName("styles_content__1Phom");
        if(navbar){
            navbar[0].style.zIndex = 1;
            this.setState({
                toDelete: null,
                openModal: false
            });
        }
    }

    changeHandler(e, id) {
        let name = e.target.name
        let actualMesages = [...this.state.messages];
        let value = e.target.value;
        value = name === "price" ? parseInt(value) : value;
        if (!actualMesages[id]) {
            actualMesages[id] = {
                content : '',
                media : [],
                error : null,
                price : 0
            }
        }
        actualMesages[id][name] = value.toString();

        this.setState({
            messages: actualMesages
        })
    }

    fileChangedHandler = async (e, id) => {
        const files = e.target.files;
        let actualMesages = [...this.state.messages];
        // eslint-disable-next-line
        let currFiles = [];
        if (!actualMesages[id]) {
            actualMesages[id] = {
                content : '',
                media : [],
                error : null,
                price : 0
            }
        }
        if(actualMesages[id].media === null){
            actualMesages[id].media = []
        }
        currFiles = actualMesages[id].media;
        currFiles = await UploadFiles.fileValidation(files, currFiles);
        this.setState({
            messages: actualMesages
        });
    }

    validate(obj, price = false) {

        if(price){
            if (!obj.media || obj.media.length === 0) {
                return false
            }
            if (isNaN(obj.price) || obj.price < 0) {
                return false
            }
        }
        else{
            if (obj.content === "" && obj.media.length === 0) {
                return false
            }
        }

        return true
    }

    async saveMessages(id) {
        let messages = [...this.state.messages];
        let currentMessages = await this.getMessages();
        let save = true;
        let uploading = 0;
        let valid = this.validate(messages[id], id);
        save = save ? valid : save;
        messages[id]["error"] = !valid
        uploading = (messages[id].media) ? uploading + messages[id].media.length : uploading;
        if (!save) {
            this.setState({
                messages: messages
            })
            return false
        }
        this.setState({
            uploading: true
        })
        if (uploading > 0) {
            this.setState({
                toUpload: uploading,
                progressSlice: 100 / uploading,
            })
        }
        let data = [];
        if (id === 1 && !currentMessages.length) {
            currentMessages = [...messages];
        }
        else {
            currentMessages[id] = messages[id];
        }
        let saved = await new Promise.map(currentMessages, async (el, id) => {
            data.push({
                content: el.content,
                price: el.price ? el.price : 0,
                media: null,
            })
            let copyFiles = (el.media) ? el.media : [];
            if (copyFiles.length > 0) {
                let mediaUploaded = []
                await Promise.map(copyFiles, async (item, index) => {
                    let media = null;
                    if (item.method === "mediabox") {
                        item.progress = 100;
                        let fileParts = item.file.split("/");
                        let posterParts = (item.poster === null) ? null : item.poster.split("/");
                        let image = item.poster === null ?
                            fileParts[fileParts.length - 1] : posterParts[posterParts.length - 1];
                        let video = item.poster === null ?
                            null : fileParts[fileParts.length - 1];
                        media = {
                            file: item.file,
                            poster: item.poster,
                            mediabox: item.mediaBoxId,
                            type: item.type,
                            method: "mediabox",
                            image: image,
                            video: video,
                        }
                    }
                    else if (item.method === "upload") {
                        media = await UploadFiles.uploadFiles(item.file, {
                            id,
                            index,
                            folder: "/message/model/"
                        }, this.axiosMedia);
                    }
                    else {
                        media = item
                    }
                    mediaUploaded.push(media)
                }).then(async () => {
                    data[id].media = mediaUploaded;
                    return true;
                });
            }
        }).then(async () => {
            return await axios.post('/api/v2/messages/welcome', {
                messages: data
            }).then((response) => {
                if (response.data.Success) {
                    return response.data.messages
                }
                return false
            }).catch(() => {
                return false
            })
        }).catch(() => {
            return false
        });
        if (saved) {
            messages[id] = saved[id]
            this.setState({
                messages: messages,
                uploading: false
            })
        }
        else {
            this.setState({
                toUpload: 0,
                progressSlice: 100,
                uploading: false
            });
        }
        return messages
    }


    axiosMedia = (url, file, option) => {

        try {
            return axios.put(url, file, {
                onUploadProgress: progressEvent => {
                    let messages = this.state.messages;
                    let progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
                    messages[option.id].media[option.index]["progress"] = progress;
                },
                headers: {
                    'Content-Type': file.type
                }
            })
                .then(async (resp) => {
                    if (resp) {
                        return true;
                    }
                })
                .catch((err) => {
                    return false;
                });
        }
        catch (e) { }
    }

    drawMessages() {
        let messages = [];
        let actualMesages = [...this.state.messages];
        let dictionary = this.props.dictionary;
        let config = this.props.config;
        let id  = 0
        for (let k = 0; k < 2; k++){
            id = k;
            let el = actualMesages[id] ? actualMesages[id] : null;
            messages.push(
                <div key={k} className={(id === 0) ? "publish border-down" : "publish"}>
                    <p className="title">{id === 0 ? dictionary.freeWelcomeMessages : dictionary.paidWelcomeMessages}</p>
                    <textarea
                        name="content"
                        id="description"
                        rows="5"
                        onChange={(e) => this.changeHandler(e, k)}
                        placeholder={dictionary.description}
                        value={el ? el.content : ""}
                        className={el ? (el.error) ? "error" : "" : ''}
                        maxLength={740}
                    >

                    </textarea>
                    <div className={"actions-content"}>
                        {id === 1 ?
                            <p>
                                <label>
                                    <CoinIcon color={config.color.principalText}
                                              width={"25px"}
                                              height={"25px"}/>
                                    <span>
                                      {dictionary.cost + " (" + dictionary.dollars + ")"}
                                    </span>

                                </label>
                            </p>
                            : null
                        }
                        {id === 1 ?
                            <div className={'half first'}>
                                <input
                                    style={{color: this.props.config.color.principalText}}
                                    name="price"
                                    id="price_input"
                                    validate="true"
                                    min={0}
                                    onChange={(e) => this.changeHandler(e, k)}
                                    type="number"
                                    value={el ? el.price ? el.price : 0 : 0}
                                    placeholder={dictionary.price}
                                    className={el ? (el.error) ? "error register-inputs validate" : "register-inputs validate" : "register-inputs validate"}
                                />
                            </div>
                            : null
                        }
                        <div className={id === 1 ? 'half second' : ''}>
                            <ul className="actions ">
                                <li>
                                    <button
                                        className="button"
                                        style={{ border: "1px solid " + config.color.principalText, color: config.color.principalText }}
                                        onClick={() => {
                                            (k === 0) ?
                                                this.fileInputFirst.current.click()
                                                :
                                                this.fileInputSecond.current.click()
                                        }}
                                    >
                                        <ImageIcon
                                            color={config.color.principalText}
                                            width={"25px"}
                                            height={"25px"}
                                        />
                                    </button>
                                    {this.props.isMobile ?
                                        <input
                                            ref={(id === 0) ? this.fileInputFirst : this.fileInputSecond}
                                            type="file"
                                            name="fileStorage"
                                            id="fileStorage"
                                            onChange={(e) => {
                                                this.fileChangedHandler(e, k)
                                            }}
                                            accept="image/*, video/*"
                                        />
                                        :
                                        <input
                                            ref={(id === 0) ? this.fileInputFirst : this.fileInputSecond}
                                            type="file"
                                            name="fileStorage"
                                            id="fileStorage"
                                            multiple
                                            onChange={(e) => {
                                                this.fileChangedHandler(e, k)
                                            }}
                                            accept="image/*, video/*"
                                        />
                                    }
                                </li>
                                <li>
                                    <button
                                        className="button"
                                        style={{ border: "1px solid " + config.color.principalText, color: config.color.principalText }}
                                        onClick={(e) => this.openMediaBox(k)}
                                    >
                                        <MediaBoxIcon
                                            color={config.color.principalText}
                                            width={"20px"}
                                            height={"20px"}
                                        />
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {el ?
                        this.renderCarousel((el.media) ? el.media : [], id)
                        :
                        null
                    }
                    <button
                        style={{
                            backgroundColor: config.color.secondary,
                            color: config.color.secondaryText,
                        }}
                        className="log-btn btn-save"
                        onClick={() => this.saveMessages(k)}
                    >
                        {dictionary.save}
                    </button>
                </div>

            )
        }
        return messages
    }

    render() {
        // eslint-disable-next-line
        let actualMesages = [...this.state.messages];
        let dictionary = this.props.dictionary;
        let config = this.props.config;
        return (
            <div className="welcome-messages">
                <MediaBoxList
                    config={config}
                    dictionary={dictionary}
                    id={this.state.idMediaBox}
                    getChoosen={this.getChoosen}
                    post={true}
                />
                <div className='cont-right-a'>
                    <ExpandHelp
                        activeBtnBackgroundColor={this.props.config.color.secondary}
                        activeBtnColor={this.props.config.color.secondaryText}
                        textBackgroundColor={this.props.config.color.principal}
                        textColor={this.props.config.color.principalText}
                        text={this.props.dictionary.welcomeMessagesHelp}
                    />
                </div>
                <div>
                    {this.drawMessages()}
                </div>
                <ModalConfirm
                    action={() => this.removeMessage(this.state.toDelete)}
                    title={dictionary.confirmWelcomeDelete}
                    close={this.closeDeleteModal}
                    id={"confirmDeleteMessageModal"}
                    message={dictionary.deleteWelcome}
                    open={this.state.openModal}
                    config={this.props.config}
                    actionText={this.props.dictionary.delete}
                />
                {this.state.uploading ?
                    <Saving
                        config={config}
                        id="savingMassive"
                        saving={this.state.uploading}
                        loading={false}
                        progress={null}
                    />
                    :
                    null
                }
            </div>
        );
    }

}
export default WelcomeMessage;
