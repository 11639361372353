import React from 'react';

const TermsSpanish = ({ hostname, color }) => {
    return (
        <div
            id="container-support"
            className="container main-container center-align"
            style={{
                zIndex: 0,
                backgroundColor: color.principal,
                color: color.principalText
            }}
        >
            <h3 className='center'>
                Términos y Condiciones de Servicio
            </h3>
            <p>
                Estos Términos y Condiciones de Servicio (en lo sucesivo los “Términos”) es un documento legal el cual explica sus derechos y obligaciones como usuario, y miembro de {hostname} sitio web, servicios de redes sociales y aplicación web (en lo sucesivo el “Sitio Web”).
            </p>
            <p>
                <strong>
                    I.- Introducción
                </strong>
            </p>
            <p>
                A continuación se mencionan los términos generales y condiciones contractuales los cuales regirán su uso del “Sitio Web”. Los términos del Sitio Web constituyen un acuerdo legal entre usted y Team Peach, LLC (en los sucesivo “nosotros”, “nuestro” o “Compañía”) relacionado con su uso del Sitio Web. Nosotros nos reservamos el derecho de actualizar y/o cambiar dichos Términos de acuerdo a nuestras necesidades, incluyendo, más no limitándose a cambios basados en nuestra comunidad, el contenido de nuestra base de datos, asuntos legales, o tendencias de mercado. Cualquier cambio realizado a estos Términos no aplicará retroactivamente, y no aplicarán a ninguna disputa en la cual ambas partes hayan tenido notificaciones reales en la fecha o previas a la fecha de modificaciones o enmendaduras. Excepto a menos que si lo estipule en estos Términos, todos los términos modificados o enmendados deberán ser efectivos inmediatamente después de haber sido publicados en el siguiente enlace: {hostname}.
            </p>
            <p>
                Al utilizar o acceder al Sitio en cualquier forma, usted expresamente reconoce y acepta el estar ligado con estos Términos y las Políticas de Privacidad (en los sucesivo las “Políticas de Privacidad”) las cuales son completamente incorporadas en este documento y mediante esta referencia. Si usted está en desacuerdo con algo de lo establecido en estos Términos, por favor deje de utilizar el Sitio y desactive su cuenta (como se describe detalladamente en este documento). El Sitio le puede proveer una traducción de dichos Términos. Usted está de acuerdo que cualquier traducción de los Términos le es proporcionada únicamente por y para su propia conveniencia, y que la versión en el idioma inglés de los Términos es la que regirá su relación con la Compañía y la utilización del Sitio. Si hubiese alguna contradicción entre la versión en inglés y su traducción en otro idioma, entonces la versión en el idioma inglés deberá prevalecer.
            </p>
            <p>
                <strong>
                    II. Site Usage Agreement
                </strong>
            </p>
            <p>
                <p>
                    <strong>
                        a.- El uso del Sitio
                    </strong>
                </p>

                Este sitio es operado y es propiedad de la Compañía. Todos los medios, audios, videos y  material informativo relacionado en cualquiera de sus medios provistos en el Sitio Web, incluyendo textos, subtítulos,  o información (colectivamente referida en lo sucesivo como “Contenido”) son propiedad de la Compañía y/o de nuestros proveedores de Contenido.
            </p>
            <p>
                Todos los contenidos están protegidos por las leyes de derechos de autor tanto Internacionales como de Estados Unidos. Nosotros podríamos restringir, terminar, o remover su acceso a nuestro Sitio en cualquier momento, o restringir, terminar, o remover cualquier contenido por cualquier razón que creamos pertinente. Aunque nos esforzamos por proporcionarle información precisa, este Sitio Web pudiera contener errores técnicos u otros errores, inexactitudes o errores tipográficos.
            </p>
            <p>
                Adicionalmente, este Sitio y cualquier Contenido pueden incluir Contenido histórico y cultural importante, de la misma  manera textos que pueden contener temas que reflejan las actitudes y circunstancias sociales de una locación o tiempo en particular. Usted deberá estar consciente que el uso del Sitio Web podría exponerlo a Contenido que pudiera parecer inapropiado para su contexto, o textos que pudieran ser potencialmente ofensivos. Mientras que la responsabilidad por el Contenido subido o publicado permanece a cargo de las terceras partes hacedoras de dicho Contenido, nosotros nos esforzamos para identificar y responder a cualquier reclamo por infringir los derechos de autor o marca registrada mediante la recepción de un acuse de recibo.
            </p>
            <p>
                Si usted ha identificado un archivo o documento que cree representa un incumplimiento, favor de notificarnos por la vía de contacto que se le proporciona a continuación en este mismo documento, para de esta manera poder tomar las medidas apropiadas.
            </p>
            <p>
                <p>
                    <strong>b. Restricción de Edad</strong>
                </p>
                El Sitio Web es solo ofrecido y está disponible para Usuarios con una edad mínima de 18 años o mayores. Al utilizar el Sitio Web, usted constituye y garantiza que tiene 18 años de edad, que tiene la edad legal para formar un contrato vinculante con la Compañía. Si usted no cumple con estas condiciones, queda prohibido utilizar o acceder al Sitio Web.
            </p>
            <p>
                <p>
                    <strong>c. Funcionamiento</strong>
                </p>
                El Sitio Web es una plataforma de redes sociales que le permite registrarse mediante una suscripción y un perfil de usuario, el cual le permite ver el Contenido publicado en el Sitio Web, y el pagar para ver Contenido mediante nuestras opciones de pago adicional. Nosotros no garantizamos que el Sitio Web sea compatible con todos los dispositivos y sistemas operativos. Es de su entera responsabilidad el determinar si el Sitio es o no compatible con su dispositivo.
            </p>
            <p>
                <p>
                    <strong>d. Uso, Registro de Cuenta, Suscripción, y Pago</strong>
                </p>
               Sujetos a todos los términos, condiciones limitaciones y restricciones contenidas en estos Términos, le otorgamos una licencia condicional, revocable, intransferible, no sublicenciable, no exclusiva y limitada para el uso del Sitio Web para su uso legal y personal únicamente.  Usted expresamente reconoce y acepta que dicha licencia puede ser revocada y terminada por la Compañía, en cualquier momento y por cualquier razón (incluyendo, sin limitarnos, si usted viola estos Términos o cualquier ley aplicable).
            </p>
            <p>
                Para convertirse en usuario, es obligatorio registrarse y crear una cuenta en el Sitio Web al proporcionar una dirección de correo electrónico válida, nombre de usuario, y contraseña. Tiene que asegurarse de que toda la información proporcionada es correcta, vigente, y está completa. Usted acuerda que toda la información proporcionada como parte del uso del Sitio cumple en su totalidad con nuestras Políticas de Privacidad. Para poder visualizar el Contenido, usted deberá proporcionarnos detalles de un método de pago por vía de un Proveedor de Pago. Un “proveedor de pago” deberá ser definido como cualquier tercera parte aprobada por la Compañía la cual le permite hacer pagos por su suscripción. Usted acepta que su registro es para su uso personal, y usted no venderá, rentará o transferirá su cuenta o ningún Contenido con una tercera parte. La Compañía se reserva el derecho en cualquier momento, de verificar la información proporcionada a la Compañía, al igual que el cumplimiento de estos Términos. Si nosotros no fuésemos capaces de verificar su información, nos reservaremos el derecho de suspender su cuenta.
            </p>
            <p>
                Usted es completamente responsable por cualquier y todas las actividades que ocurran en su cuenta, y usted es el responsable de mantener sus detalles de acceso confidenciales y seguros. Usted acepta a no revelar sus detalles de la cuenta a alguna otra persona o entidad, y a inmediatamente notificar a la Compañía si usted cree que alguien más ha utilizado su cuenta sin su autorización, o si su cuenta ha sido objeto de alguna otra violación de seguridad. Usted también acepta asegurarse de salir de su cuenta al final de cada sesión. Asegúrese de tener mucho cuidado al acceder a su cuenta desde una red o computadora compartida, para evitar que otras personas puedan tener acceso, visualice o memorice su contraseña y cualquier otra información personal. La compañía se reserva el derecho de deshabilitar su nombre de usuario, contraseña, u otro identificador, ya sea seleccionado por usted o proporcionado por la Compañía, en cualquier momento, y bajo la entera discreción de la Compañía, incluyendo si la compañía considera que usted ha violado cualquier disposición de estos Términos.
            </p>
            <p>
                La compañía se reserva el derecho de expedir reembolsos bajo petición, y/o cuestionar cualquier devolución de cobros indebidos, como la Compañía lo considere apropiado. Una “devolución de cobros indebidos” será definida como una exigencia por parte de un proveedor de tarjeta de crédito de la Compañía a reembolsar la pérdida en la que ha incurrido el proveedor de la tarjeta de crédito debido a que usted ha cuestionado una compra utilizando dicha tarjeta de crédito.
            </p>
            <p>
                <p>
                    <strong>e. Conducta</strong>
                </p>
                Usted reconoce y acepta que usted es el completo responsable de sus propias acciones y el uso del Sitio Web, incluyendo todas las consecuencias y repercusiones por dicho uso. Al aceptar estos términos usted afirma, garantiza y declara que no subirá, publicará, transmitirá, o hará nada que sea ilegal o fuera de la ley, acosar, amenazar, algún acto perjudicial, tortuoso, abusivo, difamatorio, obsceno, calumnioso, o la invasión de la privacidad de alguna persona. Usted también estará de acuerdo en no compartir, publicar, transmitir, o no pondrá  a disposición en ninguna de sus formas, programas que contengan virus, o ningún otro código de computadora, archivos o programas diseñados para destruir, interrumpir, limitar la funcionalidad de, el monitor, o persistentemente residir en o algún programa de computación  o hardware de equipo de telecomunicaciones.
            </p>
            <p>
                Usted expresamente reconoce y acepta que el Sitio Web deberá tener el derecho de determinar a su entera y libre discreción, que acción deberá tomarse en caso de algún descubrimiento o reporte de violación de los Términos aquí contenidos. En caso que la Compañía determine, a su entera discreción, cuando usted haya violado dichos Términos, la Compañía podría suspender o terminar su membresía.
            </p>
            <p>
                <p>
                    <strong>f. Desactivación de Cuentas</strong>
                </p>

                En caso de que usted desee desactivar su membresía, entonces deberá hacerlo en la sección de su cuenta. En caso de que usted desee desactivar su cuenta, dicha desactivación se llevará a cabo tan pronto como nos sea razonablemente posible. Tras la desactivación, usted no tendrá acceso al Contenido del Sitio Web. Cualquier suscripción será borrada y no podrá ser renovada subsecuentemente.
            </p>
            <p>
                <p>
                    <strong>g. Propiedad Intelectual </strong>
                </p>
                El Sitio Web y sus contenidos en totalidad, características, y funcionalidad (incluyendo, más no limitando a toda su información, programas, textos, demostraciones, imágenes, videos, audios, y el diseño, selección y arreglos del mismo), son propiedad de la Compañía, los licenciatarios de la Compañía y los creadores del Contenido, u otros proveedores de dicho material, y son protegidos por derechos de autor, marcas registradas, patentes, secreto industrial, y cualquier otra propiedad intelectual o derechos legales patentados.
            </p>
            <p>
                Usted tiene prohibido el reproducir, distribuir, modificar, crear trabajos derivados de, exhibir públicamente, representar públicamente, re publicar, descargar, enviar o transmitir cualquier Contenido del Sitio Web, a menos que se exprese de otra forma en el Sitio Web. Todos los derechos no expresamente cedidos en estos Términos son reservados por la Compañía. Cualquier uso del Sitio Web no permitido expresamente por estos Términos es una violación de dichos Términos y pudieran violar los derechos de autor, marca registrada y otras leyes.
            </p>
            <p>
                El nombre de la compañía, nombre comercial, logo y todos los productos relacionados así como los nombres de los servicios, diseños, y slogans son marcas registradas de la Compañía y/o los afiliados o licenciadores de la Compañía. Usted tiene prohibido utilizar estas marcas registradas sin el previo consentimiento por escrito de la Compañía o de él o los propietarios de los derechos. Cualquiera otros nombres, logos, nombres de productos o servicios, diseños, y slogans en el Sitio Web son marcas registradas de sus respectivos dueños.
            </p>
            <p>
                <p>
                    <strong>
                        h. Exclusión de Garantías; Limitación de Responsabilidades
                    </strong>
                </p>

                <p>Al utilizar el Sitio Web, usted reconoce y acepta, lo siguiente: </p>
                <ul className="terms-list">
                    <li>
                        En la mayor medida de lo permitido por ley, la Compañía desconoce cualquiera y todas las garantías, expresas o implicadas, con respecto a la precisión, utilidad, completitud, o confiabilidad del Sitio Web, o los resultados del uso de dicho Sitio Web. La compañía desconoce toda responsabilidad surgida de la confianza depositada en dichos materiales por parte de usted o de cualquier otro visitante del Sitio Web, o por cualquier persona quien pudiera estar informada de cualquiera de estos contenidos. La compañía tampoco garantiza que el Sitio Web y todos sus servicios y características estarán disponibles en algún momento o locación en particular; que el Sitio Web y todos sus servicios y características estarán aseguradas, ininterrumpidas, y libres de errores; que ningún defecto o error será corregido; o que el Sitio Web y todos sus servicios y características estarán libres de virus otros componentes dañinos. Usted es responsable de implementar suficientes procedimientos y controles para satisfacer sus requerimientos particulares en lo referente a la protección por antivirus y la precisión de la entrada y salida de información, y de mantener los medios externos al Sitio Web para cualquier reconstrucción de cualquier información perdida.
                    </li>
                    <li>
                        En la mayor medida de lo previsto por ley, la Compañía no será responsable por ninguna pérdida o daño causado por algún ataque distribuido de negación de servicio, virus, u otro material tecnológicamente dañino que pueda infectar el equipo de su dispositivo, programas de su dispositivo, información, o cualquier otra material patentado, derivado de su utilización del Sitio Web o cualquier otro servicio o artículos obtenidos a través del Sitio Web o de la descarga de cualquier material publicado en el mismo, o cualquier otro sitio web ligado al Sitio Web.
                    </li>
                    <li>
                        El uso del Sitio Web, sus servicios y características serán a su entero y completo riesgo.
                    </li>
                    <li>
                        EL SITIO WEB Y SU CONTENIDO SON PROVISTOS POR LA COMPAÑÍA EN UNA BASE “TAL COMO ESTÁ” Y ESTÁ DISPONIBLE, SIN NINGUNA GARANTÍA O REPRESENTACIÓN DE NINGÚN TIPO, TANTO EXPRESO, IMPLÍCITO, REGLAMENTARIO O  DE OTRA MANERA, EN LA MAYOR MEDIDA DE LO PERMITIDO POR LAS LEYES APLICABLES, LA COMPAÑÍA EXPRESAMENTE DESCONOCE CUALQUIER Y TODAS LAS GARANTÍAS, EXPRESAS O IMPLÍCITAS, INCLUYENDO PERO NO LIMITÁNDOSE A GARANTÍAS DE COMERCIABILIDAD, PRECISIÓN, COMPATIBILIDAD PARA ALGÚN PROPÓSITO EN PARTICULAR, LAS CONDICIONES IMPLÍCITAS Y NO VIOLATORIAS DE SATISFACCIÓN DE CALIDAD Y ACEPTACIÓN ASÍ COMO ALGUNA ANALOGÍA JURISDICCIONAL LOCAL ARRIBA DESCRITA Y OTRAS GARANTÍAS IMPLÍCITAS O ESTATUTARIAS, NOSOTROS NO HACEMOS NINGUNA DECLARACIÓN DE QUE EL SITIO WEB CUMPLIRÁ CON SUS EXIGENCIAS.
                    </li>
                    <li>
                        EN LA MAYOR MEDIDA DE LO PERMITIDO POR LAS LEYES APLICABLES, EN NINGÚN EVENTO LA COMPAÑÍA O SUS DIRECTORES, OFICIALES, EMPLEADOS, AFILIADOS, AGENTES, DISTRIBUIDORES, ABOGADOS O CONTRATISTAS (COLECTIVAMENTE, LAS “PARTES DE LA COMPAÑÍA” EN LOS SUCESIVO) SERÁN RESPONSABLES POR ALGÚN DAÑO COMPENSATORIO, INDIRECTO, INCIDENTAL, CONSECUENTE O ESPECIAL) PÉRDIDA DE INFORMACIÓN, INGRESO O GANANCIA, PÉRDIDA DE O DAÑO A LA PROPIEDAD, O CUALQUIER DEMANDA DE USTED U OTRA TERCERA PARTE DE CUALQUIER MANERA QUE SEA CON RESPECTO AL SITIO WEB INDEPENDIENTEMENTE DE LA TEORÍA LEGAL EN LA CUAL LA DEMANDA ESTÉ BASADA, SIN IMPORTAR SI ESTÁ BASADA EN EL CONTRATO, AGRAVIO, NEGLIGENCIA, GARANTÍA, U OTRA MANERA, INCLUSO SI NOSOTROS HEMOS SIDO ADVERTIDOS DE LA POSIBILIDAD DE ESTOS DAÑOS. EN LA MAYOR MEDIDA DE LO PERMITIDO POR LAS LEYES APLICABLES, LA COMPAÑÍA Y LAS PARTES DE LA COMPAÑÍA NO DEBERÁN SER RESPONSABLES NINGUNA PERDIDA O DAÑOS EN ABSOLUTO COMO RESULTADO DE EVENTOS MÁS ALLÁ DE NUESTRO CONTROL RAZONABLE, INCLUYENDO PERO NO LIMITÁNDOSE A, FALLAS EN EL INTERNET O CUALQUIER VIRUS, RETRASO O INTERRUPCIÓN EN LAS OPERACIONES O SERVICIOS O FALLAS DE DESEMPEÑO, YA SEAN O NO RESULTADO DE UN ACTO DE LA NATURALEZA, FALLAS EN LA COMUNICACIÓN, ROBO U OTRA.  EN LA MAYOR MEDIDA DE LO PERMITIDO POR LAS LEYES APLICABLES, EN NINGÚN EVENTO LA RESPONSABILIDAD MÁXIMA AGREGADA DE LA COMPAÑÍA Y LAS TERCERAS PARTES (EN CONJUNTO) COMO CONSECUENCIA DE O EN LO RELACIONADO A ESTOS TÉRMINOS O SU USO DEL SITIO WEB DEBERÁN EXCEDER DE (I) LA CANTIDAD PAGADA PREVIAMENTE, SI HUBIESE ALGUNA, DE USTED HACIA NOSOTROS (II) O DE 100 DLLS. AMERICANOS. LAS LIMITACIONES ESTABLECIDAS EN ESTOS TÉRMINOS NO LIMITARÁN O EXCLUIRÁN LA RESPONSABILIDAD POR NUESTRA NEGLIGENCIA GRAVE, FRAUDE, O UNA MALA CONDUCTA INTENCIONAL, MALICIOSA, O IMPRUDENTE, O POR ALGUNA LESIÓN PERSONAL O DAÑO DE PROPIEDAD CAUSADA POR SU USO DEL SITIO WEB.
                    </li>
                </ul>
            </p>
            <p>
                <strong>h. Indemnización: </strong>
                Al utilizar el Sitio Web, usted acepta indemnizar y contener los daños que la Compañía y nuestros empleados, agentes, representantes, sucesores, abogados, y encargados de y en contra cualquier y toda queja, demandas, causas, acciones, procedimientos, juicios, órdenes, daños, responsabilidades, pérdidas, costos y gastos (incluyendo, sin limitarse, a los honorarios razonables de los abogados y costos legales) como consecuencia de o relacionados con los servicios del Sitio Web, el Contenido, la violación de estos Términos o cualquier ley aplicable.
            </p>
            <p>
                <strong>i. Ley y Jurisdicción: </strong>
                En caso de que una disputa surja con relación a estos Términos, el Sitio Web, o el Contenido a expensas que se indique lo contrario, una corte con jurisdicción competente con sede en el Estado de Arizona, en el Condado de Maricopa deberá tener la jurisdicción exclusiva. Cualquier disputa relacionada con estos Términos, el Sitio Web, o el Contenido deberá ser analizada e interpretada bajo las leyes de Arizona y los estatutos federales aplicables
            </p>
            <p>
                <p><strong>j. Renuncia e Invalidez Parcial: </strong></p>

                Ninguna renuncia de algún término o condición expuesta en estos Términos deberá ser considerada a futuro o una como continuación de dicho término o condición o una renuncia de cualquier otro término o condición, y cualquier falla en aseverar un derecho o disposición bajo estos Términos tampoco deberá constituir una renuncia de dicho derecho o disposición. Si alguna disposición de estos Términos es llevada a la corte o cualquier otro tribunal de jurisdicción competente para ser invalidada, ilegal o no ejecutable por cualquier razón, dicha disposición deberá ser eliminada o limitada al mínimo alcance para que las disposiciones restantes de los Términos continúen en total vigor y efecto.
            </p>
            <p>
                <strong>III. Notificaciones y Contacto: </strong>
            </p>
            <p>
                Si usted tiene alguna pregunta, comentario, queja o preocupación en lo concerniente al Sitio Web, favor de contactar a nuestro equipo de apoyo en  <a href="mailto:legal@teampeach.app" className="text-lighten-4 link-text" style={{ color: color.secondary }} >legal@teampeach.app</a>
            </p>
        </div>
    );
};
export default TermsSpanish;
