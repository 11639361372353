import React, { Component } from 'react';
import MediaBoxElement from "./MediaBoxElement";
import axios from "axios";
import './style.css';
import Promise from "bluebird";
import Modal from '../Modal';
import {Link} from "react-router-dom";
import images from '../Images';
import AddImageIcon from '../Svg/AddImageIcon';
import LeftArrowIcon from '../Svg/LeftArrowIcon';
import MediaViewer from '../MediaViewer';
import UploadFiles from "../UploadFiles";
import toolbox from "../Toolbox/Toolbox";
import Tabs from "../Tabs";

class MediaBox extends Component{
    constructor(props) {
        super(props);
        this.state = {
            mediaBox : [],
            filesUploading : 0,
            files : [],
            showModalDelete : false,
            mediaEditing : null,
            mediaIndexEditing : null,
            openViewer : false,
            mediaOpened : [],
            defaultOpen : 0,
            offset: 0,
            option: 1,
            options: [
                {
                    option: 1,
                    name: props.dictionary.newContent
                },
                {
                    option: 2,
                    name: props.dictionary.usedContent
                }
            ],
            loading: false,
            blockLoad: false,
            fakeRender: false,
            intervalEncoding: null,
            encodingFilesId: [],
        };
        this.inputFileRef = React.createRef();
    }

    componentDidMount() {
        this.getMediaBox(true);
        this.createIntervalEncoding();
    }

    componentWillUnmount() {
        if(this.intervalEncoding) {
            clearInterval(this.intervalEncoding);
        }
    }

    openViewer = async(e, media, index) => {
        let sidenav= document.getElementsByClassName("styles_content__1Phom"); //put sidenav and notifymodal behind
        if(sidenav!== null && sidenav !== undefined){
            sidenav[0].style.zIndex = 0;
        }
        await this.setState({openViewer: true, mediaOpened : [media], defaultOpen : index});
    }
    closeViewer = async () => {
        let sidenav= document.getElementsByClassName("styles_content__1Phom");
        if(sidenav!== null && sidenav !== undefined){
            sidenav[0].style.zIndex = 1;
        }
        this.setState({openViewer: false, mediaOpened : []});
    }

    openDeleteMediaBox = (media, index, fakeRender) => {
        this.setState({showModalDelete : true, mediaEditing : media, mediaIndexEditing : index, fakeRender})
    }

    closeDeleteMediaBox = () => {
        this.setState({showModalDelete : false, mediaEditing : null, mediaIndexEditing : null, fakeRender: false})
    }

    playVideo = async (e) => {
        let video = e.target;
        if(video.paused) {
            video.play();
            video.nextSibling.classList.add("playing");
        }
        else{
            video.pause();
            video.nextSibling.classList.remove("playing");
        }
    }

    fileChangedHandler = async (e) => {
        const files = e.target.files;
        this.setState({filesUploading: files.length})
        let currFiles = [...this.state.files];
        await new Promise.map(files, async (file, i) => {
            let typeFormat = file ? images.validateFormat(file.type) : '';
            if (typeFormat === 'video' || typeFormat === 'image') {
                if (file.type.match('image/jpeg.*')) {
                    let blob = await images.rotateImage(file, i);
                    currFiles.push({
                        id : null,
                        image : null,
                        video : null,
                        file: blob,
                        progress : 0,
                        uploading : false,
                        finished : false,
                        error : false,
                        typeFormat : typeFormat
                    })
                } else {
                    currFiles.push({
                        id : null,
                        image : null,
                        video : null,
                        file: file,
                        progress : 0,
                        uploading : false,
                        finished : false,
                        error : false,
                        typeFormat : typeFormat
                    })
                }
            }
        }).then( async () => {
            this.setState({
                files : currFiles
            })
            if(currFiles.length > 0){
                await this.uploadStorageImages(currFiles);
            }
            return true;
        })
    }

    uploadStorageImages = async (currFiles) => {
        return await new Promise.map(currFiles, async (file, index) => {
            if(!file.uploading && !file.finished && !file.error)
            {
                file["uploading"] = true;
                this.setState({files : currFiles});
                let options = {
                    index,
                    type: "mediabox"
                };

                let resp = await new Promise( async (resolve) => {
                    let result = await UploadFiles.uploadFiles(file.file, options, this.axiosMedia);
                    setTimeout(_=> resolve(result),800);
                });
                // state files could change when is waiting on the promise
                let storageFiles = [...this.state.files];
                if (storageFiles.length) {
                    let indexFile;
                    storageFiles.find((item,index)=>{
                        if (!item.id && item.file === file.file) {
                            indexFile = index;
                            return true;
                        }
                    });
                    indexFile = indexFile || index;
                    if(resp){
                        if(file.typeFormat === 'image'){
                            storageFiles[indexFile]['image'] = resp.image;
                            storageFiles[indexFile]['id']=resp.stored;
                            storageFiles[indexFile]['finished']=1;
                        }
                        else if(file.typeFormat === 'video'){
                            let encodingFilesId = [...this.state.encodingFilesId];
                            encodingFilesId.push(resp.stored);
                            this.setState({encodingFilesId});
                            if (!this.state.intervalEncoding) this.createIntervalEncoding();
                            storageFiles[indexFile]['video'] = resp.video;
                            storageFiles[indexFile]['image'] = resp.screenshot;
                            storageFiles[indexFile]['id']=resp.stored;
                            // storageImages[indexFile]['finished']=1;
                            storageFiles[indexFile]['finished']=0;
                        }
                    }
                    else{
                        storageFiles[indexFile]['error']=1;
                    }
                    this.setState({files: storageFiles});
                }
                return file;
            }
        }).then(() => {
            return true;
        });
    }

    createIntervalEncoding = () => {
        let intervalEncoding = setInterval(async _ => {
            // verify encoding process
            const mediaBoxProcessing = await axios.get("/api/v2/mediabox/get/processing",{
                params :{
                    idMediabox: this.state.encodingFilesId
                }
            })
              .then(resp=>resp.data);
            let storageImages = [...this.state.files];
            if(mediaBoxProcessing.processing && !mediaBoxProcessing.processing.length){
                // Si no hay ninguno en modo procesando marcarlos como finalizados
                clearInterval(intervalEncoding);
                // limpia datos
                for (let i = 0; i < storageImages.length; i++) {
                    storageImages[i].finished = 1;
                }
                this.setState({files: storageImages, intervalEncoding: null, encodingFilesId: []});
                return true;
            } else if (storageImages.length && mediaBoxProcessing && mediaBoxProcessing.processed && mediaBoxProcessing.processed.length) {
                // set files processed as finished
                for (let j = 0; j < storageImages.length; j++){
                    for (let i=0; i < mediaBoxProcessing.processed.length; i++) {
                        if (storageImages[j].id === mediaBoxProcessing.processed[i]) {
                            storageImages[j].finished = 1;
                        }
                    }
                }
                // if have a processed then we can delete from the request ids file
                this.setState({files: storageImages, encodingFilesId: mediaBoxProcessing.processing});
                return false;
            }
        }, 5000);
        this.setState({intervalEncoding});
    }

    axiosMedia = (url, file, option) => {
        try {
            return axios.put(url, file, {
                onUploadProgress: progressEvent => {
                    let storageImages = [...this.state.files];
                    if (storageImages.length){
                        let progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
                        storageImages[option.index]["progress"] = progress;
                        this.setState({ files: storageImages });
                    }
                },
                headers: {
                    'Content-Type': file.type
                }

            })
                .then(async (resp) => {
                    if (resp) {
                        this.setState({ uploading: false })
                        return true;
                    }
                })
                .catch((err) => {
                    return false;
                });
        }
        catch (e) { }
    }

    trackScrolling = (e) => {
        const wrappedElement = document.querySelector('.main-container');
        if (wrappedElement && toolbox.isBottom(wrappedElement)) {
            this.getMediaBox(false);
        }
    }

    /**
    *
    * @param boolean  noSentMedias=true, get the media files aviables to send
    *                 noSentMedias=false, get all the media with a offset
    */
    getMediaBox = async (noSentMedias) => {
        if (this.state.loading || this.state.blockLoad) return true;
        this.setState({loading: true});
        let sentMedias = { noSentMedias };
        let offset = this.state.offset;

        if(!noSentMedias){
            sentMedias.offset = offset;
        }

        await axios.get('/api/v2/stored/media', {
            params: sentMedias
        }).then(async (resp) => {
            let mediaBox = resp.data;
            let mediaBoxRender = [];
            if(mediaBox && mediaBox.length){
                // get mediabox processing
                for (let i=0; i < mediaBox.length; i++) {
                    const item = mediaBox[i];
                    if (!item.error && !item.finished) {
                        item.progress=100;
                        let encodingFilesId = [...this.state.encodingFilesId];
                        let storageFiles = [...this.state.files];
                        storageFiles.push(item);
                        encodingFilesId.push(item.id);
                        this.setState({encodingFilesId, files: storageFiles});
                    } else mediaBoxRender.push(item);
                }
                if (!noSentMedias) {
                    if (offset) {
                        let mediaBoxCopy = [...this.state.mediaBox];
                        mediaBoxRender = mediaBoxCopy.concat(mediaBox);
                    }
                    this.setState({ offset: offset + 17 });
                }
                this.setState({ mediaBox: mediaBoxRender });
            }
            else{
                this.setState({ blockLoad: true });
            }

        }).catch(err => {
            toolbox.sendError(err, 5);
        });

        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);

    }

    delete = async () => {
        let media = this.state.mediaEditing;
        let index = this.state.mediaIndexEditing;
        await axios.post('/api/v2/delete/mediabox', {
            mediaBox : media
        }).then( resp => {
            if(resp.data.Success){
                let media;
                if (!this.state.fakeRender) {
                    media = this.state.mediaBox;
                } else {
                    media = this.state.files;
                }
                media.splice(index, 1);
                this.closeDeleteMediaBox();
            }
        })
    }

    drawModalDelete() {
        let dictionary = this.props.dictionary;
        let config = this.props.config;
        return(
            <Modal
                id="modalPost"
                open={this.state.showModalDelete}
                closeAction={this.closeDeleteMediaBox}
                head={<h4>{dictionary.delete}</h4>}
                config={this.props.config}
                footer={
                    <div className="actions">
                        <button
                            style={{
                                backgroundColor: config.color.secondary,
                                color: config.color.secondaryText
                            }}
                            onClick={(e) => this.delete(e)}
                        >
                            {dictionary.delete}
                        </button>
                    </div>
                }
                height={'35%'}
                top={'65%'}
                middle={true}
            >

            </Modal>
        )
    }

    drawMediaBox(media, fakeRender=false) {
        let config = this.props.config;
        let dictionary = this.props.dictionary;
        if ((media && !media.length) || !media) return null;
        return media.map( (el, index) => {
            el.fakeRender = fakeRender;
            return(
                <MediaBoxElement
                    media={el}
                    index={index}
                    key={index}
                    config={config}
                    dictionary={dictionary}
                    playVideo={this.playVideo}
                    delete={this.openDeleteMediaBox}
                    openViewer={this.openViewer}
                />
            )
        })
    }

    drawCanvas(size){
        let canvas = [];
        for(let i = 0; i < size; i++){
            canvas.push(<canvas key={i} id={"canvasToRotate" + i} className="hide"></canvas>)
        }
        return canvas;
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    changeTab = async(tab) => {

        if (this.state.loading) return false;

        await this.setStateAsync({ option: tab, offset: 0, blockLoad: false, mediaBox: [], files: [], encodingFilesId: [] });

        if(tab===1){
            this.getMediaBox(true);
            document.removeEventListener('scroll', this.trackScrolling)
        }
        else if(tab===2){
            this.getMediaBox(false);
            document.addEventListener("scroll", this.trackScrolling);
        }

    }


    render(){
        let config = this.props.config;
        let media = this.state.mediaBox;
        let toUpload = [...this.state.files].reverse();
        return(
            <div className="mediabox">
                <Link to='/'
                    className="back-icon"
                >
                    <LeftArrowIcon
                        color={config.color.principalText}
                        width={"29px"}
                        height={"29px"}
                    />
                </Link>
                <h5 style={{color : config.color.principalText}}>Media Box</h5>

                <Tabs
                    tabs={this.state.options}
                    changeTab={this.changeTab}
                    config={this.props.config}
                    tab={this.state.option}
                />

                {this.drawCanvas(this.state.filesUploading)}
                {this.drawModalDelete()}
                {this.state.option === 1 &&
                    <div className="mediabox-element new-mediabox-element" style={{backgroundColor : config.color.navbar}}>
                        {this.props.isMobile ?
                          <input
                            type="file"
                            name="fileStorage"
                            id="fileStorage"
                            onChange={(e) => {
                                this.fileChangedHandler(e)
                            }}
                            ref={this.inputFileRef}
                            accept="image/*, video/*"
                          />
                          :
                          <input
                            type="file"
                            name="fileStorage"
                            id="fileStorage"
                            multiple
                            onChange={(e) => {
                                this.fileChangedHandler(e)
                            }}
                            accept="image/*, video/*"
                          />
                        }
                        <span className="add-image">
                            <AddImageIcon
                              color={config.color.principalText}
                              lineColor={config.color.navbar}
                              width={"60px"}
                              height={"60px"}
                              className="add-icon"
                            />
                        </span>
                    </div>
                }

                {this.drawMediaBox(toUpload, true)}
                {this.drawMediaBox(media)}
                <MediaViewer
                    open={this.state.openViewer}
                    media={this.state.mediaOpened}
                    closeAction={this.closeViewer}
                    config={this.props.config}
                    defaultOpen={this.state.defaultOpen}
                />
            </div>
        )
    }
}

export default MediaBox;
