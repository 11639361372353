import React from 'react';

const CameraIcon= props=>{
    const {title, description} = props;
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            fill="none"
            viewBox="0 0 20 16"
            className={props.className ? props.className : null}
            role="img"
        >
            <title>{title || ""}</title>
            <desc>{description || ""}</desc>
            <path
                fill={props.color}
                fillRule="evenodd"
                d="M7.17 6.17C7.887 5.456 8.895 5 10 5s2.114.455 2.83 1.17C13.544 6.887 14 7.895 14 9c0 1.105-.44 2.098-1.17 2.83-.716.715-1.724 1.17-2.83 1.17-1.105 0-2.098-.44-2.83-1.17C6.456 11.113 6 10.105 6 9c0-1.105.44-2.098 1.17-2.83z"
                clipRule="evenodd"
            />
            <path
                fill={props.color}
                fillRule="evenodd"
                d="M19.335 2.452c-.4-.418-.951-.665-1.578-.665H14.6V1.75c0-.475-.19-.932-.514-1.236C13.764.19 13.328 0 12.853 0H7.148c-.494 0-.931.19-1.254.513-.324.324-.514.76-.514 1.236v.038H2.243c-.627 0-1.178.247-1.578.665-.399.4-.665.97-.665 1.578v9.468c0 .628.247 1.179.665 1.578.4.4.97.665 1.578.665h15.514c.627 0 1.178-.247 1.578-.665.399-.4.665-.97.665-1.578V4.03c0-.627-.247-1.178-.665-1.578zM6.463 5.463C7.358 4.57 8.618 4 10 4c1.382 0 2.642.57 3.537 1.463C14.43 6.358 15 7.618 15 9c0 1.382-.55 2.622-1.463 3.537C12.642 13.43 11.382 14 10 14c-1.382 0-2.622-.55-3.537-1.463C5.57 11.642 5 10.382 5 9c0-1.382.55-2.622 1.463-3.537zM17.7 5c0 .514-.417.932-.931.932-.515 0-.932-.418-.932-.932 0-.515.417-.932.931-.932.515 0 .932.417.932.932z"
                clipRule="evenodd"
            />
        </svg>
    )
}
export default CameraIcon;
