import React from 'react';

const StreamIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 24 24"
        >
            <path 
                fill={color}
                d="M8.143 18c0 .115.045.225.126.306.08.08.189.126.302.126h6.858c.113 0 .222-.045.303-.126.08-.081.125-.191.125-.306v-1.297H8.143V18zM22.665 2.865h-5.95v.865h3.428c.113 0 .223.045.303.126.08.081.125.191.125.306V16.27c0 .115-.045.225-.125.306-.08.081-.19.127-.303.127h-3.429v.865h5.95c.355 0 .694-.143.944-.396.25-.252.392-.595.392-.952V4.212c0-.357-.141-.7-.392-.952-.25-.253-.59-.395-.943-.395zm-.38 8.216c-.169 0-.334-.05-.476-.146-.14-.095-.25-.23-.315-.388s-.082-.332-.049-.5c.033-.167.115-.321.235-.442.12-.121.272-.204.439-.237.166-.033.338-.016.495.05.156.065.29.176.384.318.095.142.145.31.145.48 0 .23-.09.45-.252.612-.16.162-.378.253-.605.253z"
            />
            <path 
                fill={color}
                d="M19.714 4.595H3.43v11.243h16.285V4.595zM6.43 14.973H4.714c-.113 0-.222-.046-.303-.127-.08-.08-.125-.19-.125-.306v-1.73c0-.114.045-.224.125-.305.08-.081.19-.127.303-.127.114 0 .223.046.303.127.08.081.126.191.126.306v1.297h1.286c.113 0 .222.046.303.127.08.08.125.19.125.306 0 .114-.045.224-.125.305-.08.081-.19.127-.303.127zm0-8.649H5.143v1.298c0 .114-.045.224-.126.305-.08.081-.189.127-.303.127-.113 0-.222-.046-.303-.127-.08-.08-.125-.19-.125-.305v-1.73c0-.115.045-.225.125-.306.08-.081.19-.127.303-.127H6.43c.113 0 .222.046.303.127.08.081.125.191.125.306 0 .115-.045.225-.125.306-.08.08-.19.126-.303.126zM12 12.378c-.424 0-.838-.126-1.19-.364-.353-.238-.628-.575-.79-.97-.162-.395-.204-.83-.122-1.25.083-.419.287-.804.587-1.107.3-.302.681-.508 1.097-.591.416-.084.846-.041 1.238.123.392.163.726.44.962.796.235.356.36.774.36 1.201 0 .574-.226 1.123-.627 1.528-.402.406-.947.634-1.515.634zm6.857 2.162c0 .115-.045.225-.125.306-.08.081-.19.127-.303.127h-1.715c-.113 0-.222-.046-.303-.127-.08-.08-.125-.19-.125-.306 0-.114.045-.224.125-.305.08-.081.19-.127.303-.127H18v-1.297c0-.115.045-.225.125-.306.08-.081.19-.127.304-.127.113 0 .222.046.303.127.08.081.125.191.125.306v1.73zm0-6.918c0 .114-.045.224-.125.305-.08.081-.19.127-.303.127-.114 0-.223-.046-.303-.127-.08-.08-.126-.19-.126-.305V6.324h-1.286c-.113 0-.222-.045-.303-.126-.08-.081-.125-.191-.125-.306 0-.115.045-.225.125-.306.08-.081.19-.127.303-.127h1.715c.113 0 .222.046.303.127.08.081.125.191.125.306v1.73z"/>
            <path 
                fill={color}
                d="M12 8.919c-.254 0-.503.076-.714.219-.212.142-.377.345-.474.582-.097.237-.123.498-.073.75.05.251.172.482.352.663.18.182.409.306.658.356.25.05.508.024.743-.074s.436-.265.577-.478c.141-.213.217-.464.217-.72 0-.345-.136-.674-.377-.918-.241-.243-.568-.38-.909-.38zm0 1.73c-.085 0-.168-.026-.238-.073-.07-.048-.126-.115-.158-.194-.032-.08-.04-.166-.024-.25.016-.084.057-.161.117-.222.06-.06.136-.101.22-.118.082-.017.169-.008.247.025.078.032.145.088.192.159.047.071.073.155.073.24 0 .115-.046.225-.126.306-.08.081-.19.127-.303.127zM10.714 19.297h2.572V22h-2.572v-2.703zM2.571 16.27V4.162c0-.115.046-.225.126-.306.08-.08.19-.126.303-.126h4.286v-.865h-5.95c-.355 0-.694.142-.944.395S0 3.855 0 4.212V16.22c0 .357.141.7.392.952.25.253.59.395.943.396h5.95v-.865H3c-.114 0-.223-.046-.303-.127-.08-.081-.126-.191-.126-.306zm-1.285-3.46c-.085 0-.168-.025-.238-.072-.07-.048-.126-.115-.158-.194-.033-.08-.041-.166-.025-.25.017-.084.058-.16.118-.221.06-.06.136-.102.22-.119.082-.016.168-.008.247.025.078.033.145.088.192.16.047.07.072.154.072.24 0 .114-.045.224-.125.305-.08.081-.19.127-.303.127zm.428-2.161c0 .114-.045.224-.125.305-.08.082-.19.127-.303.127-.114 0-.223-.045-.303-.127-.08-.08-.126-.19-.126-.305V8.486c0-.114.045-.224.126-.305.08-.081.19-.127.303-.127.113 0 .222.046.303.127.08.08.125.19.125.305v2.163zM15.857 2.432c0-.114-.045-.224-.125-.305-.08-.081-.19-.127-.303-.127H8.57c-.113 0-.222.046-.302.127-.08.08-.126.19-.126.305V3.73h7.714V2.432z"
            />
        </svg>
    )
}
export default StreamIcon;
