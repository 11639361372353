import React, {Component} from 'react';
import {Circle} from 'react-preloaders';
import CancelMembershipSteps from './CancelMembershipSteps';

class CancelMembership extends Component {

    render() {
        if (this.props.dictionary.length === 0 || this.props.config.length === 0)
            return <div className="center-align" style={{paddingTop: 45}}>
                <Circle background="transparent !important" color="#4285f4" className="center-align"/>
            </div>;

        return (
            <div
                id="container-support"
                className="container main-container center-align"
                style={{
                    backgroundColor: this.props.config.color.principal,
                    color: this.props.config.color.principalText
                }}
            >
                <h5>{this.props.dictionary.FAQ.q5}</h5>
                <div className="faq-steps ">
                    <CancelMembershipSteps
                        dictionary={this.props.dictionary}
                        config={this.props.config}
                        getPiCodes={this.props.getPiCodes}
                    />
                </div>
            </div>
        )
    }
}

export default CancelMembership;
