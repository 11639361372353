import React, { Component } from "react";
import MembresiasGraph from './Membresias';
import PostsGraph from './Posts';
import MasiveGraph from "./Masive";
import axios from "axios";
import cookie from "js-cookie";
import moment from "moment";
import './style.css'

class Sitio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            postsUserDays: [],
            postsUserValues: [],
            masiveSendDays: [],
            masiveValues: [],
            currentMembershipsDays : [],
            currentMembershipsValues : [],
            totalPosts : 0,
            totalMemberships: 0,
            options : {
                responsive: true,
                maintainAspectRatio: false,
                scaleShowGridLines : true,
                scaleGridLineColor : "rgba(255,255,255,.09)",
                scaleGridLineWidth : .75,
                scaleShowHorizontalLines: true,
                scaleShowVerticalLines: true,
                bezierCurve : true,
                bezierCurveTension : .19,
                pointDot : true,
                pointDotRadius : 2.5,
                pointDotStrokeWidth : 1,
                pointHitDetectionRadius : 10,
                datasetStroke : false,
                datasetStrokeWidth : 2,
                datasetFill : true,
                offsetGridLines : false,
                legend : {
                    display : false
                },
                scales : {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        }
    }

    componentDidMount() {
        this.loadAnalytics();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(
            prevProps.period.start !== this.props.period.start
            || prevProps.period.end !== this.props.period.end
        ) {
            this.loadAnalytics(this.state.onlyModel);
        }
    }

    loadAnalytics = () => {
        this.setState({
            postsUserDays: [],
            postsUserValues: [],
            masiveSendDays: [],
            masiveValues: [],
            currentMembershipsDays : [],
            currentMembershipsValues : [],
            totalPosts : 0
        });
        let start = this.props.period.start;
        let end = this.props.period.end;
        axios.get("/api/v2/analytics/model/currentday", {
                params : {
                        firstInterval: start,
                        lastInterval: end,
                        today: moment(end).isSame(moment().format("YYYY-MM-DD"))
                    }
                }
            )
            .then(async res => {
                if(!res.data || res.data === 'Server Error')
                    return false;

                this.setState({
                    currentMembershipsDays: [],
                    currentMembershipsValues: [],
                })

                const dates = res.data.map(day => {
                    return moment(day.date)
                        .utc()
                        .format("MMM D");
                });
                let priceList = [];
                let objParseMemberships = [];

                const values = res.data.map(day =>{
                    day.count.forEach(item=>{
                        if(!priceList.includes(item.price)){
                            priceList.push(item.price);
                            objParseMemberships.push({
                                price: item.price,
                                listPrice: []
                            });
                        }
                    });
                    return day.count;
                });
                const countAllPrices = priceList.length;
                res.data.forEach(day=>{
                    for(let i=0; i<countAllPrices; i++){
                        if(day.count[i] && priceList.indexOf(day.count[i].price) !== -1){
                            const findIndex = priceList.indexOf(day.count[i].price);
                            if(findIndex>-1)
                                objParseMemberships[findIndex].listPrice.push(day.count[i].total || 0);
                        }
                    }

                })


                let total = 0;
                values[values.length-1].forEach(item=>{
                    total += item.total
                });

                this.setState({
                    totalMemberships: total,
                    currentMembershipsDays: dates,
                    currentMembershipsValues: objParseMemberships
                });
                this.props.getTotalMemberships(total)
            });
        axios.get("/api/v2/analytics/model/posts", {
            params : {
                start : start,
                end: end,
            }
        }).then(res => {
            if(!res.data || res.data === 'Server Error')
                return false;

            const dates = res.data.map(day => {
                return moment(day.date).utc().format('MMM D');
            });
            const values = res.data.map(day => day.count)
            this.setState({
                postsUserDays: dates,
                postsUserValues: values
            });
        });
        axios.get('/api/v2/analytics/model/posts/total').then(res => {
            this.setState({totalPosts: res.data.total})
        });
        axios.get('/api/v2/analytics/model/messages/sales',{
            params : {
                start : start,
                end: end
            }
        }).then(res =>{
            if(!res.data)
                return false;

            if (res.data !== 'Server Error'){
                const dates = res.data.map(day => {
                    return moment(day.date).utc().format('MMM D');
                });
                const values = res.data.map(day => day.total);
                this.setState({masiveSendDays: dates, masiveValues: values})
            }
        });
    };

    render() {
        let dictionary = this.props.dictionary;
        let show = this.props.show;
        return (
            <div className={(show) ? "show activity" : "activity"}>
                <MembresiasGraph
                    totalMemberships={this.state.totalMemberships}
                    dictionary={dictionary}
                    currentMembershipsDays={this.state.currentMembershipsDays}
                    currentMembershipsValues={this.state.currentMembershipsValues}
                    options={this.state.options}
                />
                <hr/>
                <h5>
                    {dictionary.post}
                </h5>
                <PostsGraph
                    dictionary={dictionary}
                    postsUserDays={this.state.postsUserDays}
                    postsUserValues={this.state.postsUserValues}
                    totalPosts={this.state.totalPosts}
                    options={this.state.options}
                />
                <MasiveGraph
                    dictionary={dictionary}
                    masiveSendDays={this.state.masiveSendDays}
                    masiveValues={this.state.masiveValues}
                    options={this.state.options}
                />
            </div>
        );
    }
}

export default Sitio;
