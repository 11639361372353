import React, { Component } from "react";
import {Line} from "react-chartjs-2";

class MembresiasGraph extends Component {

    constructor(props) {
        super(props);
        this.state = {
            datasets: [],
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scaleShowGridLines: true,
                scaleGridLineColor: "rgba(255,255,255,.09)",
                scaleGridLineWidth: .75,
                scaleShowHorizontalLines: true,
                scaleShowVerticalLines: true,
                bezierCurve: true,
                bezierCurveTension: .19,
                pointDot: true,
                pointDotRadius: 2.5,
                pointDotStrokeWidth: 1,
                pointHitDetectionRadius: 10,
                datasetStroke: false,
                datasetStrokeWidth: 2,
                datasetFill: true,
                offsetGridLines: false,
                tooltips: {
                    mode: 'index',
                    callbacks: {
                        // Use the footer callback to display the sum of the items showing in the tooltip
                        label: function (tooltipItem, data) {
                            return tooltipItem.yLabel+" "+props.dictionary.memb
                                + " " + props.dictionary.of+ " "
                                + data.datasets[tooltipItem.datasetIndex].label;
                        },
                    }
                },
                legend: {
                    display: false
                }
            },
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.currentMembershipsValues !== prevProps.currentMembershipsValues) {
            this.datasetsRender(this.props.currentMembershipsValues);
        }
    }
    datasetsRender(currentMembershipsValues){
        const memberships = [...currentMembershipsValues];
        let datasets=[];
        let colors={
            border: ['#F3876B', '#692FE1', '#5AB6E2', '#E39DCD', '#5AE2B1', "#D1E25A", "#822C2C" ],
            pointBorder: ['#F3876B', '#692FE1', '#5AB6E2', '#E39DCD', '#5AE2B1', "#D1E25A", "#822C2C"]
        }
        // eslint-disable-next-line
        memberships.map((item, index)=>{
            datasets.push({
                label:  "$"+ item.price+" USD",
                backgroundColor     : 'rgba(243,135,107,0.1)',
                borderColor         : colors.border[index],
                fill                : false,
                borderWidth         : 1,
                pointRadius         : 4,
                pointBorderWidth    : 2.5,
                pointBorderColor    : colors.pointBorder[index],
                pointBackgroundColor: colors.border[index],
                data: item.listPrice
            });
        });
        this.setState({
            datasets: datasets
        });
    }

    render() {
        let dictionary=this.props.dictionary;
        return (
            <div>
                <h6>
                    * {dictionary.membCurActive}: {this.props.totalMemberships}
                </h6>
                <div className="activity-graph">
                    <Line
                        height={250}
                        data={{
                            labels: this.props.currentMembershipsDays,
                            datasets: this.state.datasets
                        }} options={this.state.options}/>
                </div>
                <div className="legends">
                    <label>
                        <span
                            className="dot"
                            style={{backgroundColor: "#F3876B"}}
                        ></span>
                        {dictionary.activeMemb}
                    </label>
                </div>
            </div>
        );
    }
}

export default MembresiasGraph;
