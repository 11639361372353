import React from 'react';

const SafeIcon= ({color, width, height, className = null})=>{
    return(
        <svg   
            version="1.0"
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            viewBox="0 0 512.000000 512.000000"
            className={className}
        >
            <g 
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill={color} 
                stroke="none"
            >
                <path 
                    d="M345 5106 c-84 -21 -147 -57 -211 -121 -65 -65 -105 -136 -123 -223
                    -16 -75 -16 -3709 0 -3784 36 -171 175 -310 347 -347 75 -16 4328 -16 4404 0
                    128 27 241 113 301 226 51 99 57 144 57 418 l0 245 -300 0 -300 0 0 -90 c0
                    -112 -20 -159 -80 -190 -39 -20 -55 -20 -1881 -20 l-1841 0 -40 22 c-24 14
                    -48 38 -59 60 -18 36 -19 85 -19 1568 0 1483 1 1532 19 1568 11 22 35 46 59
                    60 l40 22 1841 0 c1789 0 1843 -1 1879 -19 60 -31 82 -81 82 -190 l0 -91 300
                    0 300 0 0 245 c0 144 -5 266 -11 297 -37 172 -176 311 -347 347 -81 17 -4349
                    14 -4417 -3z"
                />
                <path 
                    d="M900 2870 l0 -1350 1660 0 1660 0 0 1350 0 1350 -1660 0 -1660 0 0
                    -1350z m2130 1036 c228 -35 419 -133 585 -301 382 -387 406 -994 55 -1413
                    -203 -242 -527 -385 -840 -369 -279 15 -504 114 -699 307 -291 290 -385 708
                    -247 1095 169 472 647 756 1146 681z m-1610 -506 c26 -13 47 -34 60 -60 19
                    -37 20 -58 20 -471 0 -428 0 -432 -22 -471 -42 -74 -126 -97 -200 -56 -79 45
                    -78 40 -78 530 1 487 0 478 76 524 48 29 92 30 144 4z"
                />
                <path 
                    d="M2765 3613 c-60 -8 -145 -34 -207 -62 -113 -52 -113 -41 10 -164
                    l109 -109 49 18 c67 24 221 24 288 0 l49 -18 110 110 110 110 -45 26 c-126 73
                    -320 110 -473 89z"
                />
                <path 
                    d="M2215 3236 c-87 -149 -118 -383 -71 -551 19 -68 63 -175 84 -203 l17
                    -22 108 108 109 109 -18 49 c-24 67 -24 221 0 288 l18 49 -110 110 -110 110
                    -27 -47z"
                />
                <path 
                    d="M3386 3171 l-108 -108 18 -49 c24 -67 24 -221 0 -288 l-18 -49 109
                    -109 c123 -123 112 -123 164 -10 50 111 63 175 63 317 0 120 -3 137 -33 225
                    -26 78 -74 180 -84 180 -1 0 -51 -49 -111 -109z"
                />
                <path 
                    d="M2798 2999 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 21 -11
                    53 -20 70 -20 50 0 107 35 130 80 11 21 20 53 20 70 0 50 -35 107 -80 130 -49
                    25 -94 25 -142 -1z"
                />
                <path 
                    d="M2566 2352 l-108 -110 43 -25 c75 -44 219 -87 316 -93 144 -10 306
                    25 419 91 l47 27 -110 110 -110 110 -49 -17 c-97 -33 -192 -30 -319 10 -16 5
                    -42 -17 -129 -103z"
                />
                <path 
                    d="M4520 3670 l0 -250 300 0 300 0 0 250 0 250 -300 0 -300 0 0 -250z"
                />
                <path 
                    d="M4520 2870 l0 -250 300 0 300 0 0 250 0 250 -300 0 -300 0 0 -250z"
                />
                <path 
                    d="M4520 2070 l0 -250 300 0 300 0 0 250 0 250 -300 0 -300 0 0 -250z"
                />
                <path 
                    d="M400 220 c0 -123 18 -169 80 -200 36 -18 58 -20 295 -20 357 0 335
                    -16 431 303 5 16 -19 17 -400 17 l-406 0 0 -100z"
                />
                <path 
                    d="M3914 303 c68 -225 83 -256 136 -283 36 -18 58 -20 295 -20 237 0
                    259 2 295 20 62 31 80 77 80 200 l0 100 -406 0 c-381 0 -405 -1 -400 -17z"
                />
            </g>
        </svg>
    )
}
export default SafeIcon;
