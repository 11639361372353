import React, {Component} from 'react';
import Section from './Section';
import './style.css'

class Sections extends Component {

    drawSections() {
        let sections = [...this.props.sections];
        if (sections) {
            sections.push({
                background: "243,105,145",
                icon: "https://storage.googleapis.com/mary-baltazar-web/public/img/Beauty-icon2.png",
                image: "https://storage.googleapis.com/mary-baltazar-web/public/img/b1CQ6V5HtTaKimNsoDdCvOIQIU8A0nkG1cVo6M61tH36XWGVfw.jpg",
                sectionName: "streaming",
                iconType: "StreamIcon"
            });
            return sections.map((section, index) => {
                return (
                    <Section
                        key={index}
                        section={section}
                        active={(this.props.active === section.sectionName)}
                        config={this.props.config}
                        changeSection={this.props.changeSection}
                        dictionary={this.props.dictionary}
                    />
                )
            });
        }
    }

    render() {
        return (
            <ul className="sections">
                {this.drawSections()}
            </ul>
        )
    }
}

export default Sections;
