import React from 'react';

const PencilIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height} 
            fill="none" 
            viewBox="0 0 15 15"
            className = {className}
        >
            <path 
                fill={color}  
                d="M9.318 2.489l3.049 3.048-7.717 7.716-3.046-3.048 7.714-7.716zm5.362-.736L13.32.394c-.525-.525-1.378-.525-1.905 0l-1.303 1.302 3.049 3.049 1.519-1.52c.407-.407.407-1.064 0-1.472zM.008 14.49c-.055.25.17.474.42.413l3.397-.823L.778 11.03l-.77 3.46z" 
                opacity="1"
            />
        </svg>
    )
}
export default PencilIcon;
