import React from 'react';

const BeautyTipsIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 24 24"
        >
            <path 
                fill={color}
                d="M20.5 9.625C18.53 8.531 17.589 6 15 6c-2 0-3 2-3 3 0-1-1-3-3-3-2.589 0-3.53 2.53-5.5 3.625C2.375 10.25 0 11 0 11c5 3 5 7 12 7s7-4 12-7c0 0-2.375-.75-3.5-1.375zM12 13c-3.198 0-7.033-1.277-9.976-1.79 1.654.15 3.315.206 4.976.165 2.998-.109 4-.375 5-.375s2.002.266 5 .375c1.66.04 3.322-.014 4.976-.165C19.033 11.723 15.198 13 12 13z" 
                opacity="1"
            />
        </svg>
    )
}
export default BeautyTipsIcon;
