import React from 'react';

const ArrowLeftIcon= props=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={props.width} 
            height={props.height}
            fill="none" 
            viewBox="0 0 15 16"
            className={props.className ? props.className : null}
        >
            <path 
                fill={props.color} 
                stroke={props.color}
                strokeWidth=".5"
                d="M7.524 1.211c.282-.281.726-.281 1.008 0 .272.273.272.726 0 .998l-5.11 5.11h9.862c.393 0 .716.313.716.706 0 .393-.323.715-.716.715H3.422l5.11 5.1c.272.283.272.737 0 1.009-.282.282-.726.282-1.008 0l-6.32-6.32c-.272-.273-.272-.726 0-.998l6.32-6.32z" 
                clipRule="evenodd"
            />
        </svg>
    )
}
export default ArrowLeftIcon;