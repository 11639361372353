import React from 'react';

const ManyImgIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 20 21"
            className={className}
        >
            <path 
                fill={color}
                d="M0 3.673C0 1.645 1.649 0 3.682 0h9.207c2.034 0 3.682 1.645 3.682 3.673v9.796c0 2.029-1.649 3.674-3.682 3.674H3.682C1.65 17.143 0 15.498 0 13.469V3.673z"
            />
            <path 
                fill={color}
                d="M2.838 18.775c.687 1.076 1.945 1.796 3.384 1.796h9.841c2.175 0 3.937-1.645 3.937-3.673V7.102c0-1.252-.67-2.357-1.695-3.02.35.55.552 1.191.552 1.877v9.796c0 2.029-1.762 3.673-3.936 3.673H5.08c-.833 0-1.606-.241-2.242-.653z"
            />
        </svg>

    )
}
export default ManyImgIcon;
