import React from 'react';

const FullscreenIcon= ({color, width, height, className = null, clickFunction})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 1200.000000 1200.000000"
            preserveAspectRatio="xMidYMid meet"
            className={className}
            onClick={clickFunction}
        >
            <g 
                transform="translate(0.000000,1200.000000) scale(0.100000,-0.100000)"
                fill={color}
                stroke="none"
            >
                <path 
                    d="M600 9600 l0 -1800 600 0 600 0 0 1200 0 1200 1200 0 1200 0 0 600 0
                    600 -1800 0 -1800 0 0 -1800z"
                />
                <path 
                    d="M7800 10800 l0 -600 1200 0 1200 0 0 -1200 0 -1200 600 0 600 0 0
                    1800 0 1800 -1800 0 -1800 0 0 -600z"
                />
                <path 
                    d="M600 2400 l0 -1800 1800 0 1800 0 0 600 0 600 -1200 0 -1200 0 0
                    1200 0 1200 -600 0 -600 0 0 -1800z"
                />
                <path 
                    d="M10200 3000 l0 -1200 -1200 0 -1200 0 0 -600 0 -600 1800 0 1800 0 0
                    1800 0 1800 -600 0 -600 0 0 -1200z"
                />
            </g>
        </svg>
    )
}
export default FullscreenIcon;
