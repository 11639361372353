import React from 'react';

const LeftArrowIcon= ({color, width, height})=>{

    return(
        <svg 
            version="1.0" 
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 512.000000 910.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g 
                transform="translate(0.000000,910.000000) scale(0.100000,-0.100000)"
                fill={color} 
                stroke="none"
            >
                <path d="M3815 8655 c-33 -9 -103 -36 -155 -62 l-95 -46 -1591 -1591 c-1158
                -1158 -1606 -1611 -1644 -1666 -176 -254 -260 -565 -231 -853 25 -242 96 -436
                229 -627 28 -41 618 -639 1621 -1644 1212 -1214 1593 -1589 1649 -1626 209
                -139 486 -152 713 -33 238 124 379 359 379 630 0 153 -37 274 -123 403 -16 25
                -696 712 -1511 1527 l-1481 1483 1496 1497 c1478 1480 1496 1499 1538 1580
                140 275 99 593 -103 811 -88 94 -187 160 -297 197 -71 24 -103 29 -209 31 -87
                3 -143 -1 -185 -11z"/>
            </g>
        </svg>

    )
}
export default LeftArrowIcon;
