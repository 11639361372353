import React from 'react';
import "./Emojis.css";
const Emojis = props =>{
const arreglo= 
    [       
      'Hola',
      '😂',
      '😮',
      '😍',
      '👋',
      '👍',
      '💔',
      '❤️',
    ]

const handleClick = (e, emoji)=>{
    e.preventDefault();
    props.addEmoji(emoji);
}


const render = () =>{
   return arreglo.map((res,i)=>{
        return(<span onClick={e => {handleClick(e,res)}} key={i} className="span__emojis">
            {res}
        </span>)
    });
}

return (<React.Fragment>
        {props.show &&
            <div className="render__emojis"> 
                {render()}
            </div>
        }
        </React.Fragment>
)

}

export default Emojis;