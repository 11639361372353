import React from 'react';

const SexSchoolIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            viewBox="0 0 24 24"
        >
            <path 
                fill={color}
                d="M6.93,8.69c-0.22-0.14-0.49-0.18-0.74-0.1c-1.31,0.42-5.95,2.4-6.18,7.5c-0.27,6.08,4.06,7.84,7.03,7.84
                c2.44,0,4.51-1.24,5.18-1.69c0.13-0.09,0.31-0.08,0.43,0.01c0.58,0.44,2.38,1.54,5.47,1c3.83-0.68,6.44-4.19,5.77-7.93
                s-4.24-5.77-6.62-7.57c-2.38-1.8-4-3.37-2.71-7.06c0-0.01,0.01-0.02,0.01-0.04c0.01-0.01,0.01-0.03,0.02-0.04
                c0.21-0.42-0.38-0.77-0.64-0.38c-0.71,1.05-1.73,3.68,0.8,6.76c0.67,0.81,2.01,1.88,4.5,3.68c5.23,3.78,2.19,8.59,1.58,9.28
                c-0.66,0.75-3.7,3.18-6.7,1.04c-0.05-0.04-0.6-0.63-0.6-1.68c0-0.77-0.41-0.45-0.82,0.18C11.76,20.95,8.9,23,6.1,22.26
                c-3.34-0.88-4.49-2.59-4.6-5.54c-0.17-4.56,3.53-6.7,4.6-6.98c0.37-0.1,0.67-0.18,0.87-0.24C7.11,9.45,7.24,8.89,6.93,8.69z"
            />
        </svg>

    )
}
export default SexSchoolIcon;
