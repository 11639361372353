import React, {Component} from 'react';
import axios from "axios";
import Modal from "../Modal";
import {Oval, Preloader} from 'react-preloader-icon';
import "./getCoins.css";
import CoinsIcon from '../Svg/CoinsIcon';

class GetCoins extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            option: 0,
            catalogCoin: [],
            baseUri: window.location.hostname === "localhost"
                ? 'http://localhost:5000'
                : 'https://cms.socialtechapps.com',
            showConfirmBuy: false,
            processingBuy: false,
            isBuyConfirm: null
        }

        this.handleCoinChange = this.handleCoinChange.bind(this);
        this.buyCoins = this.buyCoins.bind(this);
    }

    componentDidMount() {
        this.loadCatalog();
    }

    handleCoinChange(index) {
        const option = index;
        this.setState({option});
    }

    loadCatalog() {
        axios.get('/api/v2/extra/charge/catalog/', {
            params:{
                pathName : window.location.pathname
            }
        })
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        catalogCoin: res.data
                    });
                }
            }).catch( err => {

            });
    }

    buyCoins = (e) => {
        e.preventDefault();
        const {option, catalogCoin} = this.state;
        if (catalogCoin[option]) {
            const {url, paymentProcessor} = catalogCoin[option];
            if (paymentProcessor === 1) {
                if (url)
                    window.location.href = url;
            } else {
                const {k} = catalogCoin[option];
                this.setState({processingBuy: true});
                axios.post('/api/v2/ccbill/purchase-coin', {k})
                    .then(res => {
                        let isBuyConfirm = false;
                        if (res.status === 200)
                            isBuyConfirm = res.data;

                        this.setState({processingBuy: false, isBuyConfirm});
                    });
            }
        }
    }

    drawOptions() {
        let dictionary = this.props.dictionary;
        let catalog = [...this.state.catalogCoin];
        return catalog.map((item, index) => {
            let style = (this.state.option === index) ? 
                        {backgroundColor: this.props.config.color.third, 
                        borderColor: this.props.config.color.secondary} : 
                        {borderColor: this.props.config.color.secondary}
            return (
                <div key={index}
                    onClick={() => this.handleCoinChange(index)}
                    className="option" 
                    style={style}
                >
                    <div className="option-coins">
                        <div className="coins-icon">
                            <CoinsIcon
                                width={'18px'} 
                                height={'18px'} 
                                color={this.props.config.color.secondary}
                                colorPrincipal={this.props.config.color.principal}
                            />
                        </div>
                        {item.coins}{" "}{dictionary.coins}
                    </div>
                    <div className="option-price">
                        {item.price}{"  "}
                        {item.currency}
                    </div>
                </div>
            );
        });
    }

    drawModalComponents() {
        const {dictionary, config, close} = this.props;
        const {option, catalogCoin, showConfirmBuy, processingBuy, isBuyConfirm} = this.state;
        const size = catalogCoin.length;
        const purchaseStyle = {
            backgroundColor: config.color.secondary,
            color: config.color.secondaryText,
        };

        let content = <div><p>{dictionary.notAviable}</p></div>;
        let footer = <div className="actions">
            <button style={purchaseStyle}
                    onClick={() => close()}
            >
                {dictionary.close}
            </button>
        </div>
        let height = '35%';
        let top = this.props.middle ? '65%' : null;

        if (size) {
            const {paymentProcessor} = catalogCoin[option];
            // content = <div className="input-select-coins">
            height = this.props.height;
            top = this.props.top;
            content = <div>
                <div
                    style={{color: config.color.principalText}}
                    id='coins-select'
                >
                    {this.drawOptions()}
                </div>
            </div>;
            footer = <div className="actions">
                <button style={purchaseStyle}
                    onClick={
                        paymentProcessor === 1
                            ? (e) => this.buyCoins(e)
                            : (e) => {
                                this.setState({showConfirmBuy: true})
                            }
                    }
                >
                    {dictionary.buy}
                </button>
            </div>;
        }

        if (showConfirmBuy) {
            const {price, currency} = catalogCoin[option];
            const priceLeyend = price + " " + currency;
            height = this.props.height;
            top = this.props.top;
            content = <div>
                <p>{dictionary.ccbillMsgCoinPurchase
                    .replace(/--button--/g, dictionary.buy)
                    .replace(/--quantity--/g, priceLeyend)}
                </p>
            </div>
            footer = <div className="actions">
                <button
                    style={{
                        backgroundColor: config.color.third,
                        color: config.color.thirdText,
                    }}
                    onClick={(e) => {
                        this.setState({showConfirmBuy: false, option: 0})
                    }}
                >
                    {dictionary.back}
                </button>
                <button style={purchaseStyle}
                    onClick={(e) => this.buyCoins(e)}
                >
                    {dictionary.buy}
                </button>
            </div>;
        }

        if (processingBuy) {
            height = this.props.height;
            top = this.props.top;
            content = <div className={'flex-center'}>
                <Preloader use={Oval}
                           size={60}
                           strokeWidth={6}
                           strokeColor={config.color.principalText}
                           duration={600}/>
            </div>;
            footer = null;
        }

        if(isBuyConfirm !== null){
            height = this.props.height;
            top = this.props.top;
            content = isBuyConfirm
                ? <div>
                    <p>{dictionary.successfulPurchase}</p>
                </div>
                : <div>
                    <p>{dictionary.purchaseError}</p>
                </div>
            footer = isBuyConfirm
                    ? null
                : <div className="actions">
                <button
                    style={{
                        backgroundColor: config.color.third,
                        color: config.color.thirdText,
                    }}
                    onClick={(e) => {
                        this.setState({isBuyConfirm: null})
                    }}
                >
                    {dictionary.back}
                </button>
            </div>;
        }


        return {content, footer, height, top};
    }





    render() {
        const {dictionary, config, id, open, close} = this.props;
        const modalComponents = this.drawModalComponents();
        return (
            <Modal
                id={id || "modalBuyCoin"}
                head={<div>{dictionary.buy} {dictionary.coins}</div>}
                config={config}
                footer={modalComponents.footer}
                open={open}
                closeAction={() => {
                    this.setState({
                        showConfirmBuy: false,
                        processingBuy: false,
                        isBuyConfirm: null,
                        option: 0
                    });
                    close();
                }}
                levelModal={this.props.levelModal}
                levelContainer={this.props.levelContainer}
                middle={this.props.middle}
                height={modalComponents.height}
                top={modalComponents.top}
            >
                {modalComponents.content}
            </Modal>
        );
    }
}

export default GetCoins;
