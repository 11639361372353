import React from 'react';

const PlaticasIcon= ({color, width, height, className = null})=>{
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path fill={color}
                  d="M12,16.93a4.42,4.42,0,0,0,4.41-4.41V4.43a4.41,4.41,0,0,0-8.82,0v8.09A4.42,4.42,0,0,0,12,16.93ZM12,1a3.48,3.48,0,0,1,3.47,3.47V8.31H8.53V4.43A3.48,3.48,0,0,1,12,1ZM8.53,9.24h6.94v3.28a3.47,3.47,0,0,1-6.94,0Zm0,0"/>
            <path fill={color}
                  d="M18,12.05a.47.47,0,0,0-.47.47,5.53,5.53,0,0,1-11.06,0,.47.47,0,1,0-.94,0,6.48,6.48,0,0,0,5.09,6.32v2.37h0a2.35,2.35,0,0,0-2.35,2.35A.47.47,0,0,0,8.7,24h6.6a.47.47,0,0,0,.47-.47,2.35,2.35,0,0,0-2.35-2.35h0V18.84a6.48,6.48,0,0,0,5.09-6.32.47.47,0,0,0-.47-.47Zm-3.25,11H9.25a1.42,1.42,0,0,1,1.33-.94h2.84a1.42,1.42,0,0,1,1.33.94Zm-2.31-1.88h-.88V19L12,19l.44,0Zm0,0"/>
            <path fill={color}
                  d="M2.34,4.4a.47.47,0,0,0-.47.47V9.45a.47.47,0,0,0,.94,0V4.87a.47.47,0,0,0-.47-.47Zm0,0"/>
            <path fill={color}
                  d="M6.09,8.75a.47.47,0,0,0,.47-.47V6a.47.47,0,0,0-.94,0V8.28a.47.47,0,0,0,.47.47Zm0,0"/>
            <path fill={color} d="M.47,2.56A.47.47,0,0,0,0,3v7.79a.47.47,0,0,0,.94,0V3a.47.47,0,0,0-.47-.47Zm0,0"/>
            <path fill={color}
                  d="M4.22,3.93a.47.47,0,0,0-.47.47v6.34a.47.47,0,0,0,.94,0V4.4a.47.47,0,0,0-.47-.47Zm0,0"/>
            <path fill={color}
                  d="M21.66,4.4a.47.47,0,0,0-.47.47V9.45a.47.47,0,0,0,.94,0V4.87a.47.47,0,0,0-.47-.47Zm0,0"/>
            <path fill={color}
                  d="M17.91,8.75a.47.47,0,0,0,.47-.47V6a.47.47,0,0,0-.94,0V8.28a.47.47,0,0,0,.47.47Zm0,0"/>
            <path fill={color}
                  d="M23.53,2.56a.47.47,0,0,0-.47.47v7.79a.47.47,0,1,0,.94,0V3a.47.47,0,0,0-.47-.47Zm0,0"/>
            <path fill={color}
                  d="M19.78,10.27a.51.51,0,0,0-.33.13.48.48,0,0,0-.14.34.47.47,0,0,0,.14.33.51.51,0,0,0,.33.13.49.49,0,0,0,.33-.13.47.47,0,0,0,.14-.33.48.48,0,0,0-.14-.34.49.49,0,0,0-.33-.13Zm0,0"/>
            <path fill={color}
                  d="M19.78,3.93a.47.47,0,0,0-.47.47V8.75a.47.47,0,0,0,.94,0V4.4a.47.47,0,0,0-.47-.47Zm0,0"/>
            <path fill={color}
                  d="M12,15a2.45,2.45,0,0,0,2.44-2.44.47.47,0,0,0-.94,0A1.5,1.5,0,0,1,12,14,.47.47,0,0,0,12,15Zm0,0"/>
            <path fill={color}
                  d="M14,11.37a.47.47,0,0,0,0-.94.44.44,0,0,0-.33.14.46.46,0,0,0,0,.66.44.44,0,0,0,.33.14Zm0,0"/>
            <path fill={color}
                  d="M14.25,3.49A1.49,1.49,0,0,0,13.18,3a1.51,1.51,0,0,0-1.08.45l0,0,0,0A1.53,1.53,0,0,0,9.43,4.57a1.49,1.49,0,0,0,.45,1.07l1.85,1.85a.48.48,0,0,0,.68,0l1.84-1.85a1.49,1.49,0,0,0,.45-1.07A1.51,1.51,0,0,0,14.25,3.49Zm-3.69.68A.54.54,0,0,1,11,4a.56.56,0,0,1,.4.17l.37.37a.5.5,0,0,0,.68,0l.37-.37a.53.53,0,0,1,.4-.17.56.56,0,0,1,.4.17.58.58,0,0,1,.16.4.59.59,0,0,1-.16.4L12.07,6.48,10.56,5a.56.56,0,0,1-.17-.4A.54.54,0,0,1,10.56,4.17Z"/>
        </svg>
    )
}
export default PlaticasIcon;
