import axios from "axios";
import moment from "moment";
import 'moment/locale/es';

let toolbox = {};



toolbox.searchParams = () => {
    let params = {};
    let parts = window.location.search.substring(1).split('&');
    for (let i = 0; i < parts.length; i++) {
        var nv = parts[i].split('=');
        if (!nv[0]) continue;
        params[nv[0]] = nv[1] || true;
    }
    return params;
};

/**
 *
 * @param {string} module Catalogue site module 1, 2, 3 ...
 *
 * @param {error} error Error object with error and stack properties
 *
 * @param {*} options some custom values
 */

toolbox.sendError = (error, module = null, options = {}) =>{

    const { response } = error;

    let errorConstructor = {
        options,
        module
    };

    if (response){
        if (response.config){
            options.detailsError = {
                urlError: response.config.url,
                response: response.config.method,
                dataPassed: response.config.data
            };
        }
        errorConstructor.stack = response.data || null;
        errorConstructor.error = response.status || null;
    }
    else errorConstructor.error = error;

    axios.post('/api/v2/send/error', errorConstructor);

    console.error(response);

};

toolbox.isBottom = (el) => {
    return Math.floor(el.getBoundingClientRect().bottom) <= window.innerHeight + (window.innerHeight * .75);
}


toolbox.formatDatePayment=(dateFormat, lenguage) =>{
    moment.locale(lenguage);
    if(lenguage ==="es"){
        return moment(dateFormat).format("D MMM YYYY");
    }
    else if(lenguage ==="en") {
        return moment(dateFormat).format("MMM D YYYY");
    }
    else {
        moment.locale( "es");
        return moment(dateFormat).format("D MMM YYYY");
    }
}

toolbox.isSafari=() => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

toolbox.kFormatter=(num)=> {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}

toolbox.numberWithCommas= (x)=> {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

toolbox.round = (num) => {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
}

export default toolbox;
