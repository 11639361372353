import React, {Component} from 'react';
import {Circle} from 'react-preloaders';
import FooterCompliances from '../../components/Footer/FooterCompliances';
import HomeThree from '../../components/Home/HomeThree'; //Rediseño landing page figma
import HomeFour from '../../components/Home/HomeFour'; //nuevo Rediseño landing page Eden Muñoz
import './style.css';

class Home extends Component {
    constructor(props) {
        super(props);

        const navLinkChanger = document.querySelectorAll('#sidenav-overlay');
        if (navLinkChanger.length > 0) {
            document.body.style.overflow = 'auto';
            document.body.style.width = 'auto';
        }
    }

    render() {
        const config = this.props.config;
        const dictionary = this.props.dictionary;
        const description = this.props.description;
        if (dictionary.length === 0 || config.length === 0)
            return <div className="center-align" style={{paddingTop: 45}}>
                {this.props.isActive === 1 ?
                    <Circle background="transparent !important" color="#4285f4" className="center-align"/>
                    : null}
            </div>;

        return (
            <>
                {this.props.landingPage === 1 ?
                    <>
                        <div className="div-imgPrincipal">
                            <img
                                alt={dictionary.profileImage}
                                src={config.storageURL + config.principalImage}
                                className={'imgPrincipal'}
                            />
                        </div>
                        <HomeThree
                            description={description}
                            dictionary={dictionary}
                            config={config}
                            rating={this.props.rating}
                            statusModel={this.props.isActive}
                        />
                    </>
                    :
                    <HomeFour
                        description={description}
                        dictionary={dictionary}
                        config={config}
                    />
                }

                <FooterCompliances
                    dictionary={dictionary}
                    color={config.color}
                />
            </>
        );
    }
}

export default Home;
