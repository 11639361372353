import React, { Component } from 'react';
import Icon from 'react-ionicons';
import axios from "axios";
import ConversationFilterItem from "./ConversationFilterItem";
class ChatConversationFilter extends Component {
    constructor(props){
        super(props);
        this.state = {
            searchTerm: '',
            searchResult: []
        };

        this.changeSearchTerm = this.changeSearchTerm.bind(this);
        this.elevateFilter = this.elevateFilter.bind(this);
    }

    elevateFilter(filter = 0) {
        this.props.toElevate.filterChange(filter);
    }

    async changeSearchTerm(e) {
        let target = e.target;
        let searchString = target.value.trim();
        this.setState({searchTerm: searchString, searchResult: []});
        if(searchString !== '' && searchString.length >= 3){
            let params = {
                typo: searchString,
                offset: 0
            };

            await axios.post('/api/v2/searchConversationUsers', params)
                .then(async res => {
                    if(res.data !== null)
                        return await Promise.all(res.data.map(async (elem) => {

                            return  <ConversationFilterItem userData={this.props.userData}
                                                            config={this.props.config}
                                                            data={elem}
                                                            toElevate={this.props.toElevate} />;
                        })).then( res => {
                            this.setState({searchResult: res});
                        });


                    return res.data;
                })
                .catch(err=>{console.log(err)})
        }
    }

    render() {

        let searchStyles = {
            backgroundColor: this.props.config.color.chat.background || 'transparent',
            color: this.props.config.color.chat.bottonText,
            filter: 'brightness(0.95)'
        };

        let searchResultStyle = this.state.searchResult.length > 0
            ? {
                display: 'block',
                backgroundColor: this.props.config.color.chat.background || 'transparent',
                color: this.props.config.color.chat.bottonText,
            }
            : {
                display: 'none',
                backgroundColor: this.props.config.color.chat.background || 'transparent',
                color: this.props.config.color.chat.bottonText,
            };

        let searchResult = this.state.searchResult.length > 0
            ? <div className={'v2-chat-search-conversation-result'}
                style={searchResultStyle}>
                <ul>
                    {this.state.searchResult}
                </ul>
            </div>
            : null;

        let inputSearchTermStyles = {
            color: this.props.config.color.principalText
        }

        let filterStyles = {
            backgroundColor: this.props.config.color.chat.background,
            color: this.props.config.color.principalText,
            borderColor: this.props.config.color.secondary
        }

        let filterStylesActive = {
            backgroundColor: this.props.config.color.secondary,
            color: this.props.config.color.principalText,
            borderColor: 'transparent'
        }

        return <div>
            <div
                style={searchStyles}
                className={'v2-chat-search-conversation'}
            >
                <Icon
                    className={'v2-chat-search-conversation-icon'}
                    icon={'md-search'}
                    color={this.props.config.color.principalText}
                />
                <input style={inputSearchTermStyles}
                       onChange={this.changeSearchTerm}
                       value={this.state.searchTerm}
                       type={'text'}
                       placeholder={this.props.dictionary.allConv} />
                {searchResult}
            </div>
            <ul
                id={"chat-conversation-filter"}
                className={"v2-chat-conversation-filter"}
            >
                <li
                    onClick={() => this.elevateFilter(0)}
                    style={this.props.filterActive === 0 ? filterStylesActive : filterStyles}
                >
                    {this.props.dictionary.all}
                </li>
                <li
                    onClick={() => this.elevateFilter(1)}
                    style={this.props.filterActive === 1 ? filterStylesActive : filterStyles}
                >
                    {this.props.dictionary.urgChats}
                </li>
                <li
                    onClick={() => this.elevateFilter(2)}
                    style={this.props.filterActive === 2 ? filterStylesActive : filterStyles}
                >
                    {this.props.dictionary.unAnsChats}
                </li>
            </ul>
        </div>;
    }
}

export default ChatConversationFilter;