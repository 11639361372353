import React from 'react';

const EyeIcon= props=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={props.width} 
            height={props.height}
            fill="none" 
            viewBox="0 0 20 16"
            className={props.className ? props.className : null}
        >
            <path 
                fill={props.color}
                fillRule="evenodd" 
                d="M19.85 4.91c-.044-.05-1.12-1.245-2.86-2.449C14.663.851 12.246 0 10 0 7.754 0 5.337.851 3.01 2.461 1.27 3.665.194 4.86.15 4.91c-.2.223-.2.56 0 .781.044.05 1.12 1.245 2.86 2.449 2.327 1.61 4.744 2.46 6.99 2.46 2.245 0 4.663-.85 6.99-2.46 1.74-1.204 2.816-2.398 2.86-2.449.2-.222.2-.558 0-.78zM3.7 7.19c-1.052-.725-1.855-1.458-2.297-1.89.757-.742 2.58-2.365 4.923-3.34-.803.882-1.294 2.054-1.294 3.339 0 1.283.49 2.454 1.29 3.336C5.306 8.213 4.41 7.68 3.7 7.191zM10 9.096c-2.093 0-3.796-1.703-3.796-3.796S7.907 1.504 10 1.504s3.796 1.703 3.796 3.796S12.093 9.096 10 9.096zm3.674-.456c.803-.883 1.293-2.055 1.293-3.34 0-1.283-.489-2.454-1.29-3.337 1.018.424 1.914.957 2.623 1.445 1.052.726 1.855 1.459 2.297 1.892-.757.74-2.58 2.364-4.923 3.34z" 
                clipRule="evenodd"
            />
            <path 
                fill={props.color}
                fillRule="evenodd" 
                d="M10 3.412c-1.042 0-1.889.847-1.889 1.888S8.958 7.188 10 7.188c.323 0 .586-.262.586-.586 0-.161-.066-.308-.172-.414-.106-.106-.252-.172-.414-.172-.395 0-.717-.321-.717-.716 0-.395.322-.717.717-.717.395 0 .716.322.716.717 0 .162.066.308.172.414.106.106.252.172.414.172.324 0 .586-.263.586-.586 0-1.041-.847-1.888-1.888-1.888z" 
                clipRule="evenodd"
            />
        </svg>
    )
}
export default EyeIcon;