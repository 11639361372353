import React from 'react';

const PhoneIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 900.000000 1220.000000"
        >
        <g 
            transform="translate(0.000000,1220.000000) scale(0.100000,-0.100000)"
            fill={color} 
            stroke="none"
        >
            <path 
                d="M2941 12191 c-12 -8 -13 -85 -7 -508 18 -1353 49 -2471 77 -2767 15
                -168 34 -191 149 -182 111 9 297 85 430 176 61 42 196 179 281 285 l56 70 -8
                540 c-5 297 -13 843 -19 1214 -12 783 -1 713 -129 842 -149 148 -394 278 -614
                324 -76 17 -194 20 -216 6z"
            />
            <path 
                d="M2450 12179 c-242 -23 -573 -93 -813 -173 -810 -271 -1340 -782
                -1542 -1490 -103 -360 -116 -688 -65 -1581 110 -1905 514 -3575 1121 -4637
                159 -278 656 -1004 1008 -1473 1138 -1513 2260 -2430 3291 -2690 242 -61 285
                -65 651 -65 l340 0 142 36 c476 119 1004 313 1417 519 159 79 430 234 430 246
                0 3 -197 161 -437 350 -241 189 -472 371 -514 404 -42 33 -125 98 -185 146
                -60 47 -278 218 -484 380 -937 738 -1130 889 -1148 900 -15 9 -33 4 -101 -30
                -178 -90 -384 -141 -565 -141 -286 1 -516 123 -700 373 -202 274 -557 899
                -1198 2112 -188 355 -828 1605 -922 1799 -65 137 -113 412 -103 596 9 169 39
                284 109 425 90 181 256 342 461 446 l77 39 0 48 c0 26 -5 182 -10 347 -5 165
                -23 768 -40 1340 -16 572 -34 1177 -40 1345 -5 168 -10 335 -10 373 l0 67 -37
                -1 c-21 -1 -81 -6 -133 -10z"
            />
            <path 
                d="M6270 3529 c-206 -109 -376 -202 -378 -207 -1 -5 213 -179 475 -388
                572 -453 1626 -1291 2060 -1636 172 -136 314 -248 317 -248 6 0 133 393 205
                633 31 102 51 187 51 216 0 100 -60 233 -152 332 -46 51 -1146 911 -1508 1180
                -271 201 -376 261 -524 299 -144 36 -126 42 -546 -181z"
            />
        </g>
    </svg>
    )
}
export default PhoneIcon;
