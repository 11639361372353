import React, {Component} from 'react';
import ConversationFilter from "./ConversationFilter";
import ConversationItem from "./ConversationItem";
import axios from "axios";
import {Oval, Preloader} from 'react-preloader-icon';
import ConversationModalBroadcast from "./ConversationModalBroadcast"
import MediaBoxSender from "../../MediaBox/MediaBoxSender"

class ChatConversation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            offset: 15,
            openModalBroadcastMessage: false,
            conversations: [],
            conversationUri: [
                '/api/v2/getConversations',
                '/api/v2/getUrgentConversations',
                '/api/v2/getUnansweredConversations'
            ],
            //0 all, 1 urgent, 2 Unanswered
            filter: 0,
            filtering: false,
            showMediaBoxSender: false,
            coinPrice: 0,
            scrollPos: 0,
            loadMediaBoxUrgent: false
        };

        this.toggleModalMediaBox = this.toggleModalMediaBox.bind(this);

        this.filterChange = this.filterChange.bind(this);

        //For <ConversationFilter />
        this.props.toElevate.filterChange = this.filterChange

        //For <ConversationModalBroadcast>
        this.toggleModalBroadcastMessage = this.toggleModalBroadcastMessage.bind(this);
        this.props.toElevate.toggleModalBroadcastMessage = this.toggleModalBroadcastMessage;

        //For this
        this.onScrollListener = this.onScrollListener.bind(this);
        this.conversationContainer = React.createRef();

        props.socket.on("RECEIVE_MESSAGE", (socketId, data) => {
            this.addConversation(socketId, data);
        });

    }

    componentDidMount() {
        //what list come from
        this.filterChange(this.props.filterConversation);
        this.getMinCoin();
    }

    async addConversation(socketId, data) {
        const conversation = {
            getTime: data.created_time,
            id_user: data.id_user,
            messageUser: data.messages,
            lastMessage: (data.model_message ? "mdl" : "usr")
        };
        let conversationsCopy = this.state.conversations;
        let isInConversation = conversationsCopy.some(item => {
            return item.id_user === data.id_user;
        });

        if (isInConversation) {
            conversationsCopy.map(async (item, index) => {
                let exist = item.id_user === data.id_user;

                if (this.state.filter === 0) {
                    if (exist) {
                        let mergedConversation = {...conversationsCopy[index], ...conversation};
                        if (index !== 0)
                            conversationsCopy[index] = conversationsCopy[0];
                        conversationsCopy[0] = mergedConversation;
                        this.setState({conversations: conversationsCopy});
                    }
                } else if (this.state.filter === 2) {
                    if (exist)
                        conversationsCopy.splice(index, 1);
                } else if (this.state.soloUrgentes) {
                    this.filterChange();
                }
            });
        } else {
            if (this.state.filter !== 1) {
                const getUserInfoFirstMessage = await this.getUserInfoFistMessage(data.id_user);
                conversation.image_profile = getUserInfoFirstMessage.image_profile || "";
                conversation.username = getUserInfoFirstMessage.username;
                conversationsCopy.unshift(conversation);
                this.setState({conversations: conversationsCopy});
            }
        }
    }

    async getUserInfoFistMessage(idUser) {
             const user = {
            userId: idUser
        }
        return await axios.post('/api/v2/getUserInfoFistMessage', user)
            .then(res => {
                return res.data;
            });
    }

    async onScrollListener() {
        let container = this.conversationContainer.current;
        let scrollPosInvert = (container.scrollHeight - container.offsetHeight) - container.scrollTop;
        this.setState({scrollPos: scrollPosInvert});

        if (scrollPosInvert <= 0 && !this.state.loading && (this.state.offset <= this.state.conversations.length)) {
            let scroll = container.scrollTop;
            let newOffset = this.state.offset + 15;
            this.setState({offset: newOffset});
            await this.getConversation(newOffset, this.state.filter);
            container.scrollTo(0, scroll);
        }
    }

    getMinCoin = async () => {
        return await axios.get('/api/v2/min/coin/price')
            .then(({data}) => {
                this.setState({coinPrice: data.minprice})
            });
    }

    toggleModalBroadcastMessage() {
        this.setState({openModalBroadcastMessage: !this.state.openModalBroadcastMessage});
    }

    toggleModalMediaBox = async () => {
        await this.setState({loadMediaBoxUrgent: true})
        setTimeout(async () => {
            await this.setState({showMediaBoxSender: !this.state.showMediaBoxSender})
        }, 350);
    }

    showMediaBox() {
        return (this.props.showModule["image-message-req"] || this.props.showModule["video-message-req"])
    }

    filterChange(filter = this.state.filter) {
        if (Number.isInteger(filter)) {
            this.setState({filter, filtering: true});
            this.getConversation(undefined, filter);
        }
    }

    async getConversation(offset = this.state.offset, filter = this.state.filter) {
        this.setState({loading: true});
        let uri = this.state.conversationUri[filter] || this.state.conversationUri[0];
        let data = {offset: offset};
        return await axios.post(uri, data)
            .then(res => {
                let result = {
                    loading: false,
                    filtering: false,
                    conversations: []
                };

                if (res.data[0] !== undefined)
                    result.conversations = res.data;

                this.setState(result);

                return res.data;
            }).catch(err => {
                this.setState({loading: false, filtering: false});
            });
    }

    createContent() {
        let result = [];
        let conversations = this.state.conversations;
        let publicMessageStyle = {
            color: this.props.config.color.chat.bottonText,
            border: 'solid 1px ' + this.props.config.color.secondary
        };

        if (!this.state.loading && conversations.length === 0) {
            result.push(<li key={'void'} className={'v2-chat-conversation-item'}>
                <div className="center-align">
                    {this.props.dictionary.noConvSection}
                </div>
            </li>);

            return result;
        }

        //If has urgent conversation
        let openMediaBoxBtn = (this.state.filter === 1 && this.showMediaBox())
            ? <li key={'mediaBoxModalBtn'}>
                <button
                    style={publicMessageStyle}
                    onClick={(e) => this.toggleModalMediaBox()}
                    className={'v2-chat-toggle-mediabox'}
                >
                    {this.props.dictionary.openMediaBox}
                </button>
            </li>
            : null;

        result.push(openMediaBoxBtn);

        for (const conversation of conversations){
            result.push(
                <ConversationItem key={conversation.id_user} config={this.props.config}
                                  dictionary={this.props.dictionary}
                                  filter={this.state.filter}
                                  toElevate={this.props.toElevate}
                                  data={conversation}/>);
        }

        return result;
    }

    render() {
        const conversationStyles = {
            backgroundColor: this.props.config.color.chat.background || 'transparent',
            color: this.props.config.color.chat.bottonText,
        };

        const publicMessageStyle = {
            color: this.props.config.color.chat.bottonText,
            backgroundColor: this.props.config.color.secondary,
        };

        let content = this.createContent();
        if (this.state.loading && !this.state.filtering)
            content.push(<li key={'loader'} className={'v2-chat-conversation-item v2-chat-conversation-loader'}>
                <Preloader
                    className={"center-align"}
                    use={Oval}
                    size={50}
                    strokeWidth={6}
                    strokeColor={this.props.config.color.secondary}
                    duration={1000}
                />
            </li>);
        if (this.state.filtering)
            content.unshift(<li key={'loader'} className={'v2-chat-conversation-loader'}>
                <Preloader
                    className={"center-align"}
                    use={Oval}
                    size={50}
                    strokeWidth={6}
                    strokeColor={this.props.config.color.secondary}
                    duration={1000}
                />
            </li>);

        return <div>
            <div style={{textAlign: "center"}}>
                <button
                    style={publicMessageStyle}
                    onClick={this.toggleModalBroadcastMessage}
                    className={'v2-chat-public-message'}
                >
                    {this.props.dictionary.MsPublic}
                </button>
            </div>
            <ConversationFilter config={this.props.config}
                                dictionary={this.props.dictionary}
                                toElevate={this.props.toElevate}
                                filterActive={this.state.filter}/>
            <ul ref={this.conversationContainer}
                onScroll={this.onScrollListener}
                style={conversationStyles}
                id={"chat-conversation"}
                className={"v2-chat-conversation"}>
                {content}
            </ul>
            <ConversationModalBroadcast
                config={this.props.config}
                dictionary={this.props.dictionary}
                toElevate={this.props.toElevate}
                open={this.state.openModalBroadcastMessage}
                coinPrice={this.state.coinPrice}
                socket={this.props.socket}
                modelData={this.props.modelData}
                userData={this.props.userData}
                height={window.screen.width <= 360 ? '70%' : '100%'}
            />
            {this.state.loadMediaBoxUrgent ?
                <MediaBoxSender
                    showMediaBoxSender={this.state.showMediaBoxSender}
                    closeMediaBoxSender={this.toggleModalMediaBox}
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                    socket={this.props.socket}
                />
                : null}
        </div>;
    }
}

export default ChatConversation;
