import React from 'react';

const GalleryIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            version="1.1" 
            id="Capa_2" 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            viewBox="0 0 24 24" 
        >
            <path 
                fill={color}
                d="M14.88,9.91a5.76,5.76,0,0,0-2.25.45.46.46,0,0,0-.25.61.46.46,0,0,0,.6.25,5,5,0,0,1,1.9-.38,4.93,4.93,0,1,1-4.55,3,.47.47,0,0,0-.25-.61.48.48,0,0,0-.61.26A5.76,5.76,0,0,0,9,15.77a5.86,5.86,0,1,0,5.86-5.86Zm0,0"
            />
            <path 
                fill={color}
                d="M14.88,19.58a3.81,3.81,0,1,0-3.81-3.81,3.82,3.82,0,0,0,3.81,3.81Zm0-6.69A2.88,2.88,0,1,1,12,15.77a2.89,2.89,0,0,1,2.88-2.88Zm0,0"
            />
            <path 
                fill={color}
                d="M21.55,7.68H20.13L18.51,4.93a.48.48,0,0,0-.4-.23H11.66a.46.46,0,0,0-.4.23L9.64,7.68H7V7.12a1.68,1.68,0,1,0-3.35,0v.56H2.45A2.36,2.36,0,0,0,.09,10V21.5a2.36,2.36,0,0,0,2.36,2.36h19.1a2.36,2.36,0,0,0,2.36-2.36V10a2.36,2.36,0,0,0-2.36-2.36Zm-9.63-2h5.92l1.21,2.05H10.72ZM4.56,7.12a.74.74,0,0,1,.74-.74.75.75,0,0,1,.75.74v.56H4.56ZM23,21.5a1.44,1.44,0,0,1-1.43,1.43H2.45A1.44,1.44,0,0,1,1,21.5V10A1.44,1.44,0,0,1,2.45,8.61h19.1A1.44,1.44,0,0,1,23,10Zm0,0"
            />
            <path 
                fill={color}
                d="M14.88,14.86a.91.91,0,0,1,.91.91.47.47,0,0,0,.93,0,1.84,1.84,0,0,0-1.84-1.84.47.47,0,0,0,0,.93Zm0,0"
            />
            <path 
                fill={color}
                d="M14.88,3.48A.47.47,0,0,0,15.35,3V.53a.47.47,0,0,0-.93,0V3a.47.47,0,0,0,.46.47Zm0,0"
            />
            <path 
                    fill={color}
                    d="M17.1,3.48a.49.49,0,0,0,.33-.14l1.7-1.71a.46.46,0,0,0,0-.65.47.47,0,0,0-.66,0l-1.7,1.7a.47.47,0,0,0,.33.8Zm0,0"
            />
            <path fill={color}
                d="M12.34,3.34A.47.47,0,0,0,13,2.68L11.29,1a.46.46,0,0,0-.65,0,.44.44,0,0,0,0,.65Zm0,0"
            />
            <path fill={color}
                d="M6.51,11.49H4.09a.47.47,0,0,0,0,.93H6.51a.47.47,0,1,0,0-.93Zm0,0"
            />
            <path fill={color}
                d="M11.07,12.42a.47.47,0,0,0,.33-.14.46.46,0,0,0,.13-.32.46.46,0,0,0-.79-.33.44.44,0,0,0-.14.33.43.43,0,0,0,.14.32.47.47,0,0,0,.33.14Zm0,0"
            />
        </svg>
    )
}
export default GalleryIcon;
