import React, { Component } from 'react';
import Modal from "../Modal";

class NotAvailable extends Component{
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
    }

    render(){
        let dictionary = this.props.dictionary;
        let config = this.props.config;
        return(
            <Modal
                id="modalNotAvailable"
                head={<p></p>}
                config={this.props.config}
                footer={
                    <div className="actions">
                        <button
                            style={{
                                backgroundColor: config.color.secondary,
                                color: config.color.secondaryText
                            }}
                            onClick={
                                (e) => this.props.close()
                            }
                        >
                            {dictionary.close}
                        </button>
                    </div>

                }
                open={this.props.open}
                closeAction={this.props.close}
                levelModal={this.props.levelModal}
                levelContainer={this.props.levelContainer}
                height={this.props.height}
                top={this.props.top}
            >
                <div>
                    <p>{this.props.dictionary.notAviable}</p>
                </div>
            </Modal>

        )
    }
}

export default NotAvailable;
