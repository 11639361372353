import React, {Component} from "react";
import Icon from "react-ionicons";
import './style.css';

class ModalCustom extends Component{

    constructor(props) {
        super(props);
    }


    render() {
        let navbar = document.getElementsByClassName("styles_content__1Phom");
        if(navbar.length > 0 && this.props.open)
            navbar[0].style.zIndex = 0;
        let id = (this.props.id) ? this.props.id : "CustomModal";
        let body = (this.props.children) ? this.props.children : "";
        let open = this.props.open;
        // eslint-disable-next-line
        let massiveClass = (this.props.massiveClass) ? this.props.massiveClass : ""
        let head = (this.props.head) ? this.props.head : <h3></h3>
        let footer = (this.props.footer) ? this.props.footer : <ul></ul>
        let back = (this.props.config) ? this.props.config.color.modal : "auto";
        let color = (this.props.config) ? this.props.config.color.modalText : "auto";
        let close = (this.props.config) ? this.props.config.color.navbar : "auto";
        let closeAction = (this.props.config) ? this.props.config.color.navbarText : "auto";
        let zindex = (this.props.levelModal === 'second') ? {zIndex: 32} : {};
        let zindexContainer = (this.props.levelContainer === 'second') ? {zIndex: 31} : {};
        let height = {};
        let top = {};

        if(window.screen.width < 750) {
            if(this.props.middle) {
                height = this.props.height ? {height: this.props.height} : {height: '45%'};
                top = this.props.top ? {top: this.props.top} : {top: '55%'};
            } else {
                height = this.props.height ? {height: this.props.height} : {};
                top = this.props.top ? {top: this.props.top} : {};
            }
        }
        return(
        <>
            <div
                onClick={() => this.props.closeAction()}
                style={(open) ? zindexContainer : {}}
                className={(open) ? "modal-container-open" : "custom-modal"}
            >
            </div>
            <div
                id={id}
                style={
                    (open) ? 
                    {...zindex, backgroundColor : back, color: color, ...height, ...top} 
                    : {}
                }
                className={((open) ? (this.props.middle ? "open middle-position" : "open custom-modal ") : "custom-modal") + massiveClass}
            >
                <span
                    className="close"
                    style={{backgroundColor: close, color : closeAction}}
                    onClick={(this.props.closeAction) ? this.props.closeAction : null}
                >
                    <Icon
                        icon="md-close"
                        color={closeAction}
                        fontSize="1.5em"
                    />
                </span>
                <div
                    className="custom-modal-head"
                >

                    {head}
                </div>
                <div
                    className="custom-modal-body"
                >
                    {body}
                </div>
                <div
                    className="custom-modal-foot"
                >
                    {footer}
                </div>
            </div>
        </>
        )
    }
}
export default ModalCustom;
