import React from 'react';

const WriteIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 16 17"
        >
            <path 
                fill={color}
                fillRule="evenodd" 
                d="M10.668 1.601c.753-.801 1.972-.801 2.724 0l1.045 1.11c.75.803.75 2.103 0 2.906l-7.941 8.47c-.003.007-.008.01-.012.014l-.004.003c0 .003-.001.004-.003.005l-.005.004-.017.014-.017.014-.02.012-.019.013c-.002.002-.005.004-.008.004-.01.007-.022.013-.034.017-.004.004-.004.004-.008.004l-4.84 1.722c-.04.013-.08.02-.122.02-.103 0-.201-.042-.274-.119-.104-.11-.14-.272-.093-.42l1.611-5.166.002-.004.002-.004.005-.016.006-.017.004-.006.004-.006.01-.017.01-.016.001-.004.002-.004.014-.019c.005-.006.01-.012.013-.018.003 0 .004-.002.005-.004l.003-.004.015-.017 7.26-7.74.681-.73zM5.51 13.623l-2.342-2.499-1.176 3.749 3.518-1.25zm-1.965-3.259l2.682 2.86 7.667-8.183c.45-.48.452-1.26.004-1.742L12.849 2.18c-.451-.481-1.182-.481-1.633 0l-7.672 8.183z" 
                clipRule="evenodd"
            />
            <path 
                stroke={color}
                strokeWidth=".8" 
                d="M6.226 13.225l-2.682-2.86m2.682 2.86l7.667-8.184c.45-.48.452-1.26.004-1.742L12.849 2.18c-.451-.481-1.182-.481-1.633 0l-7.672 8.183m2.682 2.86l-2.682-2.86m9.848-8.763c-.752-.801-1.971-.801-2.724 0l-.682.732-7.259 7.74-.015.016-.003.004c-.001.002-.002.004-.005.004l-.013.018-.014.019-.002.004-.002.004-.009.016-.01.017-.004.006c0 .002-.002.004-.004.006l-.006.017-.005.016-.002.004-.002.004-1.61 5.167c-.048.147-.012.31.092.419.073.077.171.12.274.12.041-.001.083-.008.123-.021l4.84-1.722c.003 0 .003 0 .007-.004.012-.004.024-.01.034-.017.003 0 .006-.002.008-.004l.02-.013.019-.012.017-.014.017-.014.005-.004c.002-.001.003-.002.003-.005l.004-.003c.004-.004.009-.007.012-.013l7.94-8.471c.752-.803.752-2.103 0-2.906L13.393 1.6zM3.167 11.124l2.342 2.5-3.518 1.249 1.176-3.749z"
            />
        </svg>
    )
}
export default WriteIcon;
