import React from 'react';

const TipsIcon = ({color, width, height}) => {

    return (
        <svg 
            version="1.0" 
            xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height}
            viewBox="0 0 401.000000 386.000000"
            preserveAspectRatio="xMidYMid meet">

            <g 
                transform="translate(0.000000,386.000000) scale(0.100000,-0.100000)"
                fill={color} 
                stroke="none"
            >
                <path d="M1493 3823 c-24 -31 -28 -47 -31 -112 -2 -72 17 -151 62 -254 8 -16
                5 -16 -20 -6 -16 6 -88 12 -159 13 -129 2 -131 2 -213 41 -77 36 -85 37 -122
                26 -50 -15 -83 -59 -82 -111 1 -55 24 -83 101 -124 89 -47 173 -66 296 -67
                l100 0 -55 -15 c-236 -63 -420 -251 -354 -363 24 -40 76 -65 119 -57 35 7 63
                30 108 89 23 31 109 87 133 87 4 0 -47 -55 -113 -122 -290 -297 -458 -606
                -518 -952 -8 -49 -15 -120 -15 -157 0 -54 -3 -69 -15 -69 -8 0 -19 17 -26 39
                -16 55 -60 105 -116 132 -39 19 -68 23 -177 27 -212 7 -301 -22 -353 -113
                l-23 -40 0 -705 0 -706 26 -40 c29 -45 75 -80 125 -94 44 -13 324 -13 368 0
                51 14 97 50 127 97 l26 42 82 -30 c44 -17 165 -62 267 -99 103 -38 215 -74
                250 -80 38 -6 257 -10 527 -10 498 0 549 4 673 55 61 24 1106 681 1153 724 51
                46 76 115 76 209 0 95 -23 153 -82 208 -60 58 -114 78 -201 78 l-78 0 6 25
                c40 157 48 361 20 521 -33 190 -124 418 -239 595 -62 95 -206 276 -243 305
                -69 54 -183 -5 -183 -95 0 -36 13 -57 87 -145 180 -209 308 -465 348 -694 37
                -216 2 -458 -89 -608 l-31 -52 -280 -123 c-154 -67 -283 -123 -287 -123 -5 0
                -8 9 -8 20 0 37 -42 130 -80 178 -45 57 -125 113 -195 135 -27 9 -194 32 -370
                52 -176 20 -342 42 -370 50 -27 8 -108 47 -180 87 -71 40 -168 87 -215 104
                l-85 32 -3 49 c-2 26 2 91 8 143 29 228 124 449 286 667 68 91 238 269 341
                358 l72 62 73 -19 c197 -50 567 -38 680 23 15 8 35 -4 104 -61 76 -62 90 -70
                127 -70 49 0 81 19 102 60 36 69 15 112 -98 204 -84 68 -86 70 -96 128 -6 33
                -19 74 -30 91 -18 29 -18 34 -4 52 25 34 109 207 128 265 11 30 19 84 19 121
                0 53 -5 74 -27 112 l-28 47 -549 0 -549 0 -28 -37z m457 -160 c73 -38 155 -38
                230 -1 l56 27 48 -24 c26 -13 67 -27 92 -31 24 -3 44 -10 44 -15 0 -12 -43
                -98 -88 -176 l-35 -62 -86 -15 c-101 -19 -192 -20 -295 -5 l-75 11 -39 67
                c-46 77 -77 137 -86 168 -6 19 -2 22 41 28 26 4 68 17 93 30 25 13 46 24 47
                25 0 0 24 -12 53 -27z m-70 -534 c81 -13 274 -10 365 6 44 8 81 15 83 15 1 0
                2 -6 2 -14 0 -26 -104 -41 -285 -41 -185 0 -285 15 -285 42 0 12 7 14 33 9 17
                -4 57 -11 87 -17z m-1410 -1654 c0 -170 7 -199 55 -225 57 -31 132 -7 162 51
                7 13 13 50 13 82 l0 59 53 -7 c133 -17 198 -42 457 -176 69 -36 155 -73 192
                -82 37 -9 205 -33 375 -52 169 -19 319 -38 333 -41 61 -14 120 -84 120 -144
                l0 -30 -242 0 c-135 0 -259 -5 -280 -10 -78 -22 -111 -107 -68 -177 31 -51 58
                -55 420 -51 l325 3 514 227 c283 126 527 228 543 228 37 0 65 -41 51 -73 -14
                -30 -1026 -672 -1113 -706 l-65 -26 -453 -3 c-279 -2 -479 1 -520 7 -37 6
                -189 56 -354 118 l-288 107 0 284 c0 311 -3 327 -60 357 -47 24 -95 19 -132
                -14 l-33 -29 -5 -379 -5 -378 -110 0 -110 0 -3 618 -2 617 115 0 115 0 0 -155z"/>
                <path d="M2014 2776 c-33 -15 -64 -67 -64 -110 0 -20 -8 -29 -34 -40 -51 -21
                -124 -93 -153 -151 -17 -33 -28 -72 -31 -115 -9 -125 32 -203 145 -276 l73
                -48 0 -163 c0 -90 -3 -163 -6 -163 -3 0 -34 14 -69 31 -59 28 -68 30 -100 19
                -70 -23 -96 -118 -48 -172 22 -25 144 -85 189 -94 28 -5 32 -11 37 -44 7 -53
                33 -88 76 -101 74 -22 141 34 141 118 0 24 7 32 52 52 67 31 137 102 174 176
                26 54 29 70 29 160 0 92 -2 104 -30 154 -37 66 -100 123 -171 152 l-54 23 0
                113 c0 62 2 113 4 113 2 0 17 -7 32 -15 75 -39 153 8 154 92 0 29 -7 50 -24
                70 -21 25 -98 70 -148 87 -11 3 -18 15 -18 31 0 38 -26 83 -60 100 -35 18 -55
                18 -96 1z m-67 -458 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
                m264 -427 c12 -42 -1 -110 -26 -131 -13 -11 -15 0 -15 90 0 90 2 101 15 90 8
                -7 20 -29 26 -49z"/>
            </g>
        </svg>
    )
}

export default TipsIcon;
