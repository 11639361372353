import React, {Component} from 'react';
import Ionicon from 'react-ionicons';
import'./Advice.css';

class Advice extends Component {
    constructor(props){
        super(props);
        this.state={ 
            close : false
        }
    }

    async componentDidMount() {
        //console.log(this.state.langList.length)
    }
    openLink () {
        window.location = this.props.link;
    }
    closeNotification () {
        this.setState({close : true});
    }

    render (){
        return (
            <div 
                className={(this.state.close) ? "close notification-push" : "notification-push"} 
                style={{
                    backgroundColor: this.props.config.color.modal, 
                    color: this.props.config.color.modalText
                }}
            >
                <span onClick={(e) => this.closeNotification(e)}>
                   <Ionicon 
                        icon='md-close'
                        fontSize="25px"
                        color="rgb(92, 92, 92)"
                    />
                </span>
                <p>{this.props.message}</p>
                <button 
                    onClick={(e) => this.openLink(e)} 
                    style={{backgroundColor: this.props.config.color.secondary, color: this.props.config.color.secondaryText}} 
                    className="btn__advice" 
                >
                    Go!
                </button>
            </div>
        )
    };
}
export default Advice;
