import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Circle} from 'react-preloaders';
import Ionicon from 'react-ionicons';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import CancelMembershipSteps from '../Blog/CancelMembership/CancelMembershipSteps';
import "./FAQ.css";

class FAQ extends Component {

    renderObject() {

        if (this.props.dictionary.length === 0 || this.props.config.length === 0)
            return <div className="center-align" style={{paddingTop: 45}}>
                <Circle background="transparent !important" color="#4285f4" className="center-align"/>
            </div>;

        const collapsibleHeaderStyle = {
            "border": "1px solid rgb(205, 213, 227)",
            "backgroundColor": this.props.config.color.principal,
            "color": this.props.config.color.principalText
        };

        const collapsibleItemStyle = {
            "backgroundColor": this.props.config.color.principal,
            "border": "1px solid rgb(205, 213, 227)",
            "marginBottom": 10,
            "borderBottomLeftRadius": 12,
            "borderBottomRightRadius": 12
        };

        const collapsibleTextStyle = {
            "color": this.props.config.color.principalText
        };
        const topBorder = {
            "color": this.props.config.color.principalText,
            "backgroundColor": this.props.config.color.principal,
            "border": "1px solid rgb(205, 213, 227)",
            "borderTopLeftRadius": 12,
            "borderTopRightRadius": 12
        };
        const bottomBorder = {
            "color": this.props.config.color.principalText,
            "backgroundColor": this.props.config.color.principal,
            "border": "1px solid rgb(205, 213, 227)",
            "borderBottomLeftRadius": 12,
            "borderBottomRightRadius": 12
        };
        return (
            <div>
                <Accordion className="redesign-accordion" allowMultipleExpanded={true} allowZeroExpanded={true}>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton
                                style={topBorder}
                            >
                                <Ionicon
                                    icon='md-help-circle'
                                    fontSize="25px"
                                    color="rgb(92, 92, 92)"
                                    className="icon-ask"
                                    style={{backgroundColor: this.props.config.color.principal}}
                                />
                                {this.props.dictionary.FAQ.q1}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel style={collapsibleItemStyle}>
                            <ol style={collapsibleTextStyle}>
                                <li>
                                    {this.props.dictionary.FAQ.q1p1}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q1p2}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q1p3}
                                    <ul className="sublist">
                                        <li>
                                            {this.props.dictionary.FAQ.q1p4}
                                        </li>
                                        <li>
                                            {this.props.dictionary.FAQ.q1p5}
                                        </li>
                                        <li>
                                            {this.props.dictionary.FAQ.q1p6}
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q1p7}
                                </li>
                            </ol>
                            <Link to="/support/blog/create_account">{this.props.dictionary.seemore}</Link>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton style={collapsibleHeaderStyle}>
                                <Ionicon
                                    icon='md-help-circle'
                                    fontSize="25px"
                                    color="rgb(92, 92, 92)"
                                    className="icon-ask"
                                    style={{backgroundColor: this.props.config.color.principal}}
                                />
                                {this.props.dictionary.FAQ.q8}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel style={collapsibleItemStyle}>
                            <p style={collapsibleTextStyle}>
                                {this.props.dictionary.FAQ.q8p1}
                            </p>
                            <ol style={collapsibleTextStyle}>
                                <li>
                                    {this.props.dictionary.FAQ.q8p2}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q8p3}
                                </li>
                            </ol>
                            <p style={collapsibleTextStyle}>
                                {this.props.dictionary.FAQ.q8p4}
                            </p>
                            <ol style={collapsibleTextStyle}>
                                <li>
                                    {this.props.dictionary.FAQ.q8p5}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q8p6}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q8p7}
                                </li>
                            </ol>
                            <p style={collapsibleTextStyle}>
                                {this.props.dictionary.FAQ.q8p8}
                            </p>
                            <ol style={collapsibleTextStyle}>
                                <li>
                                    {this.props.dictionary.FAQ.q8p9}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q8p10}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q8p11}
                                </li>
                            </ol>
                            <Link to="/support/blog/membership_payment">
                                {this.props.dictionary.seemore}
                            </Link>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton style={collapsibleHeaderStyle}>
                                <Ionicon
                                    icon='md-help-circle'
                                    fontSize="25px"
                                    color="rgb(92, 92, 92)"
                                    className="icon-ask"
                                    style={{backgroundColor: this.props.config.color.principal}}
                                />
                                {this.props.dictionary.FAQ.q2}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel style={collapsibleItemStyle}>
                            <p style={collapsibleTextStyle}>{this.props.dictionary.FAQ.q2p1}.</p>
                            <p style={collapsibleTextStyle}>{this.props.dictionary.seemore}.
                                <Link to="/support/blog/get_membership">{this.props.dictionary.FAQ.q7}</Link>
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton style={collapsibleHeaderStyle}>
                                <Ionicon
                                    icon='md-help-circle'
                                    fontSize="25px"
                                    color="rgb(92, 92, 92)"
                                    className="icon-ask"
                                    style={{backgroundColor: this.props.config.color.principal}}
                                />
                                {this.props.dictionary.FAQ.q3}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel style={collapsibleItemStyle}>
                            <ol style={collapsibleTextStyle}>
                                <li>
                                    {this.props.dictionary.FAQ.q3p1}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q3p2}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q3p3}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q3p4}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q3p5}
                                </li>
                            </ol>
                            <Link to="/support/blog/recover_password">
                                {this.props.dictionary.seemore}
                            </Link>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton style={collapsibleHeaderStyle}>
                                <Ionicon
                                    icon='md-help-circle'
                                    fontSize="25px"
                                    color="rgb(92, 92, 92)"
                                    className="icon-ask"
                                    style={{backgroundColor: this.props.config.color.principal}}
                                />
                                {this.props.dictionary.FAQ.q4}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel style={collapsibleItemStyle}>
                            <ol style={collapsibleTextStyle}>
                                <li>
                                    {this.props.dictionary.FAQ.q4p1}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q4p2}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q4p3}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q4p4}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q4p5}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q4p6}
                                </li>
                            </ol>
                            <Link to="/support/blog/change_password">
                                {this.props.dictionary.seemore}
                            </Link>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton style={collapsibleHeaderStyle}>
                                <Ionicon
                                    icon='md-help-circle'
                                    fontSize="25px"
                                    color="rgb(92, 92, 92)"
                                    className="icon-ask"
                                    style={{backgroundColor: this.props.config.color.principal}}
                                />
                                {this.props.dictionary.FAQ.q5} {/*Cancel membership*/}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel style={collapsibleItemStyle}>
                            {/*Cancel membership contain*/}
                            <CancelMembershipSteps
                                styles={collapsibleTextStyle}
                                dictionary={this.props.dictionary}
                                config={this.props.config}
                                getPiCodes={this.props.getPiCodes}
                            />
                            <Link to="/support/blog/cancel_membership">
                                {this.props.dictionary.seemore}
                            </Link>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton style={bottomBorder}>
                                <Ionicon
                                    icon='md-help-circle'
                                    fontSize="25px"
                                    color="rgb(92, 92, 92)"
                                    className="icon-ask"
                                    style={{backgroundColor: this.props.config.color.principal}}
                                />
                                {this.props.dictionary.FAQ.q6}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel style={collapsibleItemStyle}>
                            <Link to="/support">{this.props.dictionary.asksupport}</Link>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

    render() {

        return (
            <div
                id="container-support"
                className="container main-container center-align"
                style={{
                    zIndex: 0,
                    backgroundColor: this.props.config.color.principal
                }}
            >
                <h5
                    style={{
                        color: this.props.config.color.principalText,
                    }}
                >
                    F . A . Q .
                </h5>
                {this.renderObject()}
            </div>
        )

    }
}

export default FAQ;
