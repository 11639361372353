import React from 'react';

const ImageIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 28 21"
        >
            <path 
                fill={color} 
                d="M4.612 5.948c0 1.222.994 2.215 2.215 2.215 1.221 0 2.215-.993 2.215-2.215 0-1.22-.994-2.214-2.215-2.214-1.221 0-2.215.993-2.215 2.214z"
            />
            <path 
                fill={color} 
                fillRule="evenodd" 
                d="M4.941 0C2.212 0 0 2.212 0 4.941v10.706c0 .421.053.83.152 1.22l4.935-3.28c.945-.627 2.323-.634 3.278-.014l3.292 2.134 8.807-6.888c.509-.398 1.214-.61 1.933-.581.72.029 1.393.296 1.847.732L28 12.582v-7.64C28 2.211 25.788 0 23.059 0H4.94zM3.043 5.948c0-2.086 1.697-3.784 3.784-3.784 2.087 0 3.784 1.698 3.784 3.784 0 2.087-1.697 3.785-3.784 3.785-2.087 0-3.784-1.698-3.784-3.785z" 
                clipRule="evenodd"
            />
            <path 
                fill={color} 
                d="M28 14.967l-5.25-5.049c-.152-.146-.348-.175-.452-.18-.103-.003-.302.01-.472.143l-9.427 7.373c-.352.275-.913.296-1.296.048l-3.968-2.573c-.233-.152-.57-.15-.801.004L.83 18.39c.886 1.325 2.397 2.198 4.111 2.198H23.06c2.729 0 4.941-2.212 4.941-4.94v-.681z"
            />
        </svg>
    )
}
export default ImageIcon;
