import React, {Component} from "react";
class Tabs extends Component {
    constructor(props) {
        super();
    }

    drawTabs() {
        let tabs = [...this.props.tabs];
        let config = this.props.config;
        return tabs.map( (el, idnex) => {
            return (
                <li
                    key={idnex}
                    style={(this.props.tab === el.option) ? {
                        color : config.color.secondaryText,
                        backgroundColor : config.color.secondary
                    } : {}
                    }
                    onClick={() => this.props.changeTab(el.option)}
                >
                    {el.name}
                </li>
            )
        })
    }

    render(){
        return(
            <ul className="tab">
                {this.drawTabs()}
            </ul>
        )
    }
}

export default Tabs;
