import React from 'react';
import Modal from '../../ModalPortal/ModalPortal';
import './ModalSwitchCamera.css';
const  ModalSwitchCamera  =  props => {
    
    

    const renderObject = () => {
        if(props.videoInput.length === 0)return;
        return props.videoInput.map((res, i) => {
            
                let label = res.label.split(',');
                label=label.length>1?label[1]:res.label;
                
            return (<p key={res.deviceId}>
                    <input 
                        value={res.deviceId}
                        type="radio"
                        id={res.deviceId}
                        className="input__radius"
                        style={{checked: 'red'}}
                        onClick={()=>{props.switchVideoMode(res.deviceId)}}
                    />
                    <label for={res.deviceId}> {label}</label>
                    </p>
            )
        });
    }

    return(<Modal 
                show={props.show}
                onCancel={props.onCancel}
                config={props.config}
                header={props.title}
                footer={props.footer}
                blackModal={props.blackModal}
    >   <center style={{borderColor: props.config.color.secondary}}>
            <h6 className="title__switch__camera">Select Camera</h6>
            {renderObject()}
        </center>
        </Modal>)


};

export default ModalSwitchCamera;