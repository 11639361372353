import React, { Component } from 'react';
import userIcon from '../../../../../img/generic-user-icon.jpg';
class ConversationFilterItem extends Component {
    // constructor(props){
    //     super(props);
    // }

    render(){

        let conversation = this.props.data;

        let userImage = conversation.image_profile
            ? this.props.config.storageURL + '/' + this.props.config.storage + '/profiles/' + conversation.image_profile
            : userIcon;

        let accesChat = () => {
            let param = {
                userId: conversation.user_id_user,
                username: conversation.username,
                userImage: userImage,
                filter: this.props.filter
            }
            this.props.toElevate.onClickConversation(param)
        };

        return <li key={conversation.user_id_user} onClick={accesChat}
                    className={'v2-chat-conversation-filter-item'}>
                <img alt={"user-icon"} src={userImage} />
            <span>{conversation.username}</span>
        </li>;
    }
}

export default ConversationFilterItem;