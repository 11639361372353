import React from 'react';

const FitnessIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 24 24"
        >
            <path 
                fill={color}
                d="M23.587 9.86c-.228 0-.413.241-.413.537v3.206c0 .296.185.537.414.537.228 0 .412-.24.412-.537v-3.206c0-.296-.185-.537-.413-.537zM.414 9.86c-.229 0-.414.241-.414.537v3.205c0 .297.185.537.414.538.228 0 .412-.24.412-.537v-3.206c0-.296-.184-.537-.412-.537zM5.499 5c-.761 0-1.378.721-1.378 1.61v10.78c0 .89.617 1.61 1.378 1.61.76 0 1.378-.721 1.378-1.61v-4.092h10.025v4.093c0 .888.617 1.609 1.378 1.609.761 0 1.378-.721 1.378-1.61V6.61c0-.89-.617-1.61-1.378-1.61-.76 0-1.378.72-1.378 1.61v4.092H6.877V6.609C6.877 5.72 6.26 5 5.5 5zM21.483 7.15h-.135c-.573 0-1.039.48-1.039 1.072v7.556c0 .592.466 1.073 1.04 1.073h.134c.574 0 1.04-.48 1.04-1.072V8.223c0-.593-.466-1.073-1.04-1.073zM2.501 7.15h-.135c-.574 0-1.039.48-1.039 1.072v7.556c0 .593.465 1.073 1.039 1.073h.135c.574 0 1.038-.48 1.038-1.072V8.223C3.54 7.63 3.075 7.15 2.501 7.15z"
            />
        </svg>
    )
}
export default FitnessIcon;
