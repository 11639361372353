import React, { Component } from 'react';
import './style.css'

class Post extends Component{
    constructor(props) {
        super();
        this.state = {

        }
    }

    componentDidMount() {
    }

    render(){
        let progress = this.props.progress ? this.props.progress : 0
        let styleCustom = {
            height : this.props.height ? this.props.height : "1em",
            backgroundColor : this.props.colorBack ? this.props.colorBack : "#dfdfdf"
        }
        let styleProgress = {
            height : this.props.height ? this.props.height : "1em",
            backgroundColor : this.props.colorProgress ? this.props.colorProgress : "red",
            width : progress + "%"
        }
        //console.log(post.default)
        return(
            <div
                style={styleCustom}
                className="custom-progress-bar"
            >
                <div
                    style={styleProgress}
                    className="progress"
                >

                </div>
            </div>
        )
    }
}

export default Post;
