import React from 'react';
import {Link} from 'react-router-dom';
import CoinsIcon from '../../../Svg/CoinsIcon';
import HomeIcon from '../../../Svg/HomeIcon';
import MessagesIcon from '../../../Svg/MessagesIcon';
import LogoutIcon from '../../../Svg/LogoutIcon';
import UserIcon from '../../../Svg/UserIcon';
import toolbox from "../../../Toolbox/Toolbox";
import ListIcon from '../../../Svg/ListIcon';
import ProgressBar from 'react-customizable-progressbar'

const SideItemsLogged = ({
                             activeSection,
                             logOut,
                             admin,
                             dictionary,
                             config,
                             coins,
                             canSellCoins,
                             toElevate,
                             buyCoins,
                             open,
                             permission
                         }) => {

    const notBonusBtn = () => {
        return <>
            <li className={activeSection === "/" ? "active-item" : ""}
                style={
                    activeSection === "/" ?
                        {backgroundColor: config.color.secondary}
                        : {backgroundColor: "transparent"}
                }
            >
                <Link onClick={() => open(false)} to='/'>
                    <HomeIcon
                        className="menu-name"
                        color={activeSection === "/" ? "#fff" : "#c4c4c4"}
                        width={"20px"}
                        height={"18px"}
                    />
                    {dictionary.home}
                </Link>
            </li>
                
                <li className={activeSection === "/profile" ? "active-item" : ""}
                    style={
                        activeSection === "/profile"
                            ? {backgroundColor: config.color.secondary}
                            : {backgroundColor: "transparent"}
                    }
                >
                    <Link onClick={() => open(false)}
                        to='/profile'>
                        <UserIcon
                            color={activeSection === "/profile" ? "#fff" : "#c4c4c4"}
                            width={"20px"}
                            height={"20px"}
                            className="menu-name"
                        />
                        {dictionary.profile}
                    </Link>
                </li>
        </>
    }  

    const bonusBtn = () => { 
        return <>
            <li 
                className={activeSection === "/profile"
                            ? "active-item profile-bonus"
                            : "profile-bonus"
                }
                style={activeSection === "/profile"
                        ? {backgroundColor: config.color.secondary}
                        : {backgroundColor: "transparent"}
                }
            >
                <Link onClick={() => open(false)} to='/profile'>
                    <ProgressBar
                        radius={15}
                        progress={toElevate.bonusActivity.percentage}
                        strokeWidth={3}
                        strokeColor={activeSection === "/profile"
                            ? config.color.secondaryText
                            : config.color.secondary
                        }
                        strokeLinecap="square"
                        trackStrokeWidth={2}
                        trackStrokeColor={activeSection === "/profile"
                            ? config.color.secondary
                            : config.color.secondaryText
                        }
                        className={"progress-bonus"}
                        initialAnimation={true}
                        transition="1.5s ease 0.5s"
                        trackTransition="0s ease"
                    >
                        <div className="indicator" style={{
                            "position": "absolute",
                            "top": "11px"
                        }}>
                            <UserIcon
                                color={activeSection === "/profile" ? "#fff" : "#c4c4c4"}
                                width={"20px"}
                                height={"20px"}
                                className="menu-name"
                            />
                        </div>
                    </ProgressBar>
                    <div>
                        <div>{dictionary.profile}</div>
                        <div className={"font-strong"}>
                            {toElevate.bonusActivity.maxLevel
                                ? dictionary.max
                                : dictionary.lvl + ' ' + toElevate.bonusActivity.level
                            } - Bonus
                        </div>
                    </div>
                </Link>
            </li>
            <li className={activeSection === "/" ? "active-item" : ""}
                style={activeSection === "/" ?
                        {backgroundColor: config.color.secondary}
                        : {backgroundColor: "transparent"}
                }
            >
                <Link onClick={() => open(false)} to='/'>
                    <HomeIcon
                        className="menu-name"
                        color={activeSection === "/" ? "#fff" : "#c4c4c4"}
                        width={"20px"}
                        height={"18px"}
                    />
                    {dictionary.home}
                </Link>
            </li>
        </>
    }
    
    const profileHomeBtns = () => {
        if(canSellCoins && toElevate.bonusActivity && toElevate.bonusActivity.endPeriod) {
            if(permission === 1 || permission === 2) {
                return notBonusBtn(); 
            } else return bonusBtn();
        } else return notBonusBtn();
    }

    return (
        <div>
            <ul className="itemLogged">
                {profileHomeBtns()}
                <li className={activeSection === "/messages" ? "active-item" : ""}
                    style={activeSection === "/messages"
                        ? {backgroundColor: config.color.secondary}
                        : {backgroundColor: "transparent"}
                    }
                >
                    <Link onClick={() => open(false)} to='/messages'>
                        <MessagesIcon
                            color={activeSection === "/messages" ? "#fff" : "#c4c4c4"}
                            width={"20px"}
                            height={"18px"}
                            className="menu-name"
                        />
                        {dictionary.messages}
                    </Link>
                </li>
                {admin}
                <li className={activeSection === "/activity" ? "active-item" : ""}
                    style={
                        activeSection === "/activity" ?
                            {backgroundColor: config.color.secondary}
                            : {backgroundColor: "transparent"}
                    }
                >
                    <Link onClick={() => open(false)}
                          to='/activity'>
                        <ListIcon
                            color={activeSection === "/activity" ? "#fff" : "#c4c4c4"}
                            width={"20px"}
                            height={"20px"}
                            className="list-menu menu-name"
                        />
                        {dictionary.activity}
                    </Link>
                </li>
                {canSellCoins ?
                    <li>
                        <button
                            className='btn btn-coin'
                            onClick={buyCoins}
                            style={{
                                backgroundColor: config.color.principal,
                                color: config.color.secondary
                            }}
                        >
                            <CoinsIcon
                                color={config.color.secondary}
                                colorPrincipal={config.color.principal}
                                width={"21px"}
                                height={"21px"}
                            />
                            {(coins > 999) ? toolbox.kFormatter(coins) : coins}
                        </button>
                        <div className='hide'>

                        </div>
                    </li>
                    : null}
                <li>
                    <a href='#LogOut' onClick={logOut}>
                        <LogoutIcon
                            color={"#c4c4c4"}
                            width={"20px"}
                            height={"20px"}
                            className="menu-name"
                        />
                        {dictionary.logout}
                    </a>
                </li>

            </ul>
        </div>
    )
};
export default SideItemsLogged;
