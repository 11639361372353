import React from 'react';
import ArrowLeftIcon from '../Svg/ArrowLeftICon';
import "./BlackScreen.css";

const BlackScreen = props => {
    return <div className="black__screen">
            <div className="vertical__align">
                <p>  {props.title}</p>
                    <button 
                        style={{background:props.color.secondary}}
                        className="btn__back"
                        onClick={() =>window.location='/'}
                    >
                            <ArrowLeftIcon 
                                color={props.color.secondaryText}
                                width="15"
                                height="16"
                            />
                        </button>
            </div>
    </div>
};

export default BlackScreen;