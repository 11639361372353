import React, {Component, act} from 'react';
import cookie from 'js-cookie';
import Sidenav from 'sidenavjs';
import {Circle} from 'react-preloaders';
import Ionicon from 'react-ionicons';
import axios from 'axios';
import {Link} from 'react-router-dom';
import HeadItems from './HeadItems';
import SideItems from './SideItems';
import HeadItemsLogged from './HeadItemsLogged';
import SideItemsLogged from './SideItemsLogged';
import GetCoins from "../../GetCoins/";
import Advice from '../../Advice';
import MenuIcon from '../../Svg/MenuIcon';
import PencilIcon from '../../Svg/PencilIcon';
import MediaBoxIcon from '../../Svg/MediaBoxIcon';
import SupportIcon from '../../Svg/SupportIcon';
import SafeIcon from '../../Svg/SafeIcon/';

import './SideNav.css';

class SideNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            activeSection: '',
        };

        this.renderObject = this.renderObject.bind(this);
        this.logOut = this.logOut.bind(this);
        this.closeModalCoins = this.closeModalCoins.bind(this);
        this.buyCoins = this.buyCoins.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    toggle = open => {
        let url = window.location.href;
        
        let urlSplit = url.split("?");

        if (urlSplit.length > 1) {
            const base = urlSplit[0].split("/");
            this.setState({open,activeSection: '/'+base[base.length - 1] + "?" + urlSplit[1], });
        } else {
            this.setState({open, activeSection: window.location.pathname});
        }
    }

    componentDidMount() {
        let navLinkChanger = document.querySelectorAll('#sidenav-overlay');
        if (navLinkChanger.length > 0) {
            document.body.style.overflow = 'auto';
            document.body.style.width = 'auto';
        }
        let withSize = window.innerWidth;
        if (withSize < 993) {
            let sideBar = document.querySelector("#nav-mobile");
            if (sideBar) {
                sideBar.style.backgroundColor = this.props.config.color.sideBar;
                sideBar.style.color = this.props.config.color.sideBarText;
            }
        }
        window.addEventListener("resize", () => {
            let sizeWidth = window.innerWidth;
            if (sizeWidth < 993) {
                let sideBar = document.querySelector("#nav-mobile");
                if (sideBar) {
                    sideBar.style.backgroundColor = this.props.config.color.sideBar;
                    sideBar.style.color = this.props.config.color.sideBarText;
                }
            }
        });
        let url = window.location.href;
        
        let urlSplit = url.split("?");
        if (urlSplit.length > 1) {
            const base = urlSplit[0].split("/");
            this.setState({activeSection: '/'+base[base.length - 1] + "?" + urlSplit[1]});
        } else {
            this.setState({activeSection: window.location.pathname});
        }
    }

    logOut = async () => {
        await axios.post('/api/v2/logOut').then(res => {
            cookie.remove('username');
            cookie.remove('token');
            cookie.remove('permission');
            cookie.remove('email');
            cookie.remove("io");
            window.location = '/';
        })
    };

    buyCoins = async () => {
        this.toggle(false);
        this.setState({showModalCoins: true});
    };

    closeModalCoins = async () => {
        this.setState({showModalCoins: false});
    };

    renderObject(toElevate) {
        let extraMenu = [];

        if (this.props.permission === 1 || this.props.permission === 4) {
            extraMenu = [
                <li key={5}
                    className={this.state.activeSection === "/post" ? "active-item" : ""}
                    style={
                        this.state.activeSection === "/post" ?
                            {backgroundColor: this.props.config.color.secondary}
                            : {backgroundColor: "transparent"}
                    }
                >
                    <Link onClick={() => this.toggle(false)} to='/post'>
                        <PencilIcon
                            color={this.state.activeSection === "/post" ? "#fff" : "#c4c4c4"}
                            width={"20px"}
                            height={"18px"}
                            className='menu-name'
                        />
                        {this.props.dictionary.newPost}
                    </Link>
                </li>,
                <li key={6}
                    className={this.state.activeSection === "/analytics" ? "active-item" : ""}
                    style={
                        this.state.activeSection === "/analytics" ?
                            {backgroundColor: this.props.config.color.secondary}
                            : {backgroundColor: "transparent"}
                    }
                >
                    <Link onClick={() => this.toggle(false)} to='/analytics'>
                        <Ionicon
                            className='menu-name'
                            icon="md-analytics"
                            fontSize="22px"
                            color={this.state.activeSection === "/analytics" ? "#fff" : "#c4c4c4"}
                        />
                        {this.props.dictionary.analytics}
                    </Link>
                </li>,
                <li key={7}
                    className={this.state.activeSection === "/MediaBox" ? "active-item" : ""}
                    style={
                        this.state.activeSection === "/MediaBox" ?
                            {backgroundColor: this.props.config.color.secondary}
                            : {backgroundColor: "transparent"}
                    }
                >
                    <Link onClick={() => this.toggle(false)} to='/MediaBox'>
                        <MediaBoxIcon
                            color={this.state.activeSection === "/MediaBox" ? "#fff" : "#c4c4c4"}
                            width={"20px"}
                            height={"20px"}
                            className='menu-name'
                        />
                        {'Media Box'}
                    </Link>
                </li>,
                <li key={8}
                    className={this.state.activeSection === "/settings" ? "active-item" : ""}
                    style={
                        this.state.activeSection === "/settings" ?
                            {backgroundColor: this.props.config.color.secondary}
                            : {backgroundColor: "transparent"}
                    }
                >
                    <Link onClick={() => this.toggle(false)} to='/settings'>
                        <Ionicon
                            className='menu-name'
                            icon="md-settings"
                            fontSize="22px"
                            color={this.state.activeSection === "/settings" ? "#fff" : "#c4c4c4"}
                        />
                        {this.props.dictionary.settings}
                    </Link>
                </li>,
                <li key={9}
                    className={this.state.activeSection === "/news" ? "active-item" : ""}
                    style={
                        this.state.activeSection === "/news" ?
                            {backgroundColor: this.props.config.color.secondary}
                            : {backgroundColor: "transparent"}
                    }
                >
                    <Link onClick={() => this.toggle(false)} to='/news'>
                        <Ionicon
                            className='menu-name'
                            icon="md-paper"
                            fontSize="22px"
                            color={this.state.activeSection === "/news" ? "#fff" : "#c4c4c4"}
                        />
                        {this.props.dictionary.news}
                    </Link>
                </li>
            ];
        } else if (this.props.permission === 2) {
            extraMenu = [
                <li key={5}
                    className={this.state.activeSection === "/panel/support" ? "active-item" : ""}
                    style={
                        this.state.activeSection === "/panel/support" ?
                            {backgroundColor: this.props.config.color.secondary}
                            : {backgroundColor: "transparent"}
                    }
                >
                    <Link onClick={() => this.toggle(false)} to='/panel/support'>
                        <SupportIcon
                            color={this.state.activeSection === "/panel/support" ? "#fff" : "#c4c4c4"}
                            width={"20px"}
                            height={"20px"}
                            className='menu-name'
                        />
                        {this.props.dictionary.panelSupport}
                    </Link>
                </li>,
                <li key={6}
                    className={this.state.activeSection === "/support/faq" ? "active-item" : ""}
                    style={
                        this.state.activeSection === "/support/faq" ?
                            {backgroundColor: this.props.config.color.secondary}
                            : {backgroundColor: "transparent"}
                    }
                >
                    <Link onClick={() => this.toggle(false)}
                          className='navLink'
                          to='/support/faq'
                    >
                        <Ionicon
                            className='menu-name'
                            icon="md-help-circle"
                            fontSize="22px"
                            color={this.state.activeSection === "/support/faq" ? "#fff" : "#c4c4c4"}
                        />
                        F.A.Q.
                    </Link>
                </li>,
                <li key={7}
                    className={this.state.activeSection === "/MediaBox" ? "active-item" : ""}
                    style={
                        this.state.activeSection === "/MediaBox" ?
                            {backgroundColor: this.props.config.color.secondary}
                            : {backgroundColor: "transparent"}
                    }
                >
                    <Link onClick={() => this.toggle(false)}
                          to='/MediaBox'>
                        <MediaBoxIcon
                            color={this.state.activeSection === "/MediaBox" ? "#fff" : "#c4c4c4"}
                            width={"20px"}
                            height={"20px"}
                            className='menu-name'
                        />
                        {'Media Box'}
                    </Link>
                </li>,
                <li key={8}
                    className={this.state.activeSection === "/analytics" ? "active-item" : ""}
                    style={
                        this.state.activeSection === "/analytics" ?
                            {backgroundColor: this.props.config.color.secondary}
                            : {backgroundColor: "transparent"}
                    }
                >
                    <Link onClick={() => this.toggle(false)}
                          to='/analytics'>
                        <Ionicon
                            className='menu-name'
                            icon="md-analytics"
                            fontSize="22px"
                            color={this.state.activeSection === "/analytics" ? "#fff" : "#c4c4c4"}
                        />
                        {this.props.dictionary.analytics}
                    </Link>
                </li>
            ]
        } else {
            extraMenu = [
                <li key={5}
                    className={this.state.activeSection === "/membership" ? "active-item" : ""}
                    style={
                        this.state.activeSection === "/membership" ?
                            {backgroundColor: this.props.config.color.secondary}
                            : {backgroundColor: "transparent"}
                    }
                >
                    <Link onClick={() => this.toggle(false)} to='/membership'>
                        <Ionicon
                            className='menu-name'
                            icon="md-card"
                            fontSize="22px"
                            color={this.state.activeSection === "/membership" ? "#fff" : "#c4c4c4"}
                        />
                        {this.props.dictionary.membership}
                    </Link>
                </li>,
                <li key={6}
                    className={this.state.activeSection === "/purchases" ? "active-item" : ""}
                    style={
                        this.state.activeSection === "/purchases" ?
                            {backgroundColor: this.props.config.color.secondary}
                            : {backgroundColor: "transparent"}
                    }
                >
                    <Link onClick={() => this.toggle(false)}
                          to='/purchases'>
                        <SafeIcon
                            color={this.state.activeSection === "/purchases" ? "#fff" : "#c4c4c4"}
                            width={"22px"}
                            height={"22px"}
                            className='menu-name'
                        />
                        {this.props.dictionary.purchases}
                    </Link>
                </li>,
                <li key={7}
                    className={this.state.activeSection === "/support" ? "active-item" : ""}
                    style={
                        this.state.activeSection === "/support" ?
                            {backgroundColor: this.props.config.color.secondary}
                            : {backgroundColor: "transparent"}
                    }
                >
                    <Link
                        onClick={() => {this.toggle(false); toElevate.forceUpdate()}}
                        className='navLink'
                        to='/support'
                    >
                        <SupportIcon
                            color={this.state.activeSection === "/support" ? "#fff" : "#c4c4c4"}
                            width={"20px"}
                            height={"20px"}
                            className='menu-name'
                        />
                        {this.props.dictionary.support}
                    </Link>
                </li>,
                <li key={8} className={this.state.activeSection === "/support?subject=report" ? "active-item" : ""}
                    style={
                        this.state.activeSection === "/support?subject=report" ?
                            {backgroundColor: this.props.config.color.secondary}
                            : {backgroundColor: "transparent"}
                    }
                >
                    <Link onClick={() => {this.toggle(false); toElevate.forceUpdate()}} to='/support?subject=report'>
                        <Ionicon
                            className='menu-name'
                            icon="md-alert"
                            fontSize="22px"
                            color={this.state.activeSection === "/support?subject=report" ? "#fff" : "#c4c4c4"}
                        />
                        {this.props.dictionary.reportContent}
                    </Link>
                </li>
            ]
        }
        return extraMenu;
    }
    

    render() {
        const {
            dictionary,
            config,
            landingPage,
            authenticated,
            permission,
            toElevate,
            notification,
            coins,
            canSellCoins,
            streaming
        } = this.props;
        if (dictionary.length === 0 || config.length === 0)
            return <div className="center-align" style={{paddingTop: 45}}>
                <Circle color="#4285f4" className="center-align"/>
            </div>;

        const logo = <img src={config.storageURL + config.logo} className="responsive-img logo"
                          alt={config.nameModel}/>;
        let navbarTypeStyle = landingPage === 1 ? "new-navbar" : "nlp-navbar";

        return (
            <div className={authenticated ? "new-navbarLogged" : navbarTypeStyle}
                 style={{
                     backgroundColor: config.color.navbar
                 }}
            >
                <Sidenav open={this.state.open}
                         onSetOpen={this.toggle}
                         sidenav={
                             <div style={{backgroundColor: config.color.navbar}}
                                  className="navbarDiv"
                             >
                                 <button className="listMenu-btn navbarMenu-btn"
                                         onClick={() => {
                                             this.toggle(false)
                                         }}
                                 >
                                     <MenuIcon
                                         color={"#fff"}
                                         width={"27px"}
                                         height={"27px"}
                                     />
                                 </button>
                                 <h6 className="navbar-title">
                                     Menu
                                 </h6>
                                 {authenticated
                                     ? <SideItemsLogged
                                         activeSection={this.state.activeSection}
                                         buyCoins={this.buyCoins}
                                         open={this.toggle}
                                         handleNotify={this.state.handleNotify}
                                         config={config}
                                         dictionary={dictionary}
                                         logo={logo}
                                         logOut={this.logOut}
                                         admin={this.renderObject(toElevate)}
                                         coins={coins}
                                         canSellCoins={canSellCoins}
                                         toElevate={toElevate}
                                         permission={permission}
                                     />
                                     : <SideItems
                                         activeSection={this.state.activeSection}
                                         config={config}
                                         dictionary={dictionary}
                                         open={this.toggle}
                                         forceUpdate={toElevate.forceUpdate}
                                     />
                                 }
                             </div>
                         }
                >
                    <button className="listMenu-btn" onClick={() => {
                        this.toggle(true)
                    }}>
                        <MenuIcon
                            color={"#fff"}
                            width={"27px"}
                            height={"27px"}
                        />
                    </button>

                    {authenticated
                        ? <HeadItemsLogged dictionary={dictionary} config={config} streaming={streaming} permission={permission}/>
                        : <HeadItems landingPage={landingPage}
                                     config={config}
                                     dictionary={dictionary}
                        />
                    }
                </Sidenav>
                {authenticated &&
                <GetCoins
                    id="modal-donation-sidebar"
                    open={this.state.showModalCoins}
                    dictionary={dictionary}
                    config={config}
                    close={this.closeModalCoins}
                    middle={true}
                    height={window.screen.width < 350 ? '75%' : '70%'}
                    top={window.screen.width < 350 ? '25%' : '30%'}
                />
                }
            </div>
        )
    };
}

export default SideNav;
