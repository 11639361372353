import React from 'react';
import { Link } from 'react-router-dom';
import Ionicon from 'react-ionicons';
import './styleDropdown.css';
import MediaIcons from '../Svg/MediaIcons';

class DropdownList extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            displayMenu: false,
        };
        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    };

    showDropdownMenu(event) {
        event.preventDefault();
        this.setState({ displayMenu: true }, () => {
            document.addEventListener('click', this.hideDropdownMenu);
        });
    }

    hideDropdownMenu() {
        this.setState({ displayMenu: false }, () => {
            document.removeEventListener('click', this.hideDropdownMenu);
        });
    }
    renderItems(){
        if (this.props.items.length === 0){
            return (
                <div
                    className="center-align"
                    style={{paddingTop:45}}
                >
                    no items
                </div>
            );
        }
        let copyPosts = this.props.items;
        return copyPosts.map((item, index) => {
            return (
                <Link 
                    key={index} 
                    to={item[1]} 
                    onClick={!item[4] ? null : item[4]}
                > 
                    <li>
                          { item[2] ? 
                            <Ionicon 
                                className='dropdown-icon' 
                                icon={item[2]}
                                fontSize="20px" 
                                color={item[3] ? item[3] : "#232323"}
                            />
                        : item[5]?
                            <MediaIcons
                                iconName={item[5]}
                                color={item[3]}
                                width={item[6]}
                                height={item[6]}
                            />
                        : null }
                        {item[0] ?
                            <div>
                                {item[0]}
                            </div>
                        : null }
                    </li>
                </Link>
            )
        });
    };

    render() {
        return (
            <div  className="dropdown-list" >
                <div className="dropdown-button" onClick={this.showDropdownMenu}> 
                    {this.props.trigger}
                </div>
                { this.state.displayMenu ?
                    <ul>
                        {this.renderItems()}
                    </ul>
                : null }
            </div>
        );
    }
}

export default DropdownList;