import React, {Component} from "react";
import './style.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Cube, Circle} from "react-preloaders";
import ProgressBar from 'react-customizable-progressbar'

class MediaViewer extends Component{

    drawSaving () {
        let config = this.props.config;
        let type = this.props.savingStyle ? this.props.savingStyle : "Circle";
        switch (type) {
            case "Cube":
                return(
                    <div>
                        <span>{this.props.titleSaving}</span>
                        <Cube background="transparent !important" color={config.color.secondary} className="saving-loader" />
                    </div>
                )// eslint-disable-next-line
                break;
            case "Circle":
                return(
                    <div>
                        <span>{this.props.titleSaving}</span>
                        <Circle background="transparent !important" color={config.color.secondary} className="saving-loader"/>
                    </div>
                )// eslint-disable-next-line
                break;
            default :
                return(
                    <div>
                        <span>{this.props.titleSaving}</span>
                        <Circle background="transparent !important" color={config.color.secondary} className="saving-loader" />
                    </div>
                )// eslint-disable-next-line
                break;
        }
    }
    drawLoading () {
        let config = this.props.config;
        let progress = this.props.progress ? this.props.progress : null;
        return(
            <ProgressBar
                radius={50}
                progress={(progress !== null) ? parseFloat(progress) : 100}
                strokeWidth={10}
                strokeColor={config.color.secondary}
                strokeLinecap="square"
                trackStrokeWidth={5}
            >
                <div className="indicator">
                    {progress !== null ?
                        <div>{parseFloat(progress).toFixed(2)}%</div>
                        : null
                    }
                </div>
            </ProgressBar>
        )

    }
    render() {
        //let config = this.props.config;
        let id = this.props.id;
        let saving = this.props.saving;
        let loading = this.props.loading;
        return(
            <div
                id={id}
                className="saving"
            >
                {saving ?
                    this.drawSaving() : null
                }
                {loading ?
                    this.drawLoading() : null
                }
            </div>
        )
    }
}
export default MediaViewer;
