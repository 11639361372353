import React, {Component} from 'react';
import {Circle} from 'react-preloaders';

class CreateAccount extends Component {
    render() {
        if (this.props.dictionary.length === 0 || this.props.config.length === 0)
            return <div className="center-align" style={{paddingTop: 45}}>
                <Circle color="#4285f4" className="center-align"/>
            </div>;

        return (
            <div id="container-support"
                 className="container main-container center-align"
                 style={{
                     backgroundColor: this.props.config.color.principal,
                     color: this.props.config.color.principalText,
                 }}
            >
                <h5>{this.props.dictionary.FAQ.q1}</h5>
                <div className="faq-steps">
                    <ol>
                        <li>
                            {this.props.dictionary.FAQ.q1p1}
                        </li>
                        <li>
                            {this.props.dictionary.FAQ.q1p2}
                        </li>
                        <li>
                            {this.props.dictionary.FAQ.q1p3}
                            <ul>
                                <li>
                                    {this.props.dictionary.FAQ.q1p4}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q1p5}
                                </li>
                                <li>
                                    {this.props.dictionary.FAQ.q1p6}
                                </li>
                            </ul>
                        </li>
                        <li>
                            {this.props.dictionary.FAQ.q1p7}
                        </li>
                    </ol>
                </div>
            </div>
        )
    }
}

export default CreateAccount;
