import React, { Component } from 'react';
import Icon from "react-ionicons";
import DropdownList from "../../DropdownList";
import {Cube} from "react-preloaders";
import ProgressBar from 'react-customizable-progressbar';
import MoreIcon from '../../Svg/MoreIcon';

class MediaBoxElement extends Component{
    renderMedia(){
        let media = this.props.media;
        let config = this.props.config;
        let index = this.props.index;

        if(!media.finished && media.progress>=100){
            return (
                <Cube background="transparent !important" color={config.color.secondary} className="mediabox-loader"/>
            )
        }
        else {
            if (!media.image){
                return null;
            }
            else if (media.video) {
                return (
                    <video
                        preload="yes"
                        poster={config.storageURL + '/' + config.storage + "/message/model/" + media.image}
                        className="responsive-video imgMediaBox"
                        controlsList="nodownload"
                        onClick={(this.props.openViewer) ?
                            (e) => this.props.openViewer(e, media, index)
                            : null
                        }
                    >
                        <source
                            src={config.videoStorageURL + '/' + config.storage + "/message/model/" + media.video}
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                )
            } else {
                return (
                    <img
                        alt="media"
                        src={config.storageURL + '/' + config.storage + "/message/model/" + media.image}
                        className="responsive-img"
                        onClick={(this.props.openViewer) ?
                            (e) => this.props.openViewer(e, media, index)
                            : null
                        }
                    />
                )
            }
        }
    }

    render(){
        let config = this.props.config;
        let media = this.props.media;
        let dictionary = this.props.dictionary;
        let index = this.props.index;
        let choosen = (this.props.isChoosen) ? true : false;
        return(
            <div
                className={(this.props.openViewer) ? "openable mediabox-element" : "mediabox-element"}
                style={{
                    border : (choosen) ? "3px solid " + config.color.secondary : ""
                }}
                onClick={
                (this.props.choose) ?
                        (e) => this.props.choose(index, choosen)
                    :
                        null
                }
            >
                {media.finished && this.props.delete ?
                    <DropdownList
                        items={
                            [
                                [
                                    dictionary.delete,
                                    "#",
                                    "md-trash",
                                    config.color.secondary,
                                    () => this.props.delete(media.id, index, media.fakeRender)
                                ]
                            ]
                        }
                        trigger={
                            <MoreIcon
                                color={"#fff"}
                                width={"18px"}
                                height={"18px"}
                                className="more-media"
                            />
                        }
                    />
                    : null
                }
                {this.renderMedia()}
                {media.video && media.finished ?
                    <Icon
                        icon="md-arrow-dropright-circle"
                        className="play-video"
                        fontSize="4em"
                        color="rgba(255,255,255,0.5)"
                    />
                    :
                    null
                }
                {(choosen) ?
                    <Icon
                        icon="md-checkmark-circle"
                        className="play-video"
                        fontSize="4em"
                        color={config.color.secondary}
                    />
                    :
                    null
                }
                {(media.progress !== undefined && media.progress < 100)
                    ? <ProgressBar
                        radius={20}
                        progress={parseFloat(media.progress)}
                        strokeWidth={5}
                        strokeColor={config.color.secondary}
                        strokeLinecap="square"
                        trackStrokeWidth={5}
                    >
                        <div className="indicator">
                            <div>{parseFloat(media.progress)}%</div>
                        </div>
                    </ProgressBar>
                    : null
                }
            </div>
        )
    }
}

export default MediaBoxElement;
