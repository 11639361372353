import React from 'react';

const MenuIcon= ({color, width, height, title, description})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            fill={color}
            viewBox="0 0 34 34"
            role="img"
        >
            <title>{title || ""}</title>
            <desc>{description || ""}</desc>
            <path 
                fill="#000" 
                stroke="#F3876B" 
                d="M.5 8C.5 3.858 3.858.5 8 .5h18c4.142 0 7.5 3.358 7.5 7.5v18c0 4.142-3.358 7.5-7.5 7.5H8C3.858 33.5.5 30.142.5 26V8z" 
                opacity=".3"
            />
            <path 
                fill="#fff" 
                d="M25.772 9.917H8.227c-.632 0-1.144.56-1.144 1.25s.512 1.25 1.144 1.25h17.545c.632 0 1.145-.56 1.145-1.25s-.513-1.25-1.145-1.25zM18.907 15.75H8.227c-.631 0-1.144.56-1.144 1.25s.513 1.25 1.144 1.25h10.68c.632 0 1.144-.56 1.144-1.25s-.512-1.25-1.144-1.25zM21.195 21.583H8.227c-.631 0-1.144.56-1.144 1.25s.513 1.25 1.144 1.25h12.968c.632 0 1.145-.56 1.145-1.25s-.513-1.25-1.145-1.25z"
            />
        </svg>
    )
}
export default MenuIcon;
