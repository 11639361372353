import React from 'react';

const MusicIcon= ({color, width, height, className = null})=>{
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
        >
            <path 
                fill={color}
                d="M17.54,5.21,10,6.41a.4.4,0,0,0-.34.4v7.86A2.73,2.73,0,0,0,7.81,14a2.51,2.51,0,0,0-2.6,2.4,2.51,2.51,0,0,0,2.6,2.4,2.53,2.53,0,0,0,2.6-2.35h0V9.54L17.2,8.47v3.8a2.71,2.71,0,0,0-1.79-.67,2.51,2.51,0,0,0-2.6,2.4,2.51,2.51,0,0,0,2.6,2.4A2.5,2.5,0,0,0,18,14V5.61a.41.41,0,0,0-.14-.31.4.4,0,0,0-.32-.09ZM7.81,18A1.71,1.71,0,0,1,6,16.4a1.71,1.71,0,0,1,1.8-1.6,1.71,1.71,0,0,1,1.8,1.6A1.71,1.71,0,0,1,7.81,18Zm2.6-9.26V7.15L17.2,6.07V7.66Zm5,6.86a1.71,1.71,0,0,1-1.8-1.6,1.71,1.71,0,0,1,1.8-1.6A1.7,1.7,0,0,1,17.2,14a1.7,1.7,0,0,1-1.79,1.6Zm0,0"
            />
            <path 
                fill={color}
                d="M24,5.61H22.4V4H20.8V2.41H3.21V4H1.62v1.6H0V18.4h1.6V20H3.21v1.59H20.8V20h1.6V18.4H24ZM.82,17.6V6.41h.8V17.6Zm1.6,1.6V4.81h.79V19.2ZM20,20.8H4V3.21H20Zm1.6-1.6h-.8V4.81h.8Zm1.6-1.6h-.8V6.41h.8Zm0,0"
            />
        </svg>
    )
}
export default MusicIcon;
