import React, { Component } from "react";
import {Line} from "react-chartjs-2";

class PostsGraph extends Component {

    // constructor(props) {
    //     super(props);
    // }

    render() {
        let dictionary=this.props.dictionary;
        return (
            <div>
                <h6>
                    * Total : {this.props.totalPosts}
                </h6>
                <div className="activity-graph">
                    <Line
                        height={250}
                        data={{
                            labels: this.props.postsUserDays,
                            datasets: [{
                                label: dictionary.postByDay,
                                backgroundColor     : 'rgba(5,114,214,0.1)',
                                borderColor         : '#0572D6',
                                borderWidth         : 1,
                                pointRadius         : 4,
                                pointBorderWidth    : 2.5,
                                pointBorderColor    : '#0572D6',
                                data: this.props.postsUserValues
                            }]
                        }} options={this.props.options}/>
                </div>
                <div className="legends">
                    <label>
                        <span
                            className="dot"
                            style={{backgroundColor: "#0572D6"}}
                        ></span>
                        {dictionary.postByDay}
                    </label>
                </div>
            </div>
        );
    }
}

export default PostsGraph;
