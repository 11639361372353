import React from 'react';

const VideoIcon= ({color, width, height, className = null, title, description})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 20 20"
            className={className}
            role="img"
        >
            <title>{title || ""}</title>
            <desc>{description || ""}</desc>
            <mask id="prefix__a" fill="#fff">
                <path 
                    fillRule="evenodd" 
                    d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zm-2-7.555c0 .824.941 1.295 1.6.8l3.63-2.723c.533-.4.533-1.2 0-1.6L9.6 6.2C8.94 5.706 8 6.176 8 7v5.445z" 
                    clipRule="evenodd"
                />
            </mask>
            <path 
                fill={color}
                fillRule="evenodd" 
                d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zm-2-7.555c0 .824.941 1.295 1.6.8l3.63-2.723c.533-.4.533-1.2 0-1.6L9.6 6.2C8.94 5.706 8 6.176 8 7v5.445z" 
                clipRule="evenodd"
            />
            <path 
                fill={color}
                d="M9.6 13.245l-.84-1.12.84 1.12zm3.63-2.723l-.84-1.12.84 1.12zm0-1.6l.84-1.12-.84 1.12zM9.6 6.2l-.84 1.12.84-1.12zm9 3.8c0 4.75-3.85 8.6-8.6 8.6v2.8c6.296 0 11.4-5.104 11.4-11.4h-2.8zM10 1.4c4.75 0 8.6 3.85 8.6 8.6h2.8c0-6.296-5.104-11.4-11.4-11.4v2.8zM1.4 10c0-4.75 3.85-8.6 8.6-8.6v-2.8C3.704-1.4-1.4 3.704-1.4 10h2.8zm8.6 8.6c-4.75 0-8.6-3.85-8.6-8.6h-2.8c0 6.296 5.104 11.4 11.4 11.4v-2.8zm-1.24-6.475c.264-.197.64-.01.64.32H6.6c0 1.978 2.258 3.107 3.84 1.92l-1.68-2.24zm3.63-2.723l-3.63 2.723 1.68 2.24 3.63-2.723-1.68-2.24zm0 .64c-.214-.16-.214-.48 0-.64l1.68 2.24c1.28-.96 1.28-2.88 0-3.84l-1.68 2.24zM8.76 7.32l3.63 2.722 1.68-2.24-3.63-2.722-1.68 2.24zM9.4 7c0 .33-.376.518-.64.32l1.68-2.24C8.858 3.893 6.6 5.022 6.6 7h2.8zm0 5.445V7H6.6v5.445h2.8z" 
                mask="url(#prefix__a)"
            />
        </svg>
    )
}
export default VideoIcon;
