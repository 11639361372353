import React, {Component} from "react";
class SwitchBtn extends Component {

    render(){
        let dictionary = this.props.dictionary;
        let config = this.props.config;
        return(
            <ul className="tab">
                <li
                    style={(this.props.tab === 0) ? {
                        color : config.color.secondaryText,
                        backgroundColor : config.color.secondary
                    } : {}
                    }
                    onClick={() => this.props.changeTab(0)}
                >
                    {dictionary.sales}
                </li>
                <li
                    style={(this.props.tab === 1) ? {
                        color : config.color.secondaryText,
                        backgroundColor : config.color.secondary
                    } : {}
                    }
                    onClick={() => this.props.changeTab(1)}
                >
                    {dictionary.activity}
                </li>
            </ul>
        )
    }
}

export default SwitchBtn;
