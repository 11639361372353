import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { Preloader, Oval } from 'react-preloader-icon';

const DaySales = (props) => {
    const [todayAnalytics, getSalesDay] = useState({});
    const [onlyModel2, onlyModelChange] = useState("2");
    useEffect(() => {
        loadDayPeriod("2");
    }, []);


    const loadDayPeriod = async (onlyModel) =>{
        onlyModelChange(onlyModel);
        let start = moment().format("YYYY-MM-DD");
        let end = moment().format("YYYY-MM-DD");
        return await axios.get("/api/v2/analytics/model", {
                params: {
                    firstInterval: start,
                    lastInterval: end,
                    onlyModel: onlyModel,
                },
            })
            .then(async (res) => {
                if(!res.data)
                    return false;

                const subtotal =
                    (res.data[0].totalNews || 0) +
                    (res.data[0].totalRenews || 0) +
                    (res.data[0].totalCoins || 0);
                const todayAnalytics = {
                    dayNews: res.data[0].totalNews || 0,
                    dayRenews: res.data[0].totalRenews || 0,
                    dayCoins: res.data[0].totalCoins || 0,
                    subtotal: subtotal,
                };
                getSalesDay(todayAnalytics);
                return todayAnalytics;
            });
    };
    let loadingEarning = <span className="loaderElement" >
        <Preloader
            use={Oval}
            size={26}
            strokeWidth={8}
            strokeColor={props.config.color.secondary}
            duration={800}
        />
    </span>;
    if (!Object.keys(todayAnalytics).length) return null;
    return (
        <div className="day-sales v3-content">
            <div
                className="active-membership"
                style={{
                    backgroundColor: props.config.color.principal,
                    color: props.config.color.principalText,
                    marginBottom: 20,
                    marginTop: "-2rem",
                    zIndex: 10,
                }}
            >
                <span className="dot" style={{ backgroundColor: "#f3876b" }}></span>
                <span className="font-soft">{props.dictionary.activeMemb}</span>
                {props.loadingTotalMemberships
                    ? loadingEarning
                    : <span>
                        {
                            props.totalMemberships
                        }
                    </span>
                }
            </div>
            <div
                style={{
                    backgroundColor: props.config.color.principal,
                    color: props.config.color.principalText,
                    marginBottom: 25,
                }}
                className="v3-content-box"
            >
                <p className="title-content">{props.dictionary.todaySells}</p>
                <div className="percentage-erning">
                    <label>
                        <input
                            type="radio"
                            name="onlyModel2"
                            className="input-st"
                            value="2"
                            onChange={(e) => loadDayPeriod("2")}
                            checked={onlyModel2 === "2"}
                        />
                        <span className="span-st">
                            {props.dictionary.mySplit +
                                " (" +
                                props.percentage +
                                "%)"}
                        </span>
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="onlyModel2"
                            className="input-st"
                            value="0"
                            onChange={(e) => loadDayPeriod("0")}
                            checked={onlyModel2 === "0"}
                        />
                        <span className="span-st">Total (100%)</span>
                    </label>
                </div>
                <ul className="subtotal-list">
                    <li>
                        <span>
                            <strong>Subtotal</strong>
                        </span>{" "}
                        <span>
                            <strong>{props.formatMoney(todayAnalytics.subtotal)}</strong>
                        </span>
                    </li>
                </ul>
                <ul className="erning-list">
                    <li>
                        <span
                            className="dot"
                            style={{ backgroundColor: "#692fe1" }}
                        ></span>
                        <span className="font-soft">
                            <strong>{props.dictionary.newMembers}</strong>
                        </span>{" "}
                        <span className="font-strong">
                            {props.formatMoney(todayAnalytics.dayNews)}
                        </span>
                    </li>
                    <li>
                        <span
                            className="dot"
                            style={{ backgroundColor: "#e39dcd" }}
                        ></span>
                        <span className="font-soft">
                            <strong>{props.dictionary.renewal}</strong>
                        </span>
                        <span className="font-strong">
                            {props.formatMoney(todayAnalytics.dayRenews)}
                        </span>
                    </li>
                    <li>
                        <span
                            className="dot"
                            style={{ backgroundColor: "#5ab6e2" }}
                        ></span>
                        <span className="font-soft">
                            <strong>{props.dictionary.coins}</strong>
                        </span>
                        <span className="font-strong">
                            {props.formatMoney(todayAnalytics.dayCoins)}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default DaySales;
