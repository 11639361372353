import React, { Component } from "react";
import moment from "moment";
import "datejs";
import toolbox from "../../../components/Toolbox/Toolbox";

class SelectPeriod extends Component{
    constructor() {
        super();
        this.state= {
            period: null
        }
    }
    componentDidMount() {
        new Promise(resolve => {
            resolve(this.renderSpecificPeriod());
        }).then(resp => {
            this.setState({period: resp})
        })
    }

    renderSpecificPeriod = () => {
        let start = "2018-01-05";
        let end = "2018-01-13";

        let dateArry = [];
        let newExtraWeek=false;


        while (new Date(start) < new Date()) {
            if(newExtraWeek){
                start = Date.parse(start)
                .next()
                .saturday()
                .next()
                .saturday()
                .next()
                .saturday().toString("yyyy-MM-dd");
                newExtraWeek=false;
            }
            else{
                start = Date.parse(start)
                .next()
                .saturday()
                .next()
                .saturday()
                .toString("yyyy-MM-dd");
            }
            if(start === "2020-06-27" || newExtraWeek){
                newExtraWeek=true;
                end = Date.parse(start)
                    .next()
                    .friday()
                    .next()
                    .friday()
                    .next()
                    .friday()
                    .toString("yyyy-MM-dd");
            }
            else if (start === "2020-08-29" || newExtraWeek) {
                newExtraWeek = true;
                end = Date.parse(start)
                    .next()
                    .friday()
                    .next()
                    .friday()
                    .next()
                    .friday()
                    .toString("yyyy-MM-dd");
            }
            else{
                end = Date.parse(start)
                    .next()
                    .friday()
                    .next()
                    .friday()
                    .toString("yyyy-MM-dd");
            }
            dateArry.push({
                start: toolbox.formatDatePayment(start, this.props.dictionary.languageKey),
                end: toolbox.formatDatePayment(end, this.props.dictionary.languageKey),
                startFormatDate: start,
                endFormatDate: end
            });
        }
        dateArry = dateArry.splice(dateArry.length - 10);
        dateArry.pop();
        dateArry.reverse();
        let items = [];
        dateArry.map((item, index) => {
            return items.push(
                <option key={index + 8} value={item.startFormatDate + ":" + item.endFormatDate}>
                    {item.start} -> {item.end}
                </option>
            );
        });
        return items;
    };
    changeRange = (e) => {
        const period = e.target.value;
        let fin = moment();
        let inicio = moment();
        switch (period) {
            case "0":
                fin = moment();
                inicio = moment().day(0);
                break;
            case "1":
                fin = moment().subtract(1, "week");
                inicio = moment().subtract(1, "week").subtract(6, "days");
                break;
            case "2":
                fin = moment().subtract(2, "week");
                inicio = moment().subtract(2, "week").subtract(6, "days");
                break;
            case "3":
                fin = moment();
                inicio = moment().startOf("month");
                break;
            case "4":
                fin = moment().subtract(1, "month").endOf("month");
                inicio = moment().subtract(1, "month").startOf("month");
                break;
            case "5":
                fin = moment();
                inicio = moment().subtract(30, "days");
                break;
            case "6":
                fin = moment();
                inicio = moment().subtract(90, "days");
                break;
            case "7":
                fin = moment();
                inicio = moment().startOf("year");
                break;
            case !isNaN(period):
                fin = moment();
                inicio = moment().day(0);
                break;
            default:
                fin = moment();
                inicio = moment().day(0);
                if((period.indexOf(':') !== -1)) {
                    let split = period.trim().split(':');
                    fin = moment(split[1]);
                    inicio = moment(split[0]);
                }
                break;
        }
        this.props.changeRange(inicio, fin);
    }
    render(){
        return (
            <div className="select">
                <select
                    defaultValue={3}
                    className=""
                    name="period"
                    id="periodSelect"
                    onChange={this.changeRange}
                    style={{ color: this.props.config.color.principalText }}
                >
                    <option value="0">{this.props.dictionary.thisWeek}</option>
                    <option value="1">{this.props.dictionary.lastWeek}</option>
                    <option value="2">{this.props.dictionary.twoWeeksAgo}</option>
                    <option value="3">{this.props.dictionary.thisMonth}</option>
                    <option value="4">{this.props.dictionary.lastMonth}</option>
                    <option value="5">{this.props.dictionary.last30days}</option>
                    <option value="6">{this.props.dictionary.last90days}</option>
                    <option value="7">{this.props.dictionary.thisYear}</option>
                    <optgroup label="Specific Pay Periods">
                        {this.state.period}
                    </optgroup>
                </select>
            </div>
        );
    }

}

export default SelectPeriod;
