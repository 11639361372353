import React from "react";

const MicIcon = ({color, width, height}) => {
    return (
        <svg 
            version="1.0" 
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 512.000000 751.000000"
            preserveAspectRatio="xMidYMid meet">
            <g 
                transform="translate(0.000000,751.000000) scale(0.100000,-0.100000)"
                fill={color} 
                stroke="none"
            >
                <path d="M2542 7210 c-241 -34 -465 -145 -635 -314 -141 -139 -242 -315 -298
                -516 l-24 -85 -3 -1205 c-2 -863 0 -1228 8 -1285 51 -357 292 -680 623 -835
                436 -205 936 -110 1271 240 137 144 217 289 278 505 l23 80 3 1190 c2 834 0
                1214 -8 1270 -24 174 -91 345 -190 489 -109 158 -301 313 -479 387 -170 71
                -400 103 -569 79z m333 -525 c182 -61 319 -197 387 -385 l23 -65 0 -1195 0
                -1195 -23 -65 c-70 -193 -222 -338 -417 -396 -96 -29 -249 -24 -350 10 -97 33
                -198 99 -266 175 -57 63 -122 190 -138 269 -8 36 -11 412 -11 1205 0 991 2
                1161 15 1216 50 211 241 397 457 446 93 21 225 13 323 -20z"/>
                <path d="M464 4206 c-60 -19 -107 -58 -140 -118 -28 -51 -29 -57 -28 -183 7
                -755 419 -1498 1069 -1928 209 -138 451 -249 681 -312 99 -27 338 -75 375 -75
                5 0 9 -178 9 -399 l0 -400 -233 -3 -234 -3 -49 -30 c-57 -36 -99 -92 -116
                -157 -32 -125 54 -263 184 -299 24 -6 286 -9 725 -7 686 3 688 3 729 25 48 26
                101 82 122 132 38 90 2 228 -75 287 -64 49 -91 53 -330 54 l-223 0 0 400 c0
                220 3 400 8 400 30 0 265 44 342 64 498 130 954 426 1271 823 323 405 514 929
                520 1423 2 141 -9 181 -64 238 -53 56 -102 76 -182 76 -84 0 -135 -23 -187
                -84 -46 -53 -55 -88 -67 -266 -24 -349 -122 -649 -297 -917 -91 -138 -161
                -223 -291 -347 -261 -251 -562 -409 -932 -487 -117 -25 -143 -27 -366 -27
                -224 0 -249 2 -370 27 -586 125 -1059 485 -1319 1006 -129 256 -185 475 -204
                788 -8 126 -12 146 -36 187 -60 103 -177 148 -292 112z"/>
            </g>
        </svg>
    );
};

export default MicIcon;