import React from 'react';

const SendIcon= props=>{
    //{color, width, height, className = null}

    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={props.width} 
            height={props.height}
            fill="none" 
            viewBox="0 0 20 20"
            className={props.className ? props.className : null}
            onClick={props.onClick && props.onClick}
        >
            <path 
                fill={props.color}
                d="M19.086.07c.208-.108.46-.09.652.046.191.137.29.368.255.602l-2.291 15.416c-.028.183-.135.345-.294.442-.099.06-.211.09-.325.09-.068 0-.136-.011-.201-.033l-8.736-2.985 8.59-10.35L5.635 12.79.423 11.008c-.236-.08-.402-.294-.421-.544-.019-.249.112-.486.334-.601L19.086.07zM7.292 18.542v-3.865l3.389 1.158-2.261 3.077c-.12.163-.308.255-.503.255-.065 0-.131-.01-.195-.031-.257-.085-.43-.324-.43-.594z" 
            />
        </svg>
    )
}
export default SendIcon;