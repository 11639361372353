import React, {Component} from 'react';
import {Circle} from 'react-preloaders';
import Ionicon from 'react-ionicons';
import axios from 'axios'
import cookie from 'js-cookie';
import ReactGA from 'react-ga';
import getParams from '../../components/SearchParams/SearchParams';
import PersonIcon from '../../components/Svg/PersonIcon';
import LockIcon from '../../components/Svg/LockIcon';
import './styleLogin.css';
import {Link} from "react-router-dom";

class Login extends Component {
    constructor(props) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
        this.login = this.login.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.state = {
            username: '',
            email: '',
            password: '',
            recoverMode: false,
            sendRecoverMessage: false,
            devices: false,
            viewPass: false,
            userpwError: false,
            errorEmail: ''
        }
        this.fireTracking();
    }

    fireTracking = () => {
        ReactGA.pageview('/login');
    }

    changeHandler(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    keyPress(event) {
        if (event.key === 'Enter') {
            this.login()
        }
    }

    login = async () => {
        const params = await getParams();
        const promo = params.promo;
        let error = false;
        document.getElementById("input_1").classList.remove("sign-error");
        document.getElementById("input_2").classList.remove("sign-error");
        this.setState({
            userpwError: false
        });
        if (this.state.username.length <= 0) {
            document.getElementById("input_1").classList.add("sign-error");
            document.getElementById("input_1").focus();
            await this.setState({userpwError: this.props.dictionary.pwError});
            error = true;
        }
        if (this.state.password.length < 6) {
            document.getElementById("input_2").classList.add("sign-error");
            document.getElementById("input_2").focus();
            await this.setState({userpwError: this.props.dictionary.pwError});
            error = true;
        }
        if (error) return;
        const user = {
            username: this.state.username.trim(),
            password: this.state.password,
            device: this.state.devices
        }
        axios.post('/api/v2/login', user).then(res => {
            const validRegistration = res.data.valid;
            if (validRegistration) {
                cookie.set('username', res.data.username, {expires: 30});
                cookie.set('token', res.data.token, {expires: 30});
                if (res.data.permission) {
                    cookie.set('permission', res.data.permission, {expires: 30});
                }
                cookie.set('email', res.data.email, {expires: 30});
                ReactGA.set({userId: res.data.username});
                window.location = '/' + (promo ? '?promo=' + promo : '');
            } else if (res.data.devices) {
                this.setState({devices: true});
            } else if (res.data.count > 2) {
                this.setState({recoverMode: true});
            } else {
                this.setState({userpwError: this.props.dictionary.pwError});
            }
        })
    }
    modeRecoverPassword = () => {
        const flag = this.state.recoverMode;
        this.setState({
            recoverMode: !flag,
            sendRecoverMessage: false
        });
    }
    modeLoginAgain = () => {
        this.setState({recoverMode: false});
    }
    modeLogin = () => {
        const flag = this.state.devices;
        this.setState({devices: !flag});
    }
    resetPassword = async () => {
        if (this.state.email.trim().length === 0) return;
        const email = {
            email: this.state.email
        }

        let error = false;

        let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

        if (!emailRegex.test(this.state.email)) {
            await this.setState({errorEmail: this.props.dictionary.incorrectFormatEmail});
            error = true;
        }

        if (error) return;

        await axios.post('/api/v2/resetPassword', email)
            .then(res => {
            })
        this.setState({
            sendRecoverMessage: true,
            email: "",
            errorEmail: ""
        });
    }

    viewPassword() {
        this.setState({viewPass: !this.state.viewPass})
    }

    render() {

        if (this.props.dictionary.length === 0 || this.props.config.length === 0)
            return <div className="center-align" style={{paddingTop: 45}}>
                <Circle color="#4285f4" className="center-align"/>
            </div>;

        return (
            <div
                id="redesign-login-register"
                className="container main-container center-align redesign-login-container"
                style={{
                    zIndex: 0,
                    backgroundColor: this.props.config.color.principal,
                    color: this.props.config.color.principalText
                }}
            >
                <div
                    className={(this.state.recoverMode || this.state.devices ? 'hiddeElement' : '') + ' redesign-log-in'}>
                    <h5>
                        {this.props.dictionary.welcome}
                    </h5>
                    <div className="col input-field-login">
                        <label htmlFor="input_1">
                            <PersonIcon
                                color={"rgb(92, 92, 92)"}
                                width={"20px"}
                                height={"20px"}
                            />
                            {this.props.dictionary.user}
                        </label>
                        <input
                            style={{color: this.props.config.color.principalText}}
                            name="username"
                            id="input_1"
                            minLength="4"
                            maxLength="40"
                            onKeyPress={this.keyPress}
                            className="register-inputs validate"
                            validate="true"
                            onChange={this.changeHandler}
                            type="text"
                            value={this.state.username}
                            placeholder={this.props.dictionary.userTag + " / " + this.props.dictionary.email}
                        />
                    </div>

                    <div style={{position: 'relative'}}>
                        <div className="col input-field-login">
                            <label htmlFor="input_2">
                                <LockIcon
                                    color={"rgb(92, 92, 92)"}
                                    width={"20px"}
                                    height={"20px"}
                                />
                                {this.props.dictionary.password}
                            </label>
                            <input
                                style={{color: this.props.config.color.principalText, paddingRight: 33}}
                                name="password"
                                id="input_2"
                                minLength="6"
                                maxLength="40"
                                onKeyPress={this.keyPress}
                                className="register-inputs validate"
                                validate="true"
                                onChange={this.changeHandler}
                                type={this.state.viewPass ? 'text' : 'password'}
                                value={this.state.password}
                                placeholder={"+6 " + this.props.dictionary.chars}
                            />
                            <span onClick={() => this.viewPassword()}>
                                        <Ionicon
                                            icon={this.state.viewPass ? 'ios-eye-off-outline' : 'ios-eye-outline'}
                                            fontSize="25px"
                                            color="rgb(92, 92, 92)"
                                            small
                                            className="icon-view-pwd"
                                        />
                                    </span>
                        </div>
                        <div className="v2-forgotPwd">
                            <a
                                href='#Resetpassword'
                                className="text-lighten-4"
                                onClick={this.modeRecoverPassword}
                                style={{
                                    color: this.props.config.color.secondary,
                                    fontSize: 11
                                }}
                            >
                                {this.props.dictionary.lostpw}
                            </a>
                        </div>
                    </div>
                    <div className="center-align">
                        <button
                            onClick={this.login}
                            style={{
                                backgroundColor: this.props.config.color.secondary,
                                color: this.props.config.color.secondaryText
                            }}
                            className="create-account-button log-btn"
                            waves='light'
                        >
                            {this.props.dictionary.login}
                        </button>
                        <div className="text-center">{this.props.dictionary.or + " "}
                            <Link style={{color: this.props.config.color.secondary}} to='/register'>
                                {this.props.dictionary.createAccount}
                            </Link>
                        </div>
                    </div>
                    {this.state.userpwError ?
                        <div className="redesign-error-class">{this.state.userpwError}</div>
                        : null}
                </div>

                <div
                    className={(this.state.recoverMode ? '' : 'hiddeElement') + ' redesign-log-in'}
                >
                    {this.state.sendRecoverMessage ?
                        <div>
                            <div className='recovery-message'>
                                <span>{this.props.dictionary.recoverPwSentMessage}.</span>
                            </div>
                            <div className="center-align" style={{paddingBottom: 15}}>
                                <button
                                    onClick={this.modeRecoverPassword}
                                    className="create-account-button log-btn"
                                    waves="light"
                                    style={{
                                        backgroundColor: this.props.config.color.secondary,
                                        color: this.props.config.color.secondaryText
                                    }}
                                >
                                    {this.props.dictionary.login}
                                </button>
                            </div>
                        </div>
                        :
                        <div>
                            <div className='center-align'>
                                <h5>{this.props.dictionary.lostpw}</h5>
                            </div>
                            <div className="col input-field-login">
                                <label htmlFor="input_3">
                                    <Ionicon
                                        icon="ios-mail"
                                        fontSize="25px"
                                        color="rgb(92, 92, 92)"
                                    />
                                    Email
                                </label>

                                <input name="email"
                                       className="register-inputs right"
                                       id="input_3"
                                       type="email"
                                       onChange={this.changeHandler}
                                       value={this.state.email}
                                       minLength="4"
                                       maxLength="255"
                                       placeholder="email@gmail.com"

                                />
                                {this.state.errorEmail ?
                                    <div className="redesign-error-class">{this.state.errorEmail}</div>
                                    : null}
                            </div>
                            <div className="center-align" style={{paddingBottom: 15}}>
                                <button
                                    style={{
                                        backgroundColor: this.props.config.color.secondary,
                                        color: this.props.config.color.secondaryText
                                    }}
                                    onClick={this.resetPassword}
                                    className="log-btn"
                                >
                                    {this.props.dictionary.rstPw}
                                </button>
                            </div>
                            <div className="center-align">
                                <a href='#Login'
                                   className="text-lighten-4"
                                   onClick={this.modeRecoverPassword}
                                   style={{
                                       color: this.props.config.color.secondary,
                                       fontSize: 12
                                   }}
                                >
                                    {this.props.dictionary.login}
                                </a>
                            </div>
                        </div>
                    }
                </div>

                <div
                    className={(this.state.devices ? '' : 'hiddeElement') + ' redesign-log-in'}
                >
                    <div>
                        <div className='recovery-message'>
                                    <span>
                                        {this.props.dictionary.twoDevActive}
                                    </span>
                        </div>
                        <div
                            className="center-align"
                            style={{
                                paddingBottom: 15
                            }}
                        >
                            <button
                                style={{
                                    backgroundColor: this.props.config.color.secondary,
                                    color: this.props.config.color.secondaryText
                                }}
                                onClick={this.modeLogin}
                                className="create-account-button log-btn"
                            >
                                {this.props.dictionary.cancel}
                            </button>

                        </div>
                        <div className="center-align">
                            <a
                                onClick={this.login}
                                href='#Login'
                                style={{
                                    color: this.props.config.color.secondary,
                                    fontSize: 12
                                }}
                                className="text-lighten-4"
                            >
                                {this.props.dictionary.loginHere}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Login;
