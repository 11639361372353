import React, { Component } from "react";
import {Line} from "react-chartjs-2";

class Graph extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scaleShowGridLines: true,
                scaleGridLineColor: "rgba(255,255,255,.09)",
                scaleGridLineWidth: .75,
                scaleShowHorizontalLines: true,
                scaleShowVerticalLines: true,
                bezierCurve: true,
                bezierCurveTension: .19,
                pointDot: true,
                pointDotRadius: 2.5,
                pointDotStrokeWidth: 1,
                pointHitDetectionRadius: 10,
                datasetStroke: false,
                datasetStrokeWidth: 2,
                datasetFill: true,
                offsetGridLines: false,
                tooltips: {
                    mode: 'index',
                    callbacks: {
                        // Use the footer callback to display the sum of the items showing in the tooltip
                        label: function (tooltipItem, data) {
                            return data.datasets[tooltipItem.datasetIndex].label
                                + ": $"
                                + tooltipItem.yLabel.toFixed(2).replace(/\d(?=(\d{3})+\.)/g,
                                    '$&,');
                        },
                    }
                },
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            callback: function (value, index, values) {
                                return "$ " + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                            }
                        }
                    }]
                }
            },
            show : -1
        }
        this.changeShow = this.changeShow.bind(this);
    }

    changeShow = (e) => {
        let value = parseInt(e.target.value);
        this.setState({show : value});
    }

    render() {
        let dictionary = this.props.dictionary;
        let dataset = [];
        let show = this.state.show;
        if(show < 0){
            dataset = this.props.dataset;
        }
        else{
            dataset = [this.props.dataset[show]]
        }
        return (
            <div>
                <div className="select select-graph">
                    <select
                        className=""
                        name="period"
                        id="periodSelect"
                        onChange={this.changeShow}
                        style={{ color: this.props.config.color.principalText }}
                    >
                        <option value="-1">All</option>
                        <option value="0">{dictionary.newMembers}</option>
                        <option value="1">{dictionary.renewal}</option>
                        <option value="2">{dictionary.coins}</option>
                        <option value="3">Subtotal</option>
                    </select>
                </div>
                <div className="sales-graph">
                    <Line
                        height={250}
                        data={{
                            labels: this.props.periodEarningDays,
                            datasets: dataset
                        }}
                        options={this.state.options}
                    />
                </div>
                <div className="legends">
                    <label className="left">
                        <span
                            className="dot"
                            style={{backgroundColor: "#692FE1"}}
                        ></span>
                        {dictionary.newMembers}
                    </label>
                    <label className="right">
                        <span
                            className="dot"
                            style={{backgroundColor: "#E39DCD"}}
                        ></span>
                        {dictionary.renewal}
                    </label>
                    <label className="left">
                        <span
                            className="dot"
                            style={{backgroundColor: "#5AB6E2"}}
                        ></span>
                        {dictionary.coins}
                    </label>
                    <label className="right">
                        <span
                            className="dot"
                            style={{backgroundColor: "#5AE2B1"}}
                        ></span>
                        Subtotal
                    </label>
                </div>
                <p className="advice">
                    {this.props.dictionary.warningEarning}
                    <a href="https://reports.epoch.com">
                        &nbsp;https://reports.epoch.com
                    </a>
                </p>
            </div>
        );
    }
}

export default Graph;
