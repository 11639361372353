import React from 'react';
import {Link} from 'react-router-dom';
import MessagesIcon from '../../../Svg/MessagesIcon';

const HeadItemsLogged = ({dictionary, config, streaming, permission}) => {
    let modalLogo;
    if (config.secondLogo) {
        modalLogo = config.secondLogo;
    } else {
        modalLogo = config.logo;
    }
    return (
        <div>
           { !streaming || permission === 1 ? <button
                title={dictionary.profile}
                className="loginMenu-btn"
            >
                <Link to="/messages">
                    <MessagesIcon
                        color="#fff"
                        width={"27px"}
                        height={"27px"}
                    />
                </Link>
            </button> : null}
            <div className="navImgLogged">
                <img alt="navLogo" className="navLogo" src={config.storageURL + modalLogo}/>
            </div>
        </div>
    )
};

export default HeadItemsLogged;
