import React, {Component} from 'react';
import CancelMembershipSteps from '../../../containers/Support/Blog/CancelMembership/CancelMembershipSteps';
import Modal from '../../Modal';
import './style.css';

class SupportModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            modalAction : this.props.modalAction ? this.props.modalAction : null
        }
    }
    componentDidMount(){
        this.setState({
            modalAction : this.props.modalAction
        });
    }
    drawModalSupport(){
        let dictionary = this.props.dictionary;
        let question = this.props.optionSupp;
        let modalOptions = {
            support : {
                buttonAccept : dictionary.yes,
                buttonDeny: dictionary.no,
                head : this.props.subject,
                action :this.props.openOther,
                body :
                    <div>
                        {question === 1 ?
                            <ol>
                                <li>
                                    {dictionary.FAQ.q1p1}
                                </li>
                                <li>
                                    {dictionary.FAQ.q1p2}
                                </li>
                                <li>
                                    {dictionary.FAQ.q1p3}
                                    <ul className="sublist">
                                        <li>
                                            {dictionary.FAQ.q1p4}
                                        </li>
                                        <li>
                                            {dictionary.FAQ.q1p5}
                                        </li>
                                        <li>
                                            {dictionary.FAQ.q1p6}
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    {dictionary.FAQ.q1p7}
                                </li>
                            </ol>
                        : question === 2 ?
                            <div>
                                <p>
                                    {dictionary.FAQ.q8p1}
                                </p>
                                <ol>
                                    <li>
                                        {dictionary.FAQ.q8p2}
                                    </li>
                                    <li>
                                        {dictionary.FAQ.q8p3}
                                    </li>
                                </ol>
                                <p>
                                    {dictionary.FAQ.q8p4}
                                </p>
                                <ol  >
                                    <li>
                                        {dictionary.FAQ.q8p5}
                                    </li>
                                    <li>
                                        {dictionary.FAQ.q8p6}
                                    </li>
                                    <li>
                                        {dictionary.FAQ.q8p7}
                                    </li>
                                </ol>
                                <p>
                                    {dictionary.FAQ.q8p8}
                                </p>
                                <ol>
                                    <li>
                                        {dictionary.FAQ.q8p9}
                                    </li>
                                    <li>
                                        {dictionary.FAQ.q8p10}
                                    </li>
                                    <li>
                                        {dictionary.FAQ.q8p11}
                                    </li>
                                </ol>
                            </div>
                        : question === 3 ?
                            <p>
                                {dictionary.FAQ.q2p1}.
                            </p>
                        : question === 4 ?
                            <ol>
                                <li>
                                    {dictionary.FAQ.q3p1}.
                                </li>
                                <li>
                                    {dictionary.FAQ.q3p2}.
                                </li>
                                <li>
                                    {dictionary.FAQ.q3p3}.
                                </li>
                                <li>
                                    {dictionary.FAQ.q3p4}.
                                </li>
                                <li>
                                    {dictionary.FAQ.q3p5}.
                                </li>
                            </ol>
                        : question === 5 ?
                            <ol>
                                <li>
                                    {dictionary.FAQ.q4p1}.
                                </li>
                                <li>
                                    {dictionary.FAQ.q4p2}.
                                </li>
                                <li>
                                    {dictionary.FAQ.q4p3}.
                                </li>
                                <li>
                                    {dictionary.FAQ.q4p4}
                                </li>
                                <li>
                                    {dictionary.FAQ.q4p5}.
                                </li>
                                <li>
                                    {dictionary.FAQ.q4p6}.
                                </li>
                            </ol>
                        : question === 6 ?
                            <CancelMembershipSteps
                                dictionary={this.props.dictionary}
                                config={this.props.config}
                                getPiCodes={this.props.getPiCodes}
                            />
                        : null
                        }
                    </div>
            }
        }
        let option = this.state.modalAction;
        let config = this.props.config;
        return (
            option ?
                <Modal
                    id={"SupportPanelModal"}
                    open={this.props.showModal}
                    closeAction={this.props.closeModal}
                    head={<h4>{modalOptions[option].head}</h4>}
                    config={config}
                    footer={
                        <div className='center-align actions'>
                            <div className="useful">{dictionary.infoUseful}</div>
                            <button
                                style={{
                                    backgroundColor:config.color.secondary,
                                    color: config.color.secondaryText
                                }}
                                disabled={this.state.disabled}
                                onClick={this.props.closeModal}
                                className="half-column-btn"
                            >
                                {modalOptions[option].buttonAccept}
                            </button>
                            <button
                                style={{
                                    backgroundColor:config.color.principalText,
                                    color: config.color.principal
                                }}
                                disabled={this.state.disabled}
                                onClick={(e) => modalOptions[option].action(e)}
                                className="half-column-btn"
                            >
                                {modalOptions[option].buttonDeny}
                            </button>
                        </div>
                    }
                    height={this.props.height}
                    top={this.props.top}
                    middle={this.props.middle}
                    levelModal={this.props.levelModal}
                    levelContainer={this.props.levelContainer}
                >
                    {modalOptions[option].body}
                </Modal>
                :
                null
        )
    }
    render() {
        return(
            <div className="modal-SupportModal">
                {this.props.showModal ?
                    this.drawModalSupport()
                : null }
            </div>

        )
    }
}

export default SupportModal;
