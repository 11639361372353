import React, {Component} from 'react';
import Posts from "../../components/Feed/Posts";
import axios from "axios";
import Promise from 'bluebird';
import Toolbox from "../../components/Toolbox/Toolbox";
import Streaming from "../Streaming";
import Sections from '../../components/Feed/Sections';
import './style.css';
import Icon from "react-ionicons";
import {Circle} from "react-preloaders";
import ReactPixel from 'react-facebook-pixel';
import cookie from 'js-cookie';
import moment from 'moment';
import ConfirmPurchaseModal from '../../components/ConfirmPurchaseModal';

class NewFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            posts: [],
            section: '',
            offset: 0,
            sections: [],
            modelInfo: {},
            promo: null,
            keyCountry: "US",
            picodes: [],
            getProgress: [],
            showUpBtn: false,
            intervalGetProcessing: null,
            deletingPost: false
        }
        this.updatePosts = this.updatePosts.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling)
    }

    componentDidMount() {
        const url_string = window.location.href;
        const url = new URL(url_string);
        if (url.pathname === '/streaming') {
            new Promise(async (resolve) => {
                resolve(await this.getImagesSection());
            }).then(async () => {
                this.setState({
                    section: "streaming",
                    loading: false,
                });
            });
        } else {
            document.addEventListener("scroll", this.trackScrolling);
            const params = Toolbox.searchParams();
            this.pixelAnalitics(params);
            if (params.postProgress)
                this.progressVideo(params.postProgress)
                    .catch(err => console.log('Feed ProgressVideo ', err));

            let promo = params.promo;
            let nextState = {
                section: params.section ? params.section : "",
            };
            if (promo)
                nextState.promo = "/?promo=" + promo;

            this.setState(nextState);
            this.getModelInfo();
            if (!this.props.view) {
                new Promise((resolve) => {
                    resolve(this.getLocation());
                })
                    .then(async (keyCountry) => {
                        await this.setState({keyCountry: keyCountry, promo: promo});
                    })
                    .then(async () => {
                        await this.getPiCodes(this.state.keyCountry, this.state.promo);
                    });
            }
            new Promise(async (resolve) => {
                await this.getImagesSection();
                return resolve(true);
            }).then(async () => {
                let section = this.state.section
                    ? decodeURI(this.state.section)
                    : this.state.sections[0].sectionName;
                let processingPosts = await this.getProcessingOrErrorPost();
                let posts = await this.getPosts(0, section);

                let postProcessingSize = 0;

                if (processingPosts.length) {
                    //sorry for the mess :v (cairo 2020-12-01) <3
                    let intervalGetProcessing = setInterval(async () => {
                        let processingInterval = await this.getProcessingOrErrorPost();
                        if (!processingInterval.length) {
                            this.setState({intervalGetProcessing: clearInterval(intervalGetProcessing)});
                            await this.loadMorePosts(true);
                            return false;
                        }
                        let post = [...this.state.posts];
                        if (postProcessingSize !== processingInterval.length) {
                            postProcessingSize = processingInterval.length;
                            let section = this.state.section
                                ? decodeURI(this.state.section)
                                : this.state.sections[0].sectionName;

                            setTimeout(async () => {
                                let posts = await this.getPosts(0, section);
                                posts = [...processingInterval, ...posts];
                                post = post.map((item, index) => {
                                    if (index < posts.length) {
                                        return item = posts[index];
                                    } else return item;
                                });
                                this.setState({
                                    posts: post,
                                    offset: post.length,
                                    loading: false,
                                    section: this.state.sections[0].sectionName,
                                });
                            }, 5000);
                        }
                        processingInterval.forEach((item, index) => {
                            if (item.processing === 2) {
                                let ms = moment().diff(item.ordering);
                                let d = moment.duration(ms);
                                let minutes = d.asMinutes();
                                if (minutes > 30) {
                                    //delete post
                                    if (!this.state.deletingPost) {
                                        this.delPost(item.id_post);
                                    }
                                }
                            }
                        });
                    }, 15000);
                    this.setState({
                        intervalGetProcessing
                    });
                }

                posts = [...processingPosts, ...posts];

                this.setState({
                    posts: posts,
                    offset: 5,
                    loading: false,
                    section: this.state.sections[0].sectionName,
                });
            });
        }
    }

    async delPost(idPost) {
        this.setState({deletingPost: true});
        await axios.post('/api/v2/deletePost', {
            idPost,
            error: true
        });
        this.setState({deletingPost: false});
    }

    pixelAnalitics = (url) => {
        if (url.ans && url.ans[0] === 'Y' && url.trans_amount_usd) {
            const transAmountUsd = url.trans_amount_usd;
            if (this.props.config.pixel) {
                const advancedMatching = {
                    em: cookie.get('email')
                };
                const options = {
                    autoConfig: true,
                    debug: false,
                };
                ReactPixel.init(this.props.config.pixel, advancedMatching, options);
                ReactPixel.track('Purchase', {currency: "USD", value: transAmountUsd});
            }
        }
    };

    progressVideo = async (postProgress) => {
        let intervalProcessingVideo;
        if (postProgress) {
            intervalProcessingVideo = setInterval(() => {
                this.getProgress(postProgress, intervalProcessingVideo)
            }, 3000);
        }
    }

    getProgress = async (postProgress, intervalProcessingVideo) => {
        const getProgress = await axios
            .get("/api/v2/videoProcessig/" + postProgress, {
                headers: {'Cache-Control': 'no-cache'},
            })
            .then((getProgress) => {
                return getProgress.data;
            });
        let countFinish = 0;
        if (getProgress) {
            getProgress.map((item, index) => {
                if (item.info === 'finish') {
                    return countFinish++;
                } else {
                    return false;
                }

            });
        }
        this.setState({getProgress: getProgress});
        if (countFinish === getProgress.length || !getProgress) {
            clearInterval(intervalProcessingVideo)
            setTimeout(() => {
                window.location = '/posts?section=' + this.state.section;
            }, 1000);
        }

    }

    updateOffset = (updated) => {
        let offset = this.state.offset;
        this.setState({offset: (offset + updated)})
    }
    updatePosts = (posts) => {
        this.setState({
            loading: true,
            posts: []
        });
        setTimeout(() => {
            this.setState({
                posts: posts,
                loading: false,
            });
        }, 700);
    }
    /**
     *
     * @returns {Promise<AxiosResponse<T>>}
     */
    getLocation = async () => {
        return await axios.get('https://us-central1-private-social-media.cloudfunctions.net/locate')
            .then(res => {
                if (res.data.error)
                    return false;
                return res.data.country;
            });
    };
    /**
     *
     */
    getModelInfo = () => {
        axios.post('/api/v2/modelInfo').then(async (res) => {
            this.setState({modelInfo: res.data})
        })
    }
    /**
     *
     */
    trackScrolling = (e) => {
        const wrappedElement = document.querySelector('.main-container');
        let scroll = window.scrollY;

        if (scroll > 0 && !this.state.loading && !this.state.showUpBtn)
            this.setState({showUpBtn: true});

        if (scroll <= 0 && this.state.showUpBtn)
            this.setState({showUpBtn: false});

        if (wrappedElement && this.isBottom(wrappedElement)) {
            this.loadMorePosts();
        }
    }

    isBottom(el) {
        return Math.floor(el.getBoundingClientRect().bottom) <= window.innerHeight + (window.innerHeight * .75);
    }

    /**
     * @param numOffset
     * @param section
     * @returns {Promise<T>}
     */
    getPosts = async (numOffset, section) => {
        const offset =
            {
                offset: numOffset,
                section: section,
                membership: this.props.view
            }
        return await axios.post('/api/v2/getPost', offset)
            .then(async res => {
                return res.data;
            }).catch(err => {
                return []
            });
    };
    getProcessingOrErrorPost = async () => {
        return await axios.get('/api/v2/get/post/processing')
            .then(async res => {
                return res.data;
            }).catch(err => {
                return [];
            });
    };
    /**
     * @param section
     * @returns {Promise<void>}
     */
    changeSection = async (section) => {
        if (this.state.section !== section) {
            await this.setState({
                posts: [],
                section: section
            });
            let posts = await this.getPosts(0, section);
            let processingPosts = await this.getProcessingOrErrorPost();
            posts = [...processingPosts, ...posts];
            this.setState({
                posts: posts,
                offset: 5,
                loading: false
            });
        }
    }

    /**
     *
     * @returns {Promise<void>}
     */
    loadMorePosts = async (noMoreOffset = false) => {
        let offset = noMoreOffset ? 0 : this.state.offset;
        let section = this.state.section;
        if (!this.state.loading) {
            this.setState({loading: true});
            let posts = await this.getPosts(offset, section);
            let copyPosts = [...this.state.posts];
            let newPosts = [];
            if (noMoreOffset) newPosts = posts;
            else newPosts = copyPosts.concat(posts);
            this.setState({
                posts: newPosts,
                offset: noMoreOffset ? offset : offset + 5,
                loading: false
            });
        }
    }

    /**
     *
     * @returns {Promise<void>}
     */
    getImagesSection = async () => {
        await axios.post('/api/v2/getImagesSection')
            .then(async res => {
                this.setState({sections: res.data});
            });
    };

    /**
     *
     * @param keyCountry
     * @param promo
     * @returns {Promise<void>}
     */
    getPiCodes = async (keyCountry, promo) => {
        axios.post('/api/v2/picode', {
            promo: promo,
            location: keyCountry
        })
            .then(res => {
                const detailsmembership = res.data;
                this.setState({
                    picodes: detailsmembership
                });
            });
    }

    scrollToUp() {
        window.scrollTo(0, 0);
    }

    render() {

        let showUpBtn = this.state.showUpBtn && this.state.section !== "streaming" // facepalm
            ?
            <button
                id="postUp-btn"
                style={{
                    backgroundColor: this.props.config.color.secondary
                }}
                onClick={this.scrollToUp}
                className={'post-up-btn'}
            >
                <Icon
                    icon="ios-arrow-up"
                    color={this.props.config.color.principalText}
                    className="postUp-icon"
                />
            </button>
            : null;

        let loading = this.state.loading
            ? <div className="center-align">
                <Circle background={"transparent !important"} color={this.props.config.color.secondary}
                        className="loaderPost"/>
            </div>
            : null;

        return (
            <>
                {showUpBtn}
                <div
                    className="container main-container"
                    style={{
                        backgroundColor: this.props.config.color.principal,
                        color: this.props.config.color.principalText,
                    }}
                >
                    <Sections
                        sections={this.state.sections}
                        active={this.state.section}
                        config={this.props.config}
                        dictionary={this.props.dictionary}
                        changeSection={this.changeSection}
                    />
                    {this.state.section === "streaming" ? (
                        <Streaming
                            sections={this.state.sections}
                            toElevate={this.props.toElevate}
                            dictionary={this.props.dictionary}
                            config={this.props.config}
                            view={this.props.view}
                            streamingMdl={this.props.streamingMdl}
                        />
                    ) : (
                        <Posts
                            toElevate={this.props.toElevate}
                            posts={this.state.posts}
                            config={this.props.config}
                            dictionary={this.props.dictionary}
                            model={this.state.modelInfo}
                            view={this.props.view}
                            picodes={this.state.picodes}
                            getCoins={this.props.getCoins}
                            updateOffset={this.updateOffset}
                            isSafari={this.props.isSafari}
                            isIOS={this.props.isIOS}
                            getProgress={this.state.getProgress}
                            updatePosts={this.updatePosts}
                            statusModel={this.props.statusModel}
                            middle={true}
                        />
                    )}
                    {loading}
                </div>
                <div className="open-fullscreen" id="divfullscr"/>
                <ConfirmPurchaseModal
                    toElevate={this.props.toElevate}
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                />
            </>
        );
    }
}

export default NewFeed;
