import React, {Component} from 'react';
import axios from "axios";
import Modal from "../../../Modal";
import GetCoins from "../../../GetCoins";
import NotAvailable from "../../../NotAvailable";
import "./style.css";
import CoinsIcon from '../../../Svg/CoinsIcon';

class DonatePost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            handler: true,
            coins: 10,
            modalBuy: false,
            modalNotAvailable: false
        }
        this.handleCoinChange = this.handleCoinChange.bind(this)
    }

    handleCoinChange(e) {
        let value = e.target.value;
        this.setState({coins: value})
    }

    closeModalBuy = () => {
        this.setState({modalBuy: false})
    }

    closeModalNotAvailable = () => {
        this.setState({modalNotAvailable: false})
    }

    doDonation = async (e) => {
        e.preventDefault();
        let coins = this.state.coins;
        let post = this.props.post;
        axios.post('/api/v2/spend/credit/donation/post', {
            amount: coins,
            postId: post.id_post
        })
            .then(resp => {
                if (resp.data.valid) {
                    if (post.donations)
                        post.donations = parseInt(post.donations) + parseInt(coins);
                    else
                        post.donations = coins;
                    this.props.updateCoins(resp.data.newCoinCredit)
                    this.props.toElevate.spendCoins();
                    this.props.closeModalDonate();
                }
                if (resp.data.error === "No credit") {
                    this.props.closeModalDonate();
                    this.setState({modalBuy: true})
                }
                if (resp.data.error === "Precio not found") {
                    this.props.closeModalDonate();
                    this.setState({modalNotAvailable: true})
                }
            })
    }

    render() {
        let coins = this.props.currentCoins;
        let dictionary = this.props.dictionary;
        let config = this.props.config;
        return (
            <div>
                <Modal
                    id="modalDonate"
                    head={<div>{dictionary.send} {dictionary.coins}</div>}
                    config={this.props.config}
                    footer={
                        <div className="actions">
                            <button
                                style={{
                                    backgroundColor: config.color.secondary,
                                    color: config.color.secondaryText
                                }}
                                onClick={(e) => this.doDonation(e)}
                            >
                                {dictionary.agree}
                            </button>
                        </div>

                    }
                    open={this.props.showModalDonate}
                    closeAction={this.props.closeModalDonate}
                    middle={this.props.middle}
                    height={this.props.height}
                    top={this.props.top}
                >
                    <div className="input-donate">
                        <div className="label-input-donate">
                            <CoinsIcon
                                color={config.color.secondary}
                                colorPrincipal={config.color.principal}
                                width={"21px"}
                                height={"21px"}
                            />
                            {dictionary.uHave}
                            <div 
                                className="coins-number"
                                style={{color: this.props.config.color.secondary}}
                            >
                                {coins}
                            </div>
                            {dictionary.coins}
                        </div>
                        
                        <div style={{textAlign: 'center'}}>
                            <input
                                value={this.state.coins}
                                onChange={this.handleCoinChange}
                                type="number"
                                className="input-donate-input"
                                style={{color: config.color.modalText}}
                            />
                            <sub>* {dictionary.writeCoinsToDonate}</sub>
                        </div>
                    </div>

                </Modal>
                <GetCoins
                    open={this.state.modalBuy}
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                    close={this.closeModalBuy}
                    middle={true}
                    height={'67%'}
                    top={'33%'}
                />
                <NotAvailable
                    open={this.state.modalNotAvailable}
                    dictionary={this.props.dictionary}
                    config={this.props.config}
                    close={this.closeModalNotAvailable}
                />
            </div>
        )
    }
}

export default DonatePost;
