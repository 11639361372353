import React from 'react';

const MailIcon= ({color, width, height, className = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 16 13"
        >
            <g 
                stroke={color}
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="1.1" 
                opacity=".76"
            >
                <path 
                    d="M13.278 11.889H2.722c-.951 0-1.722-.77-1.722-1.722V2.722C1 1.771 1.77 1 2.722 1h10.557C14.229 1 15 1.77 15 2.722v7.446c0 .95-.77 1.72-1.722 1.72v0z"
                />
                <path 
                    d="M1 2.556l5.979 4.512c.708.535 1.687.522 2.381-.03L15 2.557M10.225 6.351l4.76 3.967M1.016 10.318L5.9 6.25"
                />
            </g>
        </svg>
    )
}
export default MailIcon;
